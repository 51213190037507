/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: any, ignoreSvg?: boolean) => {
	const ref = useRef<any>(null);

	useEffect(() => {
		const handleClick = (event: any) => {
			if (!ref.current || ref.current.contains(event.target)) return;
			if (ignoreSvg && ['path', 'svg'].includes(event.target.tagName))
				return;
			callback();
		};

		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [ref, callback]);

	return ref;
};
