import { Button, Form, Input } from 'antd';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Content } from '../../../UI/template/account-form';
import { IUseVerificationCode } from '../hook/use-verification-code';

const title = 'Ingrese el código de verificación que hemos enviado a su email';

const VerificationCodeForm = ({
	loading,
	onSubmit,
	resendConfirmationCode
}: IUseVerificationCode) => {
	const intl = useIntl();
	return (
		<Container>
			<motion.div layout>
				<Content title={title}>
					<Form onFinish={onSubmit}>
						<Form.Item
							name="verificationCode"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.requiredField',
										description: 'Form required field',
										defaultMessage: 'Campo requerido'
									})
								}
							]}
						>
							<Input
								size="large"
								placeholder={intl.formatMessage({
									id: 'verificationCode.inputVerificatinCode',
									defaultMessage: 'Código de verificación'
								})}
								disabled={loading}
							/>
						</Form.Item>
						<Form.Item>
							<Button
								htmlType="submit"
								size="large"
								type="primary"
								loading={loading}
								block
							>
								<FormattedMessage
									id="verificationCode.validateCode"
									defaultMessage="Validar código"
								/>
							</Button>
						</Form.Item>
						<Form.Item style={{ textAlign: 'right' }}>
							<Button
								disabled={loading}
								onClick={resendConfirmationCode}
								ghost
								type="primary"
								htmlType="button"
							>
								<FormattedMessage
									id="verificationCode.resendCode"
									defaultMessage="Reenviar código"
								/>
							</Button>
						</Form.Item>
					</Form>
				</Content>
			</motion.div>
		</Container>
	);
};

export default VerificationCodeForm;
