/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	fetchAddWaitList,
	fetchGetApps,
	fetchGetAppStatus,
	fetchGetCategory,
	fetchGetConnection,
	fetchGetPaymentMethods,
	fetchLatamCountries,
	fetchPatchApp,
	fetchRemoveWaitList,
	fetchUserWaitList
} from './thunks';

export const spaSlice = createSlice({
	name: 'spa',
	initialState,
	reducers: {
		setLoadingUserWaitList: (state, action) => {
			state.loadingUserWaitList = action.payload;
		},
		clearUserWaitList: (state) => {
			state.userWaitList = [];
		},
		setLoadingRemoveUserWaitList: (state, action) => {
			state.loadingRemoveUserWaitList = action.payload;
		},
		setTrialUpdate: (state, { payload: { appId, trialApp } }: any) => {
			const apps = [...state.filterApps.data];
			const indexApp = apps.findIndex((app) => app._id === appId);
			if (indexApp > -1) {
				apps[indexApp].trialPeriod = trialApp;
			}
			state.filterApps.data = apps;
		},
		setAppTax: (state, { payload: { appId, key, value } }) => {
			const apps = [...state.filterApps.data];
			const indexApp = apps.findIndex((app) => app._id === appId);
			if (indexApp > -1) {
				apps[indexApp][key] = value;
			}
			state.filterApps.data = apps;
		},
		cleanSuccessSpa: (state: any, action) => {
			state.success[action.payload] = false;
		},
		cleanErrorSpa: (state: any, action) => {
			state.error[action.payload] = false;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGetCategory.fulfilled, (state, { payload }) => {
				state.filterCategoryLoading = 'succeeded';
				state.filterCategoryError = '';
				state.filterCategory = payload;
			})
			.addCase(fetchGetCategory.pending, (state) => {
				state.filterCategoryLoading = 'pending';
				state.filterCategoryError = '';
				state.filterCategory = [];
			})
			.addCase(fetchGetCategory.rejected, (state, { payload }) => {
				state.filterCategoryLoading = 'failed';
				state.filterCategoryError = String(payload);
				state.filterCategory = [];
			})

			.addCase(fetchGetConnection.fulfilled, (state, action) => {
				state.filterConnection = action.payload;
				state.filterConnectionLoading = 'succeeded';
				state.filterConnectionError = '';
			})
			.addCase(fetchGetConnection.rejected, (state, { payload }) => {
				state.filterConnection = [];
				state.filterConnectionLoading = 'failed';
				state.filterConnectionError = String(payload);
			})
			.addCase(fetchGetConnection.pending, (state) => {
				state.filterConnection = [];
				state.filterConnectionLoading = 'pending';
				state.filterConnectionError = '';
			})

			.addCase(fetchGetAppStatus.fulfilled, (state, action) => {
				state.filterAppStatus = action.payload;
				state.filterAppStatusLoading = 'succeeded';
				state.filterAppStatusError = '';
			})
			.addCase(fetchGetAppStatus.rejected, (state, { payload }) => {
				state.filterAppStatus = [];
				state.filterAppStatusLoading = 'failed';
				state.filterAppStatusError = String(payload);
			})
			.addCase(fetchGetAppStatus.pending, (state) => {
				state.filterAppStatus = [];
				state.filterAppStatusLoading = 'pending';
				state.filterAppStatusError = '';
			})

			.addCase(fetchGetPaymentMethods.fulfilled, (state, action) => {
				state.filterPaymentMethods = action.payload;
				state.filterPaymentMethodsLoading = 'succeeded';
				state.filterPaymentMethodsError = '';
			})
			.addCase(fetchGetPaymentMethods.rejected, (state, { payload }) => {
				state.filterPaymentMethods = [];
				state.filterPaymentMethodsLoading = 'failed';
				state.filterPaymentMethodsError = String(payload);
			})
			.addCase(fetchGetPaymentMethods.pending, (state) => {
				state.filterPaymentMethods = [];
				state.filterPaymentMethodsLoading = 'pending';
				state.filterPaymentMethodsError = '';
			})

			.addCase(fetchGetApps.rejected, (state, action) => {
				state.filterAppsLoading = 'failed';
				state.filterApps = undefined;
			})
			.addCase(fetchGetApps.pending, (state, action) => {
				state.filterAppsLoading = 'pending';
				state.filterApps = undefined;
			})
			.addCase(fetchGetApps.fulfilled, (state, action) => {
				state.filterAppsLoading = 'succeeded';
				state.filterApps = action.payload;
			})
			.addCase(fetchPatchApp.pending, (state, action) => {
				state.loadingPatchApp = 'pending';
			})
			.addCase(fetchPatchApp.fulfilled, (state, action) => {
				if (action.payload.data) {
					Object.keys(action.payload.data).map((key) => {
						state.filterApps.data[action.payload.index][key] =
							action.payload.data[key];
					});
					state.success = { ...state.success, patchApp: true };
				} else state.error = { ...state.error, patchApp: false };

				state.loadingPatchApp = 'succeeded';
			})
			.addCase(
				fetchUserWaitList.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.userWaitList = action.payload;
				}
			)
			.addCase(fetchAddWaitList.pending, (state, action) => {
				state.loadingUserWaitList = 'pending';
			})
			.addCase(fetchAddWaitList.fulfilled, (state, action) => {
				state.loadingUserWaitList = 'succeeded';
				const waitList = state.userWaitList
					? current(state.userWaitList)
					: [];
				if (!waitList.find((e: any) => e._id === action.payload._id)) {
					state.userWaitList = [...waitList, action.payload];
				}
			})
			.addCase(fetchAddWaitList.rejected, (state, action) => {
				state.loadingUserWaitList = 'failed';
			})
			.addCase(fetchRemoveWaitList.pending, (state) => {
				state.loadingRemoveUserWaitList = 'pending';
			})
			.addCase(fetchRemoveWaitList.fulfilled, (state, action) => {
				state.loadingRemoveUserWaitList = 'succeeded';
				state.userWaitList = state.userWaitList?.filter(
					(e: any) => e._id !== action.payload
				);
			})
			.addCase(fetchRemoveWaitList.rejected, (state) => {
				state.loadingRemoveUserWaitList = 'failed';
			})
			.addCase(fetchLatamCountries.pending, (state) => {
				state.loadingCountries = 'pending';
			})
			.addCase(fetchLatamCountries.fulfilled, (state, action) => {
				state.loadingCountries = 'succeeded';
				state.countries = action.payload;
			});
	}
});

export const {
	setLoadingUserWaitList,
	clearUserWaitList,
	setLoadingRemoveUserWaitList,
	cleanSuccessSpa,
	cleanErrorSpa,
	setTrialUpdate,
	setAppTax
} = spaSlice.actions;
