import { PartnerIntegration } from '@hospy/store';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import PartnerListItem from './partner-list-item';

const ListVariants = {
	show: {
		transition: {
			staggerChildren: 0.07,
			delayChildren: 0.2
		}
	},
	hidden: {
		transition: {
			staggerChildren: 0.07,
			delayChildren: 0.2
		}
	}
};

interface PartnerListProps {
	list: PartnerIntegration[];
	onSelect: (data: PartnerIntegration) => void;
}
export const PartnerList = ({ list, onSelect }: PartnerListProps) => {
	return (
		<List initial={'hidden'} animate={'show'} variants={ListVariants}>
			{list.map((item, index) => (
				<PartnerListItem key={index} data={item} onSelect={onSelect} />
			))}
		</List>
	);
};

const List = styled(motion.ul)`
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	gap: 30px;
`;
