import { IUserState } from './user.interfaces';

export const initialState = {
	user: undefined, // Datos del usuario registrado
	isAuth: false, // Flag para identificar si el usuario esta o no autenticado
	propertyId: undefined, // Propiedad en la que el usuario esta actualmente
	property: undefined, // Información completa de la propiedad actual
	propertyIntegrations: undefined, // Integraciones asociadas a la propiedad actual
	propertyIdToUpdate: undefined, // Propiedad seleccionada para actualizar desde gestión de propiedades
	addProperty: {
		loading: 'idle',
		error: ''
	},
	updateProperty: {
		loading: 'idle',
		error: ''
	},
	deleteProperty: {
		loading: 'idle',
		error: ''
	},
	users: {
		loading: 'idle',
		error: '',
		data: []
	},
	exportsCsvUsers: {
		loading: 'idle',
		error: ''
	},
	updateUserForAdmin: {
		loading: 'idle',
		error: ''
	},
	theme: 'light',
	loading: 'idle',
	loading2: 'idle',
	currentProperty: undefined,
	success: {
		verifyEmailOrPhoneUpdate: false,
		fetchUpdate: false
	},
	error: {
		verifyEmailOrPhoneUpdate: undefined,
		fetchUpdate: undefined
	},
	appName: undefined,
	propertyIntegrationsState: {
		loading: 'idle',
		data: undefined,
		error: ''
	},
	updatedPropertyIntegration: {
		loading: 'idle',
		data: undefined,
		error: ''
	}
} as IUserState;
