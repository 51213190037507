/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AccountSliceName, initialState } from './constants';
import {
	ThunkCreateStampBulk,
	ThunkFindOneInternalAccountInvoice,
	deleteAccountingInvoice,
	getAccountingDestinyTaxes,
	getAccountingInvoice,
	getAccountingOriginTaxes,
	getAccountingPaymentMethods,
	joinItems,
	partnerUpdateLocalInvoice,
	updateAccountingInvoice
} from './thunks';
import { NON_BILLABLE_PRODUCT_CODE } from '../../sat/sat.constants';
import { ItemBillingStatus } from './interface';

export const partnerAccountingSlice = createSlice({
	name: AccountSliceName,
	initialState,
	reducers: {
		setInvoice: (state, { payload }) => {
			state.invoice = payload;
		},
		setInvoiceData: (state, { payload }) => {
			state.invoice.data = payload;
		},
		setLoadingPartnerAccounting: (state: any, { payload }) => {
			state[payload.state].loading = payload.loading;
		},
		resetInvoice: (state) => {
			state.invoice = initialState.invoice;
		},
		setInvoiceToBulk: (state, { payload }) => {
			state.reservationToBulk = payload;
		},
		resetStampBulkInvoices: (state) => {
			state.stampBulk = initialState.stampBulk;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAccountingPaymentMethods.pending, (state, { meta }) => {
				const stateName =
					meta.arg.type === 'internal'
						? 'paymentMethodsInternal'
						: 'paymentMethodsExternal';

				state[stateName] = {
					loading: 'pending',
					error: undefined,
					data: []
				};
			})
			.addCase(
				getAccountingPaymentMethods.rejected,
				(state, { payload, meta }) => {
					const stateName =
						meta.arg.type === 'internal'
							? 'paymentMethodsInternal'
							: 'paymentMethodsExternal';
					state[stateName] = {
						loading: 'failed',
						error: String(payload),
						data: []
					};
				}
			)
			.addCase(
				getAccountingPaymentMethods.fulfilled,
				(state, { payload, meta }) => {
					const stateName =
						meta.arg.type === 'internal'
							? 'paymentMethodsInternal'
							: 'paymentMethodsExternal';
					state[stateName] = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(getAccountingInvoice.pending, (state) => {
				state.invoice.loading = 'pending';
				state.updatedInvoice.loading = 'idle';
			})
			.addCase(getAccountingInvoice.rejected, (state, { payload }) => {
				state.invoice.loading = 'failed';
				state.invoice.error = payload;
			})
			.addCase(
				getAccountingInvoice.fulfilled,
				(state, { payload }: any) => {
					let defaultNote = '';
					const isThereNonBillableProduct = payload.items.find(
						(item: any) => item.type === NON_BILLABLE_PRODUCT_CODE
					);
					if (isThereNonBillableProduct)
						defaultNote = `Propinas y otras adendas ${isThereNonBillableProduct.price.toLocaleString(
							'es-MX',
							{
								style: 'currency',
								currency: 'MXN'
							}
						)}`;

					state.invoice.data = {
						...payload,
						items: payload.items.map((item: any) => ({
							...item,
							selectedToBill:
								item.type !== NON_BILLABLE_PRODUCT_CODE &&
								item.active &&
								(item.billingStatus ===
									ItemBillingStatus.NOT_BILLED ||
									!item.billingStatus)
						})),
						notes: defaultNote
					};
					state.invoice.loading = 'succeeded';
				}
			);

		builder
			.addCase(updateAccountingInvoice.pending, (state) => {
				state.updatedInvoice.loading = 'pending';
			})
			.addCase(
				updateAccountingInvoice.rejected,
				(state, payload: any) => {
					state.updatedInvoice.loading = 'failed';
					state.updatedInvoice.error = payload;
				}
			)
			.addCase(
				updateAccountingInvoice.fulfilled,
				(state, { payload }: any) => {
					state.updatedInvoice.data = payload;
					state.invoice.data = payload;
					state.updatedInvoice.loading = 'succeeded';
				}
			);

		builder
			.addCase(deleteAccountingInvoice.pending, (state) => {
				state.deletedInvoice.loading = 'pending';
			})
			.addCase(
				deleteAccountingInvoice.rejected,
				(state, payload: any) => {
					state.deletedInvoice.loading = 'failed';
					state.deletedInvoice.error = payload;
				}
			)
			.addCase(
				deleteAccountingInvoice.fulfilled,
				(state, { payload }: any) => {
					state.deletedInvoice.data = payload;
					state.invoice = {
						...state.invoice,
						data: payload
					};
					state.deletedInvoice.loading = 'succeeded';
				}
			);

		builder
			.addCase(getAccountingOriginTaxes.pending, (state: any) => {
				state.taxes.loading = 'pending';
			})
			.addCase(
				getAccountingOriginTaxes.rejected,
				(state: any, payload: any) => {
					state.taxes.loading = 'failed';
					state.taxes.error = payload;
				}
			)
			.addCase(
				getAccountingOriginTaxes.fulfilled,
				(state: any, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						label: taxes.name
					}));
					state.taxes.data = payload;
					state.taxes.loading = 'succeeded';
				}
			);

		builder
			.addCase(getAccountingDestinyTaxes.pending, (state: any) => {
				state.destinyTaxes.loading = 'pending';
			})
			.addCase(
				getAccountingDestinyTaxes.rejected,
				(state: any, payload: any) => {
					state.destinyTaxes.loading = 'failed';
					state.destinyTaxes.error = payload;
				}
			)
			.addCase(
				getAccountingDestinyTaxes.fulfilled,
				(state: any, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						name: taxes.name
					}));
					state.destinyTaxes.data = payload;
					state.destinyTaxes.loading = 'succeeded';
				}
			);

		builder
			.addCase(joinItems.pending, (state) => {
				state.joinItems.loading = 'pending';
			})
			.addCase(joinItems.rejected, (state, payload: any) => {
				state.joinItems.loading = 'failed';
				state.joinItems.error = payload;
			})
			.addCase(joinItems.fulfilled, (state, { payload }: any) => {
				state.joinItems.loading = 'succeeded';
				state.joinItems.data = payload;
				state.invoice.data = payload;
			});

		builder.addCase(
			partnerUpdateLocalInvoice.fulfilled,
			(state, { payload }: any) => {
				state.invoice.data = payload;
			}
		);

		builder
			.addCase(ThunkFindOneInternalAccountInvoice.pending, (state) => {
				state.invoice.loading = 'pending';
				state.updatedInvoice.loading = 'idle';
			})
			.addCase(
				ThunkFindOneInternalAccountInvoice.rejected,
				(state, { payload }) => {
					state.invoice.loading = 'failed';
					state.invoice.error = payload;
				}
			)
			.addCase(
				ThunkFindOneInternalAccountInvoice.fulfilled,
				(state, { payload }: any) => {
					const _items = payload.items.map((item: any) => {
						let availableToBill = true;
						if (item.mapping?.length === 0 || item.total <= 0) {
							availableToBill = false;
						}
						item.taxes.map((tax: any) => {
							if (!tax.active) return tax;
							if (
								!tax.mapping ||
								tax.mapping?.length === 0 ||
								!tax.mapping[0]
							) {
								availableToBill = false;
								return tax;
							}
							const foundTax = tax.mapping[0] as any;
							const calculatedAmount =
								(foundTax.amount * item.subtotal) / 100;
							const diff = Math.abs(
								calculatedAmount - tax.amount
							);
							if (diff > 1) {
								availableToBill = false;
								return false;
							}
							return (tax.mapping?.length || 0) > 0;
						});
						return {
							...item,
							selectedToBill:
								(item.billingDetails?.billingStatus ===
									ItemBillingStatus.NOT_BILLED ||
									!item.billingDetails?.billingStatus) &&
								availableToBill
						};
					});
					state.invoice.data = {
						...payload,
						items: _items,
						payments: payload.payments.map((payment: any) => {
							const isMapped = payment.mapping?.length > 0;
							return {
								...payment,
								selectedToBill:
									isMapped &&
									payment.billingStatus !==
										ItemBillingStatus.BILLED
							};
						}),
						total: _items
							.filter((item: any) => item.selectedToBill)
							.reduce(
								(acc: number, item: any) => acc + item.total,
								0
							)
					};
					state.invoice.loading = 'succeeded';
				}
			);

		builder
			.addCase(ThunkCreateStampBulk.pending, (state) => {
				state.stampBulk.loading = 'pending';
			})
			.addCase(ThunkCreateStampBulk.rejected, (state, { payload }) => {
				state.stampBulk.loading = 'failed';
				state.stampBulk.error = payload;
			})
			.addCase(ThunkCreateStampBulk.fulfilled, (state) => {
				state.stampBulk.loading = 'succeeded';
			});
	}
});

export const {
	setInvoice,
	setInvoiceData,
	setLoadingPartnerAccounting,
	resetInvoice,
	setInvoiceToBulk,
	resetStampBulkInvoices
} = partnerAccountingSlice.actions;
