import { PARTNER } from '@hospy/store';
import { Pagination } from 'antd';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';

interface Props {
	isReservationSelected?: boolean;
	count: number;
}

const HeaderInvoiceList = ({ isReservationSelected, count }: Props) => {
	const { page, updatePage, partnerIntegrationId } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;

	const showStatusSection = partnerIntegrationId === PARTNER.CLOUDBEDS;

	const showPagination = count > 100 && !isReservationSelected;

	if (!showPagination && !showStatusSection) return null;

	return (
		<HeaderSection>
			{showStatusSection && (
				<StatusSection>
					<p>
						<StatusCircle color="not-billed" />
						No facturado
					</p>
					<p>
						<StatusCircle color="billed" />
						Facturado
					</p>
					<p>
						<StatusCircle color="process" />
						En proceso
					</p>
				</StatusSection>
			)}
			{count > 100 && !isReservationSelected && (
				<PaginationSection>
					<Pagination
						defaultCurrent={page}
						total={count}
						defaultPageSize={100}
						onChange={(selectedPage) => updatePage(selectedPage)}
						showSizeChanger={false}
						current={page}
					/>
				</PaginationSection>
			)}
		</HeaderSection>
	);
};

const HeaderSection = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 15px;
`;

const StatusSection = styled.div`
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
	gap: 20px;
	> p {
		display: flex;
		align-items: center;
		line-height: 1px;
	}
`;

const StatusCircle = styled(({ color, ...props }) => <span {...props} />)`
	height: 15px;
	width: 15px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
	${({ color, theme }) => {
		let desiredColor = theme.colors.gray.base;
		if (color === 'not-billed')
			desiredColor = theme.colors.gray.lighten['30%'];
		if (color === 'billed') desiredColor = theme.colors.success.base;
		if (color === 'process') desiredColor = '#00c8f8';
		if (color === 'error') desiredColor = theme.colors.danger.base;
		if (color === 'deleted') desiredColor = theme.colors.gray.darken['40%'];

		return css`
			background-color: ${desiredColor};
		`;
	}}
`;

const PaginationSection = styled.div``;

export default HeaderInvoiceList;
