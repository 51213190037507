/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropertyIntegrations } from '@hospy/store';
import {
	createPropertyIntegrationService,
	deletePropertyIntegrationService,
	editPropertyIntegrationService,
	getPropertyIntegrationsService,
	pathPropertyIntegrationService
} from '@hospy/util-api';

export const usePropertyIntegration = () => {
	const create = async (data: PropertyIntegrations, masterKeys?: boolean) => {
		if (masterKeys) {
			return await createPropertyIntegrationService(data, true);
		}
		return await createPropertyIntegrationService(data);
	};

	const remove = async (id: string) => {
		return await deletePropertyIntegrationService(id);
	};

	const patch = async (
		id: string,
		data: any,
		updateMode?: any,
		arrayFilters?: any
	) => {
		return await pathPropertyIntegrationService(
			id,
			data,
			updateMode,
			arrayFilters
		);
	};

	const edit = async (
		id: string,
		data: any,
		updateMode?: any,
		arrayFilters?: any
	) => {
		return await editPropertyIntegrationService(
			id,
			data,
			updateMode,
			arrayFilters
		);
	};

	const get = async (filter: string) => {
		return await getPropertyIntegrationsService({ filter });
	};

	return {
		create,
		remove,
		patch,
		get,
		edit
	};
};
export default usePropertyIntegration;
