/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppData } from '../common/common.types';
import { IBillingRangeRequest } from './interfaces';
import { ApiURL } from '@hospy/util-api';

export class adminBillingApi {
	static updateAppFees = (
		app: AppData,
		billingRange: IBillingRangeRequest
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const idToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				},
				body: JSON.stringify(billingRange)
			};

			fetch(ApiURL(`/api/common/apps/${app._id}`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static updateTrial = (app: AppData, trial: number): Promise<any> =>
		new Promise((resolve, reject) => {
			const idToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				},
				body: JSON.stringify({
					trialPeriod: +trial
				})
			};

			fetch(ApiURL(`/api/common/apps/${app._id}`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
