import { PropertyState } from './interfaces';

export const initialState = {
	properties: {
		loading: 'idle',
		error: '',
		data: undefined
	},
	propertyBillInfo: {
		loading: 'idle',
		error: '',
		data: undefined
	}
} as PropertyState;
