/* eslint-disable @typescript-eslint/no-explicit-any */
import { IInternalAccount } from '@hospy/store';
import { Reorder } from 'framer-motion';
import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';
import InvoiceItemCardSkeleton from '../../skeleton';
import InvoiceItemCard from './card';

interface Props {
	selectInternalAccount: (invoice: any, index: number) => void;
	internalAccounts: IInternalAccount[];
	invoiceLoading: boolean;
	invoice: any;
	multipleInvoices?: boolean;
}

const MainInvoice = ({
	selectInternalAccount,
	internalAccounts,
	invoice,
	invoiceLoading,
	multipleInvoices
}: Props) => {
	const { actionCloseDetail, loading, selectedSource } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;

	const [items, setItems] = useState<any[]>([0, 1, 2, 3]);

	return (
		<MainInvoiceList maxColumn={selectedSource && 1}>
			{loading ? (
				<>
					{[0, 1, 2].map((e) => (
						<InvoiceItemCardSkeleton key={e} />
					))}
				</>
			) : (
				<>
					{selectedSource !== null && (
						<MainInvoiceListOpened
							axis="y"
							values={items}
							onReorder={setItems}
						>
							{internalAccounts?.map(
								(
									internalAccount: IInternalAccount,
									index: number
								) => (
									<Reorder.Item
										key={`Invoice-card-key-${internalAccount.id}`}
										value={internalAccount}
									>
										<InvoiceItemCard
											multipleInvoices={multipleInvoices}
											internalAccount={internalAccount}
											selectInvoice={() =>
												actionCloseDetail(() => {
													window.scroll({
														top: 0,
														left: 0,
														behavior: 'smooth'
													});

													selectInternalAccount(
														internalAccount,
														index
													);
												})
											}
											detailOpened={
												selectedSource !== null
											}
											isSelected={
												(
													selectedSource as IInternalAccount
												)?.id === internalAccount.id
											}
											invoice={invoice}
											invoiceLoading={invoiceLoading}
										/>
									</Reorder.Item>
								)
							)}
						</MainInvoiceListOpened>
					)}

					{selectedSource === null &&
						internalAccounts?.map(
							(
								internalAccount: IInternalAccount,
								index: number
							) => (
								<InvoiceItemCard
									key={`Invoice-card-key-${internalAccount.id}`}
									internalAccount={internalAccount}
									selectInvoice={() =>
										actionCloseDetail(() => {
											selectInternalAccount(
												internalAccount,
												index
											);
											window.scroll({
												top: 0,
												left: 0,
												behavior: 'smooth'
											});
										})
									}
									detailOpened={selectedSource !== null}
									isSelected={false}
									invoice={invoice}
									invoiceLoading={invoiceLoading}
									multipleInvoices={multipleInvoices}
								/>
							)
						)}
				</>
			)}
		</MainInvoiceList>
	);
};

export default MainInvoice;

const MainInvoiceList = styled(({ maxColumn, ...props }) => <div {...props} />)`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	display: grid;
	gap: 30px;

	${({ theme }) =>
		theme.screens.lg &&
		css`
			grid-template-columns: repeat(2, 1fr);
		`}

	${({ theme }) =>
		theme.screens.xl &&
		css`
			grid-template-columns: repeat(
				auto-fill,
				clamp(650px, 100%/3 - 20px, 650px)
			);
		`}
		
	${({ maxColumn }) =>
		maxColumn &&
		css`
			grid-template-columns: repeat(${maxColumn}, 1fr);
		`}
`;

const MainInvoiceListOpened = styled(Reorder.Group)`
	display: grid;
	gap: 30px;
	grid-template-columns: 1fr;

	li {
		list-style: none;
	}
`;
