/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	PARTNER,
	SIIGO_DEFAULT_TAX_AMOUNT,
	accountingDestinyGetTaxes,
	accountingUpdateInvoiceSync,
	clearUpdateFieldMapping,
	updateFieldMappingV2,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Form, message } from 'antd';
import { useCallback, useEffect } from 'react';

const useFirstTimeTax = ({
	close,
	onFinish
}: {
	close?: () => void;
	onFinish?: () => void;
}) => {
	const dispatch = useAppDispatch();

	const [form] = Form.useForm();

	const { fieldMapping, updatedFieldMapping } = useAppSelector(
		({ onboarding }) => onboarding
	);
	const { invoice, updatedInvoice, destinyTaxes } = useAppSelector(
		({ siigoConnect }) => siigoConnect
	);
	const { propertyIntegrations, propertyId } = useAppSelector(
		({ user }) => user
	);
	const { appData } = useAppSelector(({ common }) => common);

	const getDestinyTaxes = useCallback(() => {
		if (!appData || !propertyId || !propertyIntegrations) return;
		const propertyIntegrationSiigo = propertyIntegrations?.find(
			(item) =>
				item.partnerIntegrationId === PARTNER.SIIGO &&
				item.appId === appData?._id
		);

		if (!propertyIntegrationSiigo) return;

		dispatch(
			accountingDestinyGetTaxes({
				appId: appData?._id,
				partnerIntegrationId: PARTNER.SIIGO,
				propertyId: propertyId
			})
		);
	}, [appData, propertyId, propertyIntegrations]);

	useEffect(() => {
		if (destinyTaxes.loading !== 'idle') return;
		getDestinyTaxes();
	}, [destinyTaxes.loading]);

	useEffect(() => {
		if (updatedFieldMapping.loading === 'idle') return;
		if (updatedFieldMapping.loading === 'succeeded') {
			(async () => {
				message.success('Mapeo de impuesto actualizado correctamente');
				await dispatch(clearUpdateFieldMapping());
				if (close) close();
				if (onFinish) onFinish();
			})();
			return;
		}
		if (updatedFieldMapping.loading === 'failed') {
			message.error('Error al actualizar el mapeo de impuestos');
			dispatch(clearUpdateFieldMapping());
			if (close) close();
		}
	}, [updatedFieldMapping.loading]);

	const onSubmit = async (taxValue: any) => {
		const defaultTaxAmount =
			invoice?.data?.defaultTaxAmount?.amount || SIIGO_DEFAULT_TAX_AMOUNT;
		if (!defaultTaxAmount) return;

		const fullTax = destinyTaxes.data?.find(
			(tax: any) => tax.thirdPartyId === taxValue
		);

		if (fullTax?.amount !== defaultTaxAmount)
			return form.setFields([
				{
					name: `tax`,
					errors: [
						`El valor debe coincidir con el establecido para Colombia (${defaultTaxAmount}%)`
					],
					value: taxValue
				}
			]);

		const fieldMappingId = fieldMapping?.data?._id || '';

		await dispatch(
			updateFieldMappingV2({
				id: fieldMappingId,
				data: {
					specific: {
						fields: {
							groupName: 'taxes',
							internalSlug: 'Iva 19%',
							internalName: 'Iva 19%',

							externalId: fullTax.id,
							externalName: fullTax.name,
							externalSlug: fullTax.id,
							extras: [
								{
									slug: 'destiny-tax-value',
									name: 'destiny-tax-value',
									type: 'percentage',
									value: fullTax.amount
								}
							]
						}
					}
				},
				updateMode: 'push',
				items: []
			})
		);
		if (invoice.data) {
			const _invoice = JSON.parse(JSON.stringify(invoice.data));

			_invoice.items[0].taxes[0].mapping = [
				{
					partnerTo: PARTNER.SIIGO,
					partnerFrom: PARTNER.AIRBNB,
					value: fullTax.id,
					name: fullTax.name,
					amount: fullTax.amount
				}
			];
			await dispatch(accountingUpdateInvoiceSync(_invoice));
		}
		if (onFinish) onFinish();
		if (close) close();
	};

	return {
		taxes: destinyTaxes?.data?.map((tax: any) => ({
			label: tax.name,
			value: tax.thirdPartyId
		})),
		loadingTaxes: destinyTaxes.loading === 'pending',
		availableRetry: {
			taxes: destinyTaxes.loading === 'failed'
		},
		getDestinyTaxes,
		updatedInvoice,
		form,
		onSubmit,
		updatedFieldMapping
	};
};

export default useFirstTimeTax;
