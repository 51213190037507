import styled, { css } from 'styled-components';

const IntegrationIcons = ({ appIcon, partnerImg, onClick }: Props) => {
	return (
		<Container clickable={Boolean(onClick !== undefined)} onClick={onClick}>
			<IntegrationIconItem img={partnerImg} />
			<IntegrationIconItem
				img={'https://cdn.hospy.co/images/favicon.png'}
			/>
		</Container>
	);
};
export default IntegrationIcons;

interface Props {
	appIcon: string;
	partnerImg: string;
	onClick?: () => void;
}

export const IntegrationIconItem = ({ img }: { img: string }) => {
	return (
		<IconContainer>
			<img src={img} alt="icon" />
		</IconContainer>
	);
};

const IconContainer = styled.div`
	width: 60px;
	height: 60px;
	padding: 10px;
	& > img {
		max-width: 100%;
		max-height: 30px;
	}
	border-radius: 30px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface ContainerProps {
	clickable?: boolean;
}
const Container = styled.div<ContainerProps>`
	display: flex;
	${IconContainer} {
		position: relative;
		&:first-child {
			left: 5px;
		}
		&:last-child {
			right: 5px;
		}
	}

	${({ clickable, theme }) =>
		clickable &&
		css`
			&:hover {
				cursor: pointer;
				${IconContainer} {
					background-color: ${theme.colors.gray.lighten['90%']};
				}
			}
		`}
`;
