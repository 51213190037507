/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { IFee } from '../common/common.types';
import { initialState } from './subscription.constants';
import {
	deleteActualPayment,
	fetchSubscriptionInfo,
	initLandingPayment,
	makeLandingPayment,
	makePayment,
	subscribe,
	tryPayment,
	validateDiscount,
	validatePaymentTried
} from './subscription.thunks';
import { IPropertySubcription } from './subscription.interfaces';

export const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		updateDiscountPropertyInInfo: (state, { payload }) => {
			if (!state.subscriptionInfo.data) return;
			const {
				propertyId,
				appId,
				discount,
				frequency
			}: {
				propertyId: string;
				appId: string;
				discount: any;
				frequency: string;
			} = payload;

			const actualInfo = [...state.subscriptionInfo.data];

			const indexPropertyToUpdate = actualInfo?.findIndex(
				(property) => property.propertyId === propertyId
			);

			if (indexPropertyToUpdate < 0) return;

			const indexAppToUpdate = actualInfo[
				indexPropertyToUpdate
			].apps?.findIndex((app) => app.id === appId);

			if (!actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate])
				return;

			actualInfo[indexPropertyToUpdate].apps[
				indexAppToUpdate
			].discountCode = discount?.code;

			actualInfo[indexPropertyToUpdate].apps[
				indexAppToUpdate
			].rawDiscount = discount;

			const maxDiscount =
				frequency === 'yearly'
					? actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate]
							.amount * 12
					: actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate]
							.amount;

			let discountValue =
				discount.type === 'fixed'
					? discount.amount
					: actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate]
							.amount *
					  (discount.amount / 100);

			if (frequency === 'yearly' && discount.type !== 'fixed')
				discountValue *= 12;

			if (discountValue >= maxDiscount) discountValue = maxDiscount - 1;

			actualInfo[indexPropertyToUpdate].apps[
				indexAppToUpdate
			].discountValue = discountValue;

			actualInfo[indexPropertyToUpdate].amount =
				actualInfo[indexPropertyToUpdate].amount - discountValue;

			actualInfo[indexPropertyToUpdate].discount =
				discountValue + actualInfo[indexPropertyToUpdate].discount;

			state.subscriptionInfo.data = [...actualInfo];
		},
		updatePropertyInInfo: (state, { payload }) => {
			if (!state.subscriptionInfo.data) return;
			const {
				propertyId,
				appId,
				monthlyFee,
				frequency,
				rooms
			}: {
				propertyId: string;
				appId: string;
				monthlyFee: IFee;
				frequency: string;
				rooms?: number;
			} = payload;

			const actualInfo = JSON.parse(
				JSON.stringify([...state.subscriptionInfo.data])
			) as IPropertySubcription[];

			const indexPropertyToUpdate = actualInfo?.findIndex(
				(property) => property.propertyId === propertyId
			);

			if (indexPropertyToUpdate < 0) return;

			const indexAppToUpdate = actualInfo[
				indexPropertyToUpdate
			].apps?.findIndex((app) => app.id === appId);

			if (!actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate])
				return;

			actualInfo[indexPropertyToUpdate].apps[
				indexAppToUpdate
			].monthlyFee = monthlyFee;

			actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate].rooms =
				rooms !== undefined ? rooms : monthlyFee.start + 1;
			actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate].amount =
				monthlyFee.byAccommodation
					? monthlyFee.value *
					  actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate]
							.rooms
					: monthlyFee.value;

			if (monthlyFee.byAccommodation)
				actualInfo[indexPropertyToUpdate].apps[
					indexAppToUpdate
				].amount =
					monthlyFee.value *
					actualInfo[indexPropertyToUpdate].apps[indexAppToUpdate]
						.rooms;

			console.log(actualInfo);
			console.log(indexAppToUpdate);

			actualInfo[indexPropertyToUpdate].discount = actualInfo[
				indexPropertyToUpdate
			].apps
				.map((app) => {
					if (!app.rawDiscount) return 0;
					const maxDiscount =
						frequency === 'yearly' ? app.amount * 12 : app.amount;

					let discountValue =
						app.rawDiscount.type === 'fixed'
							? app.rawDiscount.amount
							: app.amount * (app.rawDiscount.amount / 100);

					if (discountValue >= maxDiscount)
						discountValue = maxDiscount - 1;

					actualInfo[indexAppToUpdate].discount = actualInfo[
						indexAppToUpdate
					].apps[indexAppToUpdate].discountValue = discountValue;

					return discountValue;
				})
				.reduce((prev, curr) => prev + curr, 0);

			actualInfo[indexPropertyToUpdate].amount =
				actualInfo[indexPropertyToUpdate].apps.reduce(
					(prev, curr) => prev + curr.amount,
					0
				) - actualInfo[indexPropertyToUpdate].discount;

			actualInfo[indexPropertyToUpdate].subtotal = actualInfo[
				indexPropertyToUpdate
			].apps.reduce((prev, curr) => prev + curr.amount, 0);

			state.subscriptionInfo.data = [...actualInfo];
		},
		clearSubscriptionInfo: (state) => {
			state.subscriptionInfo = initialState.subscriptionInfo;
		},
		clearIntent: (state) => {
			state.intent = initialState.intent;
		},
		clearDiscountValidation: (state) => {
			state.discountValidation = initialState.discountValidation;
		},
		clearDiscounts: (state) => {
			if (!state.subscriptionInfo.data) return;
			let actualInfo = [...state.subscriptionInfo.data];

			actualInfo = actualInfo.map((property) => {
				const apps = property.apps.map((app) => ({
					...app,
					discountCode: undefined,
					discountValue: 0
				}));
				return {
					...property,
					discount: 0,
					amount: property.subtotal,
					apps
				};
			});

			state.subscriptionInfo.data = actualInfo;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchSubscriptionInfo.pending, (state, action) => {
			state.subscriptionInfo.loading = 'pending';
		});
		builder.addCase(
			fetchSubscriptionInfo.fulfilled,
			(state, { payload }) => {
				state.subscriptionInfo.loading = 'succeeded';
				state.subscriptionInfo.data = payload.map((property: any) => ({
					...property,
					subtotal: property.amount,
					discount: 0
				}));
			}
		);
		builder.addCase(
			fetchSubscriptionInfo.rejected,
			(state, { payload }) => {
				state.subscriptionInfo.loading = 'failed';
				state.subscriptionInfo.error = payload as string;
			}
		);

		builder.addCase(subscribe.pending, (state, action) => {
			state.intent.loading = 'pending';
		});
		builder.addCase(subscribe.fulfilled, (state, { payload }) => {
			state.intent.loading = 'succeeded';
			state.intent.data = payload;
		});
		builder.addCase(subscribe.rejected, (state, { payload }) => {
			state.intent.loading = 'failed';
			state.intent.error =
				'No se ha podido establecer comunicación con el proveedor de pagos.';
		});

		builder.addCase(makePayment.pending, (state, action) => {
			state.payment.loading = 'pending';
		});
		builder.addCase(makePayment.fulfilled, (state, { payload }) => {
			state.payment.loading = 'succeeded';
			state.payment.data = payload;
		});
		builder.addCase(makePayment.rejected, (state, { payload }) => {
			state.payment.loading = 'failed';
			state.payment.error = payload as string;
		});

		builder.addCase(validateDiscount.pending, (state, action) => {
			state.discountValidation.loading = 'pending';
		});
		builder.addCase(validateDiscount.fulfilled, (state, { payload }) => {
			state.discountValidation.loading = 'succeeded';
			state.discountValidation.data = payload;
		});
		builder.addCase(validateDiscount.rejected, (state, { payload }) => {
			state.discountValidation.loading = 'failed';
			state.discountValidation.error = payload as string;
		});

		builder.addCase(tryPayment.pending, (state, action) => {
			state.renew.loading = 'pending';
		});
		builder.addCase(tryPayment.fulfilled, (state, { payload }) => {
			state.renew.loading = 'succeeded';
			state.renew.data = payload;
		});
		builder.addCase(tryPayment.rejected, (state, { payload }) => {
			state.renew.loading = 'failed';
			state.renew.error = payload as string;
		});

		builder.addCase(deleteActualPayment.pending, (state, action) => {
			state.deleteActualPayment.loading = 'pending';
		});
		builder.addCase(deleteActualPayment.fulfilled, (state, { payload }) => {
			state.deleteActualPayment.loading = 'succeeded';
			state.deleteActualPayment.data = payload;
		});
		builder.addCase(deleteActualPayment.rejected, (state, { payload }) => {
			state.deleteActualPayment.loading = 'failed';
			state.deleteActualPayment.error = payload as string;
		});

		builder.addCase(initLandingPayment.pending, (state, action) => {
			state.initLandingSubscribe.loading = 'pending';
		});
		builder.addCase(initLandingPayment.fulfilled, (state, { payload }) => {
			state.initLandingSubscribe.loading = 'succeeded';
			state.initLandingSubscribe.data = payload;
		});
		builder.addCase(initLandingPayment.rejected, (state, { payload }) => {
			state.initLandingSubscribe.loading = 'failed';
			state.initLandingSubscribe.error = payload as string;
		});

		builder.addCase(makeLandingPayment.pending, (state) => {
			state.landingPayment.loading = 'pending';
		});
		builder.addCase(makeLandingPayment.fulfilled, (state, { payload }) => {
			state.landingPayment.loading = 'succeeded';
			state.initLandingSubscribe.data = payload;
		});
		builder.addCase(makeLandingPayment.rejected, (state, { payload }) => {
			state.landingPayment.loading = 'failed';
			state.initLandingSubscribe.error = payload as string;
		});

		builder.addCase(validatePaymentTried.pending, (state) => {
			state.validatePaymentTry.loading = 'pending';
		});
		builder.addCase(
			validatePaymentTried.fulfilled,
			(state, { payload }) => {
				state.validatePaymentTry.loading = 'succeeded';
				state.validatePaymentTry.data = payload;
			}
		);
		builder.addCase(validatePaymentTried.rejected, (state, { payload }) => {
			state.validatePaymentTry.loading = 'failed';
			state.validatePaymentTry.error = payload as string;
		});
	}
});

export const {
	updatePropertyInInfo,
	updateDiscountPropertyInInfo,
	clearSubscriptionInfo,
	clearIntent,
	clearDiscountValidation,
	clearDiscounts
} = subscriptionSlice.actions;
