import ArrowBackIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import { maxDevice } from '@hospy/feature/devices';
import { Button } from '@hospy/hospy-ui';
import { AccountForm } from '@hospy/shared-components';
import { Form, Input, Space, Typography } from 'antd';
import styled from 'styled-components';
import { IUseLogin } from '../hook/use-login';

const MFAForm = (props: IUseLogin) => {
	const onFinish = ({ verifiedCode }: any) => {
		props.onSubmitMFA(verifiedCode);
	};

	return (
		<AccountForm.Container>
			<AccountForm.Content>
				<Typography.Title level={3}>
					Verificación de seguridad
				</Typography.Title>
				<Typography.Text>
					Hemos enviado un código a tu número términado ****
					{props.phoneEncrypt?.substr(-4)}
				</Typography.Text>

				<Form onFinish={onFinish} style={{ marginTop: 10 }}>
					<Form.Item
						name="verifiedCode"
						rules={[{ required: true, message: 'Campo requerido' }]}
					>
						<Input
							size="large"
							placeholder="Ingresa el código enviado a tu télefono"
						/>
					</Form.Item>

					<Form.Item>
						<Button
							htmlType="submit"
							size="large"
							type="primary"
							block
							loading={props.loading}
						>
							Continuar
						</Button>
					</Form.Item>

					<Button
						size="large"
						block
						type="link"
						style={{ color: 'black', padding: 0 }}
						onClick={() => props.setTypeForm('LOGIN')}
					>
						<Space align="center">
							<ArrowBackIcon style={{ fontSize: "inherit" }} />
							Volver al login
						</Space>
					</Button>
				</Form>
			</AccountForm.Content>
			<div style={{ textAlign: 'center' }}>
				<ButtonTermsConditions type="link">
					Política de privacidad - Términos y condiciones
				</ButtonTermsConditions>
			</div>
		</AccountForm.Container>
	);
};

export default MFAForm;

const ButtonTermsConditions = styled(Button)`
	color: #fff;
	font-size: 0.8rem;
	@media ${maxDevice.mobileL} {
		color: #3494e0;
	}
`;
