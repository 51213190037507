import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { getLogs, getSatLogs, getSiigoLogs } from './thunks';

export const logsSlice = createSlice({
	name: 'logs',
	initialState,
	reducers: {
		cleanLogs: (state) => {
			state.logs = initialState.logs;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getLogs.pending, (state) => {
				state.logs.loading = 'pending';
				state.logs.error = undefined;
			})
			.addCase(getLogs.rejected, (state, { payload }) => {
				state.logs.loading = 'failed';
				state.logs.error = payload;
			})
			.addCase(getLogs.fulfilled, (state, { payload }) => {
				state.logs.data = payload;
				state.logs.loading = 'succeeded';
			});

		builder
			.addCase(getSiigoLogs.pending, (state) => {
				state.siigoLogs.loading = 'pending';
				state.siigoLogs.error = undefined;
			})
			.addCase(getSiigoLogs.rejected, (state, { payload }) => {
				state.siigoLogs.loading = 'failed';
				state.siigoLogs.error = payload;
			})
			.addCase(getSiigoLogs.fulfilled, (state, { payload }) => {
				state.siigoLogs.data = payload;
				state.siigoLogs.loading = 'succeeded';
			});

		builder
			.addCase(getSatLogs.pending, (state) => {
				state.satLogs.loading = 'pending';
				state.satLogs.error = undefined;
			})
			.addCase(getSatLogs.rejected, (state, { payload }) => {
				state.satLogs.loading = 'failed';
				state.satLogs.error = payload;
			})
			.addCase(getSatLogs.fulfilled, (state, { payload }) => {
				state.satLogs.data = payload;
				state.satLogs.loading = 'succeeded';
			});
	}
});

export const { cleanLogs } = logsSlice.actions;
