/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IReservationInvoice } from '@hospy/store';
import InvoiceItemCard from './card';

interface Props {
	reservation: IReservationInvoice;
	partnerIntegrationId: string;
}

const fakeFunction = () => null;

const InvoiceItemCardPreview = ({
	reservation,
	partnerIntegrationId
}: Props) => {
	return (
		<InvoiceItemCard
			hideBulk={true}
			forcePrice={true}
			detailOpened={false}
			invoiceLoading={false}
			isSelected={false}
			reservation={reservation}
			selectInvoice={fakeFunction}
			partnerIntegrationId={partnerIntegrationId}
		/>
	);
};

export default InvoiceItemCardPreview;
