import { Icon } from '@hospy/hospy-ui';
import { Modal } from '@hospy/shared-components';
import { Typography } from 'antd';
import styled from 'styled-components';

interface IHospyAlertModal {
	open: boolean;
	onCancel: () => void;
	title: string | React.ReactNode;
	subtitle: string | React.ReactNode;
}

const HospyAlertModal = ({
	open,
	onCancel,
	title,
	subtitle
}: IHospyAlertModal) => {
	return (
		<Modal open={open} onClose={onCancel}>
			<ModalWrapper>
				<Header>
					<Title>{title}</Title>
				</Header>
				<Content>
					<Icon material="warning" />
					<SubTitle>{subtitle}</SubTitle>
				</Content>
			</ModalWrapper>
		</Modal>
	);
};

const ModalWrapper = styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

const Header = styled.div`
	width: 100%;
	display: grid;
	place-items: center;
`;

const Content = styled.div`
	width: 100%;
	display: flex;
	gap: 30px;

	> svg:nth-child(1) {
		color: red;
		width: 46px;
		height: 46px;
	}
`;

const Title = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.middle}px;
`;

const SubTitle = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.middle}px;
`;

export default HospyAlertModal;
