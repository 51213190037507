import React, { useRef } from 'react';
import styled from 'styled-components';

interface Prop {
	style?: React.CSSProperties;
	label?: string | null;
	danger?: boolean;
}

export const ConnectionVector: React.FC<Prop> = ({ style, label, danger }) => {
	const ref = useRef(null);

	return (
		<Border danger={danger} style={{ ...style }}>
			{label && (
				<LabelContainer>
					<Label danger={danger} ref={ref}>
						{label}
					</Label>
				</LabelContainer>
			)}
		</Border>
	);
};

export default ConnectionVector;

interface BorderProps {
	danger?: boolean;
}
const Border = styled.div<BorderProps>`
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	&::after {
		content: '';
		left: 0;
		width: 100%;
		height: 4px;
		background: linear-gradient(
			90deg,
			${({ theme, danger }) =>
					danger
						? 'rgba(255,128,128,0.5)'
						: theme.colors.gray.lighten['50%']}
				50%,
			transparent 50%
		);
		background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
		background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
		background-position: 0px 0px, 200px 100px, 0px 100px, 200px 0px;
		animation: border-dance 20s infinite linear;
	}

	@keyframes border-dance {
		0% {
			background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
		}
		100% {
			background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
		}
	}
`;

const LabelContainer = styled.div`
	position: absolute;
	width: 100%;
	left: 0;
	display: flex;
	justify-content: center;
`;
const Label = styled.span<BorderProps>`
	padding: 5px;
	color: ${({ theme, danger }) =>
		danger ? 'rgba(255, 0, 0, 0.5)' : theme.colors.gray.darken['50%']};
	background-color: #fff;
`;
