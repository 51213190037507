/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PartnerIntegration } from '@hospy/store';
import { Tooltip, Typography } from 'antd';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const variants = {
	show: { scale: 1, opacity: 1 },
	hidden: { scale: 0.5, opacity: 0 }
};

interface ItemProps {
	data: PartnerIntegration;
	onSelect: (item: PartnerIntegration) => void;
}

const PartnerListItem = ({ data, onSelect }: ItemProps) => {
	const { icon, name, status } = data;

	const partnerContent = (
		<Container
			disabled={status === 'dev'}
			variants={variants}
			onClick={() => status !== 'dev' && onSelect(data)}
		>
			<Content>
				<img src={icon} alt={name} />
			</Content>
			<Typography.Text>{name}</Typography.Text>
		</Container>
	);

	return (
		<div>
			{status === 'dev' ? (
				<Tooltip
					overlayStyle={{
						whiteSpace: 'pre-line'
					}}
					overlayInnerStyle={{
						textAlign: 'center'
					}}
					title={`${name}:\nDisponible pronto`}
				>
					{partnerContent}
				</Tooltip>
			) : (
				<> {partnerContent}</>
			)}
		</div>
	);
};

export default PartnerListItem;

interface IContainer {
	disabled?: boolean;
}

const Container = styled(motion.li)<IContainer>`
	list-style: none;
	text-align: center;
	font-size: 12px;
	${({ theme, disabled }) =>
		disabled &&
		css`
			> div {
				background-color: ${theme.colors.gray.lighten['70%']};
			}
			img {
				opacity: 0.5 !important;
			}
		`}
	>span {
		text-transform: capitalize;
	}
`;

const Content = styled.div`
	height: 100px;
	width: 100px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.colors.gray.darken['20%']};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten['40%']};
	padding: 5px 15px;
	margin-bottom: 5px;
	box-shadow: ${({ theme }) => theme.boxShadow.level2()};
	cursor: pointer;

	> img {
		width: 60%;
		aspect-ratio: 1/1;
		object-fit: contain;
		object-position: left;
	}

	:hover {
		color: ${({ theme }) => theme.colors.primary.base};
		border-color: ${({ theme }) => theme.colors.primary.base};
	}
`;
