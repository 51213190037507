/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fetch } from '../fetch';
import { RequestCreate, RequestFindAll } from '../requests';
import { ResponseFailure, ResponseSuccess } from '../responses';
import { ApiURL } from '../util-url';
import moment from 'moment';

export const getMonthlyIncome = (
	startDate: string,
	endDate: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`api/common/analytics/monthly-income?filter={"startDt":"${startDate}","endDt":"${endDate}"}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getUserSubscriptions = (
	startDate: string,
	endDate: string,
	propertyTypes?: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		let _type = '';

		if (propertyTypes?.length) {
			_type = ` ,"propertyTypes": ${JSON.stringify(propertyTypes)}`;
		}

		fetch(
			ApiURL(
				`api/common/analytics/user-subcriptions?filter={"startDt":"${startDate}","endDt":"${endDate}" ${_type}}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReportCheckinReasonInterests = (params?: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const filter = params?.filter ? '&filter=' + params?.filter : '';
		fetch(
			ApiURL(
				`api/common/analytics/checkin-reason-interests-data?${filter}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertySubscriptions = (queryParams: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`api/common/analytics/property-subscriptions?${
					queryParams || ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReportIMRData = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const filter = params?.filter || '{}';
		fetch(
			ApiURL(`api/common/analytics/imr?filter=` + filter),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCjm = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`api/common/analytics/cjm-application`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getDataProcessed = (
	apps: string[],
	partners: string[],
	name: string,
	size: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const filter = `{"name": "${name}","apps": [${apps
			.map((app) => `"${app}"`)
			.join(',')}],"partners": [${partners
			.map((partner) => `"${partner}"`)
			.join(',')}]${size ? `,"size":${size}` : ''}}`;

		fetch(
			ApiURL(
				`api/common/analytics/processed-data-customer?filter=${filter}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReportSireTraData = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`api/common/analytics/sire-tra-data`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReportSiigoData = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`api/common/analytics/siigo-data`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
export const getReportSatData = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`api/common/analytics/sat-data`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
export const getReportCheckinData = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(ApiURL(`api/common/analytics/checkin-data`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
export const getResumeService = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`api/common/analytics/subscription-summary`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface fetchGetReportIncomeMonthlyExportProps {
	format: string;
	filter: string;
}
export const fetchGetReportIncomeMonthlyExport = (
	params: fetchGetReportIncomeMonthlyExportProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('Hospy-idToken')}`
			}
		};

		const url = ApiURL(
			`api/common/analytics/monthly-income/exports/${params.format}?filter=${params.filter}`
		);

		fetch(url, requestOptions)
			.then((response: any) => {
				if (response.ok) return response.blob();
				throw response;
			})
			.then((blob) => {
				const file = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = file;
				a.download = `Invoices-${moment().format(
					'YYYYMMDDHHmmss'
				)}.csv`;
				document.body.appendChild(a);
				a.click();
				a.remove();
				resolve({ status: 'success' });
			})
			.catch((error) => reject(error));
	});

export interface fetchGetExportProps
	extends fetchGetReportIncomeMonthlyExportProps {}
export const fetchGetPropertyWithSubscriptionsExport = (
	params: fetchGetExportProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('Hospy-idToken')}`
			}
		};

		const url = ApiURL(
			`api/common/analytics/property-subscriptions/exports/${params.format}?filter=${params.filter}`
		);

		fetch(url, requestOptions)
			.then((response: any) => {
				if (response.ok) return response.blob();
				throw response;
			})
			.then((blob) => {
				const file = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = file;
				a.download = `Clientes-${moment().format(
					'YYYYMMDDHHmmss'
				)}.csv`;
				document.body.appendChild(a);
				a.click();
				a.remove();
				resolve({ status: 'success' });
			})
			.catch((error) => reject(error));
	});

export const fetchGetInvoicesExport = (
	params: fetchGetExportProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('Hospy-idToken')}`
			}
		};

		const url = ApiURL(
			`api/common/analytics/invoices/exports/${params.format}?filter=${params.filter}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const upsellingGetBankAccounts = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/upselling/bank-accounts', params);

export const upsellingAuthorizeBankAccounts = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/upselling/bank-accounts/authorize/:id', params);

export const upsellingProcessPaymentBankAccounts = (
	params: Partial<RequestCreate>
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/upselling/bank-accounts/process/:id', params);

export const upsellingGetBankAccountsTransfers = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/upselling/bank-accounts/history-payments', params);
