/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { initialCreditsState } from './credits.constants';
import {
	CreditsMakePayment,
	createCredits,
	createCreditsInvoice,
	getCredits,
	getCreditsByProperty,
	getCreditsByUser,
	getCreditsInvoices,
	updateCredits
} from './credits.thunks';

export const creditSlice = createSlice({
	name: 'credits',
	initialState: initialCreditsState,
	reducers: {
		clearCreditsPaymentInfo: (state) => {
			state.payment = initialCreditsState.payment;
		},
		clearCreateCreditDetails: (state) => {
			state.intent = initialCreditsState.intent;
		},
		clearUpdateCredits: (state) => {
			state.updateCredits = initialCreditsState.updateCredits;
			state.creditInvoice = initialCreditsState.creditInvoice;
		},
		setRealTimeCredits: (state, { payload }) => {
			state.credits.data = {
				credits: payload.credits,
				stamps: payload.stamps
			};
		}
	},
	extraReducers: (builder) => {
		builder.addCase(createCredits.pending, (state, action) => {
			state.intent.loading = 'pending';
		});
		builder.addCase(createCredits.fulfilled, (state, { payload }) => {
			state.intent.loading = 'succeeded';
			state.intent.data = payload;
		});
		builder.addCase(createCredits.rejected, (state, { payload }) => {
			state.intent.loading = 'failed';
			state.intent.error =
				'No se ha podido establecer comunicación con el proveedor de pagos.';
		});

		builder.addCase(CreditsMakePayment.pending, (state, action) => {
			state.payment.loading = 'pending';
		});
		builder.addCase(CreditsMakePayment.fulfilled, (state, { payload }) => {
			state.payment.loading = 'succeeded';
			state.payment.data = payload;
		});
		builder.addCase(CreditsMakePayment.rejected, (state, { payload }) => {
			state.payment.loading = 'failed';
			state.payment.error = payload as string;
		});

		builder.addCase(getCredits.pending, (state, action) => {
			state.currentCredits.loading = 'pending';
			state.credits.loading = 'pending';
		});
		builder.addCase(getCredits.fulfilled, (state, { payload }) => {
			state.currentCredits.loading = 'succeeded';
			state.currentCredits.data = payload.credits;
			state.credits.loading = 'succeeded';
			state.credits.data = payload;
		});
		builder.addCase(getCredits.rejected, (state, { payload }) => {
			state.currentCredits.loading = 'failed';
			state.currentCredits.error = payload as string;

			state.credits.loading = 'pending';
			state.credits.error = payload as string;
		});

		builder.addCase(getCreditsInvoices.pending, (state, action) => {
			state.invoices.loading = 'pending';
		});
		builder.addCase(getCreditsInvoices.fulfilled, (state, { payload }) => {
			state.invoices.loading = 'succeeded';
			state.invoices.data = payload;
		});
		builder.addCase(getCreditsInvoices.rejected, (state, { payload }) => {
			state.invoices.loading = 'failed';
			state.invoices.error = payload as string;
		});

		builder
			.addCase(updateCredits.pending, (state) => {
				state.updateCredits.loading = 'pending';
			})
			.addCase(updateCredits.rejected, (state, { payload }) => {
				state.updateCredits = {
					loading: 'failed',
					data: null,
					error: payload
				};
			})
			.addCase(updateCredits.fulfilled, (state, { payload }) => {
				state.credits.data = payload;
				state.updateCredits.loading = 'succeeded';
			});

		builder
			.addCase(createCreditsInvoice.pending, (state) => {
				state.creditInvoice.loading = 'pending';
			})
			.addCase(createCreditsInvoice.rejected, (state, { payload }) => {
				state.creditInvoice = {
					loading: 'failed',
					data: null,
					error: payload
				};
			})
			.addCase(createCreditsInvoice.fulfilled, (state, { payload }) => {
				state.creditInvoice.loading = 'succeeded';
			});

		builder.addCase(getCreditsByProperty.pending, (state, action) => {
			state.credits.loading = 'pending';
		});
		builder.addCase(
			getCreditsByProperty.fulfilled,
			(state, { payload }) => {
				state.credits.loading = 'succeeded';
				state.credits.data = payload;
			}
		);
		builder.addCase(getCreditsByProperty.rejected, (state, { payload }) => {
			state.credits.loading = 'pending';
			state.credits.error = payload as string;
		});

		builder.addCase(getCreditsByUser.pending, (state, action) => {
			state.credits.loading = 'pending';
		});
		builder.addCase(
			getCreditsByUser.fulfilled,
			(state, { payload }) => {
				state.credits.loading = 'succeeded';
				state.credits.data = payload;
			}
		);
		builder.addCase(getCreditsByUser.rejected, (state, { payload }) => {
			state.credits.loading = 'pending';
			state.credits.error = payload as string;
		});
	}
});

export const {
	clearCreateCreditDetails,
	clearCreditsPaymentInfo,
	clearUpdateCredits,
	setRealTimeCredits
} = creditSlice.actions;
