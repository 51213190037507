/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';
import { cognitoService } from '@hospy/util-auth';
import { IFindUser } from './user.interfaces';

export class userApi {
	static fetchInfo = (accessToken: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(ApiURL('api/auth/me'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchFindUser = (data: IFindUser): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const params = new URLSearchParams(Object.assign(data)).toString();
			const url = ApiURL('/api/auth/find-user?' + params);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				}
			};
			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static findUsers = (data: any): Promise<any> =>
		new Promise(async (resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			let _qp: string[] = [];
			if (data) _qp = [...data];

			const url = ApiURL(
				`api/auth/users${_qp?.length > 0 ? `?${_qp.join('&')}` : ''}`
			);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchUpdate = (data: any): Promise<any> =>
		new Promise(async (resolve, reject) => {
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const requestOptions = {
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL('/api/auth/user'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchUpdateUserForAdmin = (data: any): Promise<any> =>
		new Promise(async (resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL('/api/auth/users'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static exportsCsvUsers = (data: any): Promise<any> =>
		new Promise(async (resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			let _qp: string[] = [];
			if (data) _qp = [...data];

			const url = ApiURL(
				`api/auth/users/exports/csv${
					_qp?.length > 0 ? `?${_qp.join('&')}` : ''
				}`
			);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(url, requestOptions)
				.then((response: any) => {
					if (response.ok) return response.blob();
					else if (response.status === 400) throw response.json();
					throw response;
				})
				.then((blob) => {
					const file = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = file;
					a.download = `users.csv`;
					document.body.appendChild(a);
					a.click();
					a.remove();
					resolve({ status: 'success' });
				})
				.catch((error) => reject(error));
		});

	static validatePreSavedUser = (data: IFindUser): Promise<any> =>
		new Promise((resolve, reject) => {
			//TODO Obtener access token del localStorage
			const params = new URLSearchParams(Object.assign(data)).toString();
			const url = ApiURL('/api/auth/pre-signup-user?' + params);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			};
			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchUpdatePreSavedUser = (data: any): Promise<any> =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'PATCH',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL('/api/auth/pre-signup-user'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
