import PlaylistAdd from '@2fd/ant-design-icons/lib/PlaylistPlus'; //TODO: playlist_add.svg
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from '@hospy/hospy-ui';
import { useAppDispatch, useAppSelector } from '@hospy/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface ButtonAddWaitListProps {
	id: string;
	addMeWaitingList: () => void;
	block?: boolean;
}

export const ButtonAddWaitList = ({
	id,
	addMeWaitingList,
	block
}: ButtonAddWaitListProps) => {
	const { userWaitList, loadingUserWaitList: loading } = useAppSelector(
		({ spa }) => spa
	);
	const [added, setAdded] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (id) setAdded(Boolean(userWaitList?.find((e: any) => e._id === id)));
	}, [id]);

	useEffect(() => {
		if (loading === 'idle') {
			setAdded(Boolean(userWaitList?.find((e: any) => e._id === id)));
		}
	}, [loading, userWaitList]);

	// useEffect(() => {
	// 	const fetch = async () => {
	// 		await dispatch(setLoadingUserWaitList('idle'));
	// 		dispatch(fetchUserWaitList());
	// 		message.success('Aplicación agregada a la lista correctamente');
	// 	};
	// 	if (loading === 'succeeded' && !added) {
	// 		fetch();
	// 	}
	// }, [loading]);

	if (added) {
		return (
			<LabelAdded>
				<CheckCircleOutlined style={{ fontSize: 20 }} />
				<FormattedMessage
					id="button-add-wait-list.addedSuccess"
					defaultMessage="Agregado a tu lista"
				/>
			</LabelAdded>
		);
	}

	return (
		<Button
			type="primary"
			icon={<PlaylistAdd />}
			block={block !== undefined ? block : true}
			onClick={addMeWaitingList}
			loading={loading === 'pending'}
		>
			<FormattedMessage
				id="button-add-wait-list.text"
				defaultMessage="Agregarme a la lista de espera"
			/>
		</Button>
	);
};

export default ButtonAddWaitList;

const LabelAdded = styled.div`
	height: ${({ theme }) => theme.input.height.middle}px;
	background-color: ${({ theme }) => theme.colors.success.base};
	color: #fff;
	display: flex;
	gap: 15px;
	padding: 0 25px 0 20px;
	align-items: center;
`;
