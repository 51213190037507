/* eslint-disable @typescript-eslint/no-explicit-any */
import { LottieIcon } from '@hospy/hospy-ui';
import { APPS, useAppDispatch, useAppSelector } from '@hospy/store';
import { Col, Row } from 'antd';
import { fetchGetAppData } from 'libs/store/src/lib/common/common.thunks';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Container } from '../../UI/template/account';
import { LanguageSelector } from '../../components/language-selector';
import Info from './components/info';
import LoginForm from './components/login-form';
import MFAForm from './components/mfa-form';
import useLogin from './hook/use-login';
import { useIntercom } from 'react-use-intercom';

type Info = 'TRA' | 'PAYMENT_COLLECT' | undefined;

const traId = '63755983daa4cef95db5d226';
const paymentId = '635b5c83370446c2585b7818';
const siigoConnectId = '63703fd5b7a852979f10e0d1';
const eFacturaSatId = '64011389e2103240c429d662';
const eFacturaAfipId = '641cd190107120c7f31e47ab';
const scanId = '65e8c12a74bd646992ce8030';

const ScanIdLogo = () => {
	return (
		<ScanLogoContainer>
			<SpanLogo>SCAN ID</SpanLogo>
		</ScanLogoContainer>
	);
};

const ScanLogoContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
`;

const SpanLogo = styled.span`
	font-size: 26px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary};
	background-color: ${({ theme }) => theme.colors.gray.lighten?.['60%']};
`;

export const Login = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const theme: any = useTheme();

	const { ...propsLogin } = useLogin({ location });
	const { user, isAuth } = useAppSelector((state: any) => state.user);
	const { appData } = useAppSelector(({ common }) => common);

	const [appId, setAppId] = useState<string>();
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [isDeveloper, setIsDeveloper] = useState<boolean>(false);
	const { shutdown } = useIntercom();

	const navigatePathname = useMemo(() => {
		const state = location.state as { from: any };
		if (state && state.from) return state.from?.pathname;
		return '/';
	}, [location]);

	useEffect(() => {
		if (
			process.env['NX_APP_COLOMBIA_SIRE_TRA']?.includes(
				window.location.origin
			)
		)
			setAppId(traId);
		else if (
			process.env['NX_APP_REVENUE']?.includes(window.location.origin)
		)
			setAppId(paymentId);
		else if (process.env['NX_APP_ADMIN']?.includes(window.location.origin))
			setIsAdmin(true);
		else if (
			process.env['NX_APP_DEVELOPER']?.includes(window.location.origin)
		)
			setIsDeveloper(true);
		else if (
			process.env['NX_APP_SIIGO_CONNECT']?.includes(
				window.location.origin
			)
		)
			setAppId(siigoConnectId);
		else if (
			process.env['NX_APP_EFACTURA_SAT']?.includes(window.location.origin)
		)
			setAppId(eFacturaSatId);
		else if (
			process.env['NX_APP_EFACTURA_AFIP']?.includes(
				window.location.origin
			)
		)
			setAppId(eFacturaAfipId);
		else if (
			process.env['NX_APP_SCAN_ID']?.includes(window.location.origin)
		)
			setAppId(scanId);
	}, []);

	useEffect(() => {
		if (appId) dispatch(fetchGetAppData(appId));
	}, [appId]);

	if (isAuth && user) return <Navigate to={navigatePathname} replace />;
	shutdown();

	const external = theme.screens.xs ? null : (
		<>
			{appData?._id === traId && (
				<div
					style={{
						position: 'fixed',
						bottom: -100,
						right: 100
					}}
				>
					{/* <LordIcon
						src="https://cdn.lordicon.com/opgrsnej.json"
						trigger="loop"
						delay={3500}
						colors={{
							secondary: '#e4e4e4',
							tertiary: '#4cb4fd',
							quaternary: '#fafafa'
						}}
						size={500}
					/> */}
					<LottieIcon
						json="buildings"
						delay={3500}
						loop
						style={{ width: 500 }}
					/>
				</div>
			)}
			{appData?._id === paymentId && (
				<div
					style={{
						position: 'fixed',
						bottom: -200,
						right: -100
					}}
				>
					{/* <LordIcon
						src="https://cdn.lordicon.com/znuhpvfa.json"
						trigger="loop"
						delay={3500}
						colors={{
							primary: '#0a2e5c',
							secondary: '#4bb3fd',
							tertiary: '#f9c9c0',
							quaternary: '#ebe6ef'
						}}
						size={600}
					/> */}
					<LottieIcon
						json="tapping-credit-card"
						loop
						delay={3500}
						style={{ width: 600 }}
					/>
				</div>
			)}
			{isAdmin && (
				<div
					style={{
						position: 'fixed',
						bottom: -50,
						right: 100
					}}
				>
					{/* <LordIcon
						src="https://cdn.lordicon.com/btufwjyp.json"
						trigger="loop"
						delay={2000}
						colors={{
							primary: '#3080e8',
							secondary: '#66d7ee',
							tertiary: '#ebe6ef'
						}}
						size={500}
					/> */}
					<LottieIcon
						json="game-pad"
						delay={3500}
						loop
						style={{ width: 500 }}
					/>
				</div>
			)}
			{isDeveloper && (
				<div
					style={{
						position: 'fixed',
						bottom: -50,
						right: 100
					}}
				>
					{/* <LordIcon
						src="https://cdn.lordicon.com/srkjsqaj.json"
						trigger="loop"
						delay={2000}
						colors={{
							primary: '#3080e8',
							secondary: '#66d7ee',
							tertiary: '#ebe6ef'
						}}
						size={500}
					/> */}
					<LottieIcon
						json="mouse-scroll"
						delay={3500}
						loop
						style={{ width: 500 }}
					/>
				</div>
			)}
		</>
	);

	let appInfoSection = null;
	if ((appData || isAdmin) && theme.screens.xs) {
		if (isAdmin) appInfoSection = <Info typeApp="admin" />;
		else if (isDeveloper) appInfoSection = <Info typeApp="developer" />;
		else appInfoSection = <Info />;
	}

	if (appId === APPS.SCAN_ID) appInfoSection = <ScanIdLogo />;

	return (
		<Container externalContainer={external}>
			<Row
				style={{
					marginTop: appData ? '40px' : '0',
					marginBottom: appData ? '20px' : '0'
				}}
			>
				<Col xs={24} sm={24} lg={9}>
					{propsLogin.typeForm === 'LOGIN' && (
						<LoginForm
							appId={appId}
							appInfoSection={appInfoSection}
							privacyPoliciesUrl={appData?.privacyPoliciesUrl}
							termsAndConditionsUrl={
								appData?.termsAndConditionsUrl
							}
							{...propsLogin}
						/>
					)}
					{propsLogin.typeForm === 'MFA_AUTH' && (
						<MFAForm {...propsLogin} />
					)}
					{appId !== APPS.SCAN_ID && (
						<LanguageWrapper>
							<LanguageSelector />
						</LanguageWrapper>
					)}
				</Col>
				{!theme.screens.xs && (
					<Col xs={24} sm={24} lg={15}>
						{appData && <Info />}
						{isAdmin && <Info typeApp="admin" />}
						{isDeveloper && <Info typeApp="developer" />}
					</Col>
				)}
			</Row>
		</Container>
	);
};

const LanguageWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;
`;

export default Login;
