import { Input } from 'antd';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import { useDebounce } from '@hospy/shared-components';
import SearchOutlined from '@ant-design/icons/SearchOutlined';

interface Props {
	onSearch: (search: string) => any;
}

const Search: React.FC<Props> = ({ onSearch }) => {
	const [value, setValue] = useState('');
	const debouncedSearch = useDebounce(value, 500);

	useEffect(() => {
		onSearch(debouncedSearch);
	}, [debouncedSearch]);

	return (
		<SearchInput
			prefix={<SearchOutlined />}
			size="large"
			onChange={(e) => setValue(e.target.value)}
			allowClear
		/>
	);
};

export default Search;

const SearchInput = styled(Input)`
	margin-top: 10px;
	border-radius: 45px;
	&,
	& .ant-input {
		background-color: ${({ theme }) => theme.colors.gray.lighten!['90%']};
	}
	.anticon.anticon-close-circle {
		font-size: 21.5px;
		:hover {
			color: initial;
		}
	}
`;
