import { FileUnknownOutlined } from '@ant-design/icons';
import { Icon as HospyIcon } from '@hospy/hospy-ui';
import { snakeCase } from 'lodash';
import React from 'react';

const cdn = 'https://cdn.hospy.co';

const resolveIcon = (folder: string, name: string) => {
	let Icon = null;
	try {

		if (name.includes("Outlined")) {
			Icon = React.forwardRef((props, ref) => {
				return <HospyIcon
					src={`${cdn}/${folder}/outlined/${snakeCase(name.replace('Outlined', ''))}.svg`}
					{...props}
				/>
			});
		} else {
			Icon = React.forwardRef((props, ref) => {
				return <HospyIcon
					src={`${cdn}/${folder}/${snakeCase(name)}.svg`}
					{...props}
				/>
			});
		}
	} catch (error: any) {
		console.error(error)
		return null;
	}
	return Icon;
}

export const useUiApps = () => {
	const getIcon: any = (icon: {
		type: 'icons-material' | 'icons-ant' | 'icons-hospy';
		name: string;
		active?: boolean;
	}) => {
		const DefaultIcon = FileUnknownOutlined;
		
		try {
			if (['third-party-app-icons'].includes(icon?.type?.toLowerCase())) { 
				return `${cdn}/${icon.type.toLowerCase()}/${icon.name.toLowerCase()}${(icon.active ? '-active' : '')}.png`;
			}
			let Icon = null;
			if (icon?.type && icon?.name) {
				Icon = resolveIcon(icon.type, icon.name);
			}

			return Icon || DefaultIcon;

		} catch (error) {
			console.error(error);
		}
		return DefaultIcon;
	};

	return {
		getIcon
	};
};

export default useUiApps;
