import { AsyncV2 } from '@hospy/store/common/common.interfaces';
import { IAdminpaymentsInitialState } from './interface';

export const AdminPaymentsSliceName = 'adminPayments';

const initialAsync: AsyncV2<any> = {
	loading: 'idle',
	error: undefined,
	payload: undefined
};
export const AdminPaymentsInitialState: IAdminpaymentsInitialState = {
	upsellingBankAccounts: initialAsync,
	upsellingBankAccountsTransfers: initialAsync
};
