import { IGuestReportState } from './interfaces';

export const initialState = {
	loading: {
		findReportCategories: false,
		findReports: false,
		deleteReport: false,
		createReport: false
	},
	reportCategories: [],
	reports: [],
	nextPage: 0,
	query: '',
	countries: []
} as IGuestReportState;
