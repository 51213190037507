/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import moment from 'moment';

interface IGetDaysExpirePassword {
	daysExpire: number;
	isExpire: boolean;
}

export interface ISessionMonitoring {
	getDaysExpirePassword: () => Promise<IGetDaysExpirePassword>;
}

const useSessionMonitoring = (): ISessionMonitoring => {
	const { user } = useAppSelector((state) => state.user);

	const getDaysExpirePassword = async (): Promise<IGetDaysExpirePassword> => {
		const session = await cognitoService.getSession();

		const isGoogleUser = session?.idToken?.payload.identities?.find(
			(provider: any) => provider.providerName === 'Google'
		);
		const local_date = moment.utc(user?.lastPasswordUpdatedAt).local();
		const now = moment();
		const days = now.diff(local_date, 'days');

		return {
			daysExpire: 90 - days,
			isExpire:
				!!user && days > 90 && !isGoogleUser && !user.userViewAdmin
		};
	};

	return {
		getDaysExpirePassword
	};
};

export default useSessionMonitoring;
