/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	fetchGetAutoBillInfo,
	fetchGetProperties
} from './thunks';

export const propertySlice = createSlice({
	name: 'property',
	initialState,
	reducers: {
		setStateProperty: (state: any, action) => {
			state[action.payload.state] = action.payload.value;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGetProperties.pending, (state) => {
				state.properties.loading = 'pending';
				state.properties.error = undefined;
			})
			.addCase(fetchGetProperties.rejected, (state, { payload }) => {
				state.properties.loading = 'idle';
				state.properties.error = payload;
			})
			.addCase(fetchGetProperties.fulfilled, (state, { payload }) => {
				state.properties.loading = 'idle';
				state.properties.data = payload;
			})

		builder
			.addCase(fetchGetAutoBillInfo.pending, (state) => {
				state.propertyBillInfo.loading = 'pending';
				state.propertyBillInfo.error = undefined;
			})
			.addCase(fetchGetAutoBillInfo.rejected, (state, { payload }) => {
				state.propertyBillInfo.loading = 'failed';
				state.propertyBillInfo.error = payload;
			})
			.addCase(fetchGetAutoBillInfo.fulfilled, (state, { payload }) => {
				state.propertyBillInfo.loading = 'succeeded';
				state.propertyBillInfo.data = payload;
			})
	}
});

export const {
	setStateProperty
} = propertySlice.actions;
