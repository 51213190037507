import { Button as HospyBtn, Icon } from '@hospy/hospy-ui';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';

interface IButtonFilterProcess {
	active?: boolean;
}

const ProcessedFailedFilter = () => {
	const {
		justReservationsInProcess,
		swipeJustInvoiceInProcess,
		inProcessOrFailureCounter,
		loading
	} = useContext(InvoiceFilterContext) as InvoiceFilterContextType;

	const renderBadge = () => {
		return inProcessOrFailureCounter > 0;
	};

	if (!justReservationsInProcess)
		return (
			<ButtonFilterProcess
				onClick={swipeJustInvoiceInProcess}
				shape="round"
				disabled={loading}
			>
				{renderBadge() && (
					<BadgeCounter>{inProcessOrFailureCounter}</BadgeCounter>
				)}
				En proceso o fallidas
			</ButtonFilterProcess>
		);

	return (
		<ButtonFilterProcess
			onClick={swipeJustInvoiceInProcess}
			active
			shape="round"
		>
			En proceso y fallidas
			<Icon material="cancel" />
		</ButtonFilterProcess>
	);
};

const BadgeCounter = styled.span`
	position: absolute !important;
	right: 6px;
	bottom: 74%;
	background-color: ${({ theme }) => theme.colors.danger.base};
	color: white;
	width: 25px;
	height: 25px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
`;

const ButtonFilterProcess = styled(HospyBtn)<IButtonFilterProcess>`
	position: relative;
	background-color: transparent;
	border: 1px solid #888888;
	padding-inline: 20px;
	width: auto !important;
	display: flex;
	align-items: center;
	${({ active }) =>
		active &&
		css`
			border: 1px solid #2298e4;
			color: #2298e4;
		`}

	svg {
		font-size: 30px;
		color: ${({ theme }) => theme.colors.danger.base};
	}
`;

export default ProcessedFailedFilter;
