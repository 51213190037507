/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL, validateEmailExist } from '@hospy/util-api';
import { IVerificationCode, cognitoService } from '@hospy/util-auth';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../login/hook/use-login';

export interface IUseVerificationCode {
	onSubmit: (data: IVerificationCode) => Promise<void>;
	loading: boolean;
	loadingConfirmed?: boolean;
	confirmed?: boolean;
	resendConfirmationCode: () => Promise<void>;
}

const useVerificationCode = (
	username: string,
	password: string,
	location: any
): IUseVerificationCode => {
	const [loading, setLoading] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [loadingConfirmed, setLoadingConfirmed] = useState(true);
	const [userSub, setUserSub] = useState('');

	const navigate = useNavigate();
	const { login } = useLogin();

	useEffect(() => {
		const fetch = async () => {
			try {
				const user = await validateEmailExist(username);
				setConfirmed(user.confirmed);
				setUserSub(user.userSub);
				if (user.confirmed) {
					const { pathname, search, ...rest } = location.state;
					if (pathname) {
						navigate(pathname + search, { state: { ...rest } });
						return;
					}
					navigate('/');
				}
			} catch (error: any) {
				console.error({ error });
			} finally {
				setLoadingConfirmed(false);
			}
		};
		if (username && password) {
			fetch();
		}
	}, []);

	const onSubmit = async ({ verificationCode }: IVerificationCode) => {
		if (!username) return;
		setLoading(true);
		try {
			const rs = await cognitoService.confirmRegistration(
				username,
				verificationCode
			);
			const { user } = await activeUser();
			if (rs === 'SUCCESS' && user.userConfirmed)
				await completeVerification();
		} catch (error: any) {
			if (
				error.message ===
				'User cannot be confirmed. Current status is CONFIRMED'
			) {
				const { user } = await activeUser();
				if (user.userConfirmed) {
					await completeVerification();
					return;
				}
			}
			if (error.code === 'ExpiredCodeException') {
				message.error('El código de confirmación ha caducado');
				return;
			}
			message.error(
				'Ha ocurrido un error en el proceso, por favor intente nuevamente'
			);
		} finally {
			setLoading(false);
		}
	};

	const completeVerification = async () => {
		await cognitoService.login({ username, password });
		await login(location.state);
		message.success('Felicidades, tu cuenta ya está activa');
	};

	const resendConfirmationCode = async () => {
		if (username) {
			setLoading(true);
			await cognitoService.resendConfirmationCode(username);
			setLoading(false);
			message.success('Código de verificación enviado correctamente');
		}
	};

	const activeUser: any = async () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ userSub })
		};
		return new Promise((resolve, reject) => {
			fetch(ApiURL('api/auth/active-user'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
	};

	return {
		loading,
		onSubmit,
		confirmed,
		loadingConfirmed,
		resendConfirmationCode
	};
};

export default useVerificationCode;
