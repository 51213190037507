import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminPaymentsSliceName } from './constants';
import {
	upsellingGetBankAccounts,
	upsellingAuthorizeBankAccounts,
	upsellingGetBankAccountsTransfers
} from '@hospy/util-api';
import { RequestCreate, RequestFindAll } from '@hospy/util-api/requests';

export const ThunkFindAllBankAccounts = createAsyncThunk(
	`${AdminPaymentsSliceName}/${upsellingGetBankAccounts.name}`,
	async (params: Partial<RequestFindAll>, { rejectWithValue }) => {
		const response = await upsellingGetBankAccounts(params as any);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkAuthorizeBankAccounts = createAsyncThunk(
	`${AdminPaymentsSliceName}/${upsellingAuthorizeBankAccounts.name}`,
	async (params: Partial<RequestCreate>, { rejectWithValue }) => {
		const response = await upsellingAuthorizeBankAccounts(params as any);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkBankAccountsTransfers = createAsyncThunk(
	`${AdminPaymentsSliceName}/${upsellingGetBankAccountsTransfers.name}`,
	async (params: Partial<RequestCreate>, { rejectWithValue }) => {
		const response = await upsellingGetBankAccountsTransfers(params as any);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
