import React from 'react';

type Json =
	| 'check'
	| 'check-fill'
	| 'clock'
	| 'error'
	| 'rocket'
	| 'server'
	| 'interface-settings'
	| 'buildings'
	| 'game-pad'
	| 'tapping-credit-card'
	| 'mouse-scroll'
	| 'loader';

interface LottieIconProps {
	json?: Json;
	animationData?: any;
	loop?: boolean;
	autoplay?: boolean;
	speed?: number;
	delay?: number;
	style?: React.CSSProperties;
}

export const LottieIcon: React.FC<LottieIconProps> = ({
	json,
	animationData,
	loop = false,
	autoplay = true,
	speed = 1,
	style,
	delay
}) => {
	return (
		<img
			alt={`icon-${json}`}
			src={`https://cdn.hospy.co/lottie-icons/${json}.gif`}
			width={style?.width || '500'}
			height={style?.height || style?.width || '500'}
		/>
	);
};
export default LottieIcon;
