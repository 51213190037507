import { Radio as RadioAnt, RadioGroupProps } from 'antd';
import styled, { css } from 'styled-components';

type groupProps = {
	direction?: 'column' | 'row';
	gap?: number;
	type?: 'primary';
} & RadioGroupProps;

const Button = styled(RadioAnt.Button)``;

const Group = styled(RadioAnt.Group)<groupProps>`
	display: flex;
	gap: ${({ gap }) => gap || 0}px;
	flex-direction: ${({ direction }) => direction || 'row'};
	${({ type, direction }) =>
		type === 'primary' &&
		css`
			${Button} {
				&:hover {
					color: ${({ theme }) => theme.colors.primary.base};
					border-color: ${({ theme }) => theme.colors.primary.base};
				}

				&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
					color: ${({ theme }) => theme.colors.primary.base};
					background: ${({ theme }) =>
						theme.colors.primary.lighten['90%']};
					border-color: ${({ theme }) => theme.colors.primary.base};
				}

				&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
					background-color: ${({ theme }) =>
						theme.colors.primary.base};
				}

				${direction === 'column' &&
				css`
					&.ant-radio-button-wrapper {
						border-left-width: 1px;
					}
					&.ant-radio-button-wrapper:not(:first-child)::before {
						display: none;
					}
				`}
			}
		`}
`;

export const Radio = {
	Group,
	Button
};
export default Radio;
