import { ISubscriptionsState } from './interfaces';

const initialAsync = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const initialState = {
	subscription: initialAsync,

	customer: initialAsync,
	updateCustomer: initialAsync,

	intentStripe: initialAsync,
	paymentMethods: initialAsync,
	deletePaymentMethod: initialAsync,
	updateCustomerStripe: initialAsync,

	invoices: initialAsync,

	discounts: initialAsync,
	createDiscount: initialAsync,
	deleteDiscount: initialAsync,
	subscriptionToRenew: initialAsync
} as ISubscriptionsState;
