import { Button } from '@hospy/hospy-ui';
import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';

const AdditionalFilters = () => {
	const { loading, updateAdditionalFilters } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;

	const [selectedFilter, setSelectedFilter] = useState('');

	const onFilterClick = (filter: string) => {
		if (selectedFilter === filter) {
			setSelectedFilter('');
			updateAdditionalFilters([]);
			return;
		}
		setSelectedFilter(filter);
		updateAdditionalFilters([filter]);
	};

	return (
		<AdditionalFiltersWrapper>
			<FilterButton
				isSelected={selectedFilter === 'open'}
				onClick={() => onFilterClick('open')}
				shape="round"
				disabled={loading}
			>
				Cuentas abiertas
			</FilterButton>
			<FilterButton
				isSelected={selectedFilter === 'closed'}
				onClick={() => onFilterClick('closed')}
				shape="round"
				disabled={loading}
			>
				Cuentas cerradas
			</FilterButton>
		</AdditionalFiltersWrapper>
	);
};

const FilterButton = styled(({ isSelected, ...props }) => (
	<Button {...props} />
))`
	position: relative;
	${({ theme, isSelected }) =>
		isSelected
			? css`
					background-color: ${theme.colors.primary.base} !important;
					color: white !important;
			  `
			: css`
					background-color: white !important;
					color: black !important;
			  `};
	svg {
		font-size: 22px;
	}
`;

const AdditionalFiltersWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

export default AdditionalFilters;
