import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './common.constants';
import {
	createQuotation,
	fetchGetAppData,
	getAllCurrencyRates,
	getCountries,
	getCurrencyRate,
	initRealTime
} from './common.thunks';
import { APPS } from './common.types';

export const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setFullWith: (state, { payload }) => {
			state.fullWidth = payload;
		},
		setAppData: (state, { payload }) => {
			state.appData = payload;
		},
		resetQuotation: (state) => {
			state.quotation = {
				loading: 'idle'
			};
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchGetAppData.fulfilled, (state, { payload }) => {
			if (!state.appData) state.appData = payload;
			if (payload._id === APPS.SIIGO_CONNECT) {
				state.appDataSiigo = payload;
			}
		});
		builder.addCase(initRealTime.fulfilled, (state, { payload }) => {
			state.realtime = payload;
		});

		builder
			.addCase(getCurrencyRate.pending, (state) => {
				state.currencyRate = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getCurrencyRate.rejected, (state, { payload }) => {
				state.currencyRate = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(getCurrencyRate.fulfilled, (state, { payload }) => {
				state.currencyRate = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(getAllCurrencyRates.pending, (state) => {
				state.currencyRates = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getAllCurrencyRates.rejected, (state, { payload }) => {
				state.currencyRates = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(getAllCurrencyRates.fulfilled, (state, { payload }) => {
				state.currencyRates = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(getCountries.pending, (state) => {
				state.countries.loading = 'pending';
			})
			.addCase(getCountries.rejected, (state, { payload }) => {
				state.countries.error = payload;
				state.countries.loading = 'failed';
			})
			.addCase(getCountries.fulfilled, (state, { payload }) => {
				state.countries.loading = 'succeeded';
				state.countries.data = payload;
			});

		builder
			.addCase(createQuotation.pending, (state) => {
				state.quotation.loading = 'pending';
			})
			.addCase(createQuotation.rejected, (state, { payload }) => {
				state.quotation = {
					loading: 'failed',
					error: payload
				};
			})
			.addCase(createQuotation.fulfilled, (state, { payload }) => {
				state.quotation = {
					loading: 'succeeded',
					data: payload
				};
			});
	}
});

export const { setFullWith, setAppData, resetQuotation } = commonSlice.actions;
