/* eslint-disable @typescript-eslint/no-explicit-any */
// /* eslint-disable @typescript-eslint/no-explicit-any */
// import { createSlice } from '@reduxjs/toolkit';
// import { siigoInitialState } from './constants';
// import { SiigoConnectInitialState } from './interface';
// import {
// 	accountingApplyCreditNote,
// 	accountingDeleteInvoice,
// 	accountingDestinyAccomodations,
// 	accountingDestinyGetTaxes,
// 	accountingGetCostCenters,
// 	accountingGetDestinyProducts,
// 	accountingGetDocumentTypes,
// 	accountingGetInvoice,
// 	accountingGetOriginProducts,
// 	accountingGetPaymentMethods,
// 	accountingGetPaymentTypes,
// 	accountingGetReservations,
// 	accountingGetStamp,
// 	accountingGetTaxes,
// 	accountingInvoiceStamp,
// 	accountingInvoiceStampErrors,
// 	accountingOriginAccomodations,
// 	accountingOriginGetTaxes,
// 	accountingUpdateInvoice,
// 	createFieldsMapping,
// 	crmCustomerDocumentTypes,
// 	crmUsers,
// 	retryAdvance,
// 	retryJournal,
// 	siigoUpdateStampInvoice
// } from './thunks';

import { createSlice } from '@reduxjs/toolkit';
import { analyticsInitialState } from './constants';
import { AnalyticsInitialState } from './interface';
import {
	analyticsGetApps,
	analyticsGetCjm,
	analyticsGetCountries,
	analyticsGetDataProcessed,
	analyticsGetPartners,
	analyticsGetPropertySubscriptions,
	analyticsGetResume,
	getSellByApplication,
	analyticsGetSireTraData,
	analyticsGetSiigoData,
	analyticsGetSatData,
	analyticsGetCheckinData,
	analyticsGetIMRData
} from './thunks';

export const analyticsSlice = createSlice({
	name: 'analytics',
	initialState: analyticsInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(
				getSellByApplication.pending,
				(state: AnalyticsInitialState) => {
					state.sellByApplication.loading = 'pending';
				}
			)
			.addCase(
				getSellByApplication.rejected,
				(state: AnalyticsInitialState, payload: any) => {
					state.sellByApplication = {
						loading: 'failed',
						error: payload,
						data: undefined
					};
				}
			)
			.addCase(
				getSellByApplication.fulfilled,
				(state: AnalyticsInitialState, { payload }: any) => {
					state.sellByApplication = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(analyticsGetApps.pending, (state) => {
				state.apps.loading = 'pending';
			})
			.addCase(analyticsGetApps.rejected, (state, { payload }) => {
				state.apps = {
					...state.apps,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetApps.fulfilled, (state, { payload }) => {
				state.apps = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(analyticsGetCountries.pending, (state) => {
				state.countries.loading = 'pending';
			})
			.addCase(analyticsGetCountries.rejected, (state, { payload }) => {
				state.countries = {
					...state.countries,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetCountries.fulfilled, (state, { payload }) => {
				state.countries = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(analyticsGetPropertySubscriptions.pending, (state) => {
				state.propertySubscriptions.loading = 'pending';
			})
			.addCase(
				analyticsGetPropertySubscriptions.rejected,
				(state, { payload }) => {
					state.propertySubscriptions = {
						...state.propertySubscriptions,
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				analyticsGetPropertySubscriptions.fulfilled,
				(state, { payload }) => {
					state.propertySubscriptions = {
						error: undefined,
						loading: 'succeeded',
						data: payload
					};
				}
			);

		builder
			.addCase(analyticsGetCjm.pending, (state) => {
				state.cjm.loading = 'pending';
			})
			.addCase(analyticsGetCjm.rejected, (state, { payload }) => {
				state.cjm = {
					...state.cjm,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetCjm.fulfilled, (state, { payload }) => {
				state.cjm = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(analyticsGetDataProcessed.pending, (state) => {
				state.dataProcessed.loading = 'pending';
			})
			.addCase(
				analyticsGetDataProcessed.rejected,
				(state, { payload }) => {
					state.dataProcessed = {
						...state.dataProcessed,
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				analyticsGetDataProcessed.fulfilled,
				(state, { payload }) => {
					state.dataProcessed = {
						error: undefined,
						loading: 'succeeded',
						data: payload
					};
				}
			);
		builder
			.addCase(analyticsGetSireTraData.pending, (state) => {
				state.sireTraData.loading = 'pending';
			})
			.addCase(analyticsGetSireTraData.rejected, (state, { payload }) => {
				state.sireTraData = {
					...state.sireTraData,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(
				analyticsGetSireTraData.fulfilled,
				(state, { payload }) => {
					state.sireTraData = {
						error: undefined,
						loading: 'succeeded',
						data: payload
					};
				}
			);
		builder
			.addCase(analyticsGetSiigoData.pending, (state) => {
				state.siigoData.loading = 'pending';
			})
			.addCase(analyticsGetSiigoData.rejected, (state, { payload }) => {
				state.siigoData = {
					...state.siigoData,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetSiigoData.fulfilled, (state, { payload }) => {
				state.siigoData = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});
		builder
			.addCase(analyticsGetSatData.pending, (state) => {
				state.satData.loading = 'pending';
			})
			.addCase(analyticsGetSatData.rejected, (state, { payload }) => {
				state.satData = {
					...state.satData,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetSatData.fulfilled, (state, { payload }) => {
				state.satData = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});
		builder
			.addCase(analyticsGetCheckinData.pending, (state) => {
				state.checkinData.loading = 'pending';
			})
			.addCase(analyticsGetCheckinData.rejected, (state, { payload }) => {
				state.checkinData = {
					...state.checkinData,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(
				analyticsGetCheckinData.fulfilled,
				(state, { payload }) => {
					state.checkinData = {
						error: undefined,
						loading: 'succeeded',
						data: payload
					};
				}
			);
		builder
			.addCase(analyticsGetIMRData.pending, (state) => {
				state.imrData.loading = 'pending';
			})
			.addCase(analyticsGetIMRData.rejected, (state, { payload }) => {
				state.imrData = {
					...state.imrData,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetIMRData.fulfilled, (state, { payload }) => {
				state.imrData = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});
		builder
			.addCase(analyticsGetPartners.pending, (state) => {
				state.partners.loading = 'pending';
			})
			.addCase(analyticsGetPartners.rejected, (state, { payload }) => {
				state.partners = {
					...state.partners,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetPartners.fulfilled, (state, { payload }) => {
				state.partners = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(analyticsGetResume.pending, (state) => {
				state.resume.loading = 'pending';
			})
			.addCase(analyticsGetResume.rejected, (state, { payload }) => {
				state.resume = {
					...state.resume,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(analyticsGetResume.fulfilled, (state, { payload }) => {
				state.resume = {
					error: undefined,
					loading: 'succeeded',
					data: payload
				};
			});
	}
});
