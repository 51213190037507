import { motion } from 'framer-motion';
import styled from 'styled-components';
import { StepTitle } from '..';

interface Props {
	step: string;
	stepTitles: StepTitle[];
	direction: 1 | -1;
}

const Title = ({ step, stepTitles, direction }: Props) => {
	return (
		<Container>
			<Label>
				Paso
				<Number
					key={`step-container-title-${step}`}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					{stepTitles.map(
						(e: StepTitle, index: number) =>
							e.step === step && (e.number ? e.number : index + 1)
					)}
				</Number>
				:
				<Text
					key={`step-${step}`}
					initial={{ y: direction === 1 ? 10 : -10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: direction === 1 ? -10 : 10, opacity: 0 }}
				>
					{stepTitles.map(
						(e: StepTitle, index: number) =>
							e.step === step && e.title
					)}
				</Text>
			</Label>
		</Container>
	);
};
export default Title;

const Container = styled.div`
	text-align: center;
	margin-bottom: 30px;
`;

const Label = styled.span`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary.base};
`;

const Text = styled(motion.div)`
	display: inline-block;
	margin-left: 5px;
	font-weight: normal;
`;

const Number = styled(motion.span)`
	margin-left: 5px;
	font-weight: normal;
`;
