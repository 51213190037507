import { Tag } from 'antd';
import styled from 'styled-components';

interface Props {
	name: string;
}

export function IconName(props: Props) {

    const { name } = props;

      const getInitialsName = (name: any) => {
		const names = name.split(' ')
		if(names.length > 1) return names[0].charAt(0) + names[1].charAt(0)
		return names[0].charAt(0);
	}

    return (
        <TagStyle>{getInitialsName(name)}</TagStyle>
    );
};

const TagStyle = styled(Tag)`
	background: ${({ theme }) => theme.colors.primary.base};
	color: white;
	font-size: 15px;
	border-radius: 100px;
	width: 40px;
	height: 40px;
	align-items: center;
	justify-content: center;
	display: flex;
`