/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { guestApi } from './guestAPI';
import {
	IValidatePartnerIntegration,
	IValidatePartnerIntegrationOauth
} from './interfaces';

export const fetchPartnerIntegrations = createAsyncThunk(
	'guest/getPartnerIntegrations',
	async (queryParams?: string[]) => {
		const response = await guestApi.getPartnersIntegrations(queryParams);
		return response.data;
	}
);

export const validatePartnerIntegrationApiKey = createAsyncThunk(
	'guest/validatePartnerIntegrationApiKey',
	async (
		{ apiKey, accessToken }: IValidatePartnerIntegration,
		{ getState }
	) => {
		try {
			const state: any = getState();
			const response = await guestApi.validatePartnerIntegrationApiKey(
				state.guest.selectedPms,
				apiKey,
				accessToken
			);

			if (response.data) return { ...response.data, apiKey };
			return response;
		} catch (error) {
			return error;
		}
	}
);

export const validatePartnerIntegrationOauth = createAsyncThunk(
	'guest/validatePartnerIntegrationOauth',
	async ({ code }: IValidatePartnerIntegrationOauth, { getState }) => {
		try {
			const state: any = getState();
			const response = await guestApi.validatePartnerIntegrationOauth(
				state.guest.selectedPms,
				code,
				state.guest.oauthCredentials.clientId,
				state.guest.oauthCredentials.clientSecret
			);

			return response.data;
		} catch (error) {
			return error;
		}
	}
);
