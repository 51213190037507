import { IThirdPartyReservationsState } from './interfaces';

export const initialState = {
	theme: 'light',
	checkInReservations: undefined,
	checkOutReservations: undefined,
	loading: {
		findThirdPartyReservationCheckIn: 'idle',
		findThirdPartyReservationCheckOut: 'idle',
		updateThirdPartyReservation: 'idle',
		deleteThirdPartyReservation: 'idle'
	},
	success: {
		findThirdPartyReservationCheckIn: false,
		findThirdPartyReservationCheckOut: false,
		updateThirdPartyReservation: false,
		deleteThirdPartyReservation: false
	},
	error: {
		findThirdPartyReservationCheckIn: undefined,
		findThirdPartyReservationCheckOut: undefined,
		updateThirdPartyReservation: undefined,
		deleteThirdPartyReservation: undefined
	},
	reservationDetail: {
		loading: 'idle',
		error: '',
		data: undefined
	},
	reservationSummary: {
		loading: 'idle',
		error: '',
		data: undefined
	},
	tpReservations: {
		loading: 'idle'
	},
	tpReservationsNoRegistered: {
		loading: 'idle'
	},
	tpReservationResendReports: {
		loading: 'idle'
	},
	tpCreateReservation: {
		loading: 'idle'
	},
	summaryCheckin: {
		loading: 'idle'
	}
} as IThirdPartyReservationsState;
