import { ApiURL } from '@hospy/util-api';
import { cognitoService } from '@hospy/util-auth';
import { IReservation } from './interfaces';

export class Api {
	static createReservation = (data: IReservation): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const requestOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL('/api/checkin-express/reservations'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static findReservations = (propertyId: string): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const params = new URLSearchParams({ propertyId }).toString();
			const url = ApiURL('/api/checkin-express/reservations?' + params);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				}
			};
			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
