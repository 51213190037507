/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton } from 'antd';
import styled, { css } from 'styled-components';

const InvoiceItemCardSkeleton = () => (
	<InvoiceWrapper>
		<MainInfo>
			<div style={{ display: 'flex', height: 20, marginBottom: 15 }}>
				<Skeleton.Input style={{ height: 20 }} active />
			</div>
			<div
				style={{
					display: 'flex',
					height: 18,
					width: '80%',
					overflow: 'hidden'
				}}
			>
				<Skeleton.Input style={{ height: 18 }} active />
			</div>
		</MainInfo>
		<SecondaryInfo>
			<Skeleton.Button size="small" shape="round" active />
		</SecondaryInfo>
	</InvoiceWrapper>
);

const InvoiceWrapper = styled(({ isSelected, ...props }) => (
	<button {...props} />
))`
	position: relative;
	min-width: 380px;
	height: 88px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
	padding: 1rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.base};
	box-shadow: ${({ theme }) => theme.boxShadow.level1()};
	background-color: white;
	cursor: pointer;
	${({ isSelected }) =>
		isSelected &&
		css`
			border: none;
			background-color: #edfbff;
			:after {
				content: '';
				position: absolute;
				height: 0;
				width: 0;
				left: 100%;
				top: 0;
				border: 44px solid transparent;
				border-left: 50px solid #edfbff;
			}
			:hover {
				background-color: #edfbff !important;
			}
			:active {
				background-color: #edfbff !important;
			}
		`}
`;

const MainInfo = styled(({ detailOpened, ...props }) => <div {...props} />)`
	width: 80%;
	display: flex;
	flex-direction: column;
	span {
		text-align: start;
	}
	${({ theme, detailOpened }) =>
		(theme.screens.sm || !detailOpened) &&
		css`
			width: 50%;
			grid-template-columns: repeat(1, 1fr);
		`}
`;

const SecondaryInfo = styled.div`
	display: flex;
	/* flex-direction: column-reverse; */
	align-items: center;
	height: 100%;
	${({ theme }) =>
		theme.screens['sm'] &&
		css`
			flex-direction: row;
			gap: 20px;
		`}
`;

export default InvoiceItemCardSkeleton;
