import {  AsyncV2 } from '@hospy/store/common/common.interfaces';
import {IListingsInitialState} from './interface'
export const ListingsSliceName = 'Listings'

const initialAsync: AsyncV2<any> = {
	loading: 'idle',
	error: undefined, // error.message
	payload: undefined // var.data.data {data: any}
}; 
export const ListingsInitialState: IListingsInitialState = {
	findAllListings: initialAsync,
	updateListings: initialAsync,
	removeListings: initialAsync,
	importListings: initialAsync

};
