/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CurrencyInput, Icon, Modal } from '@hospy/hospy-ui';
import {
	ThunkCreateStampBulk,
	getAllCurrencyRates,
	resetStampBulkInvoices,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Form, Typography, message } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import 'swiper/css';
import 'swiper/css/virtual';
import { Swiper, SwiperSlide } from 'swiper/react';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';
import InvoiceItemCardPreview from './card-preview';

interface Props {
	onClose: () => void;
	refresh: () => void;
	open: boolean;
}

const StampBulkModal = ({ open, onClose, refresh }: Props) => {
	const dispatch = useAppDispatch();
	const { partnerIntegrationId, partnerIntegrationIdTo } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;
	const { reservationToBulk, stampBulk } = useAppSelector(
		({ partnerAccounting }) => partnerAccounting
	);
	const { currencyRates, appData } = useAppSelector(({ common }) => common);
	const { propertyId } = useAppSelector(({ user }) => user);

	const [showPrevButton, setShowPrevButton] = useState(false);
	const [showNextButton, setShowNextButton] = useState(true);

	const [conversionValue, setConversionValue] = useState<number>(1);

	const swiperRef = useRef<any>(null);
	const theme: any = useTheme();

	const [form] = Form.useForm();

	const loadingCurrency = currencyRates.loading === 'pending';

	const reservationsToConvert = reservationToBulk?.filter(
		(r) => r.currency?.toLowerCase() === 'usd'
	);

	const handleSwiper = (swiper: any) => {
		if (swiper) {
			swiper.on('slideChange', updateButtons);
			swiper.on('reachEnd', updateButtons);
			swiper.on('reachBeginning', updateButtons);
		}
	};

	const updateButtons = useCallback(() => {
		if (swiperRef?.current) {
			setShowPrevButton(!swiperRef?.current?.swiper?.isBeginning);
			setShowNextButton(!swiperRef?.current?.swiper?.isEnd);
		}
	}, [swiperRef?.current?.swiper]);

	const onFinish = (values: any) => {
		if (!appData || !propertyId || !partnerIntegrationId) return;
		//TODO
		dispatch(
			ThunkCreateStampBulk({
				data: {
					reservations: reservationToBulk.map((r) => r.reservationID),
					currency: {
						currencyCode: 'COP',
						currencyExchangeRate: values.currency
					}
				},
				propertyId,
				partnerIntegrationId,
				partnerIntegrationIdTo: partnerIntegrationIdTo,
				appId: appData._id
			})
		);
	};

	const modifyConversionValue = (value: number) => {
		setConversionValue(value);
	};

	useEffect(() => {
		if (currencyRates.loading === 'idle') dispatch(getAllCurrencyRates());
	}, [currencyRates.loading]);

	useEffect(() => {
		if (currencyRates.loading === 'succeeded') {
			const foundCurrency = currencyRates.data?.find(
				(rate) =>
					rate.currencyIso3From === 'COP' &&
					rate.currencyIso3To === 'USD'
			);
			if (!foundCurrency) return;

			form.setFieldValue('currency', foundCurrency?.rate || 1);
			setConversionValue(foundCurrency?.rate || 1);
		}
	}, [currencyRates.loading]);

	useEffect(() => {
		if (stampBulk.loading === 'succeeded') {
			onClose();
		}

		if (stampBulk.loading === 'failed') {
			dispatch(resetStampBulkInvoices());
			message.error('No se pudo iniciar el proceso de emisión.');
		}
	}, [stampBulk.loading]);

	return (
		<Modal width={950} maxWidth={950} onClose={onClose} open={open}>
			<Wrapper>
				<TitleSection>
					<Title>Convertir totales a COP</Title>
					<Subtitle>
						<FormattedMessage
							id="partner.invoice.counter"
							defaultMessage="{reservationsToConvert, plural, =0 {Ninguna reserva a convertir} one {Una reserva a convertir} other {# reservas a convertir} }"
							values={{
								reservationsToConvert:
									reservationsToConvert?.length
							}}
						/>
					</Subtitle>
				</TitleSection>

				<Container>
					<SwiperWrapper center={reservationsToConvert?.length === 1}>
						{reservationsToConvert?.length === 1 ? (
							<InvoiceItemCardPreview
								reservation={reservationsToConvert[0]}
								partnerIntegrationId={partnerIntegrationId}
							/>
						) : (
							<>
								{showPrevButton && (
									<ButtonPrev
										shape="circle"
										onClick={() =>
											void swiperRef?.current?.swiper?.slidePrev()
										}
									>
										<Icon
											material="arrow_forward_ios"
											ant
										/>
									</ButtonPrev>
								)}
								<Swiper
									ref={swiperRef}
									slidesPerView={
										theme.screens.xs ||
										reservationsToConvert?.length === 1
											? 1
											: 2
									}
									spaceBetween={10}
									onSwiper={handleSwiper}
								>
									{reservationsToConvert?.map(
										(reservation) => (
											<SwiperSlide
												key={reservation.reservationID}
											>
												<InvoiceItemCardPreview
													reservation={reservation}
													partnerIntegrationId={
														partnerIntegrationId
													}
												/>
											</SwiperSlide>
										)
									)}
								</Swiper>
								{showNextButton && (
									<ButtonNext
										shape="circle"
										icon={
											<Icon
												material="arrow_forward_ios"
												ant
											/>
										}
										onClick={() =>
											void swiperRef?.current?.swiper?.slideNext()
										}
									/>
								)}
							</>
						)}
					</SwiperWrapper>
				</Container>

				<ConversionSection>
					<TitleConversion>Tasa de cambio</TitleConversion>

					<FormWrapper onFinish={onFinish} form={form}>
						<PriceSection>
							<SubtotalSection>
								<Form.Item name="">
									<CurrencyPreview>1 USD</CurrencyPreview>
								</Form.Item>
							</SubtotalSection>

							<IconConversion material="arrow_forward_ios" />

							<SubtotalSection>
								<Form.Item
									name="currency"
									rules={[
										{
											required: true,
											message: 'Campo requerido'
										}
									]}
								>
									<CurrencyInput
										style={{
											height: '50px',
											fontSize: 20,
											minWidth: 150,
											marginRight: -40
										}}
										prefix="COP"
										onChange={(e) => {
											modifyConversionValue(e);
										}}
										value={conversionValue}
										placeholder="0"
										disabled={loadingCurrency}
									/>
								</Form.Item>
							</SubtotalSection>
						</PriceSection>
					</FormWrapper>
				</ConversionSection>

				<ButtonSection>
					<Button
						onClick={onClose}
						disabled={stampBulk.loading === 'pending'}
						type="primary"
						danger
					>
						Cancelar facturas
					</Button>
					<Button
						loading={stampBulk.loading === 'pending'}
						onClick={() => form.submit()}
						type="primary"
					>
						Continuar
					</Button>
				</ButtonSection>
			</Wrapper>
		</Modal>
	);
};

const Title = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.middle}px;
`;

const Subtitle = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.middle}px;
`;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
`;

const Container = styled.div`
	width: 100%;
	overflow-x: hidden;
	padding-left: 40px;
	.swiper {
		overflow: visible;
	}
`;

const TitleSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ConversionSection = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
`;

const TitleConversion = styled(Typography.Text)``;

const FormWrapper = styled(Form)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	input {
		padding: 0px;
		border: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.9);
	}
`;

const PriceSection = styled.div`
	width: 30%;
	display: flex;
	align-items: center;
	gap: 10px;

	input[type='text'] {
		font-size: 20px;
		text-align: center;
	}
`;

const SubtotalSection = styled.div`
	width: min-content;
`;

const IconConversion = styled(Icon)`
	margin-bottom: 20px;
	font-size: 30px;
`;

const CurrencyPreview = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 50px;
	border: 1px solid #d9d9d9;

	font-size: 20px;
`;

const ButtonSection = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;

	button {
		width: 200px;
	}
`;

const ButtonNext = styled(Button)`
	position: absolute;
	right: 20px;
	top: 30%;
	z-index: 2;
`;

const ButtonPrev = styled(Button)`
	z-index: 2;
	position: absolute;
	left: 10px;
	top: 30%;

	svg {
		transform: scaleX(-1);
	}
`;

const SwiperWrapper = styled(({ center, ...props }) => <div {...props} />)`
	width: 96%;
	overflow-x: hidden;

	${({ center }) =>
		center &&
		css`
			display: flex;
			justify-content: center;
		`}
`;

export default StampBulkModal;
