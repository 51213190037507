/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { CustomerSliceName, initialState } from './constants';
import {
	ThunkCreateCustomer,
	ThunkFindAllCustomer,
	createCustomer,
	updateCustomer
} from './thunks';

export const partnerCustomersSlice = createSlice({
	name: CustomerSliceName,
	initialState,
	reducers: {
		cleanUpdateCustomer: (state) => {
			state.updateCustomer = initialState.updateCustomer;
		},
		cleanCreateCustomer: (state) => {
			state.createCustomer = initialState.createCustomer;
		},
		cleanCustomers: (state) => {
			state.customers = initialState.customers;
		},
		manualAddCustomer: (state, { payload }) => {
			state.customers.data = {
				data: [
					{
						companyName: payload.companyName,
						documentNumber: payload.identification,
						documentTypeId: payload.id_type?.code,
						documentTypeName: payload.id_type.name,
						email: payload.contacts[0]?.email,
						lastName: payload.name[1] || '',
						name: payload.name[0],
						personType: payload.person_type,
						phone: payload.phones[0]?.number,
						thirdPartyId: payload.id
					}
				]
			};
			state.customers.loading = 'succeeded';
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(createCustomer.pending, (state) => {
				state.createCustomer.loading = 'pending';
				state.createCustomer.error = undefined;
				state.createCustomer.data = undefined;
			})
			.addCase(createCustomer.rejected, (state, { payload }) => {
				state.createCustomer.loading = 'failed';
				state.createCustomer.error = payload;
			})
			.addCase(createCustomer.fulfilled, (state, { payload }) => {
				state.createCustomer.data = payload;
				state.createCustomer.loading = 'idle';
			})

			.addCase(updateCustomer.pending, (state) => {
				state.updateCustomer.loading = 'pending';
				state.updateCustomer.error = undefined;
				state.updateCustomer.data = undefined;
			})
			.addCase(updateCustomer.rejected, (state, { payload }) => {
				state.updateCustomer.loading = 'failed';
				state.updateCustomer.error = payload;
			})
			.addCase(updateCustomer.fulfilled, (state, { payload }) => {
				state.updateCustomer.data = payload;
				state.updateCustomer.loading = 'succeeded';
			})

			.addCase(ThunkFindAllCustomer.pending, (state) => {
				state.customers.loading = 'pending';
				state.customers.error = undefined;
				state.customers.data = undefined;
			})
			.addCase(ThunkFindAllCustomer.rejected, (state, { payload }) => {
				state.customers.loading = 'failed';
				state.customers.error = payload;
			})
			.addCase(ThunkFindAllCustomer.fulfilled, (state, { payload }) => {
				state.customers.data = payload;
				state.customers.loading = 'succeeded';
			})

			.addCase(ThunkCreateCustomer.pending, (state) => {
				state.createCustomer.loading = 'pending';
				state.createCustomer.error = undefined;
				state.createCustomer.data = undefined;
			})
			.addCase(ThunkCreateCustomer.rejected, (state, { payload }) => {
				state.createCustomer.loading = 'failed';
				state.createCustomer.error = payload;
			})
			.addCase(
				ThunkCreateCustomer.fulfilled,
				(state, { payload }: any) => {
					state.createCustomer.data = payload?.data;
					state.createCustomer.loading = 'succeeded';
				}
			);
	}
});

export const {
	cleanUpdateCustomer,
	cleanCreateCustomer,
	cleanCustomers,
	manualAddCustomer
} = partnerCustomersSlice.actions;
