import { maxDevice } from '@hospy/feature/devices';
import { Button as HospyBtn, Icon } from '@hospy/hospy-ui';
import { LanguageTextRender } from '@hospy/shared-components';
import {
	availableLanguages,
	defaultLanguage,
	ILanguageItem,
	setLanguage,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface ILanguageSelectorPopover {
	language: string;
	languages: ILanguageItem[];
	setIsVisible: (visible: boolean) => void;
}

const LanguageSelectorPopover = ({
	language,
	languages,
	setIsVisible
}: ILanguageSelectorPopover) => {
	const dispatch = useAppDispatch();

	return (
		<PopoverContent>
			{languages.map((languageItem) => (
				<LanguageButton
					key={languageItem.code}
					isSelected={languageItem.code === language}
					onClick={() => {
						window.scrollTo(0, 0);
						setIsVisible(false);
						dispatch(setLanguage(languageItem.code));
					}}
				>
					{
						languageItem.name[
							languageItem.code as keyof typeof languageItem.name
						]
					}
					{language === languageItem.code && (
						<Icon material="check" />
					)}
				</LanguageButton>
			))}
		</PopoverContent>
	);
};

export const LanguageSelector = ({ style }: { style?: React.CSSProperties }) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const { language } = useAppSelector(({ language }) => language);
	const { appData } = useAppSelector(({ common }) => common);
	const [languages, setLanguages] = useState<ILanguageItem[]>([
		availableLanguages[0]
	]);

	useEffect(() => {
		if (appData && appData.availableLanguages?.length > 0) {
			setLanguages(
				availableLanguages.filter((availableLanguage) =>
					appData.availableLanguages.includes(availableLanguage.code)
				)
			);
		} else {
			setLanguages(availableLanguages);
		}
	}, [appData]);

	return (
		<Popover
			trigger="click"
			onVisibleChange={(state) => setIsVisible(state)}
			content={
				<LanguageSelectorPopover
					language={language}
					languages={languages}
					setIsVisible={setIsVisible}
				/>
			}
			visible={isVisible}
		>
			<Button
				icon={<Icon material="language" ant />}
				style={{ ...style }}
			>
				<span>
					<LanguageTextRender
						name={
							availableLanguages.find(
								(languageItem) => languageItem.code === language
							)?.name || ''
						}
						locale={
							availableLanguages.find(
								(languageItem) => languageItem.code === language
							)?.code || defaultLanguage
						}
					/>
				</span>
			</Button>
		</Popover>
	);
};

const Button = styled(HospyBtn)`
	background-color: transparent;
	color: white;

	:hover {
		color: white;
	}

	@media ${maxDevice.laptop} {
		border: none;
		color: black;
		> span {
			color: black;
		}
	}
`;

const PopoverContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const LanguageButton = styled(HospyBtn)<{ isSelected: boolean }>`
	color: ${(props) =>
		props.isSelected
			? props.theme.colors.primary.base
			: 'rgba(0,0,0,.85)'} !important;
	border: none;
	text-align: start;
	display: flex;
	gap: 10px;

	span {
		min-width: 82px;
	}

	svg {
		margin-bottom: 6px;
	}
`;
