/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Async,
	IReservationInvoice,
	PARTNER,
	ThunkCreateStampBulk,
	cleanInvoice,
	getFieldMapping,
	setInvoiceToBulk,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect, useMemo, useState } from 'react';
import { MAX_RESERVATIONS_TO_BULK } from '../../../../context';

const channelIcons: any = {
	cloudbeds:
		'https://cdn.hospy.co/images/partner-integrations/icons/cloudbeds.svg',
	airbnb: 'https://cdn.hospy.co/images/partner-integrations/logos/airbnb4.svg',
	direct: 'https://cdn.hospy.co/images/icons/hospy.svg',
	booking:
		'https://cdn.hospy.co/images/partner-integrations/icons/booking-round.svg',
	vrbo: 'https://cdn.hospy.co/images/partner-integrations/icons/vrbo.svg',
	despegar:
		'https://cdn.hospy.co/images/partner-integrations/icons/despegar.svg',
	expedia:
		'https://cdn.hospy.co/images/partner-integrations/icons/expedia.svg',
	stays: 'https://cdn.hospy.co/images/partner-integrations/icons/stays.svg'
};

export interface Props {
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	reservations: Async;
	getReservations: (payload: any) => any;
	setReservations: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	showCreateInvoiceButton?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedReservation: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	multipleInvoices?: boolean;
	refresh?: () => void;
}

export const useInvoices = ({ reservations, ...props }: Props) => {
	const dispatch = useAppDispatch();

	const [allReservationsToBulk, setAllReservationsToBulk] =
		useState<boolean>(false);

	const { invoice, reservationToBulk, stampBulk } = useAppSelector(
		({ partnerAccounting }) => partnerAccounting
	);
	const { propertyId, propertyIntegrationsState } = useAppSelector(
		({ user }) => user
	);
	const { appData } = useAppSelector(({ common }) => common);
	const { fieldMapping } = useAppSelector(({ onboarding }) => onboarding);

	const [stampBulkModal, setStampBulkModal] = useState<boolean>(false);
	const [firstTimeTaxRequired, setFirstTimeTaxRequired] = useState<boolean>();
	const [firstTimePaymentWayRequired, setFirstTimePaymentWayRequired] =
		useState<boolean>();

	const fullReservations: IReservationInvoice[] = useMemo(() => {
		return (
			reservations.data?.data?.map((reservation: any) => {
				let channelIcon = '';
				if (
					props.partnerIntegrationId === PARTNER.AIRBNB &&
					channelIcons[
						reservation.channelName ||
							reservation.sourceName ||
							'airbnb'
					]
				) {
					channelIcon =
						channelIcons[
							reservation.channelName ||
								reservation.sourceName ||
								'airbnb'
						];
					return {
						...reservation,
						channelIcon
					};
				}
				if (
					props.partnerIntegrationId === PARTNER.CLOUDBEDS &&
					(reservation.sourceName
						?.toLowerCase()
						.includes('directa') ||
						reservation.channelName
							?.toLowerCase()
							.includes('directa'))
				) {
					return {
						...reservation,
						channelIcon: channelIcons['cloudbeds']
					};
				}

				channelIcon =
					findMatchingIcon(
						reservation.channelName || reservation.sourceName || ''
					) || channelIcons['cloudbeds'];

				if (channelIcon) {
					return {
						...reservation,
						channelIcon
					};
				}
				if (props.partnerIntegrationId === PARTNER.STAYS) {
					return {
						...reservation,
						channelIcon: channelIcons.stays
					};
				}
				return {
					...reservation,
					channelIcon: channelIcons.cloudbeds
				};
			}) || []
		);
	}, [JSON.stringify(reservations.data?.data)]);

	useEffect(() => {
		if (invoice.loading === 'succeeded')
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
	}, [invoice.loading]);

	useEffect(() => {
		if (!propertyIntegrationsState.data) return;
		const pmsIntegration = propertyIntegrationsState.data?.find(
			(integration) =>
				['pms', 'ota'].includes(integration.partnerIntegrationType) &&
				integration.appId === appData?._id
		);
		const financeIntegration = propertyIntegrationsState.data?.find(
			(integration) =>
				integration.partnerIntegrationType === 'finance' &&
				integration.appId === appData?._id
		);

		if (propertyId && pmsIntegration?._id && financeIntegration?._id) {
			dispatch(
				getFieldMapping({
					propertyIntegrationId: pmsIntegration._id || '',
					propertyIntegrationIdTo: financeIntegration._id || '',
					partnerIntegrationId: PARTNER.SIIGO,
					propertyId: propertyId
				})
			);
		}
	}, [propertyId, propertyIntegrationsState?.loading]);

	useEffect(() => {
		if (reservations.loading === 'pending') {
			dispatch(setInvoiceToBulk([]));
		}
	}, [reservations?.loading]);

	useEffect(() => {
		if (reservations.data?.data.length === 0) return;
		const allSelectableReservations =
			reservations.data?.data.filter(
				(r: IReservationInvoice) => !r.stampStatus
			) || [];
		if (allSelectableReservations?.length === 0) return;

		const selectedReservations = reservations.data?.data
			.filter((r: IReservationInvoice) => !r.stampStatus)
			?.filter((reservation: IReservationInvoice) =>
				reservationToBulk.some(
					(reservationToBulk: IReservationInvoice) =>
						reservationToBulk.reservationID ===
						reservation.reservationID
				)
			).length;

		let allSelected =
			allSelectableReservations?.length === selectedReservations;
		if (!allSelected) {
			allSelected = selectedReservations === MAX_RESERVATIONS_TO_BULK;
		}
		setAllReservationsToBulk(allSelected);
	}, [reservations.data?.data?.length, reservationToBulk?.length]);

	const closeInvoiceDetail = () => {
		dispatch(cleanInvoice());
	};

	const actionCloseDetail = (action: any) => {
		if (props.actionCloseDetail) props.actionCloseDetail(() => action());
		else action();
	};

	function findMatchingIcon(channelSlug: string): string | null {
		/**
		 * Se realiza el cambio de la key de booking, por booking.com, para la integración con cloudbeds
		 * para evitar el siguiente error:
		 * [cloudbeds] slug = Website/Booking Engine
		 * [booking] slug = Booking.com (Hotel Collect Booking)
		 */
		const { booking, ...rest } = channelIcons;
		const bookingKey =
			props.partnerIntegrationId === PARTNER.STAYS
				? 'booking'
				: 'booking.com';

		const _channelIcons: any = {
			...rest,
			hostelworld: 'https://cdn.hospy.co/images/icons/hostelworld.svg',
			[bookingKey]:
				'https://cdn.hospy.co/images/partner-integrations/icons/booking-round.svg'
		};

		const regex = new RegExp(Object.keys(_channelIcons).join('|'), 'i');
		const match = channelSlug.toLocaleLowerCase().match(regex);

		if (match && _channelIcons[match[0]]) {
			return _channelIcons[match[0]];
		} else {
			return null;
		}
	}

	const onSelectAllToBulk = (state: boolean) => {
		const _reservationToBulk: IReservationInvoice[] = [];
		if (state) {
			fullReservations?.forEach((reservation: IReservationInvoice) => {
				if (
					_reservationToBulk.length < MAX_RESERVATIONS_TO_BULK &&
					!reservation.stampStatus
				)
					_reservationToBulk.push(reservation);
			});
		}
		dispatch(setInvoiceToBulk(_reservationToBulk));
	};

	const handleStampBulk = () => {
		if (!appData || !propertyId || !props.partnerIntegrationId) return;

		const fieldMappingTax =
			fieldMapping.data?.fields?.filter(
				(field) => field.groupName === 'taxes'
			) || [];

		if (fieldMappingTax?.length === 0) return openFirstTimeTaxModal();

		const fieldMappingPaymentWay = fieldMapping.data?.fields?.filter(
			(field) => field.groupName === 'paymentWay'
		);

		if (fieldMappingPaymentWay?.length === 0)
			return openFirstTimePaymentWayModal();

		if (reservationToBulk.some((r) => r.currency?.toLowerCase() === 'usd'))
			return setStampBulkModal(true);
		return dispatch(
			ThunkCreateStampBulk({
				data: {
					reservations: reservationToBulk.map((r) => r.reservationID)
				},
				propertyId,
				partnerIntegrationId: props.partnerIntegrationId,
				partnerIntegrationIdTo: props.partnerIntegrationIdTo,
				appId: appData._id
			})
		);
	};

	const closeStampBulkModal = () => {
		setStampBulkModal(false);
	};

	const checkReservationDisabled = useMemo(() => {
		if (
			reservations.data?.data?.every(
				(r: IReservationInvoice) => r.stampStatus
			)
		)
			return 'No hay reservas disponibles para emitir';

		return undefined;
	}, [reservations.data?.data, fieldMapping.loading]);

	const closeFirstTimeTaxModal = () => {
		setFirstTimeTaxRequired(false);
	};

	const openFirstTimeTaxModal = () => {
		setFirstTimeTaxRequired(true);
	};

	const closeFirstTimePaymentWayModal = () => {
		setFirstTimePaymentWayRequired(false);
	};

	const openFirstTimePaymentWayModal = () => {
		setFirstTimePaymentWayRequired(true);
	};

	const onFinishTaxModal = async () => {
		handleStampBulk();
	};

	const onFinishPaymentWayModal = async () => {
		handleStampBulk();
	};

	return {
		loading: reservations.loading === 'pending',
		reservations: fullReservations,
		closeInvoiceDetail,
		invoice: invoice.data,
		invoiceLoading: invoice.loading === 'pending',
		actionCloseDetail,
		reservationToBulk,
		onSelectAllToBulk,
		allReservationsToBulk,
		handleStampBulk,
		stampBulkModal,
		closeStampBulkModal,
		checkReservationDisabled,
		fieldMappingLoading: fieldMapping.loading,

		closeFirstTimeTaxModal,
		openFirstTimeTaxModal,
		firstTimeTaxRequired,
		onFinishTaxModal,

		closeFirstTimePaymentWayModal,
		openFirstTimePaymentWayModal,
		firstTimePaymentWayRequired,
		onFinishPaymentWayModal,

		stampBulkLoading: stampBulk.loading
	};
};
