import { Button, Modal } from '@hospy/hospy-ui';
import styled from 'styled-components';

import { fetchInvoiceNotifications } from '@hospy/util-api';
import { Form, Input, Typography, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

interface InvoiceModalMailProps {
	open: boolean;
	onClose: () => void;
	data: {
		_id: string;
		appId: string;
		partnerIntegrationId: string;
		partnerIntegrationIdTo: string;
		propertyId: string;
		thirdPartyId?: string;
	};
}

const InvoiceModalMail = ({ open, onClose, data }: InvoiceModalMailProps) => {
	const [form] = useForm();
	const [loading, setLoading] = useState(false);

	const onSubmit = async ({ email }: any) => {
		try {
			setLoading(true);
			await fetchInvoiceNotifications({
				id: data._id,
				data: {
					appId: data.appId,
					partnerIntegrationId: data.partnerIntegrationIdTo,
					partnerIntegrationIdTo: data.partnerIntegrationId,
					propertyId: data.propertyId,
					thirdPartyId: data.thirdPartyId,
					emails: [email]
				}
			});
			form.resetFields();
			onClose();
			message.success('Email enviado');
		} catch (e) {
			message.error('Error al intentar enviar el email');
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose} destroyOnClose size="small">
			<ModalTitle>Reenvío de Factura Electrónica</ModalTitle>
			<ModalContent>
				<Form form={form} onFinish={onSubmit}>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								type: 'email',
								message: 'El campo no es un correo válido!'
							}
						]}
					>
						<Input size="large" placeholder="Email" type="email" />
					</Form.Item>
					<ButtonContainer>
						<Button
							size="middle"
							disabled={loading}
							onClick={onClose}
						>
							Cancelar
						</Button>
						<Button
							size="middle"
							htmlType="submit"
							type="primary"
							loading={loading}
						>
							Enviar
						</Button>
					</ButtonContainer>
				</Form>
			</ModalContent>
		</Modal>
	);
};
export default InvoiceModalMail;
const ModalTitle = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.middle}px;
	margin-bottom: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
`;
const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 40px;
	textarea {
		resize: none;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 15px;
	margin-top: 50px;
`;
