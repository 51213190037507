/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestFindAll} from '../requests';
import { Fetch } from '../fetch';

import { ResponseFailure, ResponseSuccess } from '../responses';

export const RequestFindAllWhatsappTemplates = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch(
		RequestFindAllWhatsappTemplates.name,
		'api/notifications/whatsapp/templates',
		params
	);
