/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	initRealTime,
	setRealTimeCredits,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect } from 'react';
import * as Realm from 'realm-web';
import { Observable, from } from 'rxjs';
import { setAppData } from '../../../../store/src/lib/common';

export const observableEvent = (source: any): any => {
	return new Observable((subscriber) => {
		const subscription = from(source).subscribe({
			next(value: any) {
				subscriber.next(value);
			},
			error(error) {
				subscriber.error(error);
			},
			complete() {
				subscriber.complete();
			}
		});

		return () => {
			// Stop the collection watcher
			source.return(undefined);

			subscription.unsubscribe();
		};
	});
};

export const useRealTime = () => {
	const dispatch = useAppDispatch();
	const { realtime, appData } = useAppSelector(({ common }) => common);
	const { propertyId } = useAppSelector(({ user }) => user);
	const { credits } = useAppSelector(({ credits }) => credits);

	useEffect(() => {
		dispatch(initRealTime());
	}, []);

	useEffect(() => {
		if (!realtime || !realtime.app || !realtime.client || !appData) return;

		const getData = async () => {
			const collApps = realtime.client
				.db(process.env['NX_MONGO_DB_REALM'] || '')
				.collection('apps');
			const currentApp = await collApps.find({
				_id: new Realm.BSON.ObjectId(appData?._id)
			});
			const stream = collApps.watch({ ids: [currentApp[0]._id] });

			if (!stream) return;
			for await (const change of stream) {
				const {
					operationType,
					fullDocument: { maintenance, maintenanceMessage }
				} = change;
				if (operationType === 'update'|| operationType === 'replace') {
					dispatch(
						setAppData({
							...appData,
							maintenance,
							maintenanceMessage
						})
					);
				}
			}
		};
		getData();
	}, [realtime?.user, appData?._id]);

	useEffect(() => {
		if (
			!realtime ||
			!realtime.app ||
			!realtime.client ||
			!appData ||
			!propertyId ||
			!credits?.data?.credits?._id
		)
			return;

		const getCreditData = async () => {
			const collCredits = realtime.client
				.db(process.env['NX_MONGO_DB_REALM'] || '')
				.collection('credits');
			const currentCredits = await collCredits.find({
				_id: new Realm.BSON.ObjectId(credits?.data?.credits?._id)
			});
			if (!currentCredits || !currentCredits[0]) return;

			const stream = collCredits.watch({ ids: [currentCredits[0]._id] });

			if (!stream) return;
			try {
				for await (const change of stream) {
					const {
						operationType,
						fullDocument: { properties, amount, ...creditInfo }
					} = change;
					if (operationType === 'update') {
						const currentStamps =
							properties
								?.find(
									(property: any) =>
										property.id?.toString() === propertyId
								)
								?.apps?.find(
									(app: any) =>
										app.id?.toString() === appData._id
								)?.quantity || -1;
						dispatch(
							setRealTimeCredits({
								credits: { ...creditInfo, amount },
								stamps: currentStamps
							})
						);
					}
				}
			} catch (error) {
				console.log('Error with realm for Credits');
				console.log(error);
			}
		};
		getCreditData();
	}, [propertyId, realtime, appData?._id, credits?.data?.credits?._id]);

	return {};
};
