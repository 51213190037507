/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	deleteTermsAndConditionsService,
	getTermsAndConditionsService,
	postTermsAndConditionsService,
	putTermsAndConditionsService
} from '@hospy/util-api';

export const getTermsAndConditions = createAsyncThunk(
	'common/get-terms-and-conditions',
	async (
		params: { appId: string; propertyId: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await getTermsAndConditionsService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			if (response.data?.length > 0) return response.data[0];
			return null;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const createTermsAndConditions = createAsyncThunk(
	'common/create-terms-and-conditions',
	async (
		params: {
			appId: string;
			propertyId: string;
			description: string;
			rawDescription: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await postTermsAndConditionsService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const updateTermsAndConditions = createAsyncThunk(
	'common/update-terms-and-conditions',
	async (
		params: {
			_id: string;
			appId: string;
			propertyId: string;
			description: string;
			rawDescription: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await putTermsAndConditionsService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
export const deleteTermsAndConditions = createAsyncThunk(
	'common/delete-terms-and-conditions',
	async (
		params: {
			_id: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await deleteTermsAndConditionsService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
