/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CreditApi } from './credits.api';
import { CreditProperty, Credits, IPaymentCredit } from './credits.interfaces';
import { hospyApi } from './hospyAPI';

const stripErrorDictionary = {
	card_declined: 'La información de la tarjeta es inválida'
};

export const getCredits = createAsyncThunk(
	'credits/get',
	async (payload, { rejectWithValue, getState }) => {
		try {
			const state: RootState = getState() as RootState;

			let { statusCode, data } = await CreditApi.getCreditsByProperty(
				state.user.currentProperty || ''
			);
			if (statusCode > 299 || statusCode < 200)
				return rejectWithValue('Error al consultar los créditos');
			if (data === null) {
				const secondResponse = await CreditApi.getCredits();
				if (
					secondResponse.statusCode > 299 ||
					secondResponse.statusCode < 200
				)
					return rejectWithValue('Error al consultar los créditos');
				data = secondResponse.data;
			}

			const credits = data as Credits | null;

			const propertyId = state.user.currentProperty;
			const appId = state.common.appData?._id;

			const currentStamps =
				credits?.properties
					?.find((property) => property.id == propertyId)
					?.apps?.find((app) => app.id == appId)?.quantity || -1;

			const response = {
				credits: credits,
				stamps: currentStamps
			};

			return response;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const getCreditsByUser = createAsyncThunk(
	'credits/getByUser',
	async (payload, { rejectWithValue, getState }) => {
		try {
			const state: RootState = getState() as RootState;

			const { statusCode, data } = await CreditApi.getCredits();
			if (statusCode > 299 || statusCode < 200)
				return rejectWithValue('Error al consultar los créditos');

			const credits = data as Credits | null;

			const propertyId = state.user.currentProperty;
			const appId = state.common.appData?._id;

			const currentStamps =
				credits?.properties
					?.find((property) => property.id == propertyId)
					?.apps?.find((app) => app.id == appId)?.quantity || -1;

			const response = {
				credits: credits,
				stamps: currentStamps
			};

			return response;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const createCredits = createAsyncThunk(
	'credits/create',
	async (
		{
			amount //,
		}: //properties
		{
			amount: number;
			//properties: any[];
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await hospyApi.fetchClientSecret(
				amount
				//properties
			);

			if (response.errors)
				return rejectWithValue(
					'No se pudo establecer la conexión con el provedor'
				);

			if (response.statusCode === 201) return response.data;

			return rejectWithValue(response.errors);
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const CreditsMakePayment = createAsyncThunk(
	'credits/makePayment',
	async (
		{
			payment,
			invoiceId
		}: {
			payment: IPaymentCredit;
			invoiceId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const subscriptionDetail = JSON.parse(
				localStorage.getItem('creditsCustomer') || '{}'
			);
			localStorage.setItem(
				'creditsCustomer',
				JSON.stringify({
					...subscriptionDetail,
					tried: true
				})
			);

			const response = await hospyApi.postSavePayment(payment, invoiceId);
			if (response.statusCode === 400) {
				const { message: messageRaw } = response;
				let messageFormatted = messageRaw?.split(':');
				if (messageFormatted?.length > 0 && messageFormatted[1])
					messageFormatted = messageFormatted[0] as string;
				return rejectWithValue(
					stripErrorDictionary[
						messageFormatted.trim() as keyof typeof stripErrorDictionary
					]
				);
			}

			return response.data;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const getCreditsInvoices = createAsyncThunk(
	'subscriptions/getInvoices',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await hospyApi.fetchGetCreditsInvoices(payload);
			if (!data) return rejectWithValue('Error al obtener facturas');
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al obtener facturas');
		}
	}
);

interface UpdateCreditsProps {
	id: string;
	properties: CreditProperty[];
	amount: number;
}

export const updateCredits = createAsyncThunk(
	'credits/update',
	async (
		{ id, properties, amount }: UpdateCreditsProps,
		{ getState, rejectWithValue }
	) => {
		const { statusCode, data } = await CreditApi.update({
			id,
			data: { properties, amount }
		});
		if (statusCode > 299 || statusCode < 200)
			return rejectWithValue('Error al consultar los créditos');

		const credits = data as Credits | null;

		const state: RootState = getState() as RootState;

		const propertyId = state.user.currentProperty;
		const appId = state.common.appData?._id;

		const currentStamps =
			credits?.properties
				?.find((property) => property.id == propertyId)
				?.apps?.find((app) => app.id == appId)?.quantity || -1;

		const response = {
			credits: credits,
			stamps: currentStamps
		};

		return response;
	}
);

export interface InvoiceCreditAppDto {
	id: string;
	quantity: number;
	amount: number;
	name: string;
}

export interface InvoiceCreditProperty {
	id: string;
	amount: number;
	apps: InvoiceCreditAppDto[];
}

export interface InvoiceCreditsProps {
	amount: number;
	active: boolean;
	id: string;
	properties: InvoiceCreditProperty[];
}

export const createCreditsInvoice = createAsyncThunk(
	'credits/invoiceCreate',
	async (credit: InvoiceCreditsProps, { rejectWithValue }) => {
		const { statusCode, data } = await CreditApi.createInvoicedCredit(
			credit
		);
		if (statusCode > 299 || statusCode < 200)
			return rejectWithValue('Error al consultar los créditos');

		return data;
	}
);

export const getCreditsByProperty = createAsyncThunk(
	'credits/getByProperty',
	async (
		{
			propertyId,
			appId
		}: {
			propertyId: string;
			appId: string;
		},
		{ rejectWithValue, getState }
	) => {
		try {
			const { statusCode, data } = await CreditApi.getCreditsByProperty(
				propertyId
			);
			if (statusCode > 299 || statusCode < 200)
				return rejectWithValue('Error al consultar los créditos');

			const credits = data as Credits | null;

			const currentStamps =
				credits?.properties
					?.find((property) => property.id == propertyId)
					?.apps?.find((app) => app.id == appId)?.quantity || -1;

			const response = {
				credits: credits,
				stamps: currentStamps
			};

			return response;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);
