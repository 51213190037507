import { urlJoin } from 'url-join-ts';

export function ApiURL(path: string): string {
	let endpoint = '/dev';
	if (/api\/spa|api\/v[1,2]\/spa/.test(path)) {
		endpoint = process.env['NX_URL_SPA_API'] || endpoint;
	} else if (/api\/auth|api\/v[1,2]\/auth/.test(path)) {
		endpoint = process.env['NX_URL_AUTH_API'] || endpoint;
	} else if (/api\/payment-collect|api\/v[1,2]\/payment-collect/.test(path)) {
		endpoint = process.env['NX_URL_REVENUE_API'] || endpoint;
	} else if (/api\/invoice|api\/v[1,2]\/invoice/.test(path)) {
		endpoint = process.env['NX_URL_INVOICE_API'] || endpoint;
	} else if (/api\/checkin-express|api\/v[1,2]\/checkin-express/.test(path)) {
		endpoint = process.env['NX_URL_GUEST_API'] || endpoint;
	} else if (/api\/common|api\/v[1,2]\/common/.test(path)) {
		endpoint = process.env['NX_URL_COMMON_API'] || endpoint;
	} else if (/api\/third-party|api\/v[1,2]\/third-party/.test(path)) {
		endpoint = process.env['NX_URL_THIRD_PARTY_API'] || endpoint;
	} else if (/api\/guest-report|api\/v[1,2]\/guest-report/.test(path)) {
		endpoint = process.env['NX_URL_GUEST_REPORT_API'] || endpoint;
	} else if (/api\/pms|api\/v[1,2]\/pms/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	} else if (/api\/accounting|api\/v[1,2]\/accounting/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	} else if (/api\/warehouse|api\/v[1,2]\/warehouse/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	} else if (/api\/crm|api\/v[1,2]\/crm/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	} else if (/api\/hubspot|api\/v[1,2]\/hubspot/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	} else if (/api\/core|api\/v[1,2]\/core/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	} else if (/api\/whatsapp|api\/v[1,2]\/whatsapp/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_WHATSAPP_API'] || endpoint;
	} else if (/api\/notifications|api\/v[1,2]\/notifications/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_NOTIFICATION_API'] || endpoint;
	} else if (/api\/upselling|api\/v[1,2]\/upselling/.test(path)) {
		endpoint = process.env['NX_URL_HOSPY_PARTNER_API'] || endpoint;
	}

	let url = urlJoin(endpoint, path);

	if (
		path.includes('filter') ||
		path.includes('sort') ||
		path.includes('fields') ||
		path.includes('flags')
	) {
		url = encodeURI(url);
	}
	return url;
}
