import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICheckInIntegration, ICheckInRequiredFields } from './interfaces';
import { Api } from './api';

export const createCheckInIntegration = createAsyncThunk(
	'checkin/createCheckInIntegration',
	async (data: ICheckInIntegration) => {
		const response = await Api.createCheckInIntegration(data);

		if (response) {
			return response;
		} else return response;
	}
);

export const findCheckInIntegration = createAsyncThunk(
	'checkin/findCheckInIntegration',
	async (propertyId: any) => {
		const response = await Api.findCheckInIntegration(propertyId);
		if (response?.data.length > 0) {
			return response.data;
		} else return undefined;
	}
);

export const createCheckInRequiredFields = createAsyncThunk(
	'checkin/createCheckInRequiredFields',
	async (data: ICheckInRequiredFields) => {
		const response = await Api.createCheckInRequiredFields(data);

		if (response) {
			return response;
		} else return response;
	}
);

export const findCheckInRequiredFields = createAsyncThunk(
	'checkin/findCheckInRequiredFields',
	async (propertyId: any) => {
		const response = await Api.findCheckInRequiredFields(propertyId);

		if (response?.data) {
			return response.data;
		} else return undefined;
	}
);
