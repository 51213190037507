import {
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect, useRef, useState } from 'react';

export interface useAddPhoneChannelPropertyModalProps {
	open: boolean;
	onClose: () => void;
}
const useAddPhoneChannelPropertyModal = ({
	onClose
}: useAddPhoneChannelPropertyModalProps) => {
	const ref = useRef<any>(null);
	const dispatch = useAppDispatch();
	const { propertyIdToUpdate } = useAppSelector(({ user }) => user);
	const [blocking, setBlocking] = useState(false);

	useEffect(() => {
		if (!open) ref?.current?.reset();
	}, [open]);

	const handleOnClose = () => void onClose();

	return {
		ref,
		handleOnClose,
		blocking,
		propertyIdToUpdate,
		setBlocking
	};
};

export default useAddPhoneChannelPropertyModal;
