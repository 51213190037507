/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchCreateInvoice,
	fetchDeleteStampInvoice,
	fetchGetAllInvoice,
	fetchGetAllSummaryReport,
	fetchGetStampInvoice,
	fetchSearchPublicReservation,
	fetchSelfStampInvoice,
	fetchStampInvoice,
	fetchUpdateInvoice,
	getFieldMappingService,
	getInvoiceStampsErrorsService,
	getListingTypes,
	getPaymentMethods as getPaymentMethodsService,
	getProducts,
	getTaxes as getTaxesService,
	getUnits,
	updateFieldMappingService,
	updateStampInvoiceService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PartnerRequestProps, PayloadBase } from '../common/common.interfaces';
import { APPS, PARTNER } from '../common/common.types';
import { SearchPublicReservationQuery } from '../siigo-connect/interface';
import { SatGetFieldMappingProp } from './sat.interface';
import { Invoice } from '../partner/accounting/interface';
import moment from 'moment';

export const satGetPaymentMethods = createAsyncThunk(
	'sat/get-payment-method',
	async ({ type, ...payload }: PayloadBase, { rejectWithValue }) => {
		try {
			const { data, errors } = await getPaymentMethodsService({
				...payload
			});
			if (errors) {
				rejectWithValue('Error');
			}
			return data;
		} catch (error: any) {
			console.error('getPaymentMethods: ', { error });
			rejectWithValue('Error');
		}
	}
);

export const satGetTaxes = createAsyncThunk(
	'sat/get-taxes',
	async ({ type, ...payload }: PayloadBase, { rejectWithValue }) => {
		try {
			const { data, errors } = await getTaxesService({
				...payload
			});
			if (errors) {
				rejectWithValue('Error');
			}
			return data;
		} catch (error: any) {
			console.error('getPaymentMethods: ', { error });
			rejectWithValue('Error');
		}
	}
);

export const satGetAccommodations = createAsyncThunk(
	'sat/get-accommodations',
	async ({ type, ...payload }: PayloadBase, { rejectWithValue }) => {
		try {
			if (type === 'internal') {
				const { statusCode, data } = await getListingTypes({
					...payload
				});
				if (statusCode !== 200) {
					return rejectWithValue(
						'No se pudo obtener los alojamientos'
					);
				}
				return data;
			} else {
				const { statusCode, data } = await getProducts({
					...payload,
					filter: `{"type":"accommodation"}`
				});
				if (statusCode !== 200) {
					return rejectWithValue('No se pudo obtener los productos');
				}
				return data;
			}
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const satGetProducts = createAsyncThunk(
	'sat/get-products',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const { statusCode, data } = await getProducts(payload);
			if (statusCode !== 200) {
				return rejectWithValue('No se pudo obtener los productos');
			}
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const satGetFieldMapping = createAsyncThunk(
	'sat/get-field-mapping',
	async (
		{ propertyId, partnerIntegrationId, appId }: SatGetFieldMappingProp,
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getFieldMappingService(
				`{ propertyId: ObjectId("${propertyId}"), partnerIntegrationId: ObjectId("${partnerIntegrationId}"), appId: ObjectId("${appId}") }`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

interface IUpdateFieldMapping {
	id: string;
	data: any;
	arrayFilters?: any[];
	updateMode?: 'pull' | 'push';
	items: any[];
}

export const satUpdateFieldMapping = createAsyncThunk(
	'sat/update-field-mapping',
	async (
		{ id, data, arrayFilters, updateMode, items }: IUpdateFieldMapping,
		{ rejectWithValue }
	) => {
		const response = await updateFieldMappingService(
			id,
			data,
			`filter=${JSON.stringify({ arrayFilters, updateMode })}`
		);

		if (response.statusCode !== 200) {
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);
		}

		if (updateMode === 'push') {
			return {
				items: response.data,
				updateMode
			};
		}

		return { items, updateMode };
	}
);

export const satGetStamp = createAsyncThunk(
	'sat/get-stamp',
	async (payload: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const response = await fetchGetStampInvoice(
				{
					partnerIntegrationId: PARTNER.FACTURAMA,
					partnerIntegrationIdTo: PARTNER.CLOUDBEDS,
					appId: APPS.EFACTURA_SAT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000',
					_id: payload
				},
			);

			if (response.statusCode !== 200)
				return rejectWithValue(response.errors);

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const satInvoiceStamp = createAsyncThunk(
	'sat/invoice-stamp',
	async (payload: any, { rejectWithValue }) => {
		const createInvoice = await fetchCreateInvoice(
			{
				appId: APPS.EFACTURA_SAT,
				partnerIntegrationId: payload.partnerIntegrationId,
				partnerIntegrationIdTo: payload.partnerIntegrationIdTo,
				propertyId: payload.propertyId,
				limit: '',
				page: '1',
				filter: ''
			},
			{ ...payload.invoice, checked: true }
		);

		if (createInvoice.statusCode !== 201)
			return rejectWithValue(['No se pudo procesar la factura']);

		const response = await fetchStampInvoice({
			...payload,
			partnerIntegrationId: payload.partnerIntegrationIdTo,
			partnerIntegrationIdTo: payload.partnerIntegrationId,
			_id: createInvoice.data._id
		});

		if (response.statusCode === 400) {
			return rejectWithValue(response);
		}

		return response.data;
	}
);

export const satUpdateInvoiceStamp = createAsyncThunk(
	'sat/update-invoice-stamp',
	async (payload: any, { rejectWithValue }) => {
		const response: any = await updateStampInvoiceService(
			{ ...payload },
			payload._id
		);

		if (response.statusCode < 200 || response.statusCode > 299)
			return rejectWithValue(
				response.errors.map((error: any) => error.message)
			);

		return response.data;
	}
);

export const satGetStampErrors = createAsyncThunk(
	'sat/get-stamp-errors',
	async (reservationId: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();
			const { statusCode, errors, data } =
				await getInvoiceStampsErrorsService(reservationId, {
					partnerIntegrationId: PARTNER.FACTURAMA,
					partnerIntegrationIdTo: PARTNER.CLOUDBEDS,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId
				});

			if (statusCode !== 200) return rejectWithValue(errors);

			return data;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue('Error al intentar cargar los errores');
		}
	}
);

export const satUpdateInvoiceError = createAsyncThunk(
	'sat/update-invoice-error',
	async (updatedInvoice: Invoice, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const { statusCode, data } = await fetchUpdateInvoice(
				{
					partnerIntegrationId: PARTNER.CLOUDBEDS,
					partnerIntegrationIdTo: PARTNER.FACTURAMA,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				updatedInvoice.thirdPartyId,
				updatedInvoice
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo guardar la información');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const satInvoiceCancelStamp = createAsyncThunk(
	'sat/invoice-cancel-stamp',
	async (payload: any, { rejectWithValue }) => {
		const response = await fetchDeleteStampInvoice(payload.id, payload);

		if (response.statusCode === 400) {
			return rejectWithValue(response);
		}

		return response.data;
	}
);

export const satSearchPublicReservation = createAsyncThunk(
	'sat/search-public-reservation',
	async (searchArgs: SearchPublicReservationQuery, { rejectWithValue }) => {
		try {
			const { statusCode, data }: { statusCode: number; data: Invoice } =
				await fetchSearchPublicReservation(
					APPS.EFACTURA_SAT,
					searchArgs
				);

			if (statusCode !== 200 || Object.keys(data).length === 0)
				return rejectWithValue(
					'El ID de reserva no fue encontrado en el sistema, contacta con el hotel'
				);

			const base =
				(data?.items || [])
					.filter((item: any) => item.active)
					.reduce((prev: any, curr: any) => +curr.total + prev, 0) ||
				0;

			const payment = +(
				data?.payments
					.filter((payment: any) => payment.active)
					.reduce((prev: any, curr: any) => prev + +curr.amount, 0) ||
				0
			).toFixed(2);
			//base 100 payment 120 pending -20
			let pending = Math.abs(base - payment);
			if (pending < 1) pending = 0;

			if (
				data.status !== 'checked_out' ||
				pending > 0 ||
				moment(data.endDate).month() !== moment().month()
			)
				return rejectWithValue(
					'No se puede realizar la autofactura para esta reserva. Por favor contáctanos. '
				);

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const satSelfInvoiceStamp = createAsyncThunk(
	'sat/self-invoice-stamp',
	async (payload: any, { rejectWithValue }) => {
		const response = await fetchSelfStampInvoice(
			{ ...payload },
			payload.invoiceId
		);

		if (response.statusCode === 400) {
			return rejectWithValue(response);
		}

		return response.data;
	}
);

export const satGetPublicStamp = createAsyncThunk(
	'sat/get-public-stamp',
	async (searchArgs: SearchPublicReservationQuery, { rejectWithValue }) => {
		try {
			const { statusCode, data }: { statusCode: number; data: Invoice } =
				await fetchSearchPublicReservation(
					APPS.EFACTURA_SAT,
					searchArgs
				);

			if (statusCode !== 200 || Object.keys(data).length === 0)
				return rejectWithValue(
					'El ID de reserva no fue encontrado en el sistema, contacta con el hotel'
				);

			if (data.status !== 'checked_out')
				return rejectWithValue(
					'La reserva aún no puede facturarse, por favor intenta más tarde'
				);

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

interface SatGetInvoiceReportProps extends PartnerRequestProps {
	limit: number;
	paginating: boolean;
	page: number;
}

export const satGetInvoiceReport = createAsyncThunk(
	'sat/get-invoice-report',
	async (
		{
			filter,
			propertyId,
			partnerIntegrationId,
			partnerIntegrationIdTo,
			appId,
			limit,
			paginating,
			page
		}: SatGetInvoiceReportProps,
		{ rejectWithValue }
	) => {
		try {
			const { statusCode, data, errors, meta } = await fetchGetAllInvoice(
				{
					filter,
					propertyId,
					partnerIntegrationId,
					partnerIntegrationIdTo,
					appId,
					limit,
					page
				}
			);

			if (statusCode === 200) return { paginating, data, meta };
			if (errors) return rejectWithValue(errors);
			return;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const satGetSummaryReport = createAsyncThunk(
	'sat/get-summary-report',
	async (
		{
			filter,
			propertyId,
			partnerIntegrationId,
			partnerIntegrationIdTo,
			appId
		}: PartnerRequestProps,
		{ rejectWithValue }
	) => {
		try {
			const { statusCode, data, errors, meta } =
				await fetchGetAllSummaryReport({
					filter,
					propertyId,
					partnerIntegrationId,
					partnerIntegrationIdTo,
					appId
				});

			if (statusCode === 200) return { data, meta };
			if (errors) return rejectWithValue(errors);
			return;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const satGetUnits = createAsyncThunk(
	'sat/get-units',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const { statusCode, data } = await getUnits(payload);
			if (statusCode !== 200) {
				return rejectWithValue('No se pudo obtener los productos');
			}
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);
