import { Async } from '../common/common.interfaces';
import { AnalyticsInitialState } from './interface';

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const analyticsInitialState: AnalyticsInitialState = {
	sellByApplication: initialAsync,
	countries: initialAsync,
	apps: initialAsync,
	partners: initialAsync,
	propertySubscriptions: initialAsync,
	cjm: initialAsync,
	dataProcessed: initialAsync,
	resume: initialAsync,
	sireTraData: initialAsync,
	siigoData: initialAsync,
	satData: initialAsync,
	checkinData: initialAsync,
	imrData: initialAsync
};
