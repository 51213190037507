/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { AdminPaymentsInitialState, AdminPaymentsSliceName } from './constants';
import { IAdminpaymentsInitialState } from './interface';

import { ThunkBankAccountsTransfers, ThunkFindAllBankAccounts } from './thunks';

export const AdminPaymentsSlice = createSlice({
	name: AdminPaymentsSliceName,
	initialState: AdminPaymentsInitialState,
	reducers: {
		adminUpdateBankAccount: (state, { payload }) => {
			console.log({ state, payload });

			if (state.upsellingBankAccounts.payload) {
				const newArray = current(
					state.upsellingBankAccounts.payload.data
				);
				console.log({ newArray });
				const data = newArray.map((e: any) => {
					if (e._id === payload._id) {
						console.log({ e: { ...e, ...payload } });
						return {
							...e,
							...payload
						};
					}
					return e;
				});
				console.log({ data });
				state.upsellingBankAccounts = {
					loading: 'succeeded',
					error: undefined,
					payload: {
						...current(state.upsellingBankAccounts.payload),
						data
					}
				};
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				ThunkFindAllBankAccounts.pending,
				(state: IAdminpaymentsInitialState) => {
					state.upsellingBankAccounts.loading = 'pending';
				}
			)
			.addCase(
				ThunkFindAllBankAccounts.rejected,
				(state: IAdminpaymentsInitialState, payload: any) => {
					state.upsellingBankAccounts = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkFindAllBankAccounts.fulfilled,
				(state: IAdminpaymentsInitialState, { payload }: any) => {
					state.upsellingBankAccounts = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);

		builder
			.addCase(
				ThunkBankAccountsTransfers.pending,
				(state: IAdminpaymentsInitialState) => {
					state.upsellingBankAccountsTransfers.loading = 'pending';
				}
			)
			.addCase(
				ThunkBankAccountsTransfers.rejected,
				(state: IAdminpaymentsInitialState, payload: any) => {
					state.upsellingBankAccountsTransfers = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkBankAccountsTransfers.fulfilled,
				(state: IAdminpaymentsInitialState, { payload }: any) => {
					state.upsellingBankAccountsTransfers = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
	}
});

export const { adminUpdateBankAccount } = AdminPaymentsSlice.actions;
