import { Typography } from 'antd';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import styled, { css } from 'styled-components';
import Icon from '../../atoms/icon';
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
interface IconButtonsProps {
	readonly active?: boolean;
}
export const IconButtons = styled.button<IconButtonsProps>`
	&,
	& svg {
		height: 25px;
		width: 25px;
	}
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.gray.darken['30%']};
	&:hover {
		color: ${({ theme }) => theme.colors.gray.darken['50%']};
	}
	${({ active }) => active && activeColor}
`;

const activeColor = css`
	&,
	&:hover {
		color: #000;
	}
`;

export const Toolbar = styled.div`
	height: 55px;
	position: sticky;
	top: 0;
	padding: 15px;
	z-index: 2;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
	display: flex;
	gap: 15px;
	background-color: #fff;
`;

export const SidebarTitle = styled(Typography.Text)`
	display: block;
`;
export const Sidebar = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	flex: 1;
	border-left: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
	padding: 40px 15px;
`;

export const SidebarContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	position: sticky;
	top: 15px;
`;

export const SlateContent = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
	flex: 3;
	position: relative;
`;

export const SlateContainer = styled.div`
	display: flex;
`;

export const RenderElement = ({ attributes, children, element }: any) => {
	const style = { textAlign: element.align };
	switch (element.type) {
		case 'block-quote':
			return (
				<blockquote style={style} {...attributes}>
					{children}
				</blockquote>
			);
		case 'bulleted-list':
			return (
				<ul style={{ ...style, marginLeft: 17 }} {...attributes}>
					{children}
				</ul>
			);
		case 'heading-one':
			return (
				<h1 style={style} {...attributes}>
					{children}
				</h1>
			);
		case 'heading-two':
			return (
				<h2 style={style} {...attributes}>
					{children}
				</h2>
			);
		case 'list-item':
			return (
				<li style={style} {...attributes}>
					{children}
				</li>
			);
		case 'numbered-list':
			return (
				<ol style={{ ...style, marginLeft: 15 }} {...attributes}>
					{children}
				</ol>
			);

		default:
			return (
				<p style={style} {...attributes}>
					{children}
				</p>
			);
	}
};

export const RenderLeaf = ({ attributes, children, leaf }: any) => {
	if (leaf.bold) {
		children = <b>{children}</b>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	if (leaf.code) {
		children = (
			<span {...attributes} style={{ backgroundColor: '#c3c3c3' }}>
				{children}
			</span>
		);
	}

	return <span {...attributes}>{children}</span>;
};

const isBlockActive = (editor: any, format: any, blockType = 'type') => {
	const { selection } = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n) =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n[blockType as keyof typeof n] === format
		})
	);

	return !!match;
};

const isMarkActive = (editor: any, format: any) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format as keyof typeof marks] === true : false;
};

const toggleBlock = (editor: any, format: any) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true
	});
	let newProperties: Partial<SlateElement>;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format
		} as any;
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format
		};
	}
	Transforms.setNodes<SlateElement>(editor, newProperties);

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const toggleMark = (editor: any, format: any) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

export const BlockButton = ({ format, icon, iconStyle }: any) => {
	const editor = useSlate();
	const active = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	);
	const handleOnMouseDown: React.MouseEventHandler<HTMLButtonElement> = (
		e
	) => {
		e.preventDefault();
		toggleBlock(editor, format);
	};
	return (
		<IconButtons active={active} onMouseDown={handleOnMouseDown}>
			<Icon ant materialOutlined={icon} style={{ ...iconStyle }} />
		</IconButtons>
	);
};

export const MarkButton = ({ format, icon, iconStyle }: any) => {
	const editor = useSlate();
	const active = isMarkActive(editor, format);
	const handleOnMouseDown: React.MouseEventHandler<HTMLButtonElement> = (
		e
	) => {
		e.preventDefault();
		toggleMark(editor, format);
	};
	return (
		<IconButtons active={active} onMouseDown={handleOnMouseDown}>
			<Icon ant materialOutlined={icon} style={{ ...iconStyle }} />
		</IconButtons>
	);
};

interface GetHtmlTextProps {
	text: string;
	bold?: boolean;
	italic?: boolean;
}

const GetHtmlText = ({ text, bold, italic }: GetHtmlTextProps) => {
	let paragraph = `{{text}}`;

	const __styles: any = { bold, italic };

	const styles = Object.keys(__styles).filter((e) => __styles[e]);
	const total = Object.keys(styles).length;

	if (total > 0) {
		let openingTags = '';
		let closingTags = '';
		styles.forEach((style) => {
			if (style === 'bold') {
				openingTags += '<b>';
				closingTags = '</b>' + closingTags;
			}
			if (style === 'italic') {
				openingTags += '<i>';
				closingTags = '</i>' + closingTags;
			}
		});
		paragraph = `${openingTags}${text}${closingTags}`;
	} else {
		paragraph = text;
	}

	return paragraph;
};

export const serialize = (nodes: any) => {
	const formatted = nodes?.map((node: any) => {
		switch (node.type) {
			case 'paragraph':
				return `<p>${node.children
					.map((children: any) => GetHtmlText(children))
					.join('')}</p>`;

			case 'heading-one':
				return node.children
					.map((children: any) => `<h1>${GetHtmlText(children)}</h1>`)
					.join('');

			case 'heading-two':
				return node.children
					.map((children: any) => `<h2>${GetHtmlText(children)}</h2>`)
					.join('');

			case 'numbered-list':
				return `<ol>${serialize(node.children).join('')}</ol>`;

			case 'bulleted-list':
				return `<ul>${serialize(node.children).join('')}</ul>`;

			case 'list-item':
				return node.children
					.map((children: any) => `<li>${GetHtmlText(children)}</li>`)
					.join('');

			default:
				return '';
		}
	});

	return formatted;
};

export const SlateDisabled = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-color: #c3c3c36a;
	cursor: no-drop;
`;
