/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import BusinessIcon from '@2fd/ant-design-icons/lib/Domain';
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
	fetchSelectedProperty,
	getPropertyIntegrations,
	satResetFieldMapping,
	setCurrentProperty,
	setPropertyId,
	setShowPropertyModal,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import { Button, Grid, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Modal from '../../template/modal';
import { DrawerMenuDivider } from '../nav-bar/nav-bar-menu';

interface SelectPropertyProps {
	onCloseDrawer?: () => void;
	loading?: boolean;
	hideAddPropertyButton?: boolean;
	appName?: string;
	style?: {
		select?: React.CSSProperties;
	};
	selectProp?: any;
}

const SelectProperty = (
	props: SelectPropertyProps | undefined = SelectPropertyDefaultProps
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const screens = useBreakpoint();

	const { onCloseDrawer, loading, hideAddPropertyButton, appName } = props;
	const { user } = useAppSelector(({ user }) => user);
	const { currentProperty } = useAppSelector(({ user }) => user);

	const [properties, setProperties] = useState<Array<any>>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [current, setCurrent] = useState('');

	if (!user) return <></>;
	if (!user.properties) return <></>;

	useEffect(() => {
		if (appName) {
			const _properties = (user?.properties || [])
				?.filter((e: any) => e.apps)
				.filter((e: any) =>
					e.apps.map((e: any) => e.name.es).includes(appName)
				);
			if (_properties.length === 0) {
				if (!window.location.href.includes('/partner/oauth'))
					window.location.href = process.env['NX_APP_START'] || '';
				return;
			}
			setProperties(_properties);
		} else {
			if ((user?.properties || [])?.length > 0)
				setProperties(user?.properties || []);
		}
	}, [user?.properties?.length]);

	useEffect(() => {
		if (currentProperty) setCurrent(currentProperty);

		if (
			user &&
			currentProperty &&
			(user?.properties || [])?.length > 0 &&
			!user.properties?.map((e) => e._id).includes(currentProperty)
		) {
			let _id = null;
			if (user && (user?.properties || []).length > 0) {
				_id = (user?.properties || [{ _id: null }])[0]?._id || '';
			}

			if (_id) updateCurrent(_id);
		}

		if (currentProperty) dispatch(getPropertyIntegrations(currentProperty));
	}, [currentProperty, user?.properties?.length]);

	const addPropertyButton = hideAddPropertyButton ? null : (
		<Button
			onClick={() => {
				if (onCloseDrawer) onCloseDrawer();
				dispatch(setShowPropertyModal(true));
			}}
			block
			type="link"
			size="large"
			icon={<PlusOutlined />}
			disabled={loading}
		>
			<FormattedMessage
				id="select-property.addProperty"
				defaultMessage="Agregar negocio"
			/>
		</Button>
	);

	const handleChange = (value: string[]) => {
		setCurrent(String(value));
		updateCurrent(String(value));
	};

	// TODO: factorizar servicio, uso del accessToken
	const updateCurrent = async (id: string) => {
		//TODO Refactorizar el accessToken
		if (!localStorage.getItem('userIdView')) {
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();
			dispatch(
				fetchSelectedProperty({
					idToken,
					currentProperty: id,
					user: session?.idToken?.payload
				})
			);
			dispatch(setCurrentProperty(id));
			dispatch(satResetFieldMapping());
			dispatch(setPropertyId(id));
			navigate('/');
		} else {
			dispatch(setCurrentProperty(id));
			dispatch(setPropertyId(id));
			dispatch(satResetFieldMapping());
		}
	};

	if (screens?.xs) {
		const dataCurrentProperty = (properties || []).find(
			(e: any) => e._id === currentProperty
		);
		return (
			<>
				{(properties || []).length > 0 && (
					<>
						<SectionProperty>
							<CurrentPropertyContainer>
								<BusinessIcon
									style={{ marginRight: 10, fontSize: 26 }}
								/>
								{dataCurrentProperty?.name}
							</CurrentPropertyContainer>
							<div style={{ textAlign: 'center' }}>
								<Button
									style={{ borderRadius: 50, marginTop: 20 }}
									type="primary"
									size="large"
									onClick={() => {
										setOpen(true);
									}}
								>
									<FormattedMessage
										id="select-property.updateProperty"
										defaultMessage="Cambiar de negocio"
									/>
								</Button>
							</div>
						</SectionProperty>
						<Modal open={open} onClose={() => setOpen(false)}>
							<div style={{ textAlign: 'center' }}>
								<Typography.Text>
									<FormattedMessage
										id="select-property.title"
										defaultMessage="Seleccionar negocio"
									/>
								</Typography.Text>
							</div>
							<div>
								{(properties || []).map(
									(item: any, index: number) => (
										<Button
											key={index}
											type="text"
											block
											size="large"
											onClick={async () => {
												if (onCloseDrawer)
													onCloseDrawer();
												setCurrent(item._id);
												updateCurrent(item._id);
												setOpen(false);
											}}
											style={{
												height: 'auto',
												paddingLeft: 0,
												paddingRight: 0
											}}
										>
											<PropertyCard
												property={item}
												isSelected={
													current === item._id ||
													false
												}
											/>
										</Button>
									)
								)}
							</div>
						</Modal>
					</>
				)}
				{(properties || []).length === 0 && <DrawerMenuDivider />}
				{addPropertyButton}
			</>
		);
	}

	return (
		<>
			{properties.length > 0 && (
				<Select
					{...props.selectProp}
					optionLabelProp="label"
					style={{
						width: 240,
						backgroundColor: '#edfbff',
						...props.style?.select
					}}
					bordered={false}
					onChange={handleChange}
					defaultValue={[current]}
					value={[current]}
					loading={loading}
					dropdownRender={(menu) => (
						<>
							<div style={{ textAlign: 'center', padding: 15 }}>
								<Typography.Text>
									<FormattedMessage
										id="select-property.selectProperty"
										defaultMessage="Selecciona un negocio"
									/>
								</Typography.Text>
							</div>
							{menu}
							{addPropertyButton && (
								<div style={{ padding: 10, paddingTop: 30 }}>
									{addPropertyButton}
								</div>
							)}
						</>
					)}
				>
					{properties.map((e: any, index) => (
						<Select.Option key={index} value={e._id} label={e.name}>
							<PropertyCard property={e} />
						</Select.Option>
					))}
				</Select>
			)}
		</>
	);
};

export default SelectProperty;

const PropertyName = styled(Typography.Text)`
	font-size: 14px;
	line-height: 1.2;
	color: ${({ theme }) => theme.colors.black.base || '#000000'};
	display: block;
`;

const PropertyRol = styled(Typography.Text)`
	font-size: 12px;
	line-height: 1.2;
	color: ${({ theme }) =>
		(theme.colors.gray.darken && theme.colors.gray.darken['50%']) ||
		'#000000'};
	display: block;
`;

const CheckIcon = styled(CheckCircleOutlined)`
	font-size: 25px;
	margin-right: 15px;
	color: ${({ theme }) => theme.colors.primary.base};
`;

const { useBreakpoint } = Grid;

export const PropertyCard = ({
	property,
	isSelected
}: {
	property: any;
	isSelected?: boolean;
}) => {
	const screens = useBreakpoint();
	return (
		<div style={{ position: 'relative', margin: '10px 0' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span
					style={{
						width: screens.xs ? 'auto' : 185,
						textAlign: 'left',
						whiteSpace: 'normal',
						fontWeight: 'normal'
					}}
				>
					<PropertyName>{property.name}</PropertyName>
					<PropertyRol>
						<FormattedMessage
							id="select-property.propertyRol"
							defaultMessage="Eres {owner, select, true {propietario} other {colaborador}} "
							values={{
								owner: property.rol === 'owner'
							}}
						/>
					</PropertyRol>
				</span>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{isSelected && screens.xs && <CheckIcon />}
					<CircleFlag
						height={20}
						countryCode={String(
							property.country_iso
						).toLocaleLowerCase()}
					/>
				</div>
			</div>
		</div>
	);
};

const SelectPropertyDefaultProps: SelectPropertyProps = {
	onCloseDrawer: undefined,
	loading: undefined,
	hideAddPropertyButton: undefined,
	appName: undefined
};

const CurrentPropertyContainer = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	line-height: 1.25;
	font-size: 20px;
`;

const SectionProperty = styled.div`
	background-color: ${({ theme }) =>
		theme.colors.gray.lighten && theme.colors.gray.lighten['90%']}ab;
	padding: 2.5rem 0;
	margin: 1rem 0;
	border: 1px solid
		${({ theme }) =>
			theme.colors.gray.lighten && theme.colors.gray.lighten['80%']};
`;
