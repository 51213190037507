/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	createPropertyService,
	deletePropertyService,
	getPropertyIntegrationsService,
	getPropertyService,
	getUserInformationService,
	patchMeService,
	patchPropertyService,
	pathPropertyIntegrationService,
	updatePropertyService
} from '@hospy/util-api';
import { cognitoService } from '@hospy/util-auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PropertyIntegrations } from '../common/common.types';
import {
	GetPropertyIntegrations,
	IFindUser,
	updateUser
} from './user.interfaces';
import { userApi } from './userAPI';

export const fetchUserInformation = createAsyncThunk(
	'user/fetchStatus',
	async ({ user }: any) => {
		const { data } = await getUserInformationService();
		const providers = user.identities?.map(
			(item: any) => item.providerName
		);
		return { ...data, providers };
	}
);

export const fetchSelectedProperty = createAsyncThunk(
	'user/fetchSelectedProperty',
	async ({ idToken, currentProperty, user }: any) => {
		const { data } = await patchMeService(idToken, { currentProperty });
		const providers = user.identities?.map(
			(item: any) => item.providerName
		);
		return { ...data, providers };
	}
);

export const fetchUpdate = createAsyncThunk(
	'user/fetchUpdate',
	async ({ accessToken, data }: any) => {
		const response = await patchMeService(accessToken, data);
		return { ...response, updateKeys: Object.keys(data) };
	}
);

export const updateUserForAdmin = createAsyncThunk(
	'user/updateUserForAdmin',
	async (data: updateUser) => {
		const response = await userApi.fetchUpdateUserForAdmin(data);

		if (response.statusCode != 200)
			return { error: 'Ocurrió un error al actualizar el usuario' };

		return { response, data };
	}
);

export const verifyEmailOrPhoneUpdate = createAsyncThunk(
	'user/verifyEmailOrPhoneUpdate',
	async (data: IFindUser) => {
		let attributeUpdate = Object.keys(data)[0],
			value = Object.assign(data)[attributeUpdate];

		const user = await userApi.fetchFindUser(data);

		if (!user) {
			if (attributeUpdate === 'phoneNumber') {
				attributeUpdate = 'phone_number';
				value = '+' + value;
			}

			//TODO: Se podría modificar el método updateAttributesUser para actualizar multiples por ahora no es necesario
			const response = await cognitoService.updateAttributesUser(
				attributeUpdate,
				value
			);

			if (response != 'SUCCESS') return { error: response };

			return { response, type: attributeUpdate };
		} else
			return {
				error: attributeUpdate.toUpperCase() + '_EXIST',
				detail: user
			};
	}
);

export const validateUserExists = createAsyncThunk(
	'user/validateUserExists',
	async (data: IFindUser) => {
		const user = await userApi.fetchFindUser(data);

		return user;
	}
);

export const getProperty = createAsyncThunk(
	'user/getProperty',
	async (propertyId: string) => {
		const { data } = await getPropertyService(propertyId);
		return data.length > 0 ? data[0] : undefined;
	}
);

export const getPropertyIntegrations = createAsyncThunk(
	'user/getPropertyIntegrations',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await getPropertyIntegrationsService({
				propertyId
			});
			if (errors) {
				console.error(errors);
				return rejectWithValue(String(errors));
			}
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(String(error));
		}
	}
);

export const addProperty = createAsyncThunk(
	'start/AddProperty',
	async (property: any, { rejectWithValue }) => {
		try {
			const { data, errors }: any = await createPropertyService(property);
			if (errors) {
				console.error(errors);
				return rejectWithValue(
					'Error al intentar crear un nuevo negocio'
				);
			}
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error al intentar crear un nuevo negocio');
		}
	}
);

export const updateProperty = createAsyncThunk(
	'user/UpdateProperty',
	async (
		{ propertyId, data: d }: { propertyId: string; data: any },
		{ rejectWithValue }
	) => {
		try {
			const { data, errors }: any = await updatePropertyService(
				propertyId,
				d
			);

			if (errors) {
				console.error(errors);
				return rejectWithValue('No se pudo actualizar el negocio');
			}
			if (!errors && !data) {
				return rejectWithValue('No se pudo actualizar el negocio');
			}
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('No se pudo actualizar el negocio');
		}
	}
);

export const patchProperty = createAsyncThunk(
	'user/PatchProperty',
	async (
		{
			propertyId,
			data: d,
			arrayFilters
		}: { propertyId: string; data: any; arrayFilters?: any[] },
		{ rejectWithValue }
	) => {
		try {
			const response: any = await patchPropertyService(
				propertyId,
				d,
				arrayFilters && `filter=${JSON.stringify({ arrayFilters })}`
			);
			const data = response?.data;
			const errors = response?.errors;

			if (errors) {
				console.error(errors);
				return rejectWithValue('No se pudo actualizar el negocio');
			}
			if (!errors && !data) {
				return rejectWithValue('No se pudo actualizar el negocio');
			}
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('No se pudo actualizar el negocio');
		}
	}
);

export const deleteProperty = createAsyncThunk(
	'user/DeleteProperty',
	async (property_id: string, { rejectWithValue }) => {
		try {
			const { data, errors }: any = await deletePropertyService(
				property_id
			);

			if (errors) {
				console.error(errors);
				return rejectWithValue('No se pudo eliminar el negocio');
			}
			if (!errors && !data) {
				return rejectWithValue('No se pudo eliminar el negocio');
			}
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('No se pudo eliminar el negocio');
		}
	}
);

export const fetchFindUsers = createAsyncThunk(
	'user/fetchFindUsers',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await userApi.findUsers(params);
			return response;
		} catch (error: any) {
			return rejectWithValue(
				'Ocurrió un error al consultar los usuarios'
			);
		}
	}
);

export const fetchExportsCsvUsers = createAsyncThunk(
	'user/fetchExportsCsvUsers',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await userApi.exportsCsvUsers(params);
			return response;
		} catch (error: any) {
			const payload = await error;

			if (payload.errors.length > 0)
				return rejectWithValue(payload.errors[0].message);

			return rejectWithValue(
				'Ocurrió un error al generar reporte de usuarios'
			);
		}
	}
);

export const updatePropertyIntegrationExtras = createAsyncThunk(
	'user/updatePropertyIntegration-extras',
	async (propertyIntegration: PropertyIntegrations, { rejectWithValue }) => {
		if (!propertyIntegration._id)
			return rejectWithValue('No se puede actualizar la integración');

		try {
			const { errors, data } = await pathPropertyIntegrationService(
				propertyIntegration._id,
				{ extras: propertyIntegration.extras }
			);

			if (errors) {
				console.error(errors);
				return rejectWithValue(String(errors));
			}
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(String(error));
		}
	}
);

export const getPropertyIntegrationsByContext = createAsyncThunk(
	'user/getPropertyIntegrationsByContext',
	async (
		{ propertyId, appId }: GetPropertyIntegrations,
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getPropertyIntegrationsService({
				filter: `{"appId": ObjectId("${appId}"), "propertyId": ObjectId("${propertyId}")}`
			});
			if (errors) {
				console.error(errors);
				return rejectWithValue(String(errors));
			}
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(String(error));
		}
	}
);

export const updatePropertyIntegration = createAsyncThunk(
	'user/update-property-integration',
	async (d: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await pathPropertyIntegrationService(
				d._id,
				{ ...d }
			);
			if (errors) {
				return rejectWithValue(String(errors));
			}
			return data;
		} catch (error) {
			console.log({ error });
		}
	}
);
