import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { ICheckinState } from './interfaces';
import {
	createCheckInIntegration,
	createCheckInRequiredFields,
	findCheckInIntegration,
	findCheckInRequiredFields
} from './thunks';

export const slice = createSlice({
	name: 'checkin',
	initialState,
	reducers: {
		setLoading: (state: any, action) => {
			state.loading = action.payload;
		},
		cleanSuccess: (state: any, action) => {
			state.success[action.payload] = false;
		},
		cleanError: (state: any, action) => {
			state.error[action.payload] = undefined;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				createCheckInIntegration.fulfilled,
				(state: ICheckinState, action) => {
					let payload: any = action.payload;
					state.loading = 'idle';
					if (payload) {
						state.success = {
							...state.success,
							createCheckInIntegration: true
						};
						state.checkInIntegrations = [payload.data];
					} else
						state.error = {
							...state.error,
							createCheckInIntegration: payload.error
						};
				}
			)
			.addCase(
				createCheckInIntegration.pending,
				(state: ICheckinState) => {
					state.loading = 'pending';
				}
			)
			.addCase(findCheckInIntegration.fulfilled, (state: any, action) => {
				let payload: any = action.payload;
				if (payload) {
					state.checkInIntegrations = action.payload;
					state.success = {
						...state.success,
						findCheckInIntegration: true
					};
				} else
					state.error = {
						...state.error,
						findCheckInIntegration: true
					};
			})
			.addCase(
				createCheckInRequiredFields.fulfilled,
				(state: any, action) => {
					let payload: any = action.payload;
					if (payload) {
						state.success = {
							...state.success,
							createCheckInRequiredFields: true
						};
						state.checkInRequiredFields = payload;
					} else
						state.error = {
							...state.error,
							createCheckInRequiredFields: payload.error
						};
				}
			)
			.addCase(
				findCheckInRequiredFields.fulfilled,
				(state: any, action) => {
					let payload: any = action.payload;
					if (payload) {
						state.success = {
							...state.success,
							findCheckInRequiredFields: true
						};
						state.checkInRequiredFields = action.payload;
					} else
						state.error = {
							...state.error,
							findCheckInRequiredFields: true
						};
				}
			);
	}
});

export const { cleanSuccess, cleanError, setLoading } = slice.actions;
