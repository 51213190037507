import { cognitoService } from '@hospy/util-auth';
import { ApiURL } from '../util-url';

export const getUserInformationService = (): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const params = new URL(window.location.href).searchParams;
		const userIdView: any =
			params.get('userIdView') || localStorage.getItem('userIdView');
		let isAdmin: any = false;
		if (userIdView)
			isAdmin = await cognitoService.isRolUser({
				Name: 'custom:admin-app-acl',
				Value: 'admin'
			});

		const idToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`
			}
		};

		let url = ApiURL('api/auth/me');

		if (userIdView && isAdmin) {
			localStorage.setItem('userIdView', userIdView);
			url = ApiURL(
				`api/auth/users?userIdView=${
					userIdView || ''
				}&filter=${JSON.stringify({ _id: userIdView })}`
			);
		}

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) =>
				resolve(
					data?.data?.length > 0
						? {
								data: {
									...data.data[0],
									userViewAdmin: userIdView
								}
						  }
						: data
				)
			)
			.catch((error) => reject(error));
	});
