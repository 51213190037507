/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { errorDictionary, initialState } from './constants';
import {
	fetchPartnerIntegrations,
	validatePartnerIntegrationApiKey,
	validatePartnerIntegrationOauth
} from './thunks';

export const guestSlice = createSlice({
	name: 'guest',
	initialState,
	reducers: {
		selectPms: (state, action) => {
			state.selectedPms = action.payload;
		},
		saveOauthPmsCrendentials: (state, action) => {
			state.oauthCredentials = action.payload;
		},
		cleanPmsConnection: (state) => {
			state.selectedPms = initialState.selectedPms;
			state.success = {
				...state.success,
				partnerValidated: false,
				partnerOauthValidated: false
			};

			state.oauthCredentials = initialState.oauthCredentials;
			state.apiCredential = initialState.apiCredential;

			state.error = {
				...state.error,
				partnerValidated: false,
				partnerOauthValidated: false
			};
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPartnerIntegrations.fulfilled, (state, action) => {
			state.partnerIntegrations = action.payload;
			state.loading = 'idle';
		});

		builder.addCase(fetchPartnerIntegrations.pending, (state, action) => {
			state.error = {
				...state.error,
				getPartnerIntegrations: false
			};
			state.success = {
				...state.success,
				getPartnerIntegrations: false
			};
			state.loading = 'pending';
		});

		builder.addCase(
			validatePartnerIntegrationApiKey.fulfilled,
			(state, action) => {
				const payload: any = action.payload;

				state.loading = 'idle';

				if (payload.statusCode !== 200) {
					state.error = {
						...state.error,
						partnerValidated: payload.statusCode
					};
					return;
				}

				state.apiCredential = {
					apiKey: payload.apiKey
				};

				state.success = {
					...state.success,
					partnerValidated: true
				};

				state.error = {
					...state.error,
					partnerValidated: null
				};
			}
		);

		builder.addCase(
			validatePartnerIntegrationApiKey.pending,
			(state, action) => {
				state.error = {
					...state.error,
					partnerValidated: false
				};
				state.success = {
					...state.success,
					partnerValidated: false
				};
				state.loading = 'pending';
			}
		);

		builder.addCase(
			validatePartnerIntegrationOauth.fulfilled,
			(state, action) => {
				const payload: any = action.payload;

				state.loading = 'idle';
				if (payload.error_description) {
					state.error = {
						...state.error,
						partnerOauthValidated:
							errorDictionary[payload.error_description] ||
							payload.error_description
					};
					return;
				}

				state.oauthCredentials = {
					...state.oauthCredentials,
					accessToken: payload.access_token,
					refreshToken: payload.refresh_token,
					accessTokenExpirationDate: moment()
						.add(payload.expires_in, 'minutes')
						.toDate()
				};

				state.success = {
					...state.success,
					partnerOauthValidated: true
				};

				state.error = {
					...state.error,
					partnerOauthValidated: null
				};
			}
		);

		builder.addCase(
			validatePartnerIntegrationOauth.pending,
			(state, action) => {
				state.error = {
					...state.error,
					partnerOauthValidated: false
				};
				state.success = {
					...state.success,
					partnerOauthValidated: false
				};
				state.loading = 'pending';
			}
		);
	}
});

export const { selectPms, cleanPmsConnection, saveOauthPmsCrendentials } =
	guestSlice.actions;
