/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDebounce } from '@hospy/shared-components';
import {
	Async,
	cleanAddFavoriteInternalAccount,
	cleanRemoveFavoriteInternalAccount,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

export interface Props {
	appId: string;
	partnerIntegrationId: string;
	internalAccounts: Async;
	getInternalAccounts: (payload: any) => any;
	setInternalAccounts: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedReservation: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	closeInvoiceDetail: () => void;
}

export const filterTypes = [
	{
		value: 'createdAt',
		label: 'Fecha de Creación'
	}
];

export const useInvoiceListFilters = ({
	internalAccounts,
	...props
}: Props) => {
	const dispatch = useAppDispatch();
	const [selectedDates, setSelectedDates] = useState<string[]>([]);
	const [selectedFilterField, setSelectedFilterField] =
		useState<string>('createdAt');
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [page, setPage] = useState(1);
	const [selectedAdditionalFilters, setSelectedAdditionalFilters] = useState<
		string[]
	>([]);

	const { propertyIntegrations, propertyId } = useAppSelector(
		({ user }) => user
	);
	const { addFavoriteInternalAccount, removeFavoriteInternalAccount } =
		useAppSelector(({ siigoConnect }) => siigoConnect);

	const debouncedSearch = useDebounce(searchValue || '', 500);

	useEffect(() => {
		if (debouncedSearch && debouncedSearch.length > 3) {
			props.closeInvoiceDetail();
			setPage(1);
			setSelectedAdditionalFilters([]);
			executeFilter(`{"search":"${debouncedSearch}"}`);
		}
	}, [debouncedSearch]);

	useEffect(() => {
		if (!propertyIntegrations) return;
		const filterQuery = generateFilterQuery(
			[],
			selectedAdditionalFilters,
			selectedFilterField
		);

		executeFilter(filterQuery);
	}, [propertyIntegrations]);

	useEffect(() => {
		if (removeFavoriteInternalAccount.loading === 'succeeded') {
			dispatch(cleanRemoveFavoriteInternalAccount());
			message.success('Cuenta eliminada de favoritos exitosamente');
		}
	}, [removeFavoriteInternalAccount]);

	useEffect(() => {
		if (addFavoriteInternalAccount.loading === 'succeeded') {
			dispatch(cleanAddFavoriteInternalAccount());
			message.success('Cuenta agregada a favoritos exitosamente');
		}
	}, [addFavoriteInternalAccount]);

	const updateSelectedDates = (start?: string, end?: string) => {
		props.closeInvoiceDetail();
		setSearchValue('');
		setPage(1);
		if (!start || !end) {
			setSelectedDates([]);

			const filterQuery = generateFilterQuery(
				[],
				selectedAdditionalFilters,
				selectedFilterField
			);

			executeFilter(filterQuery);

			return;
		}
		const startDate = moment(start)
			.utc(true)
			.startOf('day')
			.format('YYYY-MM-DDTHH:mm:ss');

		const endDate = moment(end)
			.utc(true)
			.endOf('day')
			.format('YYYY-MM-DDTHH:mm:ss');

		setSelectedDates([startDate, endDate]);

		const filterQuery = generateFilterQuery(
			[startDate, endDate],
			selectedAdditionalFilters,
			selectedFilterField
		);

		executeFilter(filterQuery);
	};

	const updateFilterField = (key: string) => {
		props.closeInvoiceDetail();
		setSelectedFilterField(key);
		setSearchValue('');
		setPage(1);

		const filterQuery = generateFilterQuery(
			selectedDates,
			selectedAdditionalFilters,
			key
		);

		executeFilter(filterQuery);
	};

	const refresh = () => {
		props.closeInvoiceDetail();
		if (!searchValue) {
			const filterQuery = generateFilterQuery(
				selectedDates,
				selectedAdditionalFilters,
				selectedFilterField
			);

			executeFilter(filterQuery);
			return;
		}
		dispatch(
			props.getInternalAccounts({
				filter: `{"search":"${searchValue}"}`,
				appId: props.appId,
				partnerIntegrationId: props.partnerIntegrationId,
				propertyId,
				page
			})
		);
	};

	const searchByValue = (value: string | null) => {
		props.closeInvoiceDetail();
		setSearchValue(value || '');
		setPage(1);
		if (value === '') {
			dispatch(
				props.getInternalAccounts({
					filter: `{"${selectedFilterField}":{"$gte":ISODate('${selectedDates[0]}'), "$lt":ISODate('${selectedDates[1]}')}}`,
					appId: props.appId,
					partnerIntegrationId: props.partnerIntegrationId,
					page,
					propertyId
				})
			);
		}

		return;
	};

	const updatePage = (currentPage: number) => {
		props.closeInvoiceDetail();
		setPage(currentPage);
		if (searchValue !== '') {
			dispatch(
				props.getInternalAccounts({
					filter: `{"${selectedFilterField}":{"$gte":ISODate('${selectedDates[0]}'), "$lt":ISODate('${selectedDates[1]}')}}`,
					appId: props.appId,
					partnerIntegrationId: props.partnerIntegrationId,
					page: currentPage,
					propertyId
				})
			);
		} else {
			const filterQuery = generateFilterQuery(
				selectedDates,
				selectedAdditionalFilters,
				selectedFilterField
			);
			executeFilter(filterQuery, currentPage);
			dispatch(
				props.getInternalAccounts({
					filter: `{"${selectedFilterField}":{"$gte":ISODate('${selectedDates[0]}'), "$lt":ISODate('${selectedDates[1]}')}}`,
					appId: props.appId,
					partnerIntegrationId: props.partnerIntegrationId,
					page: currentPage,
					propertyId
				})
			);
		}
	};

	const updateAdditionalFilters = (filters: string[]) => {
		props.closeInvoiceDetail();
		setSelectedAdditionalFilters(filters);
		setSearchValue('');
		setPage(1);
		const filterQuery = generateFilterQuery(
			selectedDates,
			filters,
			selectedFilterField
		);
		executeFilter(filterQuery);
	};

	const executeFilter = (filterQuery: string, currentPage?: number) => {
		dispatch(
			props.getInternalAccounts({
				filter: filterQuery,
				appId: props.appId,
				partnerIntegrationId: props.partnerIntegrationId,
				page: currentPage || page,
				propertyId
			})
		);
	};

	return {
		selectedDates,
		updateSelectedDates,
		loading: internalAccounts.loading === 'pending',
		internalAccounts: internalAccounts.data?.data,
		updateFilterField,
		refresh,
		searchByValue,
		searchValue,
		page,
		updatePage,
		selectedAdditionalFilters,
		updateAdditionalFilters,
		selectedFilterField
	};
};

const generateFilterQuery = (
	dates: string[],
	filters: string[],
	filterField: string
): string => {
	let dateFilter = '';
	if (filterField && dates[0] && dates[1])
		dateFilter = `"${filterField}":{"$gte":ISODate('${dates[0]}'), "$lt":ISODate('${dates[1]}')}`;
	let filterStatus = '';

	if (filters.length > 0) {
		console.log({ found: filters.find((filter) => filter === 'open') });
		filterStatus = filters.find((filter) => filter === 'open')
			? `, "status":"open"`
			: '';
		filterStatus = filters.find((filter) => filter === 'closed')
			? `, "status":"closed"`
			: filterStatus;
	}

	return `{${dateFilter}${filterStatus}}`;
};
