import { createSlice } from '@reduxjs/toolkit';
import { customFormInitialState } from './constants';
import { createCustomForm, getForm, updateCustomForm } from './thunks';

export const customFormSlice = createSlice({
	name: 'forms',
	initialState: customFormInitialState,
	reducers: {
		cleanGetForms: (state) => {
			state.forms = customFormInitialState.forms;
		},
		cleanCreateForms: (state) => {
			state.createForm = customFormInitialState.createForm;
		},
		cleanUpdateForms: (state) => {
			state.updateForm = customFormInitialState.updateForm;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getForm.pending, (state) => {
				state.forms = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getForm.rejected, (state, { payload }) => {
				state.forms = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(getForm.fulfilled, (state, { payload }) => {
				state.forms = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(createCustomForm.pending, (state) => {
				state.createForm = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(createCustomForm.rejected, (state, { payload }) => {
				state.createForm = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(createCustomForm.fulfilled, (state, { payload }) => {
				state.createForm = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
				const currentForms = state.forms.data || [];
				currentForms.push(payload);
				state.forms = {
					loading: 'succeeded',
					error: undefined,
					data: currentForms
				};
			});

		builder
			.addCase(updateCustomForm.pending, (state) => {
				state.updateForm = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(updateCustomForm.rejected, (state, { payload }) => {
				state.updateForm = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(updateCustomForm.fulfilled, (state, { payload }) => {
				state.updateForm = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
				const currentForms = (state.forms.data || []).map((form) => {
					return form._id === payload._id ? payload : form;
				});

				state.forms = {
					loading: 'succeeded',
					error: undefined,
					data: currentForms
				};
			});
	}
});

export const { cleanGetForms, cleanCreateForms, cleanUpdateForms } =
	customFormSlice.actions;
