export interface ParseHttpFilters {
	value: any;
	key: string;
	typeOf: 'string' | 'number' | 'ObjectId' | 'boolean';
}

export const parseHttpFilters = (rawFilters: ParseHttpFilters[]) => {
	const filters = `{${rawFilters
		.map(({ key, value, typeOf }) => {
			let filterItem = `"${key}":`;
			switch (typeOf) {
				case 'ObjectId':
					filterItem += `new ObjectId("${value}")`;
					break;
				case 'number':
					filterItem += `${value})`;
					break;

				case 'boolean':
					filterItem += `${value})`;
					break;

				default:
					filterItem += `"${value}"`;
					break;
			}
			return filterItem;
		})
		.join(',')}}`;
	return filters;
};
