import { useOutsideClick } from '@hospy/shared-components';
import {
	clearUpdateCredits,
	getCredits,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect, useState } from 'react';

const useCreditView = () => {
	const dispatch = useAppDispatch();
	const [isOpen, setIsOpen] = useState(false);

	const handleClickOutside = () => {
		// setIsOpen(false);
	};
	const creditRef = useOutsideClick(handleClickOutside);

	const { credits, updateCredits, creditInvoice } = useAppSelector(
		({ credits }) => credits
	);

	const availableCredits =
		(credits?.data?.stamps || -1) > 0 ? credits?.data?.stamps || -1 : 0;

	useEffect(() => {
		dispatch(getCredits());
	}, []);

	useEffect(() => {
		if (
			updateCredits.loading === 'idle' ||
			updateCredits.loading === 'pending'
		)
			return;

		if (updateCredits.loading === 'succeeded') setIsOpen(false);

		dispatch(clearUpdateCredits());
	}, [creditInvoice.loading, updateCredits.loading]);

	return {
		availableCredits,
		creditRef,
		isOpen,
		setIsOpen,
		credits
	};
};

export default useCreditView;
