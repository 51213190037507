/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestCreateCreditNote,
	RequestCreateFavoriteInternalAccount,
	RequestCreateInternalAccountInvoice,
	RequestRemoveFavoriteInternalAccount,
	RequestUpdateInternalAccountInvoice,
	createFieldMappingService,
	fetchAdvancePayment,
	fetchApplyCreditNote,
	fetchDeleteInvoice,
	fetchGetInvoice,
	fetchGetStampInvoice,
	fetchInvoiceReservations,
	fetchRetryJournal,
	fetchStampInvoiceAsync,
	fetchUpdateInvoice,
	getCostCenters,
	getCustomerDocumentTypes,
	getDocumentTypes,
	getInvoiceStampsErrorsService,
	getListingTypes,
	getPaymentMethods,
	getPaymentTypes,
	getProducts,
	getTaxes,
	getUsers,
	updateAsyncStampInvoiceService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayloadBase } from '../common/common.interfaces';
import { APPS, PARTNER } from '../common/common.types';
import { Invoice, InvoiceStampAsync } from '../partner/accounting/interface';
import { InvoiceCreditNoteStampRequest } from './interface';
import { SiigoConnectSliceName } from './constants';
import {
	RequestCreate,
	RequestFindAll,
	RequestRemove,
	RequestUpdate
} from '@hospy/util-api/requests';
import { RequestFindAllAccounts } from '@hospy/util-api/service/internal-account';

export const accountingGetPaymentMethods = createAsyncThunk(
	'siigo-connect/accountingGetPaymentMethods',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const { data } = await getPaymentMethods(payload);
			return data;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const accountingGetPaymentTypes = createAsyncThunk(
	'siigo-connect/accountingGetPaymentTypes',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const { data } = await getPaymentTypes(
				payload,
				'filter={"type": "bill", "due_date":false}'
			);
			if (!data) return rejectWithValue('Error');
			return data;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const createFieldsMapping = createAsyncThunk(
	'siigo-connect/createFieldsMapping',
	async (payload: any, { rejectWithValue }) => {
		try {
			const response = await createFieldMappingService(payload);
			return response;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const accountingGetDocumentTypes = createAsyncThunk(
	'siigo-connect/getDocumentTypes',
	async (
		{
			type,
			...payload
		}: PayloadBase & {
			type?:
				| 'cashReceipt'
				| 'bills'
				| 'creditNotes'
				| 'credits'
				| 'accountingAdjustments';
		},
		{ rejectWithValue }
	) => {
		if (type) {
			try {
				const filterTypes = {
					cashReceipt: 'cash-receipt',
					bills: 'bill',
					creditNotes: 'credit-note',
					credits: 'bill',
					accountingAdjustments: 'accounting-adjustments'
				};
				let filter = `filter={"type": "${filterTypes[type]}"}`;
				if (type === 'creditNotes')
					filter = `filter={"type": "${filterTypes[type]}", "electronic_type": "ElectronicCreditNote"}`;

				if (type === 'bills')
					filter = `filter={"type": "${filterTypes[type]}", "electronic_type": "ElectronicInvoice"}`;

				if (type === 'credits') {
					const { data, statusCode } = await getPaymentTypes(
						{ ...payload },
						filter
					);

					if (statusCode !== 200) {
						return rejectWithValue(
							'No se pudo obtener los tipos de pago'
						);
					}
					return {
						type,
						[type]: data?.map(({ name, thirdPartyId }: any) => ({
							thirdPartyId,
							code: thirdPartyId,
							name
						}))
					};
				}

				const { data, statusCode } = await getDocumentTypes(
					{ ...payload },
					filter
				);
				if (statusCode !== 200) {
					return rejectWithValue(
						'No se pudo obtener los recibos de caja'
					);
				}
				return {
					type,
					[type]: data
				};
			} catch (error: any) {
				console.error({ error });
				return rejectWithValue('Error: ' + String(error));
			}
		}

		try {
			const cashReceipt = await getDocumentTypes(
				{ ...payload },
				'filter={"type": "cash-receipt"}'
			);
			if (cashReceipt.statusCode !== 200) {
				return rejectWithValue(
					'No se pudo obtener los recibos de caja'
				);
			}

			const bills = await getDocumentTypes(
				{ ...payload },
				'filter={"type": "bill", "electronic_type": "ElectronicInvoice"}'
			);
			if (bills.statusCode !== 200) {
				return rejectWithValue(
					'No se pudo obtener los recibos de caja'
				);
			}

			const creditNotes = await getDocumentTypes(
				{ ...payload },
				'filter={"type": "credit-note", "electronic_type": "ElectronicCreditNote"}'
			);
			if (creditNotes.statusCode !== 200) {
				return rejectWithValue(
					'No se pudo obtener los recibos de caja'
				);
			}

			const credits = await getPaymentTypes(
				{ ...payload },
				'filter={"type": "bill", "due_date":true}'
			);

			if (credits.statusCode !== 200) {
				return rejectWithValue('No se pudo obtener los tipos de pago ');
			}

			const accountingAdjustments = await getDocumentTypes(
				{ ...payload },
				'filter={"type": "accounting-adjustments"}'
			);

			if (accountingAdjustments.statusCode !== 200) {
				return rejectWithValue('No se pudo obtener los tipos de pago ');
			}

			return {
				cashReceipt: cashReceipt.data,
				bills: bills.data,
				creditNotes: creditNotes.data,
				credits: credits.data?.map(({ name, thirdPartyId }: any) => ({
					code: thirdPartyId,
					name,
					thirdPartyId
				})),
				accountingAdjustments: accountingAdjustments.data
			};
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue('Error');
		}
	}
);

export const accountingGetCostCenters = createAsyncThunk(
	'siigo-connect/getCostCenters',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const costCenters = await getCostCenters(payload);
			if (costCenters.statusCode !== 200)
				return rejectWithValue(
					'No se pudo obtener los centros de costo'
				);

			return costCenters.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingOriginGetTaxes = createAsyncThunk(
	'siigo-connect/getOriginTaxes',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const taxes = await getTaxes(payload, '{}');
			if (taxes.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los impuestos');

			return taxes.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingGetTaxes = createAsyncThunk(
	'siigo-connect/getTaxes',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const taxes = await getTaxes(payload);
			if (taxes.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los impuestos');

			return taxes.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingDestinyGetTaxes = createAsyncThunk(
	'siigo-connect/getDestinyTaxes',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const taxes = await getTaxes(payload);
			if (taxes.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los impuestos');

			return taxes.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingOriginAccomodations = createAsyncThunk(
	'siigo-connect/getOriginAccomodations',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const accomodations = await getListingTypes(payload);
			if (accomodations.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los alojamientos');

			return accomodations.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingDestinyAccomodations = createAsyncThunk(
	'siigo-connect/getDestinyAccomodations',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const accomodations = await getTaxes(payload);
			if (accomodations.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los alojamientos');

			return accomodations.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingGetOriginProducts = createAsyncThunk(
	'siigo-connect/getOriginProducts',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const products = await getProducts(payload);
			if (products.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los productos');

			return products.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingGetDestinyProducts = createAsyncThunk(
	'siigo-connect/getDestinyProducts',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const products = await getProducts(payload);
			if (products.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los productos');

			return products.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingGetReservations = createAsyncThunk(
	'siigo-connect/getReservations',
	async (
		{ filter, appId, partnerIntegrationId, page }: any,
		{ rejectWithValue, getState }
	) => {
		try {
			const state: any = getState();

			const { statusCode, data, meta } = await fetchInvoiceReservations({
				partnerIntegrationId,
				appId,
				propertyId: state.user.propertyId,
				filter: filter,
				page: page || '1',
				limit: '100'
			});
			if (statusCode !== 200)
				return rejectWithValue('No se pudo obtener los productos');

			return { data, meta: meta };
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingGetInvoice = createAsyncThunk(
	'siigo-connect/getInvoice',
	async (reservationId: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);

			const { statusCode, data } = await fetchGetInvoice(
				{
					partnerIntegrationId: integrationFrom?.partnerIntegrationId,
					partnerIntegrationIdTo: PARTNER.SIIGO,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				reservationId
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo obtener los productos');

			if (data?.stamp?.status === 'draft') {
				fetchGetStampInvoice(
					{
						partnerIntegrationId: PARTNER.SIIGO,
						partnerIntegrationIdTo:
							integrationFrom?.partnerIntegrationId,
						appId: APPS.SIIGO_CONNECT,
						propertyId: state.user.propertyId,
						filter: '',
						page: '1',
						limit: '1000'
					},
					reservationId
				);
			}

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingDeleteInvoice = createAsyncThunk(
	'siigo-connect/deleteInvoice',
	async (thirdPartyId: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);

			const { statusCode, data } = await fetchDeleteInvoice(
				{
					partnerIntegrationId: integrationFrom?.partnerIntegrationId,
					partnerIntegrationIdTo: PARTNER.SIIGO,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				thirdPartyId
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo guardar la informaciónn');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingUpdateInvoice = createAsyncThunk(
	'siigo-connect/updateInvoice',
	async (updatedInvoice: Invoice) => {
		return updatedInvoice;
	}
);

export const crmCustomerDocumentTypes = createAsyncThunk(
	'siigo-connect/getCustomersDocumentTypes',
	async (payload: PayloadBase, { rejectWithValue, getState }) => {
		try {
			const response = await getCustomerDocumentTypes(payload);
			return response.data;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const crmUsers = createAsyncThunk(
	'siigo-connect/getUsers',
	async (payload: PayloadBase, { rejectWithValue, getState }) => {
		try {
			const response = await getUsers(payload);
			return response.data;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const accountingInvoiceStamp = createAsyncThunk(
	'siigo-connect/stampInvoice',
	async (payload: InvoiceStampAsync, { rejectWithValue, getState }) => {
		const response: any = await fetchStampInvoiceAsync({ ...payload });

		if (response.statusCode < 200 || response.statusCode > 299) {
			if (response.statusCode === 504) {
				const state: any = getState();
				const integrationFrom =
					state.user.propertyIntegrationsState?.data.find(
						(integration: any) =>
							integration.appId === state.common.appData?._id &&
							['ota', 'pms'].includes(
								integration.partnerIntegrationType
							)
					);
				await manualAwait();
				const responseGetStamp = await fetchGetStampInvoice(
					{
						partnerIntegrationId: PARTNER.SIIGO,
						partnerIntegrationIdTo:
							integrationFrom?.partnerIntegrationId,
						appId: APPS.SIIGO_CONNECT,
						propertyId: state.user.propertyId,
						filter: '',
						page: '1',
						limit: '1000'
					},
					payload.invoiceId
				);

				if (
					!responseGetStamp ||
					responseGetStamp.statusCode < 200 ||
					responseGetStamp.statusCode > 299 ||
					!responseGetStamp.data
				)
					return rejectWithValue(['timeout']);

				const invoice = responseGetStamp.data;
				if (invoice.stamp) return responseGetStamp.data;
			}
			return rejectWithValue(
				response.errors.map((error: any) => error.message)
			);
		}
		return response.data?.invoice;
	}
);

const manualAwait = () => {
	const promised = new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, 2000);
	});
	return promised;
};

export const siigoUpdateStampInvoice = createAsyncThunk(
	'siigo-connect/update-stamp-invoice',
	async (
		payload: InvoiceStampAsync & { _id: string },
		{ rejectWithValue }
	) => {
		const response: any = await updateAsyncStampInvoiceService(
			{ ...payload },
			payload._id
		);

		if (response.statusCode < 200 || response.statusCode > 299)
			return rejectWithValue(
				response.errors.map((error: any) => error.message)
			);

		return response.data;
	}
);

export const accountingGetStamp = createAsyncThunk(
	'siigo-connect/getStampInvoice',
	async (payload: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();
			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);

			const response = await fetchGetStampInvoice(
				{
					partnerIntegrationId: PARTNER.SIIGO,
					partnerIntegrationIdTo:
						integrationFrom?.partnerIntegrationId,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				payload
			);

			if (response.statusCode !== 200)
				return rejectWithValue(response.errors);

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingApplyCreditNote = createAsyncThunk(
	'siigo-connect/accountCreditNote',
	async (
		payload: InvoiceCreditNoteStampRequest,
		{ rejectWithValue, getState }
	) => {
		try {
			const state: any = getState();
			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);

			const response = await fetchApplyCreditNote(
				{
					partnerIntegrationId: PARTNER.SIIGO,
					partnerIntegrationIdTo:
						integrationFrom?.partnerIntegrationId,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					notes: payload.notes
				},
				payload.invoiceId
			);

			if (response.statusCode !== 201)
				return rejectWithValue(response.errors);

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingInvoiceStampErrors = createAsyncThunk(
	'siigo-connect/stampInvoiceErrors',
	async (reservationId: string, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();
			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);
			const { statusCode, errors, data } =
				await getInvoiceStampsErrorsService(reservationId, {
					partnerIntegrationId: PARTNER.SIIGO,
					partnerIntegrationIdTo:
						integrationFrom?.partnerIntegrationId,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId
				});

			if (statusCode !== 200) return rejectWithValue(errors);

			return data;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue('Error al intentar cargar los errores');
		}
	}
);

export const accountingUpdateInvoiceSync = createAsyncThunk(
	'siigo-connect/updateInvoiceSync',
	async (updatedInvoice: Invoice, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();
			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);

			const { statusCode, data } = await fetchUpdateInvoice(
				{
					partnerIntegrationId: integrationFrom?.partnerIntegrationId,
					partnerIntegrationIdTo: PARTNER.SIIGO,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				updatedInvoice.thirdPartyId,
				{ ...updatedInvoice, state: updatedInvoice.state || 'draft' }
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo guardar la información');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const accountingUpdateInvoiceError = createAsyncThunk(
	'siigo-connect/updateInvoiceError',
	async (updatedInvoice: Invoice, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();
			const integrationFrom =
				state.user.propertyIntegrationsState?.data.find(
					(integration: any) =>
						integration.appId === state.common.appData?._id &&
						['ota', 'pms'].includes(
							integration.partnerIntegrationType
						)
				);

			const { statusCode, data } = await fetchUpdateInvoice(
				{
					partnerIntegrationId: integrationFrom?.partnerIntegrationId,
					partnerIntegrationIdTo: PARTNER.SIIGO,
					appId: APPS.SIIGO_CONNECT,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				updatedInvoice.thirdPartyId,
				updatedInvoice
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo guardar la información');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const retryAdvance = createAsyncThunk(
	'siigo-connect/retryAdvance',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await fetchAdvancePayment(params);
			if (response.statusCode !== 201)
				return rejectWithValue(response.message);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const retryJournal = createAsyncThunk(
	'siigo-connect/retryJournal',
	async (
		params: { externalId: string; reservationId: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await fetchRetryJournal(params);
			if (response.statusCode !== 201)
				return rejectWithValue(response.message);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const ThunkFindAllAccounts = createAsyncThunk(
	`${SiigoConnectSliceName}/${RequestFindAllAccounts.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllAccounts(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateInternalAccountStampAsync = createAsyncThunk(
	`${SiigoConnectSliceName}/${RequestCreateInternalAccountInvoice.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateInternalAccountInvoice(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkUpdateInternalAccountStampAsync = createAsyncThunk(
	`${SiigoConnectSliceName}/${RequestUpdateInternalAccountInvoice.name}`,
	async (params: RequestUpdate, { rejectWithValue }) => {
		const response = await RequestUpdateInternalAccountInvoice(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreditNoteInternalAccountStampAsync = createAsyncThunk(
	`${SiigoConnectSliceName}/${RequestCreateCreditNote.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateCreditNote(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateFavoriteInternalAccount = createAsyncThunk(
	`${SiigoConnectSliceName}/${RequestCreateFavoriteInternalAccount.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateFavoriteInternalAccount(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkDeleteFavoriteInternalAccount = createAsyncThunk(
	`${SiigoConnectSliceName}/${RequestRemoveFavoriteInternalAccount.name}`,
	async (params: RequestRemove, { rejectWithValue }) => {
		const response = await RequestRemoveFavoriteInternalAccount(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
