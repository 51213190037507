import { IOnboardingState } from './onboarding.interfaces';

export const initialState = {
	onboardings: {
		error: null,
		loading: 'idle'
	},
	newOnboarding: {
		error: null,
		loading: 'idle'
	},
	updatedOnboarding: {
		error: null,
		loading: 'idle'
	},
	resetedOnboarding: {
		error: null,
		loading: 'idle'
	},
	fieldMapping: {
		error: null,
		loading: 'idle',
		data: undefined
	},
	updatedFieldMapping: {
		error: null,
		loading: 'idle',
	}
} as IOnboardingState;
