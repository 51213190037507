import { Typography } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Step } from '../hooks/use-connect-partner';

interface Props {
	step: Step;
	direction: 1 | -1;
}
const StepTitle = ({ step, direction }: Props) => {
	return (
		<Container>
			{/* <StepLabel>Paso</StepLabel>
			<Number
				key={`number-${step}`}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				{step === 'SELECT_PARTNER' && 1}
				{step === 'AUTHORIZATION' && 2}
			</Number>
			<StepLabel>:</StepLabel> */}
			<StepContainer
				key={`step-${step}`}
				initial={{ y: direction === 1 ? 10 : -10, opacity: 0 }}
				animate={{ y: 0, opacity: 1 }}
				exit={{ y: direction === 1 ? -10 : 10, opacity: 0 }}
			>
				{step === 'SELECT_PARTNER' && (
					<Label>
						Selecciona la aplicación que deseas integrar 👇
					</Label>
				)}
				{step === 'AUTHORIZATION' && (
					<Label>Completar datos de conexión</Label>
				)}
			</StepContainer>
		</Container>
	);
};

const Label = styled(Typography.Text)``;
const Container = styled.div`
	margin-bottom: 20px;
	/* text-align: center; */
`;

const StepLabel = styled.span`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary.base};

	/* margin-right: 5px; */
`;
const Number = styled(motion.span)`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.primary.base};
`;
const StepContainer = styled(motion.div)`
	display: block;
	/* margin-left: 5px; */
`;

export default StepTitle;
