import {
	deleteSireTraSettingsService,
	getSireTraSettingsService,
	rntValidateCredentialsService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { find } from 'lodash';

export const loadTraSireSettings = createAsyncThunk(
	'traSire/loadTraSireSettings',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { errors, data } = await getSireTraSettingsService({
				propertyId
			});
			if (errors) {
				return rejectWithValue(
					`Error cargar las configuraciones, ${errors.toString}`
				);
			}
			return data.length > 0 ? data[0] : null;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue(
				`Error al obtener las configuraciones de TRA & SIRE, ${error.toString()}`
			);
		}
	}
);

export const deleteTraSireSettings = createAsyncThunk(
	'traSire/deleteTraSireSettings',
	async (id: string, { rejectWithValue }) => {
		try {
			const { errors, data } = await deleteSireTraSettingsService(id);
			if (errors) {
				return rejectWithValue(
					`Error al reiniciar las configuraciones, ${errors.toString()}`
				);
			}
			return data;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue(
				`Error al obtener las configuraciones de TRA & SIRE, ${error.toString()}`
			);
		}
	}
);

export const validateRnt = createAsyncThunk(
	'traSire/validateRnt',
	async (d: any, { rejectWithValue }) => {
		try {
			const { errors } = await rntValidateCredentialsService(d);
			if (errors) {
				const el = find(errors, 'constraints.invalidToken');
				if (el) return rejectWithValue('Datos de acceso incorrecto');
				return rejectWithValue(
					'Ocurrió un error inesperado, por favor intente nuevamente'
				);
			}
			return d;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue(
				`La plataforma del gobierno que recibe el reporte no está funcionando. Por favor, intente mas tarde. ${error.toString()}`
			);
		}
	}
);
