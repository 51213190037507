import { createSlice } from "@reduxjs/toolkit";
import { initialState } from './constants';
import { cloudbedsActiveWebhook } from "./thunks";

export const thirdPartyCloudbeds = createSlice({
    name: 'third-party-cloudbeds',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(cloudbedsActiveWebhook.fulfilled, (action, { payload }) => {
            payload
        })
    }
})