import { cognitoService } from '@hospy/util-auth';
import { ApiURL } from '../util-url';

export const getCategoryService = (queryParams: string[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`api/common/apps/categories${
					queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getConnectionService = (queryParams: string[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`api/common/partners/integrations${
					queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getAppStatusService = (queryParams: string[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`api/common/apps/status${
					queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getAppsService = (queryParams: string[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		let _qp: string[] = [];
		if (queryParams) {
			_qp = [...queryParams];
			_qp.push('sort={"sequence":1,"name.es": 1}');
		}

		fetch(
			ApiURL(
				`api/common/apps${_qp?.length > 0 ? `?${_qp.join('&')}` : ''}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const updateAppService = (id: string, data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const session = await cognitoService.getSession();
		const idToken = session.getIdToken().getJwtToken();

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL('api/common/apps/' + id), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPaymentMethodsService = (queryParams: string[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`api/common/apps/pricing-models${
					queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
