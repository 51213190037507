import { Async, InitialState } from "./interface";

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
}

export const initialState: InitialState = {
	companies: initialAsync,
	createCompany: initialAsync
}