import {
	fetchApps,
	logOut,
	setCurrentProperty,
	setPropertyId,
	setUserLoading,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import moment from 'moment';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
	redirect,
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom';
import useSessionMonitoring from '../../hooks/use-session-monitoring';

export const SessionMonitoring = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const sessionMonitoring = useSessionMonitoring();
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const { isAuth, user } = useAppSelector(({ user }) => user);
	//const { boot, shutdown } = useIntercom();

	const { start, pause, reset } = useIdleTimer({
		timeout: 1000 * 60 * 60,
		onIdle: async () => {
			dispatch(setUserLoading('pending'));
			await cognitoService.signOut();
			dispatch(logOut());
			dispatch(setUserLoading('idle'));
			redirect('/login');
		}
	});

	const listenCookieChange = (
		callback: (data: any) => Promise<void>,
		interval = 1000
	) => {
		let lastCookie = document.cookie;
		const timer = setInterval(() => {
			let cookie = document.cookie;
			if (cookie !== lastCookie) {
				try {
					callback({ oldValue: lastCookie, newValue: cookie });
				} finally {
					lastCookie = cookie;
				}
			}
		}, interval);

		return timer;
	};

	useEffect(() => {
		const queyProperty = searchParams.get('property') || '';
		if (queyProperty) {
			if (
				user?.properties?.map((e: any) => e._id).includes(queyProperty)
			) {
				dispatch(setCurrentProperty(queyProperty));
				dispatch(setPropertyId(queyProperty));
				searchParams.delete('property');
				setSearchParams(searchParams);
			}
		}

		const timerCookies = listenCookieChange(
			async ({ oldValue, newValue }) => {
				if (!oldValue && newValue) {
					if (!isAuth && localStorage.getItem('userIdView'))
						window.location.reload();
					return;
				}
				if (oldValue && !newValue) {
					await cognitoService.signOut();
					const _window: any = window;
					if (_window?.Intercom) {
						_window?.Intercom('shutdown');
						_window?.Intercom('boot', {
							email: undefined,
							userId: undefined,
							name: undefined,
							company: undefined
						});
					}

					dispatch(logOut());
					return;
				}
			},
			1000
		);

		return () => {
			if (timerCookies) clearInterval(timerCookies);
		};
	}, [isAuth]);

	useEffect(() => {
		const fetch = async () => {
			let session = await cognitoService.getSession();

			if (!session) {
				await session.checkTokenExpiration();
				session = await cognitoService.getSession();
			}

			if (
				`${window.location.origin}/` !== process.env['NX_APP_SPA'] &&
				`${window.location.origin}/` !== process.env['NX_APP_ADMIN']
			) {
				if (session) await dispatch(fetchApps());
			}
		};
		let timer: any = null;
		if (isAuth) {
			fetch();
			const upTimer = (expirationDate: any) => {
				timer = setInterval(() => {
					const now = moment();
					if (now > expirationDate) {
						clearInterval(timer);
						updateToken();
					}
				}, 1000);
			};

			const updateToken = async () => {
				localStorage.removeItem('Hospy-idToken');
				localStorage.removeItem('Hospy-expiration');

				const currentUser = await cognitoService.getCurrentUser();
				if (currentUser) {
					await cognitoService.checkTokenExpiration();
					const session = await cognitoService.getSession();

					const idToken = session.getIdToken().getJwtToken();
					const expiration = session.getIdToken().getExpiration();
					const _expirationDate = moment(0).add(
						expiration,
						'seconds'
					);

					localStorage.setItem('Hospy-idToken', idToken);
					localStorage.setItem(
						'Hospy-expiration',
						_expirationDate.format('YYYY-MM-DD HH:mm:ss')
					);
					upTimer(_expirationDate);
				}
			};

			const expirationDate = moment(
				localStorage.getItem('Hospy-expiration')
			);
			if (expirationDate.isValid()) {
				upTimer(expirationDate);
			}
		} else {
			if (timer) clearInterval(timer);
		}

		return () => {
			if (timer) clearInterval(timer);
		};
	}, [isAuth]);

	useEffect(() => {
		const fetch = async () => {
			const { isExpire } =
				await sessionMonitoring.getDaysExpirePassword();

			if (isExpire) {
				navigate('/expired-password');
			} else if (location.pathname === '/expired-password') {
				navigate('/');
			}
		};

		if (isAuth && user) {
			reset();
			start();
			fetch();
		} else {
			pause();
		}
	}, [isAuth, user?.email, location?.pathname]);

	return <></>;
};
