/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchExportCsvPropertiesById,
	fetchExportCsvUserWaitListById,
	getPropertyById, getUserWaitListById,
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cleanErrorApp, cleanLoadingApp, cleanSuccessApp } from './slice';

export const fetchGetPropertyById = createAsyncThunk(
	'app/fetchGetPropertyById',
	async (appId: string, { rejectWithValue }) => {
		try {
			const { data } = await getPropertyById(appId);
			if (!data)
				return rejectWithValue('Error al cargar la lista de negocios');
			return data;
		} catch (error: any) {
			console.error('getPropertyById:', error);
			return rejectWithValue('Error al cargar la lista de negocios');
		}
	}
);

export const fetchGetUserWaitListById = createAsyncThunk(
	'app/fetchGetUserWaitListById',
	async (appId: string, { rejectWithValue }) => {
		try {
			const { data } = await getUserWaitListById(appId);
			if (!data)
				return rejectWithValue('Error al cargar la lista de usuarios en espera');
			return data;
		} catch (error: any) {
			console.error('getUserWaitListById:', error);
			return rejectWithValue('Error al cargar la lista de usuarios en espera');
		}
	}
);

export const exportCsvPropertiesById = createAsyncThunk(
	'app/exportCsvPropertiesById',
	async (appId: string, { rejectWithValue, dispatch }) => {
		try {
			dispatch(cleanLoadingApp({ state: 'exportCsvPropertiesById', value: 'pending' }))

			const { data } = await fetchExportCsvPropertiesById(appId);
			dispatch(cleanLoadingApp({ state: 'exportCsvPropertiesById', value: 'idle' }))
			return data;
		} catch (error: any) {
			console.error('exportCsvPropertiesById:', error);
			dispatch(cleanLoadingApp({ state: 'exportCsvPropertiesById', value: 'idle' }))
			return rejectWithValue('Error al exportar csv');
		}
	}
);

export const exportCsvUserWaitListById = createAsyncThunk(
	'app/exportCsvUserWaitListById',
	async (appId: string, { rejectWithValue, dispatch }) => {
		try {
			dispatch(cleanLoadingApp({ state: 'exportCsvUserWaitListById', value: 'pending' }))

			const { data } = await fetchExportCsvUserWaitListById(appId);
			dispatch(cleanLoadingApp({ state: 'exportCsvUserWaitListById', value: 'idle' }))
			return data;
		} catch (error: any) {
			console.error('exportCsvUserWaitListById:', error);
			dispatch(cleanLoadingApp({ state: 'exportCsvUserWaitListById', value: 'idle' }))
			return rejectWithValue('Error al exportar csv');
		}
	}
);