/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	createReservationPaymentCollect,
	deletePaymentIntegration,
	deleteReservationPaymentCollect,
	fetchChannelsReservations,
	fetchCountries,
	fetchPartnerPaymentsIntegrations,
	fetchPaymentIntegrations,
	fetchReservation,
	fetchReservations,
	savePaymentIntegration,
	updateReservationPaymentCollect
} from './thunks';

export const paymentCollectSlice = createSlice({
	name: 'paymentCollect',
	initialState,
	reducers: {
		selectPartnerIntegration: (state, action) => {
			state.selectedPartnerIntegration = action.payload;
		},
		clearPaymentIntegration: (state) => {
			state.selectedPartnerIntegration =
				initialState.selectedPartnerIntegration;
			state.success.paymentIntegrationSaved =
				initialState.success.paymentIntegrationSaved;
			state.error.paymentIntegrationSaved =
				initialState.error.paymentIntegrationSaved;
		},
		clearSaveReservation: (state) => {
			state.success.reservationSaved =
				initialState.success.reservationSaved;
			state.error.reservationSaved = initialState.error.reservationSaved;
		},
		clearUpdateReservation: (state) => {
			state.success.reservationUpdated =
				initialState.success.reservationUpdated;
			state.error.reservationUpdated =
				initialState.error.reservationUpdated;
			state.error.reservationUpdated =
				initialState.error.reservationUpdated;

			state.reservationToUpdate = initialState.reservationToUpdate;
			state.reservationLoading = initialState.reservationLoading;
			state.error.getReservation = initialState.error.getReservation;
			state.success.getReservation = initialState.success.getReservation;
		},
		clearDeleteReservation: (state) => {
			state.success.reservationDeleted =
				initialState.success.reservationDeleted;
			state.error.reservationDeleted =
				initialState.error.reservationDeleted;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(
			fetchPartnerPaymentsIntegrations.fulfilled,
			(state, action) => {
				state.partnerIntegrations = action.payload;
				state.loading = 'idle';
				state.success.getPartnerIntegrations = true;
			}
		);

		builder.addCase(fetchPartnerPaymentsIntegrations.pending, (state) => {
			state.error = {
				...state.error,
				getPartnerIntegrations: false
			};
			state.success = {
				...state.success,
				getPartnerIntegrations: false
			};
			state.loading = 'pending';
		});

		builder.addCase(fetchPaymentIntegrations.fulfilled, (state, action) => {
			state.paymentIntegrations = action.payload;
			state.loading = 'idle';
			state.success.getPaymentIntegrations = true;
		});

		builder.addCase(fetchPaymentIntegrations.pending, (state) => {
			state.error = {
				...state.error,
				getPaymentIntegrations: false,
				paymentConnectionRestarted: false,
				paymentIntegrationSaved: undefined
			};
			state.success = {
				...state.success,
				getPaymentIntegrations: false,
				paymentConnectionRestarted: false,
				paymentIntegrationSaved: false
			};
			state.loading = 'pending';
		});

		builder.addCase(savePaymentIntegration.fulfilled, (state, action) => {
			state.loading = 'idle';
			if (action.payload === true)
				state.success.paymentIntegrationSaved = action.payload;
			else state.error.paymentIntegrationSaved = action.payload.errors;
		});

		builder.addCase(savePaymentIntegration.pending, (state) => {
			state.error = {
				...state.error,
				paymentIntegrationSaved: undefined
			};
			state.success = {
				...state.success,
				paymentIntegrationSaved: false
			};
			state.loading = 'pending';
		});

		builder.addCase(deletePaymentIntegration.fulfilled, (state, action) => {
			state.loading = 'idle';
			if (action.payload === true)
				state.success.paymentConnectionRestarted = action.payload;
			else state.error.paymentConnectionRestarted = action.payload.errors;
		});

		builder.addCase(deletePaymentIntegration.pending, (state) => {
			state.error = {
				...state.error,
				paymentConnectionRestarted: false
			};
			state.success = {
				...state.success,
				paymentConnectionRestarted: false
			};
			state.loading = 'pending';
		});

		builder.addCase(
			fetchChannelsReservations.fulfilled,
			(state, action) => {
				state.channelsReservations = action.payload;
				state.loading = 'idle';
				state.success.getChannelsReservations = true;
			}
		);

		builder.addCase(fetchChannelsReservations.pending, (state) => {
			state.error = {
				...state.error,
				getChannelsReservations: false
			};
			state.success = {
				...state.success,
				getChannelsReservations: false
			};
			state.loading = 'pending';
		});

		builder.addCase(fetchCountries.fulfilled, (state, action) => {
			state.countries = action.payload;
			state.countryLoading = 'idle';
			state.success.getCountries = true;
		});

		builder.addCase(fetchCountries.pending, (state) => {
			state.error = {
				...state.error,
				getCountries: false
			};
			state.success = {
				...state.success,
				getCountries: false
			};
			state.countryLoading = 'pending';
		});

		builder.addCase(
			createReservationPaymentCollect.fulfilled,
			(state, action) => {
				if (action.payload === true)
					state.success.reservationSaved = action.payload;
				else state.error.reservationSaved = action.payload;
				state.saveReservationLoading = 'idle';
			}
		);

		builder.addCase(createReservationPaymentCollect.pending, (state) => {
			state.error = {
				...state.error,
				reservationSaved: false
			};
			state.success = {
				...state.success,
				reservationSaved: false
			};
			state.saveReservationLoading = 'pending';
		});

		builder.addCase(fetchReservations.fulfilled, (state, action) => {
			if (action.payload?.length > 0 && !state.hasReservations)
				state.hasReservations = true;

			state.reservations = action.payload;
			state.reservationsLoading = 'idle';
			state.success.getReservations = true;
		});

		builder.addCase(fetchReservations.pending, (state) => {
			state.success = {
				...state.success,
				getReservations: false
			};
			state.error = {
				...state.error,
				getReservations: false
			};
			state.reservationsLoading = 'pending';
		});

		builder.addCase(
			deleteReservationPaymentCollect.fulfilled,
			(state, action) => {
				state.loading = 'idle';
				if (action.payload === true)
					state.success.reservationDeleted = action.payload;
				else state.error.reservationDeleted = action.payload;
			}
		);

		builder.addCase(deleteReservationPaymentCollect.pending, (state) => {
			state.error = {
				...state.error,
				reservationDeleted: false
			};
			state.success = {
				...state.success,
				reservationDeleted: false
			};
			state.loading = 'pending';
		});

		builder.addCase(fetchReservation.fulfilled, (state, action) => {
			state.reservationLoading = 'idle';

			if (action.payload !== null) {
				state.reservationToUpdate = action.payload;
				state.success.getReservation = true;
			} else {
				state.error.getReservation = true;
			}
		});

		builder.addCase(fetchReservation.pending, (state) => {
			state.success = {
				...state.success,
				getReservation: false
			};
			state.error = {
				...state.error,
				getReservation: false
			};
			state.reservationLoading = 'pending';
		});

		builder.addCase(
			updateReservationPaymentCollect.fulfilled,
			(state, action) => {
				if (action.payload === true)
					state.success.reservationUpdated = action.payload;
				else state.error.reservationUpdated = action.payload;
				state.updateReservationLoading = 'idle';
			}
		);

		builder.addCase(updateReservationPaymentCollect.pending, (state) => {
			state.error = {
				...state.error,
				reservationUpdated: false
			};
			state.success = {
				...state.success,
				reservationUpdated: false
			};
			state.updateReservationLoading = 'pending';
		});
	}
});

export const {
	selectPartnerIntegration,
	clearPaymentIntegration,
	clearSaveReservation,
	clearDeleteReservation,
	clearUpdateReservation
} = paymentCollectSlice.actions;
