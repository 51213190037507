import {
	fetchUserInformation,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { ApiURL, validateEmailExist } from '@hospy/util-api';
import { cognitoService, IRegister } from '@hospy/util-auth';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type isValidEmailType = '' | 'VALID_EMAIL' | 'INVALID_EMAIL';
export type stepType = 'EMAIL_VALIDATION' | 'COMPLETE_FORM';

export interface IUseRegister {
	onSubmit: (data: IRegister) => Promise<void>;
	loading: boolean;
	step: string;
	isValidEmail: isValidEmailType;
	setIsValidEmail: React.Dispatch<isValidEmailType>;
	register: (data: any) => Promise<any>;
	isAuth?: boolean;
}

const useRegister = (): IUseRegister => {
	const [step, setStep] = useState<stepType>('EMAIL_VALIDATION');
	const [loading, setLoading] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState<isValidEmailType>('');
	const { isAuth } = useAppSelector((state: any) => state.user);
	const location = useLocation();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isValidEmail === 'VALID_EMAIL') {
			setStep('COMPLETE_FORM');
			return;
		}
		setStep('EMAIL_VALIDATION');
	}, [isValidEmail]);

	const actionForm: any = {
		EMAIL_VALIDATION: async (data: IRegister) => {
			const user = await validateEmailExist(data.username);

			if (user) {
				setIsValidEmail('INVALID_EMAIL');
				message.error(
					'El email ingresado ya se encuentra en nuestro sistema'
				);
				return;
			}

			setIsValidEmail('VALID_EMAIL');
		},
		COMPLETE_FORM: async (data: IRegister) => {
			const { userConfirmed, userSub } = await cognitoService.register(
				data
			);

			await register({
				cognitoUsername: userSub,
				name: data.name,
				email: data.username,
				userConfirmed,
				userSub
			});

			if (userConfirmed) {
				const currentUser = await cognitoService.getCurrentUser();
				if (currentUser) {
					const session = await cognitoService.getSession();
					await dispatch(
						fetchUserInformation({
							user: session?.idToken?.payload
						})
					);
				}

				const { pathname, search, ...rest } = location.state;
				if (pathname) {
					navigate(pathname + search, { state: { ...rest } });
					return;
				}

				navigate('/');
				return;
			}

			navigate(`/verification-code/${data.username}`, {
				state: {
					password: data.password,
					...location.state
				}
			});
		}
	};

	const onSubmit = async (data: IRegister) => {
		setLoading(true);
		try {
			await actionForm[step](data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const register = (data: any): Promise<any> =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data)
			};
			fetch(ApiURL('api/auth/signup'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	return {
		step,
		loading,
		onSubmit,
		isValidEmail,
		setIsValidEmail,
		register,
		isAuth
	};
};

export default useRegister;
