const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

/**
 * https://www.tabnine.com/code/javascript/classes/amazon-cognito-identity-js/CognitoUserPool
 * https://www.npmjs.com/package/amazon-cognito-identity-js
 * https://gist.github.com/kndt84/5be8e86a15468ed1c8fc3699429003ad
 */

export interface IVerificationCode {
	verificationCode: string;
}

export interface ICognitoLogin {
	username: string;
	password: string;
}

export interface IRecoverPassword {
	username: string;
	code: string;
	password: string;
	confirm: string;
}

export interface IRegister extends ICognitoLogin {
	name: string;
	email?: string;
	userConfirmed?: boolean;
	userSub?: string;
}

export interface IUpdatePassword {
	old_password: string;
	password: string;
}

export interface ICognitoNewPasswordChallenge extends ICognitoLogin {
	name: string;
	newPassword: string;
}

interface IAttributes {
	Name: string;
	Value: string;
}

export class CognitoService {
	userPool: any;
	userPoolId: string;
	clientId: string;

	constructor(userPoolId: string, clientId: string) {
		this.userPoolId = userPoolId;
		this.clientId = clientId;

		this.userPool = new AmazonCognitoIdentity.CognitoUserPool({
			UserPoolId: userPoolId,
			ClientId: clientId,
			Storage: this.cookieCognito()
		});
	}

	cookieCognito() {
		return new AmazonCognitoIdentity.CookieStorage({
			domain: process.env['NX_DOMAIN_COOKIE_STORAGE'],
			secure: false // Cookie secure flag (default: true)
		});
	}

	async login(data: ICognitoLogin): Promise<any> {
		return this.loginCognito(data);
	}

	async newPasswordChallenge(
		data: ICognitoNewPasswordChallenge
	): Promise<any> {
		return this.loginCognito(data);
	}

	async loginCognito(data: ICognitoNewPasswordChallenge | ICognitoLogin) {
		const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
			Username: data.username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		});

		const authenticationDetails =
			new AmazonCognitoIdentity.AuthenticationDetails({
				Username: data.username,
				Password: data.password
			});

		const promise = new Promise((resolve, reject) =>
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess: (result: any) => resolve(result),
				onFailure: (err: any) => reject(err),
				mfaRequired: (result: string, data: any) => {
					resolve({
						mfa: true,
						session: cognitoUser.Session,
						destiny: data.CODE_DELIVERY_DESTINATION
					});
				},
				newPasswordRequired: (userAttributes: any) => {
					delete userAttributes.email_verified;
					delete userAttributes.email;
					if (
						(data as ICognitoNewPasswordChallenge).newPassword !==
						undefined
					) {
						const dataTmp = <ICognitoNewPasswordChallenge>data;
						userAttributes.name = dataTmp.name;
						cognitoUser.completeNewPasswordChallenge(
							dataTmp?.newPassword,
							userAttributes,
							this
						);
					}
				}
			})
		);

		try {
			return await promise;
		} catch (error: any) {
			console.error("loginCognito", { error });

			return Promise.reject(error);
		}
	}

	async loginMFA(data: {
		username: string;
		verificationCode: string;
		session: string;
	}) {
		const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
			Username: data.username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		});

		cognitoUser.Session = data.session;

		const promise = new Promise((resolve, reject) => {
			cognitoUser.sendMFACode(data.verificationCode, {
				onSuccess: (result: any) => resolve(result),
				onFailure: (err: any) => reject(err)
			});
		});

		try {
			return await promise;
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async register(data: IRegister): Promise<any> {
		const nameAttribute = new AmazonCognitoIdentity.CognitoUserAttribute({
			Name: 'name',
			Value: data.name
		});

		return new Promise((success, error) => {
			const callback = (err: any, result: any) => {
				if (err) {
					error(err);
					return;
				}
				success(result);
			};

			this.userPool.signUp(
				data.username,
				data.password,
				[nameAttribute],
				null,
				callback
			);
		});
	}

	async confirmRegistration(username: string, verificationCode: string) {
		const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
			Username: username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		});

		return new Promise((success, error) => {
			const callback = (err: any, result: any) => {
				if (err) {
					error(err);
					return;
				}
				success(result);
			};

			cognitoUser.confirmRegistration(verificationCode, true, callback);
		});
	}

	async resendConfirmationCode(username: string): Promise<void> {
		const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
			Username: username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		});

		return new Promise((success, error) => {
			const callback = (err: any, result: any) => {
				if (err) {
					error(err);
					return;
				}
				success(result);
			};
			cognitoUser.resendConfirmationCode(callback);
		});
	}

	async signOut() {
		return this.userPool.getCurrentUser()?.signOut();
	}

	async getCurrentUser() {
		return this.userPool.getCurrentUser();
	}

	getTokens = function (session: any) {
		return {
			accessToken: session.getAccessToken(),
			idToken: session.getIdToken().getJwtToken(),
			refreshToken: session.getRefreshToken().getToken()
		};
	};

	async checkTokenExpiration() {
		let user = this.userPool.getCurrentUser();
		if (user != null) {
			user.getSession((error: any, session: any) => {
				if (error) return;
				if (!session.isValid()) {
					let refreshToken =
						new AmazonCognitoIdentity.CognitoRefreshToken({
							RefreshToken: this.getTokens(session).refreshToken
						});
					user.refreshSession(
						refreshToken,
						(rsError: any, rsSession: any) => {
							console.error(
								'checkTokenExpiration',
								rsError,
								rsSession
							); // TODO: fallback refreshSession
						}
					);
				}
			});
		}
	}

	async getSession(): Promise<any> {
		let user = this.userPool.getCurrentUser();
		if (user != null) {
			let session = new Promise((resolve, reject) =>
				user.getSession((e: any, r: any) => {
					if (!e) {
						resolve(r);
					} else {
						reject(e);
					}
				})
			);
			return Promise.resolve(await session);
		}
		// return Promise.reject(Error('User is not login'));
		return null;
	}

	async forgotPassword(username: string): Promise<any> {
		const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
			Username: username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		});

		return new Promise((resolve, reject) => {
			cognitoUser.forgotPassword({
				onSuccess: (result: any) => {
					resolve(result);
					console.log(result);
				},
				onFailure: (error: any) => {
					reject(error);
					console.log(error);
				}
			});
		});
	}

	async changePassword(data: IUpdatePassword): Promise<any> {
		return new Promise((resolve, reject) => {
			const currentUser = this.userPool.getCurrentUser();
			if (currentUser) {
				currentUser.getSession((err: any, session: any) => {
					if (err) reject(err);
					else {
						currentUser.changePassword(
							data.old_password,
							data.password,
							(err: any, res: any) => {
								if (err) reject(err);
								else resolve(res);
							}
						);
					}
				});
			}
		});
	}

	async confirmPassword(data: any): Promise<any> {
		const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
			Username: data.username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		});

		return new Promise((resolve, reject) => {
			cognitoUser.confirmPassword(data.code, data.password, {
				onSuccess: () => resolve(true),
				onFailure: (error: any) => reject(error)
			});
		});
	}

	async generateSessionByGoogleAuth(
		RefreshToken: string,
		Username: string
	): Promise<any> {
		const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
			RefreshToken
		});

		const userData: any = {
			Username,
			Pool: this.userPool,
			Storage: this.cookieCognito()
		};

		const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

		return new Promise((resolve, reject) => {
			cognitoUser.refreshSession(
				refreshToken,
				(err: any, session: any) => {
					if (err) reject(err);
					if (session) resolve(session);
				}
			);
		});
	}

	async updateAttributesUser(key: string, value: string) {
		return new Promise((resolve, reject) => {
			const cognitoUser = this.userPool.getCurrentUser();

			cognitoUser.getSession((error: any, session: any) => {
				if (error) return;

				if (session.isValid()) {
					var attributeList = [];
					var attribute =
						new AmazonCognitoIdentity.CognitoUserAttribute({
							Name: key,
							Value: value
						});
					attributeList.push(attribute);

					cognitoUser.updateAttributes(
						attributeList,
						async (err: any, result: any) => {
							if (err) reject(err);
							if (result) resolve(result);
						}
					);
				}
			});
		});
	}

	async verifyAttribute(key: string, verificationCode: string) {
		return new Promise((resolve, reject) => {
			const cognitoUser = this.userPool.getCurrentUser();

			cognitoUser.getSession((error: any, session: any) => {
				if (error) return;

				if (session.isValid()) {
					cognitoUser.verifyAttribute(key, verificationCode, {
						onSuccess: (result: any) => {
							if (key === 'phone_number') {
								const smsMfaSettings = {
									PreferredMfa: true,
									Enabled: true
								};
								cognitoUser.setUserMfaPreference(
									smsMfaSettings,
									null,
									(err2: any, result2: any) => {
										if (err2) reject(err2);
										else resolve(result);
									}
								);
							} else {
								resolve(result);
							}
						},
						onFailure: (err: any) => reject(err)
					});
				}
			});
		});
	}

	async removePhone() {
		return new Promise((resolve, reject) => {
			const cognitoUser = this.userPool.getCurrentUser();

			cognitoUser.getSession((error: any, session: any) => {
				if (error) return;

				if (session.isValid()) {
					var attributeList = [];
					var attribute =
						new AmazonCognitoIdentity.CognitoUserAttribute({
							Name: 'phone_number',
							Value: undefined
						});
					attributeList.push(attribute);

					cognitoUser.updateAttributes(
						attributeList,
						async (err: any, result: any) => {
							if (err) reject(err);
							if (result) {
								const smsMfaSettings = {
									PreferredMfa: false,
									Enabled: false
								};
								cognitoUser.setUserMfaPreference(
									smsMfaSettings,
									null,
									(err2: any) => {
										if (err2) reject(err2);
										else resolve(result);
									}
								);
							}
						}
					);
				}
			});
		});
	}

	async getAttributeVerificationCode(attribute: string) {
		return new Promise((resolve, rejects) => {
			const cognitoUser = this.userPool.getCurrentUser();
			cognitoUser.getSession((error: any, session: any) => {
				console.log({ error, session });

				if (error) return;
				cognitoUser.getAttributeVerificationCode(attribute, {
					onSuccess: function (result: any) {
						console.log('call result: ' + result);
						resolve(result);
					},
					onFailure: function (err: any) {
						console.error(err.message || JSON.stringify(err));
						rejects(err);
					}
				});
			});
		});
	}

	async isRolUser(Rol: IAttributes) {
		return new Promise(async (resolve, reject) => {
			const user = this.userPool.getCurrentUser();
			
			if (user) {
				user.getSession(async (err: any) => {
					if (err) {
						reject(`Ocurrió un error al intentar autenticar al usuario. ${err?.message || ''}`);
					} else {
						const isAdmin: boolean = await new Promise((resolve, reject) => {
							user.getUserAttributes((err: any, attributes: IAttributes[]) => {
								if (err) return reject(err);
								const isRol = attributes.some(attr => attr.Name === Rol.Name && attr.Value == Rol.Value);
								resolve(isRol);
							});
						});
						resolve(isAdmin);
					}
				});
			} else {
				reject(`Ocurrió un error al obtener el user.`);
			}
		});	
	}
}

export const cognitoService = new CognitoService(
	process.env['NX_COGNITO_USER_POOL_ID'] || '',
	process.env['NX_COGNITO_CLIENT_ID'] || ''
);
