import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import styled from 'styled-components';

const CouldbedsIconSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="69.0397mm"
			height="47.7174mm"
			version="1.1"
			viewBox="0 0 5659.48 3911.6"
		>
			<g id="Capa_x0020_1">
				<path
					fill="#3D67AF"
					d="M4815.26 1902.33c23.83,-71.36 35.63,-154.51 35.63,-237.8 0,-546.86 -439.88,-986.85 -986.73,-986.85 -118.92,0 -237.8,23.83 -344.8,59.46 -237.8,-439.88 -701.5,-737.14 -1236.58,-737.14 -760.95,0 -1379.19,618.23 -1379.19,1379.18 0,83.29 11.92,178.36 23.84,261.51 -380.54,59.46 -665.89,380.55 -665.89,772.87 0,285.35 142.74,523.16 368.62,665.9 190.28,-107 416.18,-190.26 665.9,-261.64 0,0 -285.48,-974.94 47.54,-1046.29 404.12,-71.37 427.95,35.75 1248.36,-47.55 879.75,-95.06 1640.71,47.55 1640.71,47.55 154.63,0 142.74,321.07 118.89,558.89 -23.68,237.82 -106.97,451.8 -106.97,451.8 309.17,83.28 582.59,190.24 808.48,320.95 190.28,-118.77 309.2,-320.95 309.2,-546.86 23.68,-344.79 -225.91,-618.36 -547,-653.99z"
				/>
				<path
					fill="#3D67AF"
					d="M2829.76 3341.03c1141.36,0 2163.84,154.51 2829.72,570.57 -428.08,-570.57 -1533.84,-974.82 -2829.72,-974.82 -1296.04,0 -2401.67,392.31 -2829.76,962.9 665.77,-404.13 1688.24,-558.65 2829.76,-558.65z"
				/>
			</g>
		</svg>
	);
};

export function CloudbedsIcon(props: Partial<CustomIconComponentProps>) {
	return <LogoIcon {...props} component={CouldbedsIconSvg} />;
}

const LogoIcon = styled(Icon)`
	svg {
		width: 28px;
		height: 28px;
	}
`;
