import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { CloudbedsIcon, FacebookIcon, GoogleIcon } from '../../atoms/icons';

export const SocialMediaButton = (props: ISocialMediaButtonProp) => {
	const { type, label, ...rest } = props;

	let icon = null;

	if (type === 'GOOGLE') {
		icon = <GoogleIcon style={iconStyles} />;
	} else if (type === 'FACEBOOK') {
		icon = <FacebookIcon style={iconStyles} />;
	} else if (type === 'CLOUDBEDS') {
		icon = <CloudbedsIcon style={iconStyles} />;
	}
	// const icon = icons[type] ? icons[type] : null;

	if (!icon) return null;

	return (
		<Btn type="text" size="large" block {...rest}>
			{icon}
			<Typography style={{ fontSize: 15 }}>{label}</Typography>
		</Btn>
	);
};

type typeButton = 'GOOGLE' | 'FACEBOOK' | 'CLOUDBEDS';

export interface ISocialMediaButtonProp {
	type: typeButton | string;
	label: string;
	onClick?: () => void;
	disabled?: boolean;
	style?: React.CSSProperties;
}

const iconStyles: React.CSSProperties = {
	position: 'absolute',
	top: 'calc(50% - 14px)',
	left: 10
};

const icons = {
	GOOGLE: <GoogleIcon style={iconStyles} />,
	FACEBOOK: <FacebookIcon style={iconStyles} />,
	CLOUDBEDS: <FacebookIcon style={iconStyles} />,
	MINIHOTEL: <FacebookIcon style={iconStyles} />
};

const Btn = styled(Button)`
	position: relative;
	-webkit-box-shadow: 0px 5px 9px -2px rgba(209, 209, 209, 1) !important;
	-moz-box-shadow: 0px 5px 9px -2px rgba(209, 209, 209, 1) !important;
	box-shadow: 0px 5px 9px -2px rgba(209, 209, 209, 1) !important;
	margin-bottom: 1rem;
	font-size: 0.8rem;
	text-align: center;

	&:hover {
		background-color: ${({ theme }) => theme.colors.gray.lighten['80%']};
	}
`;
