/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';
import { IUserWorkTeam } from './interfaces';

export class workTeamApi {
	static getUsers = (filters?: any): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(
				ApiURL(
					`/api/auth/teams${
						filters ? '?filter=' + filters.filter : ''
					}`
				),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static deleteCollaborator = (id: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(ApiURL(`/api/auth/teams/${id}`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchApps = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(
				ApiURL(
					'api/common/apps?filter={"status":{"$in":["live","dev"]}}'
				),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static saveWorkTeam = (user: IUserWorkTeam): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(user)
			};

			fetch(ApiURL('/api/auth/teams'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static updateAccess = (user: IUserWorkTeam): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(user)
			};

			fetch(ApiURL('/api/auth/teams'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
