import { ISubscriptionState } from './subscription.interfaces';

export const initialState = {
	subscriptionInfo: {
		error: null,
		loading: 'idle'
	},
	intent: {
		error: null,
		loading: 'idle'
	},
	payment: {
		error: null,
		loading: 'idle'
	},
	discountValidation: {
		error: null,
		loading: 'idle'
	},
	deleteActualPayment: {
		error: null,
		loading: 'idle'
	},
	renew: {
		error: null,
		loading: 'idle'
	},
	initLandingSubscribe: {
		error: null,
		loading: 'idle'
	},
	landingPayment: {
		loading: 'idle',
		error: null
	},
	validatePaymentTry: {
		loading: 'idle',
		error: null
	}
} as ISubscriptionState;
