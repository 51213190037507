import { PlaylistsInitialState } from './interface';

export const initialState: PlaylistsInitialState = {
	playlists: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	playlistVideo: {
		loading: 'idle',
		error: undefined,
		data: []
	}
};
