import { maxDevice } from '@hospy/feature/devices';
import styled from 'styled-components';

const Main = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: linear-gradient(#66d5ee, #0470a9);
	@media ${maxDevice.mobileL} {
		background: #fff;
	}
	position: relative;
`;

const Content = styled.div`
	width: 100%;
	max-width: 1124px;
	margin: 0 auto;
	z-index: 1;
`;

export const Container = ({
	children,
	externalContainer
}: {
	children: any;
	externalContainer?: any;
}) => (
	<Main>
		<Content>{children}</Content>
		{externalContainer}
	</Main>
);
