/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fetch } from '../fetch';
import {
	RequestCreate,
	RequestFindAll
} from '../requests';

import { ResponseFailure, ResponseSuccess } from '../responses';

export const RequestFindAllCustomers = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/crm/customers', params);

export const RequestCreateCustomer = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/crm/customers', params);