import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { getCurrencyRate } from './thunks';

export const partnerCurrencySlice = createSlice({
	name: 'partner-currency',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCurrencyRate.pending, (state) => {
				state.exchangeRate.loading = 'pending';
			})
			.addCase(getCurrencyRate.rejected, (state, payload) => {
				state.exchangeRate.loading = 'idle';
				state.exchangeRate.error = payload;
			})
			.addCase(getCurrencyRate.fulfilled, (state, { payload }) => {
				state.exchangeRate.data = payload;
				state.exchangeRate.loading = 'succeeded';
			});
	}
});
