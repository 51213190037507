import { InvoiceDetailSection } from '../siigo-connect/interface';
import { SatInitialState } from './sat.interface';

export const SAT_GENERAL_PUBLIC_RFC = ['XAXX010101000', 'XEXX010101000'];

export const SAT_INVOICE_DETAIL_SECTIONS: InvoiceDetailSection[] = [
	{
		key: 'customer',
		hasError: false,
		fields: {
			companyName: {
				hasError: false
			},
			rfc: {
				hasError: false
			},
			email: {
				hasError: false
			},
			fiscalRegime: {
				hasError: false
			},
			documentType: {
				hasError: false
			},
			cfdiUse: {
				hasError: false
			},
			zipCode: {
				hasError: false
			}
		}
	},
	{
		key: 'items',
		hasError: false,
		fields: {}
	}
];

export const SAT_CREATE_CUSTOMER_FIELD_MESSAGE = {
	companyName: {
		key: 'companyName',
		name: 'Nombre o razón social del contribuyente'
	},
	documentNumber: { key: 'documentNumber', name: 'RFC' },
	fiscalRegime: { key: 'fiscalRegime', name: 'Régimen fiscal' },
	cfdiUse: { key: 'cfdiUse', name: 'Uso de la factura' },
	postalCode: { key: 'postalCode', name: 'Código Postal' }
};

export const satInitialState: SatInitialState = {
	paymentMethodsInternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	paymentMethodsExternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	taxesInternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	taxesExternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	accommodationsInternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	accommodationsExternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	productsInternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	productsExternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	productsAll: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	fieldMapping: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	updatedFieldMapping: {
		loading: 'idle',
		error: undefined
	},
	stamp: {
		loading: 'idle',
		error: undefined,
		data: undefined
	},
	invoiceValidation: SAT_INVOICE_DETAIL_SECTIONS,
	updateStampInvoice: {
		loading: 'idle'
	},
	stampErrors: {
		loading: 'idle',
		data: undefined,
		error: undefined
	},
	publicReservation: {
		loading: 'idle',
		data: undefined,
		error: undefined
	},
	selfStamp: {
		loading: 'idle',
		error: undefined,
		data: undefined
	},
	cancelStampInvoice: {
		loading: 'idle'
	},
	invoiceReports: {
		loading: 'idle'
	},
	summaryReports: {
		loading: 'idle'
	},
	unitsInternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	unitsExternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	unitsAll: {
		loading: 'idle',
		error: undefined,
		data: []
	}
};

export const NON_BILLABLE_PRODUCT_CODE = 'NON_BILLABLE_PRODUCT';
