import { createSlice } from '@reduxjs/toolkit';
import {
	createTermsAndConditions,
	deleteTermsAndConditions,
	getTermsAndConditions,
	updateTermsAndConditions
} from './thunks';
import { termsAndConditionsInitialState } from './constants';

export const termsAndConditionsSlice = createSlice({
	name: 'termsAndConditions',
	initialState: termsAndConditionsInitialState,
	reducers: {
		cleanGetTermsAndConditions: (state) => {
			state.termsAndConditions =
				termsAndConditionsInitialState.termsAndConditions;
		},
		cleanPostTermsAndConditions: (state) => {
			state.create = termsAndConditionsInitialState.create;
			state.update = termsAndConditionsInitialState.update;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getTermsAndConditions.pending, (state) => {
				state.termsAndConditions = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getTermsAndConditions.rejected, (state, { payload }) => {
				state.termsAndConditions = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(getTermsAndConditions.fulfilled, (state, { payload }) => {
				state.termsAndConditions = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(createTermsAndConditions.pending, (state) => {
				state.create = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				createTermsAndConditions.rejected,
				(state, { payload }) => {
					state.create = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				createTermsAndConditions.fulfilled,
				(state, { payload }) => {
					state.create = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
					state.termsAndConditions = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(updateTermsAndConditions.pending, (state) => {
				state.update = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				updateTermsAndConditions.rejected,
				(state, { payload }) => {
					state.update = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				updateTermsAndConditions.fulfilled,
				(state, { payload }) => {
					state.update = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
					state.termsAndConditions = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);
		builder
			.addCase(deleteTermsAndConditions.pending, (state) => {
				state.deleteTerms = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				deleteTermsAndConditions.rejected,
				(state, { payload }) => {
					state.deleteTerms = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				deleteTermsAndConditions.fulfilled,
				(state, { payload }) => {
					state.deleteTerms = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
					state.termsAndConditions = {
						loading: 'succeeded',
						error: undefined,
						data: null
					};
				}
			);
	}
});

export const { cleanGetTermsAndConditions, cleanPostTermsAndConditions } =
	termsAndConditionsSlice.actions;
