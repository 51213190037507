/* eslint-disable @typescript-eslint/no-explicit-any */
// /* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import {
	Async,
	IInternalAccount,
	IReservationInvoice,
	cleanInvoice,
	resetInvoice,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useState } from 'react';
import ScreenHeader from './components/screen-header';
import { ListInvoicesReservation } from './components/list-invoice-reservation';
import { ListInvoicesAccounts } from './components/list-invoice-account';

export interface BillingScreen {
	label: string;
	value: string;
}

export const AVAILABLE_SCREENS: BillingScreen[] = [
	{
		label: 'Facturar reservas',
		value: 'reservations'
	},
	{
		label: 'Facturar cuentas internas',
		value: 'internalAccounts'
	}
];

interface ListInvoiceProps {
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	source: Async;
	getSource: (payload: any) => any;
	setSource: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	showCreateInvoiceButton?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedSource: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	screen: string;
	selectScreen: (screen: string) => void;
	multipleScreenMode?: boolean;
	multipleInvoices?: boolean;
}

export const ListInvoices = (props: ListInvoiceProps) => {
	const dispatch = useAppDispatch();
	const { appData } = useAppSelector(({ common }) => common);

	const [selectedDetail, setSelectedDetail] = useState<
		IReservationInvoice | IInternalAccount | null
	>(null);

	const closeInvoiceDetail = () => {
		setSelectedDetail(null);
		dispatch(resetInvoice());
		dispatch(cleanInvoice());
	};

	const selectSource = (
		source: IReservationInvoice | IInternalAccount | null,
		index: number
	) => {
		setSelectedDetail(source);
		const _source = [...props.source.data.data];
		const deleted = _source.splice(index, 1);
		_source.unshift(...deleted);
		dispatch(props.setSource(_source));
	};

	return (
		<InvoiceWrapper>
			<InvoiceListWrapper detailOpened={selectedDetail}>
				<ScreenHeader
					multipleScreenMode={!!props.multipleScreenMode}
					screen={props.screen}
					selectScreen={props.selectScreen}
					app={appData}
					selectedDetail={selectedDetail}
				/>

				{props.screen === 'reservations' && (
					<ListInvoicesReservation
						{...props}
						getReservations={props.getSource}
						reservations={props.source}
						setReservations={props.setSource}
						selectSource={(
							source: IReservationInvoice | IInternalAccount,
							index: number
						) => {
							selectSource(source, index);
						}}
						selectedSource={selectedDetail}
						multipleInvoices={props.multipleInvoices}
					/>
				)}
				{props.screen === 'internalAccounts' && (
					<ListInvoicesAccounts
						{...props}
						getInternalAccounts={props.getSource}
						internalAccounts={props.source}
						setInternalAccounts={props.setSource}
						selectSource={(
							source: IReservationInvoice | IInternalAccount,
							index: number
						) => {
							selectSource(source, index);
						}}
						selectedSource={selectedDetail}
						multipleInvoices={props.multipleInvoices}
					/>
				)}
			</InvoiceListWrapper>
			{props.detailChildren &&
				selectedDetail &&
				props.detailChildren(selectedDetail, closeInvoiceDetail)}
		</InvoiceWrapper>
	);
};

const InvoiceWrapper = styled.div`
	display: flex;
	height: 100%;
	gap: 20px;
	margin: 20px 20px 40px 20px;

	${({ theme }: any) =>
		theme.screens.sm &&
		css`
			margin: 20px 20px 40px 20px;
		`}

	${({ theme }: any) =>
		theme.screens.lg &&
		css`
			margin: 0px 30px 0px 30px;
		`}
			
	${({ theme }: any) =>
		theme.screens.xxl &&
		css`
			width: 1440px;
			margin: auto;
		`}
`;

const InvoiceListWrapper = styled(({ detailOpened, ...props }) => (
	<div {...props} />
))`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px 0;
	width: 100%;

	${({ detailOpened }) =>
		detailOpened &&
		css`
			width: 30%;
		`}

	@media only screen and (max-width: 1400px) {
		${({ detailOpened }) =>
			detailOpened &&
			css`
				display: none;
			`}
	}
`;
