/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon } from '@hospy/hospy-ui';
import { Form, Input, Spin, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import {
	APPS,
	Loading,
	PartnerIntegration
} from 'libs/store/src/lib/common/common.types';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HospyAlert from '../../../UI/organisms/hospy-alert';
import { Auth } from '../hooks/use-connect-partner';

let _appId: string;
if (process.env['NX_APP_COLOMBIA_SIRE_TRA']?.includes(window.location.origin)) {
	_appId = APPS.SIRE_TRA;
}

if (process.env['NX_APP_SIIGO_CONNECT']?.includes(window.location.origin)) {
	_appId = APPS.SIIGO_CONNECT;
}

interface AuthPartnerProps {
	onBack: () => void;
	auth: Auth;
	partnerIntegration?: PartnerIntegration | undefined;
	onSubmit: (data: any) => Promise<void>;
	loading: Loading;
	disabled?: boolean;
	error?: string | React.ReactElement;
	title?: string;
	backText?: string;
	icon?: React.ReactElement;
	hideTitle?: boolean;
	hideBack?: boolean;
	appId?: string;
	footerSection?: React.ReactNode;
}

const AuthPartner = (props: AuthPartnerProps) => {
	const {
		hideBack,
		onBack,
		auth,
		partnerIntegration,
		onSubmit,
		loading,
		error,
		title,
		backText,
		icon,
		hideTitle,
		appId,
		disabled,
		footerSection
	} = props;
	const [form] = Form.useForm();
	const [hideForm, setHideForm] = useState(false);

	const currentAppId = appId ? appId : _appId;

	useEffect(() => {
		if (partnerIntegration && currentAppId) {
			const credentials =
				partnerIntegration?.appsPartnerCredentials?.find(
					(e) => e.appId === currentAppId
				);
			setHideForm(false);
			if (credentials) {
				setHideForm(true);
				form.setFieldsValue({
					clientId: credentials?.clientId,
					clientSecret: credentials?.clientSecret,
					avoidCredentialValidation: '1'
				});
				form.submit();
			}
		}
	}, [partnerIntegration]);

	const preSubmit = (d: any) => {
		if (!hideForm) return onSubmit(d);
		if (partnerIntegration && currentAppId) {
			const credentials =
				partnerIntegration?.appsPartnerCredentials?.find(
					(e) => e.appId === currentAppId
				);
			if (credentials) {
				return onSubmit({
					clientId: credentials?.clientId,
					clientSecret: credentials?.clientSecret,
					avoidCredentialValidation: '1'
				});
			}
		}
		return;
	};

	return (
		<div>
			{icon && <LogosContainer>{icon}</LogosContainer>}
			{!hideTitle && (
				<TitleContainer>
					<Title>{title || partnerIntegration?.name}</Title>
				</TitleContainer>
			)}

			<Form form={form} onFinish={preSubmit} style={{ width: '100%' }}>
				<Form.Item
					name="avoidCredentialValidation"
					style={{ display: 'none' }}
				>
					<input type="hidden" value="0" />
				</Form.Item>
				{hideForm ? (
					<div>
						{!error && (
							<ProcessAuthContainer>
								<Spin />
								Procesando autenticación...
							</ProcessAuthContainer>
						)}
					</div>
				) : (
					<>
						{auth === 'OAUTH' && (
							<>
								<Form.Item
									name="clientId"
									rules={[
										{
											required: true,
											message: 'Campo requerido'
										}
									]}
								>
									<Input
										size="large"
										placeholder="Client ID"
										disabled={
											loading === 'pending' || disabled
										}
									/>
								</Form.Item>
								<Form.Item
									name="clientSecret"
									rules={[
										{
											required: true,
											message: 'Campo requerido'
										}
									]}
								>
									<Input.Password
										size="large"
										placeholder="Client Secret"
										disabled={
											loading === 'pending' || disabled
										}
									/>
								</Form.Item>
							</>
						)}
						{auth === 'API' && (
							<Form.Item
								name="apiToken"
								rules={[
									{
										required: true,
										message: 'Campo requerido'
									}
								]}
							>
								<Input
									placeholder="Api token"
									disabled={loading === 'pending' || disabled}
								/>
							</Form.Item>
						)}
						{partnerIntegration?.ipRequired && (
							<IpRequiredContainer>
								<IpRequiredTitle>
									<Icon material="warning" />
									<IpRequiredText>
										Esta conexión requiere validación de IP
									</IpRequiredText>
								</IpRequiredTitle>
								<IpRequiredContent>
									Ingresa a tu cuenta de{' '}
									{partnerIntegration?.name} y agrega la IP
									<span style={{ fontWeight: 'bold' }}>
										122.23.44.4
									</span>
									en la selección de configuraciones
								</IpRequiredContent>
							</IpRequiredContainer>
						)}
					</>
				)}
				<AnimatePresence>
					{error && (
						<motion.div
							initial={{ opacity: 0, y: -10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -10 }}
						>
							<HospyAlert type="danger">
								<div>{error}</div>
							</HospyAlert>
						</motion.div>
					)}
				</AnimatePresence>

				{footerSection}

				{(!hideForm || (hideForm && error)) && (
					<Form.Item style={{ marginTop: 50 }}>
						<Button
							type="primary"
							block
							htmlType="submit"
							loading={loading === 'pending'}
							disabled={disabled}
							icon={
								<Icon ant material="settings_input_component" />
							}
						>
							{error
								? 'Reintentar conexión con'
								: 'Continuar conexión con'}{' '}
							{capitalize(partnerIntegration?.name)}
						</Button>
					</Form.Item>
				)}
			</Form>
			{!hideBack && (
				<Button
					type="text"
					block
					disabled={loading === 'pending'}
					onClick={onBack}
				>
					{backText || 'Volver a la lista'}
				</Button>
			)}
		</div>
	);
};

export default AuthPartner;

const LogosContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
`;

const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
`;

const Title = styled(Typography.Text)`
	display: block;
	text-align: center;
	font-size: ${({ theme }) => theme.title.size.middle}px;
	font-weight: bold;
`;

const IpRequiredContainer = styled.div`
	padding: 15px 0;
	margin-bottom: 15px;
	text-align: center;
	background-color: ${({ theme }) => theme.colors?.danger?.lighten?.['90%']};
`;

const IpRequiredTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: ${({ theme }) => theme.colors.danger.base};
	font-size: 20px;
	padding-bottom: 5px;
`;

const IpRequiredText = styled(Typography.Text)`
	color: ${({ theme }) => theme.colors.danger.base};
	font-size: 15px;
`;

const IpRequiredContent = styled.div``;

const ProcessAuthContainer = styled.div`
	display: flex;
	gap: 15px;
	justify-content: center;
	align-items: center;
	padding: 30px 0;
`;
