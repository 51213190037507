/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin, Tooltip, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IconPartner {
	name: 'siigo';
	width: string;
	height: string;
}

interface props {
	icon?: React.ElementType | any;
	iconPartner?: IconPartner;
	text: string | null;
	onClick?: ((data: any) => void) | any;
	loadingText?: string;
	loading?: boolean;
	button?: React.ElementType | any;
	suffix?: React.ElementType | any;
	width?: number;
	tooltipText?: string | undefined;
}

export const Tag = ({
	icon,
	text,
	iconPartner,
	onClick,
	loadingText,
	loading,
	button,
	suffix,
	width,
	tooltipText
}: props) => {
	return (
		<Content
			onClick={onClick}
			style={{
				width: width || 'default',
				cursor: onClick ? 'pointer' : 'default'
			}}
		>
			<Space size={20}>
				{icon}
				{iconPartner && (
					<img
						alt="siigo"
						src={`https://cdn.hospy.co/third-party-app-icons/${iconPartner.name}.png`}
						width={iconPartner.width || '55'}
						height={iconPartner.height || '30'}
					/>
				)}
				<Tooltip title={tooltipText || ''}>
					<Typography.Text style={{ fontWeight: 'bold' }}>
						<Space size={15}>
							{loadingText || text}
							{loading && (
								<Spin
									indicator={
										<LoadingOutlined
											style={{ fontSize: 24 }}
											spin
										/>
									}
								/>
							)}
						</Space>
					</Typography.Text>
				</Tooltip>
			</Space>
			{button}
			{suffix && <Space size={15}>{suffix}</Space>}
		</Content>
	);
};

const Content = styled.div`
	border: 1px solid ${({ theme }: any) => theme.colors.gray.lighten['80%']};
	background-color: ${({ theme }: any) => theme.colors.gray.lighten['80%']};
	padding: 20px 20px 20px 20px;
	display: flex;
	height: 70px;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
`;
