/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppData } from '../common/common.types';

import { adminBillingApi } from './api';
import { IBillingRangeRequest } from './interfaces';

export const updateAppFees = createAsyncThunk(
	'admin-billing/updateAppFees',
	async ({
		app,
		billingRange
	}: {
		app: AppData;
		billingRange: IBillingRangeRequest;
	}) => {
		const response = await adminBillingApi.updateAppFees(app, billingRange);

		return response.statusCode === 200;
	}
);

export const updateAppTrial = createAsyncThunk(
	'amdin-billing/updateTrial',
	async ({ app, value }: any) => {
		const response = await adminBillingApi.updateTrial(app, value);

		return response.statusCode === 200;
	}
);
