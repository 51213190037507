import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { postRntReportsBulkThunk } from './thunks';

export const thirdPartyRntReportsSlice = createSlice({
	name: 'third-party-rnt-reports',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		cleanSuccess: (state, action) => {
			if (action.payload === 'postReportsBulk') {
				state.success.postReportsBulk = false;
				state.data.postReportsBulk = undefined;
				state.loading.postReportsBulk = 'idle';
			}
		},
		cleanError: (state: any, action) => {
			state.error[action.payload] = undefined;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				postRntReportsBulkThunk.fulfilled,
				(state, { payload }) => {
					state.data.postReportsBulk = payload;
					state.loading.postReportsBulk = 'succeeded';
					state.success = { ...state.success, postReportsBulk: true };
				}
			)
			.addCase(postRntReportsBulkThunk.pending, (state) => {
				state.loading.postReportsBulk = 'pending';
			})
			.addCase(postRntReportsBulkThunk.rejected, (state, { payload }) => {
				state.loading.postReportsBulk = 'failed';
				// state.error = { ...state.error, postReportsBulk: false }
			});
	}
});
