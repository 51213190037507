/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	deleteTermsAndConditionsService,
	getFormService,
	postCustomFormService,
	putCustomFormsService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getForm = createAsyncThunk(
	'common/get-form',
	async (
		params: { appId: string; propertyId: string; name: string },
		{ rejectWithValue }
	) => {
		try {
			const filter = `filter={"name": "${params.name}", "appId":new ObjectId("${params.appId}"), "propertyId":new ObjectId("${params.propertyId}")}`;
			const response = await getFormService(filter);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const createCustomForm = createAsyncThunk(
	'common/create-form',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await postCustomFormService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

interface updateCustomFormProp {
	_id: string;
	data: any;
	updateMode?: 'pull' | 'push';
}
export const updateCustomForm = createAsyncThunk(
	'common/update-form',
	async (params: updateCustomFormProp, { rejectWithValue }) => {
		try {
			const response = await putCustomFormsService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const deleteForm = createAsyncThunk(
	'common/delete-terms-and-conditions',
	async (
		params: {
			_id: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await deleteTermsAndConditionsService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode > 299 || response.statusCode < 200)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
