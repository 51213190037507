/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	createQuotationService,
	getAppDataInfoService,
	getCountryService,
	getCurrencyRates,
	patchCity
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Realm from 'realm-web';

export const fetchGetAppData = createAsyncThunk(
	'common/fetchGetAppData',
	async (id: string) => {
		const { data }: { data: any[] } = await getAppDataInfoService(id);
		return data.length > 0 ? data[0] : undefined;
	}
);

export const deleteSiigoCity = createAsyncThunk(
	'common/siigo',
	async ({
		city,
		data: d,
		arrayFilters
	}: {
		city: string;
		data: any;
		arrayFilters?: any[];
	}) => {
		await patchCity(
			city,
			d,
			arrayFilters && `filter=${JSON.stringify({ arrayFilters })}`
		);
	}
);

export const initRealTime = createAsyncThunk(
	'common/init-real-time',
	async () => {
		const accessToken: any = localStorage.getItem('Hospy-idToken');

		const app = new Realm.App({
			id: process.env['NX_MONGO_APP_ID_REALM'] || ''
		});

		if (!app) {
			console.error('Real time app not instanced');
			return null;
		}

		const user = await app.logIn(Realm.Credentials.jwt(accessToken));
		const client = app.currentUser?.mongoClient('mongodb-atlas');

		return {
			app,
			user,
			client
		};
	}
);

export const getCurrencyRate = createAsyncThunk(
	'common/getCurrencyRate',
	async (
		{
			currencyCode,
			currencyCodeFrom
		}: { currencyCode: string; currencyCodeFrom: string },
		{ rejectWithValue }
	) => {
		const filter = `{"currencyIso3From":"${currencyCode.toUpperCase()}"}`;
		const { data, statusCode } = await getCurrencyRates({ filter });

		if (statusCode !== 200)
			rejectWithValue('Error al obtener el tipo de cambio');

		const foundRate = data.find(
			(currency: any) =>
				currency.currencyIso3To === currencyCodeFrom?.toUpperCase()
		);

		return foundRate;
	}
);

export const getAllCurrencyRates = createAsyncThunk(
	'common/getAllCurrencyRates',
	async (_, { rejectWithValue }) => {
		const { data, statusCode } = await getCurrencyRates({ filter: '' });

		if (statusCode !== 200)
			rejectWithValue('Error al obtener el tipo de cambio');

		return data;
	}
);

export const getCountries = createAsyncThunk(
	'common/get-countries',
	async (filter: string, { rejectWithValue }) => {
		const { data, errors } = await getCountryService(
			filter ||
				`fields=["_id","name","iso2","phoneCode","translations"]&limit=250`
		);
		if (errors) return rejectWithValue(errors);
		return data;
	}
);

export const createQuotation = createAsyncThunk(
	'common/create-quotation',
	async (d: any, { rejectWithValue }) => {
		const { data, errors } = await createQuotationService(d);
		if (errors) return rejectWithValue(errors);
		return data;
	}
);
