/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from 'antd';

const TooltipDisabled = (props: any) => {
	return (
		<Tooltip {...props}>
			{props.disabled && (
				<div
					style={{
						position: 'relative',
						display: 'inline-block',
						cursor: 'not-allowed'
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							zIndex: 1
						}}
					/>
					{props.children}
				</div>
			)}
			{!props.disabled && props.children}
		</Tooltip>
	);
};

export default TooltipDisabled;
