/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { fetchUserInformation, logIn, useAppDispatch } from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userApi } from '../../../../../../store/src/lib/user/userAPI';

interface IUser {
	username: string;
	password: string;
}

export interface IUseLogin {
	onSubmit: (data: IUser) => Promise<void>;
	onSubmitMFA: (verificationCode: string) => Promise<void>;
	setTypeForm: (form: string) => any;
	typeForm: string;
	phoneEncrypt: string;
	loading: boolean;
	login: (stateDataDecrypt?: any) => Promise<void>;
	loadSessionInfo: () => Promise<boolean>;
	appInfoSection?: any;
	privacyPoliciesUrl?: string;
	termsAndConditionsUrl?: string;
	appId?: string;
}

interface Props {
	disableUseNavigate?: boolean;
	location?: any;
}

const defaultProps: Props = {
	disableUseNavigate: undefined,
	location: undefined
};

const useLogin = (props: Props | undefined = defaultProps): IUseLogin => {
	const dispatch = useAppDispatch();
	const navigate = !props.disableUseNavigate ? useNavigate() : null;
	const location = !props.disableUseNavigate ? useLocation() : null;

	const [loading, setLoading] = useState(false);
	const [typeForm, setTypeForm] = useState('LOGIN');
	const [username, setUsername] = useState('');
	const [phoneEncrypt, setPhoneEncrypt] = useState('');
	const [session, setSession] = useState('');

	useEffect(() => {
		if (location) {
			const { state } = location;
			if (state?.mfa) {
				setUsername(state.username);
				setSession(state.session);
				setPhoneEncrypt(state.destiny);
				setTypeForm('MFA_AUTH');
			}
		}
	}, []);

	const onSubmit = async ({ username: _username, password }: IUser) => {
		setLoading(true);
		try {
			const loginResponse = await cognitoService.login({
				username: _username,
				password
			});

			const isSiteAdmin = process.env['NX_APP_ADMIN']?.includes(
				window.location.origin
			);
			const isSiteDeveloper = process.env['NX_APP_DEVELOPER']?.includes(
				window.location.origin
			);

			if (isSiteAdmin || isSiteDeveloper) {
				const prefix = isSiteAdmin ? 'admin' : 'developer';
				const isValidRol = await cognitoService.isRolUser({
					Name: `custom:${prefix}-app-acl`,
					Value: prefix
				});

				if (!isValidRol) {
					message.error(`No tiene accesos de ${prefix}`);
					setLoading(false);
					await cognitoService.signOut();
					return;
				}
			}

			if (loginResponse.mfa) {
				setUsername(_username);
				setSession(loginResponse.session);
				setPhoneEncrypt(loginResponse.destiny);
				setTypeForm('MFA_AUTH');
			} else {
				await login();
				localStorage.setItem('type_login', 'BASIC');
			}
		} catch (error: any) {
			if (error.code === 'NotAuthorizedException') {
				const isUserPreSaved = await userApi.validatePreSavedUser({
					email: _username,
					temporalPassword: password
				});
				if (isUserPreSaved) {
					const { userConfirmed, userSub } =
						await cognitoService.register({
							name: isUserPreSaved.name,
							password: isUserPreSaved.temporalPassword,
							username: isUserPreSaved.email,
							email: isUserPreSaved.email
						});

					await userApi.fetchUpdatePreSavedUser({
						email: _username,
						userConfirmed,
						userSub
					});
					onSubmit({ username: _username, password });
					return;
				}
			}

			if (error?.code === 'UserNotConfirmedException') {
				if (navigate) {
					navigate(`/verification-code/${_username}`, {
						state: { password }
					});
				}
				return;
			}
			message.error('Usuario y contraseña incorrecta');
		}
		setLoading(false);
	};

	const onSubmitMFA = async (verificationCode: string) => {
		setLoading(true);
		try {
			await cognitoService.loginMFA({
				username,
				verificationCode,
				session
			});
			await login();
		} catch (error: any) {
			if (error?.code === 'CodeMismatchException') {
				message.error('El código ingresado no es correcto');
			}
		}
		setLoading(false);
	};

	const login = async (stateDataDecrypt?: any) => {
		await loadSessionInfo();
		if (navigate) {
			const { pathname, search, ...rest } = stateDataDecrypt
				? stateDataDecrypt
				: props?.location?.state || {};

			if (pathname) {
				navigate(pathname + search, { state: { ...rest } });
				return;
			}

			if (location?.state?.from) navigate(location?.state?.from);
			else navigate('/');
		}
	};

	const loadSessionInfo = async (): Promise<boolean> => {
		const currentUser = await cognitoService.getCurrentUser();
		if (!currentUser) return false;
		dispatch(logIn());
		const session = await cognitoService.getSession();
		const idToken = session.getIdToken().getJwtToken();
		const expiration = session.getIdToken().getExpiration();
		const expirationDate = moment(0)
			.add(expiration, 'seconds')
			.format('YYYY-MM-DD HH:mm:ss');
		localStorage.setItem('Hospy-idToken', idToken);
		localStorage.setItem('Hospy-expiration', expirationDate);

		await dispatch(
			fetchUserInformation({ user: session?.idToken?.payload })
		);

		return true;
	};

	return {
		typeForm,
		setTypeForm,
		loading,
		phoneEncrypt,
		onSubmit,
		onSubmitMFA,
		login,
		loadSessionInfo
	};
};

export default useLogin;
