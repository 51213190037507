/* eslint-disable @typescript-eslint/no-explicit-any */
import AppsIcon from '@2fd/ant-design-icons/lib/Apps';
import OpenInNew from '@2fd/ant-design-icons/lib/OpenInNew';
import { Apps, useAppSelector } from '@hospy/store';
import { Button, Col, Dropdown, Menu, Row, Typography } from 'antd';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import useUiApps from '../../../hooks/use-ui-apps';
import { CardApp } from '../../molecules/card-app';
import Modal from '../../template/modal';

const ButtonApp = styled(Button)`
	color: #ffffff;
	padding: 0 !important;
	& svg {
		font-size: 30px;
		transform: scale(1.23);
	}
	&:hover,
	&:active,
	&:focus {
		color: #ffffff;
	}
`;

const Title = styled(Typography.Text)`
	font-weight: bold;
	margin-bottom: 10px;
`;

const ButtonLink = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.colors.primary.base} !important;
	margin-top: 10px;
`;

const AppSelectApp = ({
	title,
	style
}: {
	title?: string;
	style?: React.CSSProperties;
}) => {
	const { user } = useAppSelector(({ user }) => user);
	const { currentProperty } = useAppSelector(({ user }) => user);
	const { apps: hospyApps } = useAppSelector(({ start }) => start);
	const [apps, setApps] = useState<Array<any>>([]);
	const { getIcon } = useUiApps();
	const theme: any = useTheme();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const getUrl = (_id: string): string =>
			hospyApps
				? (hospyApps.find((e: any) => e._id === _id) || { url: '' })
						?.url || ''
				: '';
		const getIconData = (_id: string): any =>
			hospyApps
				? (
						hospyApps.find((e: any) => e._id === _id) || {
							icon: { type: '', name: '' }
						}
				  )?.icon || null
				: null;
		const getAppType = (_id: string): 'front' | 'back' =>
			hospyApps
				? (
						hospyApps.find((e: any) => e._id === _id) || {
							type: 'front'
						}
				  )?.type || 'front'
				: 'front';

		let _apps: Apps[] = [];
		if (user?.properties && user?.properties?.length > 0) {
			const __apps = user?.properties.find(
				(e: any) => e._id === currentProperty
			)?.apps;
			if (__apps && __apps.length > 0) {
				_apps = __apps;
			}
		}

		if (title) _apps = sortBy(_apps, [(e) => e.name.es !== title]);

		setApps(
			_apps.map((e: any, index: number) => ({
				key: index,
				label: (
					<a href={`${getUrl(e._id)}?property=${currentProperty}`}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								...(theme.screens.xs
									? {}
									: { marginTop: 10, marginBottom: 10 })
							}}
						>
							<CardApp
								key={e._id}
								icon={getIcon({
									...getIconData(e._id),
									active: true
								})}
								status={true}
								type={getAppType(e._id)}
								styleProps={{
									container: {
										margin: 0,
										border: 0,
										...(theme.screens.xs
											? { marginRight: 15 }
											: { marginRight: 8 })
									},
									wrapper: {
										...(theme.screens.xs
											? { width: 40, height: 40 }
											: { width: 30, height: 30 }),
										borderRadius: 4
									},
									button: {
										borderRadius: 4,
										opacity: e.name.es === title ? 0.5 : 1
									}
								}}
							/>
							<div
								style={{
									...(theme.screens.xs
										? { fontSize: 18 }
										: {}),
									opacity: e.name.es === title ? 0.5 : 1
								}}
							>
								{e.name.es}
							</div>
						</div>
					</a>
				),
				disabled: e.name.es === title
			}))
		);
	}, [user?.properties, currentProperty, hospyApps?.length]);

	const menuChildren = [
		...apps,
		{
			key: 'goback',
			label: (
				<ButtonLink
					type="link"
					href={`${process.env['NX_APP_START']}?property=${currentProperty}`}
				>
					Ir al inicio de Hospy <OpenInNew style={{ fontSize: 15 }} />
				</ButtonLink>
			)
		}
	];

	const menu = (
		<Menu
			items={[
				{
					key: 'menu',
					type: 'group',
					label: <Title>Seleccionar aplicación:</Title>,
					children: menuChildren
				}
			]}
		/>
	);

	if (theme.screens.xs) {
		return (
			<>
				<ButtonApp
					type="text"
					size="middle"
					icon={<AppsIcon />}
					onClick={() => setOpen(true)}
					style={{ ...style }}
				/>
				<Modal open={open} onClose={() => setOpen(false)}>
					<Row>
						<Col
							span={24}
							style={{
								marginBottom: 25,
								textAlign: 'center',
								fontSize: 20
							}}
						>
							<Title>
								{title
									? 'Cambiar de aplicación'
									: 'Seleccionar de aplicación'}
							</Title>
						</Col>
						{menuChildren.map(({ key, label }: any) => (
							<Col span={24} key={key}>
								{label}
							</Col>
						))}
					</Row>
				</Modal>
			</>
		);
	}

	return (
		<Dropdown overlay={menu} trigger={['click']} placement="topRight">
			<ButtonApp
				type="text"
				size="large"
				icon={<AppsIcon />}
				style={{ ...style }}
			/>
		</Dropdown>
	);
};

export default AppSelectApp;
