/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import styled from 'styled-components';
import usePropertyForm, { usePropertyFormProps } from './use-property-form';

const LoadingIcon = styled(LoadingOutlined)`
	font-size: 20px;
	& svg {
		color: ${({ theme }) => theme.colors.primary.base || 'tomato'};
	}
`;

type Props = usePropertyFormProps;

const PropertyForm = forwardRef((props: Props, ref) => {
	const intl = useIntl();

	const {
		form,
		properties,
		countries,
		states,
		cities,
		currencies,
		timeZones,
		onSubmit,
		createProperty,
		loadingCountries,
		loadingStates,
		loadingCities,
		loadingProperty,
		updateProperty,
		propertyIdToUpdate,
		countryId,
		type
	} = usePropertyForm({ ...props, ref });

	const validatePhoneNumber = (_: any, value: string) => {
		if (!value) return Promise.resolve();
		const phoneNumberRegex = /^\+?[()0-9\-.\s]{8,}$/;
		if (!value || phoneNumberRegex.test(value)) return Promise.resolve();
		return Promise.reject(
			'Por favor, introduce un número de teléfono válido'
		);
	};

	return (
		<Form form={form} onFinish={onSubmit} autoComplete="off">
			<Row gutter={24} justify={'center'}>
				<Col xs={24} sm={12}>
					<Form.Item
						name="name"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Input
							size="large"
							placeholder={intl.formatMessage({
								id: 'property-form.propertyName',
								defaultMessage: 'Nombre del negocio'
							})}
							disabled={
								createProperty.loading === 'pending' ||
								updateProperty.loading === 'pending' ||
								loadingProperty
							}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="type"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Select
							placeholder={intl.formatMessage({
								id: 'property-form.propertyType',
								defaultMessage: 'Tipo del negocio'
							})}
							size="large"
							disabled={
								createProperty.loading === 'pending' ||
								updateProperty.loading === 'pending' ||
								loadingProperty
							}
						>
							{properties.map((e: any, index: number) => (
								<Select.Option key={index} value={e.value}>
									{e.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="country_id"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Select
							showSearch
							placeholder={intl.formatMessage({
								id: 'property-form.country',
								defaultMessage: 'País'
							})}
							size="large"
							disabled={
								propertyIdToUpdate &&
								updateProperty.loading !== 'pending'
									? false
									: createProperty.loading === 'pending' ||
									  updateProperty.loading === 'pending'
							}
							loading={
								propertyIdToUpdate ? false : loadingCountries
							}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase()) ||
								(option?.name ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare(
										(optionB?.label ?? '').toLowerCase()
									)
							}
							options={countries}
							suffixIcon={
								loadingCountries && !propertyIdToUpdate ? (
									<LoadingIcon style={{ color: 'red' }} />
								) : (
									<DownOutlined />
								)
							}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="state_id"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Select
							showSearch
							placeholder={intl.formatMessage({
								id: 'property-form.state',
								defaultMessage: 'Estado'
							})}
							size="large"
							disabled={
								propertyIdToUpdate &&
								updateProperty.loading !== 'pending'
									? false
									: createProperty.loading === 'pending' ||
									  updateProperty.loading === 'pending' ||
									  (states && states.length === 0)
							}
							loading={propertyIdToUpdate ? false : loadingStates}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase()) ||
								(option?.name ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare(
										(optionB?.label ?? '').toLowerCase()
									)
							}
							options={states}
							suffixIcon={
								loadingStates && !propertyIdToUpdate ? (
									<LoadingIcon />
								) : (
									<DownOutlined />
								)
							}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="city_id"
						rules={[
							{
								required: cities.length > 0,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Select
							showSearch
							placeholder={intl.formatMessage({
								id: 'property-form.city',
								defaultMessage: 'Ciudad'
							})}
							size="large"
							disabled={
								propertyIdToUpdate &&
								updateProperty.loading !== 'pending'
									? false
									: createProperty.loading === 'pending' ||
									  updateProperty.loading === 'pending' ||
									  (cities && cities.length === 0)
							}
							loading={propertyIdToUpdate ? false : loadingCities}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare(
										(optionB?.label ?? '').toLowerCase()
									)
							}
							options={cities}
							suffixIcon={
								loadingCities && !propertyIdToUpdate ? (
									<LoadingIcon />
								) : (
									<DownOutlined />
								)
							}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="timezone"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Select
							showSearch
							placeholder={intl.formatMessage({
								id: 'property-form.timeZone',
								defaultMessage: 'Zona horaria'
							})}
							size="large"
							disabled={
								createProperty.loading === 'pending' ||
								updateProperty.loading === 'pending' ||
								loadingProperty ||
								(timeZones && timeZones.length === 0)
							}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare(
										(optionB?.label ?? '').toLowerCase()
									)
							}
							options={timeZones}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="currency"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.requiredField',
									description: 'Form required field',
									defaultMessage: 'Campo requerido'
								})
							}
						]}
					>
						<Select
							showSearch
							placeholder={intl.formatMessage({
								id: 'property-form.currency',
								defaultMessage: 'Moneda'
							})}
							size="large"
							disabled={
								createProperty.loading === 'pending' ||
								updateProperty.loading === 'pending' ||
								loadingProperty
							}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare(
										(optionB?.label ?? '').toLowerCase()
									)
							}
							options={currencies}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12}>
					<Form.Item
						name="phone"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'airbnbForm.requiredField',
									defaultMessage: 'Campo requerido'
								})
							},
							{
								validator: validatePhoneNumber
							}
						]}
					>
						<PhoneInput
							country={
								countryId
									? String(countryId).toLowerCase()
									: 'us'
							}
							enableSearch={true}
							inputStyle={{
								borderRadius: 0,
								width: '100%',
								height: 50,
								fontSize: 16,
								fontFamily: 'AmikoRegular'
							}}
							inputProps={{
								name: 'phone',
								required: true
							}}
							searchStyle={{
								borderRadius: 0,
								height: 50,
								width: 'calc(100% - 15px)'
							}}
							dropdownStyle={{
								padding: 0,
								marginTop: 2,
								minWidth: 320
							}}
							searchPlaceholder="Buscar"
							disabled={
								createProperty.loading === 'pending' ||
								updateProperty.loading === 'pending'
							}
							containerClass={
								createProperty.loading === 'pending' ||
								updateProperty.loading === 'pending'
									? 'disabled'
									: ''
							}
						/>
					</Form.Item>
				</Col>
				{countryId === 'CO' && type === 'short rental' && (
					<Col xs={24} sm={12}>
						<SwitchContainer>
							<span>¿Eres usuario Cloudbeds?</span>
							<Form.Item
								name="isCloudbedsUser"
								valuePropName="checked"
								style={{ margin: 0 }}
							>
								<Switch
									checkedChildren={intl.formatMessage({
										id: 'form.yes',
										defaultMessage: 'Si'
									})}
									unCheckedChildren={intl.formatMessage({
										id: 'form.no',
										defaultMessage: 'No'
									})}
								/>
							</Form.Item>
						</SwitchContainer>
					</Col>
				)}
				<Col span={24} style={{ marginTop: 30 }}>
					<Button
						htmlType="submit"
						type="primary"
						block
						size="large"
						loading={
							createProperty.loading === 'pending' ||
							updateProperty.loading === 'pending'
						}
					>
						{propertyIdToUpdate ? (
							<FormattedMessage
								id="property-modal.updatePropertyBtn"
								defaultMessage="Actualizar negocio"
							/>
						) : (
							<FormattedMessage
								id="property-modal.createPropertyBtn"
								defaultMessage="Crear negocio"
							/>
						)}
					</Button>
				</Col>
			</Row>
		</Form>
	);
});

export default PropertyForm;

const SwitchContainer = styled.div`
	display: block;
	/* border: 1px solid rgb(217, 217, 217); */
	padding: 0 11px;
	display: flex;
	gap: 10px;
	align-items: center;
	height: 50px;
	justify-content: center;
`;
