/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchDeleteInvoice,
	fetchGetInvoice,
	getPaymentMethods as fetchGetPaymentMethods,
	fetchJoinItems,
	fetchStampInvoice,
	fetchUpdateInvoice,
	getTaxes,
	updateStampInvoiceService
} from '@hospy/util-api';
import { RequestCreate, RequestFindOne } from '@hospy/util-api/requests';
import { ResponseSuccess } from '@hospy/util-api/responses';
import { RequestCreateStampBulk, RequestFindOneInternalAccountInvoice } from '@hospy/util-api/service/partner';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayloadBase } from '../../common/common.interfaces';
import { AccountSliceName } from './constants';
import { Invoice, InvoiceStamp, PayloadBaseInvoice } from './interface';

export const getAccountingPaymentMethods = createAsyncThunk(
	'partner-accounting/getPaymentMethods',
	async ({ type, ...payload }: PayloadBase, { rejectWithValue }) => {
		try {
			const { data, errors }: any = await fetchGetPaymentMethods({
				...payload
			});

			if (errors) {
				rejectWithValue('Error: ' + String(errors));
			}

			return data;
		} catch (error: any) {
			console.error({ error });
			rejectWithValue('Error: ' + String(error));
		}
	}
);

export const getAccountingInvoice = createAsyncThunk(
	'partner-accounting/getInvoice',
	async (payload: PayloadBaseInvoice<any>, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const { statusCode, data } = await fetchGetInvoice(
				{
					partnerIntegrationId: payload.partnerIntegrationId,
					partnerIntegrationIdTo: payload.partnerIntegrationIdTo,
					appId: payload.appId,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				payload.reservationId
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo obtener los productos');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const deleteAccountingInvoice = createAsyncThunk(
	'partner-accounting/deleteInvoice',
	async (payload: PayloadBaseInvoice<any>, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const { statusCode, data } = await fetchDeleteInvoice(
				{
					partnerIntegrationId: payload.partnerIntegrationId,
					partnerIntegrationIdTo: payload.partnerIntegrationIdTo,
					appId: payload.appId,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				payload.thirdPartyId
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo guardar la informaciónn');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const updateAccountingInvoice = createAsyncThunk(
	'partner-accounting/updateInvoice',
	async (
		payload: PayloadBaseInvoice<Invoice>,
		{ rejectWithValue, getState }
	) => {
		try {
			const state: any = getState();

			const { statusCode, data } = await fetchUpdateInvoice(
				{
					partnerIntegrationId: payload.partnerIntegrationId,
					partnerIntegrationIdTo: payload.partnerIntegrationIdTo,
					appId: payload.appId,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				payload.thirdPartyId,
				payload as any
			);
			if (statusCode !== 200)
				return rejectWithValue('No se pudo guardar la informaciónn');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const partnerUpdateLocalInvoice = createAsyncThunk(
	'partner/update-local-invoice',
	async (payload: PayloadBaseInvoice<Invoice>) => {
		return payload;
	}
);

export const getAccountingOriginTaxes = createAsyncThunk(
	'partner-accounting/getOriginTaxes',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const taxes = await getTaxes(payload, '{"type": "tax"}');
			if (taxes.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los impuestos');

			return taxes.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const getAccountingDestinyTaxes = createAsyncThunk(
	'partner-accounting/getDestinyTaxes',
	async (payload: PayloadBase, { rejectWithValue }) => {
		try {
			const taxes = await getTaxes(payload);
			if (taxes.statusCode !== 200)
				return rejectWithValue('No se pudo obtener los impuestos');

			return taxes.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const joinItems = createAsyncThunk(
	'partner-accounting/joinItems',
	async (payload: PayloadBaseInvoice<any>, { rejectWithValue, getState }) => {
		try {
			const state: any = getState();

			const { statusCode, data } = await fetchJoinItems(
				{
					partnerIntegrationId: payload.partnerIntegrationId,
					partnerIntegrationIdTo: payload.partnerIntegrationIdTo,
					appId: payload.appId,
					propertyId: state.user.propertyId,
					filter: '',
					page: '1',
					limit: '1000'
				},
				payload.thirdPartyId,
				payload.name
			);
			if (statusCode !== 201)
				return rejectWithValue('No se pudo guardar la informaciónn');

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const partnerAccountingInvoiceStamp = createAsyncThunk(
	'partner-accounting/invoice-stamp',
	async (payload: InvoiceStamp, { rejectWithValue }) => {
		const response: any = await fetchStampInvoice(
			{ ...payload },
			payload.invoiceId
		);

		if (response.statusCode < 200 || response.statusCode > 299)
			return rejectWithValue(
				response.errors.map((error: any) => error.message)
			);

		return response.data;
	}
);

export const partnerAccountingUpdateInvoiceStamp = createAsyncThunk(
	'partner-accounting/update-invoice-stamp',
	async (payload: InvoiceStamp & { _id: string }, { rejectWithValue }) => {
		const response: any = await updateStampInvoiceService(
			{ ...payload },
			payload._id
		);

		if (response.statusCode < 200 || response.statusCode > 299)
			return rejectWithValue(
				response.errors.map((error: any) => error.message)
			);

		return response.data;
	}
);

export const ThunkFindOneInternalAccountInvoice = createAsyncThunk(
	`${AccountSliceName}/${RequestFindOneInternalAccountInvoice.name}`,
	async (params: RequestFindOne, { rejectWithValue }) => {
		const response = await RequestFindOneInternalAccountInvoice(params);
		if (response.status === 'success')
			return (response as ResponseSuccess<Invoice>).data;
		return rejectWithValue(response);
	}
);

export const ThunkCreateStampBulk = createAsyncThunk(
	`${AccountSliceName}/${RequestCreateStampBulk.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateStampBulk(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
