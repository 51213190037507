/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BlockLoading } from '../../UI/molecules/block-loading';
import Modal from '../../UI/template/modal';
import PropertyForm from './components/property-form/property-form';
import usePropertyModal, { usePropertyModalProps } from './use-property-modal';

export const PropertyModal: React.FC<usePropertyModalProps> = (props) => {
	const { ref, handleOnClose, blocking, propertyIdToUpdate, setBlocking } =
		usePropertyModal({
			...props
		});

	const title = propertyIdToUpdate ? (
		<FormattedMessage
			id={'property-modal.editTitle'}
			defaultMessage={'Editar negocio'}
		/>
	) : (
		<FormattedMessage
			id={'property-modal.newTitle'}
			defaultMessage={'Nuevo negocio'}
		/>
	);

	return (
		<Modal
			open={props.open}
			onClose={handleOnClose}
			width={700}
			destroyOnClose
		>
			<BlockLoading blocking={blocking}>
				<Title>{title}</Title>
				<PropertyForm
					onBlocking={(blocking) => setBlocking(blocking)}
					isCreate={propertyIdToUpdate ? false : true}
					ref={ref}
					onComplete={handleOnClose}
				/>
			</BlockLoading>
		</Modal>
	);
};

export default PropertyModal;

const Title = styled(Typography.Text)`
	margin-top: 15px;
	margin-bottom: 30px;
	font-size: 24px;
	display: block;
	text-align: center;
`;
