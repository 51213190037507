import { ApiURL } from '@hospy/util-api';
import { cognitoService } from '@hospy/util-auth';
import { ICheckInIntegration, ICheckInRequiredFields } from './interfaces';

export class Api {
	static createCheckInIntegration = (
		data: ICheckInIntegration
	): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const requestOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL('/api/checkin-express/integrations'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static findCheckInIntegration = (propertyId: string): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const params = new URLSearchParams({ propertyId }).toString();
			const url = ApiURL('/api/checkin-express/integrations?' + params);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				}
			};
			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static createCheckInRequiredFields = (
		data: ICheckInRequiredFields
	): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const requestOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(
				ApiURL('/api/checkin-express/required-fields'),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static findCheckInRequiredFields = (propertyId: string): Promise<any> =>
		new Promise(async (resolve, reject) => {
			//TODO Obtener access token del localStorage
			const session = await cognitoService.getSession();
			const idToken = session.getIdToken().getJwtToken();

			const params = new URLSearchParams({ propertyId }).toString();
			const url = ApiURL(
				'/api/checkin-express/required-fields?' + params
			);

			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`
				}
			};
			fetch(url, requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
