/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';
import { IPaymentCredit } from './credits.interfaces';

export class hospyApi {
	static fetchGetCurrentCredits = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions: any = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(ApiURL(`api/common/credits/current`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchClientSecret = (
		amount: number
		//properties: any
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					amount
					//properties
				})
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(ApiURL(`api/third-party/stripe/credits`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static postSavePayment = (
		payment: IPaymentCredit,
		invoiceId: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(payment)
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/third-party/stripe/payment/${invoiceId}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchGetCreditsInvoices = (payload: any): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions: any = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/common/credits/invoices?status=${payload.status}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchGetCurrentCreditsByProperty = (propertyId: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions: any = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(ApiURL(`api/common/credits/current/${propertyId}`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
