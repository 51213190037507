/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownOutlined } from '@ant-design/icons'; //18.5k
import { Button, Icon } from '@hospy/hospy-ui';
import { DatePicker, Form, Input, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { pmsFilterTypes, otaFilterTypes } from '../hooks/use-invoice-filters';
import ProcessedFailedFilter from './processed-failed-filter';
import { useContext } from 'react';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';
import { useNavigate } from 'react-router-dom';
import { PARTNER, useAppSelector } from '@hospy/store';
import AdditionalFilters from './additional-filters';
import UploadReservationTotal from './upload-reservation-total';
import FilterByRoom from './filter-by-room';

export interface Props {
	showCreateInvoiceButton?: boolean;
}

const InvoiceFilterForm = ({ showCreateInvoiceButton }: Props) => {
	const {
		actionCloseDetail,
		updateFilterField,
		loading,
		selectedSource,
		updateSelectedDates,
		refresh,
		searchValue,
		searchByValue,
		filterField,
		dates,
		partnerIntegrationId
	} = useContext(InvoiceFilterContext) as InvoiceFilterContextType;

	const navigate = useNavigate();

	const { credits } = useAppSelector(({ credits }) => credits);

	const hasStamps = (credits?.data?.stamps || 0) > 0;

	const showRoomFilterAndCsv = partnerIntegrationId === PARTNER.AIRBNB;

	return (
		<InvoiceForm>
			<SelectFilterType
				defaultValue="endDate"
				value={filterField}
				onChange={(value: any) => {
					actionCloseDetail(() => {
						updateFilterField(value);
					});
				}}
				placeholder={'Seleccionar filtro'}
				size="large"
				disabled={loading}
				options={
					partnerIntegrationId === PARTNER.AIRBNB
						? otaFilterTypes
						: pmsFilterTypes
				}
				suffixIcon={<DownOutlined />}
			/>
			<Form>
				<DateSection detailOpened={!!selectedSource}>
					<DatePicker.RangePicker
						defaultValue={[
							moment().subtract(1, 'd'),
							moment(new Date())
						]}
						value={[moment(dates[0]), moment(dates[1])]}
						onChange={(value) => {
							actionCloseDetail(() => {
								if (value) {
									const baseDay = moment().toISOString();
									updateSelectedDates(
										value[0]?.toISOString() || baseDay,
										value[1]?.toISOString() || baseDay
									);
								}
							});
						}}
						size="large"
						locale={locale}
						format={'DD [de] MMMM'}
						bordered={false}
						suffixIcon={<Icon material="arrow_drop_down" />}
						separator={'-'}
						disabled={loading}
					/>
					<Button
						size="small"
						disabled={loading}
						onClick={() => actionCloseDetail(refresh)}
					>
						<Icon materialOutlined="sync" />
					</Button>
				</DateSection>
				<SecondSection>
					<ListForm
						detailOpened={selectedSource !== null}
						hasExtras={partnerIntegrationId === PARTNER.AIRBNB}
					>
						<Form.Item>
							<Input
								disabled={loading}
								prefix={<Icon material="search" />}
								size="large"
								allowClear
								value={searchValue || ''}
								onChange={(value) => {
									actionCloseDetail(() => {
										searchByValue(value.target.value);
									});
								}}
								placeholder="Buscar por nombre o ID de la reserva"
							/>
						</Form.Item>
						{showRoomFilterAndCsv && <FilterByRoom />}
						<AdditionalFilters />
						{showRoomFilterAndCsv && <UploadReservationTotal />}
						{showCreateInvoiceButton && (
							<HospyBtn
								size="small"
								type="primary"
								shape="circle"
								disabled={loading || !hasStamps}
								onClick={() => navigate('/invoices/create')}
								tooltip={
									!hasStamps
										? 'No tienes timbres disponibles'
										: 'Crear factura de forma manual'
								}
							>
								<Icon material="add" />
							</HospyBtn>
						)}
					</ListForm>
					{!selectedSource && (
						<ActionSection>
							<ProcessedFailedFilter />
						</ActionSection>
					)}
				</SecondSection>
			</Form>
		</InvoiceForm>
	);
};

const InvoiceForm = styled.div`
	width: 100%;
	.ant-form-item {
		margin: 0;
	}

	.ant-picker-suffix {
		color: black;
	}
`;

const DateSection = styled(({ detailOpened, ...props }) => <div {...props} />)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 340px;

	${({ theme, detailOpened }) =>
		theme.screens.sm &&
		!detailOpened &&
		css`
			width: 54%;
		`}

	${({ theme, detailOpened }) =>
		theme.screens.lg &&
		!detailOpened &&
		css`
			width: 36%;
		`}

	.ant-picker-large {
		input {
			font-weight: bold;
			font-size: ${({ theme }) => theme.text.size.middle}px;
		}
		padding: 0;
	}

	button {
		margin-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${({ theme }) => theme.colors.primary.base};
		border-radius: 25px;
		height: 35px;
		width: 35px;
		border: none;
		svg {
			color: white;
			font-size: 1.5rem;
		}
		:disabled {
			border-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']} !important;
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']} !important;
		}
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #fff;
			border-color: ${({ theme }) => theme.colors.primary.lighten['10%']};
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']};
		}
		&.ant-btn:hover,
		&.ant-btn:active,
		&.ant-btn:focus {
			color: #fff;
			border-color: ${({ theme }) => theme.colors.primary.lighten['10%']};
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']};
		}
	}
`;

const SecondSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
	flex-wrap: wrap;

	button {
		font-size: ${({ theme }) => theme.text.size.middle}px;
		display: flex;
		gap: 20px;
	}
`;

const ListForm = styled(({ detailOpened, hasExtras, ...props }) => (
	<div {...props} />
))`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	width: 100%;

	.ant-input-affix-wrapper {
		height: 50px;
		border: 1px solid #d6d6d6;
		border-radius: 25px;
		background-color: #f7f6f6;
		> input {
			font-size: ${({ theme }) => theme.text.size.large}px;
			background-color: #f7f6f6;
		}
	}

	.ant-row,
	.ant-form-item {
		width: 100%;
	}

	${({ theme, detailOpened }) =>
		theme.screens.lg &&
		!detailOpened &&
		css`
			width: 40%;
		`}

	${({ theme, detailOpened, hasExtras }) =>
		theme.screens.xl &&
		!detailOpened &&
		css`
			${hasExtras ? 'width: 70%;' : '	width: 36%;'};
			.ant-row,
			.ant-form-item {
				width: 100%;
			}
		`}
`;

const SelectFilterType = styled(Select)`
	.ant-select-selector {
		border: none !important;
		padding-left: 0 !important;
	}
`;

const ActionSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	align-items: center;
	gap: 20px;
	margin-top: 10px;
	width: 100%;
	> button {
		width: 100%;
		justify-content: center;
	}

	${({ theme }: any) =>
		theme.screens.lg &&
		css`
			margin-top: 0px;
			justify-content: end;
			width: 25%;
			> button {
				width: auto;
			}
		`}

	${({ theme }: any) =>
		theme.screens.xl &&
		css`
			margin-top: 0px;
			justify-content: end;
			width: 25%;
			> button {
				width: 40%;
			}
		`}
`;

const HospyBtn = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	svg {
		color: white;
		font-size: 1.5rem;
	}
`;

export default InvoiceFilterForm;
