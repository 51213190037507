/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestPatchListing,
	createListingService,
	createSireTraSettingsService,
	deleteListingService,
	deletePropertyIntegrationService,
	deleteSireTraSettingsService,
	editListingService,
	getCloudbedsRoomsService,
	getFieldMappingService,
	getListingsService,
	getListingsServiceProps,
	getRefreshFieldMappingService,
	getSireTraSettingsService,
	pathPropertyService,
	putSireTraSettingsService,
	rntValidateCredentialsService,
	updateFieldMappingService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { RequestUpdate } from '../../../../util-api/src/lib/requests';
import { Listing } from '../common/common.interfaces';
import { PARTNER } from '../common/common.types';
import { TRA_SLICE_NAME } from './tra.constants';

export const getTraSireSettings = createAsyncThunk(
	'tra/getTraSireSettings',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors }: { data: any[]; errors: any } =
				await getSireTraSettingsService({ propertyId });
			if (errors)
				return rejectWithValue('Error cargar las configuraciones');
			return data.length > 0 ? data[0] : undefined;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar validar las credenciales, por favor contacte con el administrador'
			);
		}
	}
);

// Se utiliza en la APP de Colombia sire tra, para poder traer nuevamente los valores de settings
// sin necesidad de borra los que están previamente
export const getTraSireSettingsV2 = createAsyncThunk(
	'tra/getTraSireSettings-v2',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors }: { data: any[]; errors: any } =
				await getSireTraSettingsService({ propertyId });
			if (errors)
				return rejectWithValue('Error cargar las configuraciones');
			return data.length > 0 ? data[0] : undefined;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar validar las credenciales, por favor contacte con el administrador'
			);
		}
	}
);

export const rntValidateCredentials = createAsyncThunk(
	'tra/rntValidateCredentials',
	async (d: any, { rejectWithValue }) => {
		try {
			const { errors } = await rntValidateCredentialsService(d);
			if (errors) {
				const el = find(errors, 'constraints.invalidToken');
				if (el) return rejectWithValue('Datos de acceso incorrecto');
				return rejectWithValue(
					'Ocurrió un error inesperado, por favor intente nuevamente'
				);
			}
			return d;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'La plataforma del gobierno que recibe el reporte no está funcionando. Por favor, intente mas tarde'
			);
		}
	}
);

export const createSireTraSettings = createAsyncThunk(
	'tra/createSireTraSettings',
	async (d: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await createSireTraSettingsService(d);
			if (errors)
				return rejectWithValue('Error al intentar crear el registro');
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar validar las credenciales, por favor contacte con el administrador'
			);
		}
	}
);

export const getCloudbedsRooms = createAsyncThunk(
	'tra/getCloudbedsRooms',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await getCloudbedsRoomsService(propertyId);
			if (errors)
				return rejectWithValue('Error al intentar crear el registro');
			if (data?.data) return data?.data[0].rooms;
			return data[0].rooms;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar validar las credenciales, por favor contacte con el administrador'
			);
		}
	}
);

export const removePropertyIntegration = createAsyncThunk(
	'tra/removePropertyIntegration',
	async (
		{
			id,
			propertyId,
			fieldMappingId,
			sireTraSettingsId,
			apps,
			listings,
			appId,
			partnerIntegrationId
		}: {
			id: string;
			apps: any[];
			propertyId: string;
			fieldMappingId?: string;
			sireTraSettingsId?: string;
			listings?: any[];
			appId: string;
			partnerIntegrationId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await deletePropertyIntegrationService(id);

			if (sireTraSettingsId) {
				await deleteSireTraSettingsService(sireTraSettingsId);
			}

			if (listings && listings?.length > 0) {
				listings?.forEach(async (e) => {
					await deleteListingService(
						e,
						propertyId,
						appId,
						partnerIntegrationId
					);
				});
			}

			await pathPropertyService(propertyId, { apps });
			if (errors)
				return rejectWithValue(
					'Error al intentar desconectar la aplicación'
				);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar desconectar la aplicación'
			);
		}
	}
);

export const putSireTraSettings = createAsyncThunk(
	'tra/putSireTraSettings',
	async ({ id, data: settings }: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await putSireTraSettingsService(
				id,
				settings
			);
			if (errors)
				return rejectWithValue(
					'Error al intentar actualizar los ajustes'
				);
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error al intentar actualizar los ajustes');
		}
	}
);

export const deleteSireTraSettings = createAsyncThunk(
	'tra/deleteSireTraSettings',
	async (id: string, { rejectWithValue }) => {
		try {
			const rs = await deleteSireTraSettingsService(id);
			return rs.data;
		} catch (error: any) {
			return rejectWithValue(
				'Error al eliminar las configuraciones de TRA'
			);
		}
	}
);

export const getFieldMapping = createAsyncThunk(
	'tra/getFieldMapping',
	async ({ propertyId, partnerIntegrationId }: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await getFieldMappingService(
				`{propertyId:'${propertyId}', partnerIntegrationId:'${partnerIntegrationId}'}`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
interface IUpdateFieldMapping {
	id: string;
	data: any;
	arrayFilters?: any[];
	updateMode?: 'pull' | 'push';
	items?: any[];
}
export const updateFieldMapping = createAsyncThunk(
	'tra/updateFieldMapping',
	async (
		{ id, data, arrayFilters, updateMode, items }: IUpdateFieldMapping,
		{ rejectWithValue }
	) => {
		const response = await updateFieldMappingService(
			id,
			data,
			`filter=${JSON.stringify({ arrayFilters, updateMode })}`
		);

		if (response.statusCode !== 200) {
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);
		}

		if (updateMode === 'push') {
			return {
				items: response.data,
				updateMode
			};
		}

		return { items, updateMode };
	}
);

export const getRefreshFieldMapping = createAsyncThunk(
	'tra/getRefreshFieldMapping',
	async (id: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await getRefreshFieldMappingService(id);
			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const getListings = createAsyncThunk(
	'tra/getListings',
	async (
		{
			propertyId,
			appId,
			partnerIntegrationId,
			page,
			limit,
			searchValue,
			searchInside,
			state
		}: getListingsServiceProps,
		{ rejectWithValue }
	) => {
		try {
			const { data, meta, errors } = await getListingsService({
				propertyId,
				appId,
				partnerIntegrationId,
				page: page || 1,
				limit: limit || 500,
				searchValue,
				searchInside,
				state
			});
			if (errors) {
				return rejectWithValue(
					'Error cargar la lista de listings, error: ' +
						String(errors)
				);
			}

			return {
				data,
				meta
			};
		} catch (error: any) {
			console.error('getListings: ', { error });
			return rejectWithValue(
				'Error cargar la lista de listings, error: ' + String(error)
			);
		}
	}
);

export const createListing = createAsyncThunk(
	'tra/createListing',
	async (listing: Listing, { rejectWithValue }) => {
		try {
			const { data, errors }: { data: any; errors: any } =
				await createListingService(listing);
			if (errors) return rejectWithValue('Error al crear un listing');
			return data;
		} catch (error: any) {
			console.error('tra/createListing:', { error });
			return rejectWithValue('Error al crear un listing');
		}
	}
);

export const traEditListing = createAsyncThunk(
	'tra/editListing',
	async (listing: Listing, { rejectWithValue }) => {
		try {
			const { data, errors }: { data: any; errors: any } =
				await editListingService(listing);
			if (errors) return rejectWithValue('Error al crear un listing');
			return data;
		} catch (error: any) {
			console.error('tra/createListing:', { error });
			return rejectWithValue('Error al crear un listing');
		}
	}
);

export const ThunkPatchListing = createAsyncThunk(
	`${TRA_SLICE_NAME}/${RequestPatchListing.name}`,
	async (params: RequestUpdate, { rejectWithValue }) => {
		const response = await RequestPatchListing(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

interface removeListingProp {
	id: string;
	propertyId: string;
	appId: string;
	partnerIntegrationId: string;
}
export const removeListing = createAsyncThunk(
	'tra/removeListing',
	async (
		{ id, propertyId, appId, partnerIntegrationId }: removeListingProp,
		{ rejectWithValue }
	) => {
		try {
			const { errors } = await deleteListingService(
				id,
				propertyId,
				appId,
				partnerIntegrationId
			);
			if (errors) return rejectWithValue('Error al eliminar un listing');
			return id;
		} catch (error) {
			console.error('tra/deleteListing:', { error });
			return rejectWithValue('Error al eliminar un listing');
		}
	}
);

export const getAllListings = createAsyncThunk(
	'tra/get-all-listings',
	async (
		{
			propertyId,
			appId,
			partnerIntegrationId,
			page,
			limit,
			searchValue,
			searchInside,
			state
		}: getListingsServiceProps,
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getListingsService({
				propertyId,
				appId,
				partnerIntegrationId,
				page: page || 1,
				limit: limit || 25,
				searchValue,
				fields: "['_id','name']",
				searchInside,
				state
			});

			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'Error cargar la lista de listings, error: ' + String(error)
			);
		}
	}
);

export const getIotLockFieldMapping = createAsyncThunk(
	'tra/get-iot-lock-field-mapping',
	async (
		{
			propertyId,
			partnerIntegrationId
		}: { propertyId: string; partnerIntegrationId: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getFieldMappingService(
				`{propertyId:'${propertyId}', partnerIntegrationId:'${partnerIntegrationId}'}`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const updateIotLockFieldMapping = createAsyncThunk(
	'tra/update-iot-lock-field-mapping',
	async (
		{ id, data, arrayFilters, updateMode, items }: IUpdateFieldMapping,
		{ rejectWithValue }
	) => {
		const response = await updateFieldMappingService(
			id,
			data,
			`filter=${JSON.stringify({ arrayFilters, updateMode })}`
		);

		if (response.statusCode !== 200) {
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);
		}

		if (updateMode === 'push') {
			return {
				items: response.data,
				updateMode
			};
		}

		return { items, updateMode };
	}
);

export const getFlexipassFieldMapping = createAsyncThunk(
	'tra/getFlexipassFieldMapping',
	async ({ propertyId }: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await getFieldMappingService(
				`{propertyId:'${propertyId}', partnerIntegrationId:'${PARTNER.FLEXIPASS}'}`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
