import { AppState } from './interfaces';

export const initialState = {

	properties: undefined,
	userWaitList: undefined,

	loading: { 
		getPropertyById: 'idle',
		getUserWaitListById: 'idle',
		exportCsvPropertiesById: 'idle',
		exportCsvUserWaitListById: 'idle'
	},
	success: { 
		getPropertyById: false,
		getUserWaitListById: false
	},
	error: { 
		getPropertyById: false,
		getUserWaitListById: false
	}
} as AppState;
