import { IInternalAccount, IReservationInvoice } from '@hospy/store';
import React from 'react';

export type InvoiceFilterContextType = {
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	selectedAdditionalFilters: string[];
	searchValue: string | null;
	justReservationsInProcess: boolean;
	loading: boolean;
	selectedSource: IReservationInvoice | IInternalAccount | null;
	inProcessOrFailureCounter: number;
	page: number;
	dates: string[];
	filterField: string;
	listing?: string[] | undefined;

	actionCloseDetail: (action: () => void) => void;
	refresh: () => void;
	updateFilterField: (value: string) => void;
	searchByValue: (value: string | null) => void;
	changeListingSelected?: (value: string | string[]) => void;
	updateSelectedDates: (start?: string, end?: string) => void;
	updatePage: (currentPage: number) => void;
	swipeJustInvoiceInProcess: () => void;
	updateAdditionalFilters: (filters: string[]) => void;
};
const InvoiceFilterContext =
	React.createContext<InvoiceFilterContextType | null>(null);

export default InvoiceFilterContext;

export const MAX_RESERVATIONS_TO_BULK = 9;
