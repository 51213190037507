/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Icon, Modal } from '@hospy/hospy-ui';
import { Checkbox, Form, Select, Spin, Typography, Upload } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useUploadReservationTotal from '../hooks/use-upload-reservation-total';

interface Props {
	onClose: () => void;
	cleanShow: () => void;
	upload: (file: any, product?: any) => void;
	getIntegration: () => void;

	open: boolean;
	uploading: boolean;
	loadingGetIntegration: boolean;

	status: string;
	total?: number;
}

const UploadReservationTotalModal = ({
	onClose,
	upload,
	getIntegration,
	cleanShow,

	open,
	uploading,
	loadingGetIntegration,

	status,
	total
}: Props) => {
	const {
		propsUpload,
		inputFile,
		setInputFile,

		splitCleaning,
		setSplitCleaning,

		products,
		loadingProducts,
		availableRetry,
		getDestinyProducts,

		form,
		fullProduct,
		saveDisabledTooltip,

		fieldMappingLoading
	} = useUploadReservationTotal({
		status,
		getIntegration,
		open
	});

	return (
		<Modal size="small" open={open} onClose={onClose} destroyOnClose>
			{(status === 'idle' ||
				status === 'success' ||
				status === 'failed') &&
				!loadingGetIntegration && (
					<Wrapper>
						<Title>Importar archivo de Airbnb</Title>
						<Text>
							Descarga el archivo de ingresos de Airbnb y agrégalo
							aquí
						</Text>
						<FormProduct form={form}>
							<Checkbox
								disabled={fieldMappingLoading}
								checked={splitCleaning}
								onChange={() =>
									setSplitCleaning(!splitCleaning)
								}
							>
								Separar la tasa de limpieza
							</Checkbox>
							{splitCleaning && (
								<RetrySection>
									<Form.Item
										name="product-siigo"
										rules={[
											{
												required: true,
												message: 'Campo requerido'
											}
										]}
									>
										<Select
											size="large"
											options={products as any[]}
											loading={loadingProducts}
											style={{ width: '100%' }}
											placeholder="Seleccione el item para facturar en Siigo"
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												(option?.label ?? '')
													.toLowerCase()
													.includes(
														input.toLowerCase()
													)
											}
											disabled={
												products?.length === 0 ||
												loadingProducts
											}
										/>
									</Form.Item>
									{availableRetry.products && (
										<Button
											type="text"
											loading={loadingProducts}
											onClick={() => getDestinyProducts()}
										>
											{!loadingProducts && (
												<Icon
													ant
													materialOutlined="sync"
												/>
											)}
										</Button>
									)}
								</RetrySection>
							)}
						</FormProduct>
						<Footer>
							<UploadButton {...propsUpload}>
								<Button size="large" disabled={uploading}>
									{inputFile
										? inputFile.name
										: 'Cargar el archivo csv'}
								</Button>
							</UploadButton>
							<Button
								onClick={async () => {
									await upload(inputFile, fullProduct);
									form.setFieldValue(
										'product-siigo',
										undefined
									);
									setSplitCleaning(false);
									setInputFile(null);
									onClose();
								}}
								size="large"
								loading={uploading || loadingProducts}
								disabled={
									!inputFile ||
									saveDisabledTooltip !== undefined ||
									fieldMappingLoading
								}
								tooltip={saveDisabledTooltip}
								type="primary"
							>
								Guardar
							</Button>
						</Footer>
					</Wrapper>
				)}
			{status === 'pending' && !loadingGetIntegration && (
				<Wrapper style={{ paddingInline: 40 }}>
					<Title>Resultado de la importación</Title>
					<DescriptionBlock>
						La importación no ha finalizado
					</DescriptionBlock>
					<Footer>
						<Button
							onClick={onClose}
							size="large"
							loading={uploading}
							disabled={uploading}
							type="primary"
							block
						>
							Entendido
						</Button>
					</Footer>
				</Wrapper>
			)}
			{status === 'show-success' && !loadingGetIntegration && (
				<Wrapper style={{ paddingInline: 40 }}>
					<Title>Resultado de la última importación</Title>
					<DescriptionBlock>
						<FormattedMessage
							id="upload.reservation-total"
							defaultMessage="✔ {total, plural, =0 {0 reservas actualizadas} one {# reserva actualizada} other {# reservas actualizadas} }"
							values={{ total }}
						/>
					</DescriptionBlock>
					<Footer>
						<Button
							onClick={() => {
								onClose();
								cleanShow();
							}}
							size="large"
							loading={uploading}
							disabled={uploading}
							type="primary"
							block
						>
							Entendido
						</Button>
					</Footer>
				</Wrapper>
			)}
			{loadingGetIntegration && (
				<Loading>
					<Spin />
				</Loading>
			)}
		</Modal>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 14px;
	justify-content: center;
`;

const Title = styled(Typography.Text)`
	display: flex;
	font-weight: bold;
	font-family: 'AmikoBold';
	font-size: ${({ theme }) => theme.text.size.large}px;
	justify-content: center;
`;

const Text = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.middle}px;
`;

const UploadButton = styled(Upload)`
	button {
		max-width: 227px;
		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}
	.ant-upload.ant-upload-select {
		display: flex;
		justify-content: center;
	}
	.ant-upload-list {
		display: flex;
		justify-content: center;
	}
`;

const Footer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 15px;

	button:nth-child(2) {
		padding-inline: 30px;
	}
`;

const DescriptionBlock = styled.div`
	display: flex;
	justify-content: center;

	box-shadow: ${({ theme }) => theme.boxShadow.level1()};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten?.['20%']};
	padding: 15px;
	width: 100%;
`;

const Loading = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const RetrySection = styled.div`
	display: flex;
	width: 100%;
	> div:nth-child(1) {
		width: 100%;
	}

	.ant-form-item-explain-error {
		margin-top: 5px;
		margin-bottom: 40px;
	}

	button {
		width: 10%;
	}
`;

const FormProduct = styled(Form)`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export default UploadReservationTotalModal;
