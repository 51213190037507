import { CloseCircleFilled } from '@ant-design/icons';
import { Modal as AntModal } from 'antd';

interface Props {
	children: React.ReactElement | React.ReactElement[];
	open: boolean;
	onClose: () => void;
	width?: any;
	bodyStyle?: any;
	destroyOnClose?: boolean;
}

export const Modal = (props: Props) => {
	const { children, open, onClose, ...rest } = props;

	return (
		<AntModal
			centered
			open={open}
			onCancel={onClose}
			footer={null}
			closeIcon={
				<CloseCircleFilled style={{ color: 'black', fontSize: 20 }} />
			}
			{...rest}
		>
			{children}
		</AntModal>
	);
};

export default Modal;
