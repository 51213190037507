/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';
import { PartnerIntegration } from '../common/common.types';
export class guestApi {
	static getPartnersIntegrations = (queryParams?: string[]): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};

			let _qp: string[] = [];
			if (queryParams) {
				_qp = [...queryParams];
			}

			fetch(
				ApiURL(
					`/api/common/partners/integrations${
						_qp?.length > 0 ? `?${_qp.join('&')}` : ''
					}`
				),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static validatePartnerIntegrationApiKey = (
		partner: PartnerIntegration,
		apiKey: string,
		accessToken: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({ partnerId: partner?._id, apiKey })
			};
			fetch(ApiURL('/api/common/partners/integration'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static validatePartnerIntegrationOauth = (
		partner: PartnerIntegration,
		code: string,
		clientId: string,
		clientSecret: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					partnerId: partner._id,
					code,
					clientId,
					clientSecret
				})
			};
			fetch(
				ApiURL('/api/common/partners/integration/access-token'),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
