import { TraSireState } from './tra-sire.interfaces';

export const initialState = {
	traSireSettings: {
		loading: 'idle',
		data: undefined,
		error: ''
	},
	deleteTraSireSettings: {
		loading: 'idle',
		error: ''
	},
	validateRnt: {
		loading: 'idle',
		error: ''
	}
} as TraSireState;
