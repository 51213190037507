import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	postGuestReport,
	getGuestReports,
	deleteGuestReport as deleteGuestReportRequest,
	getGuestReportCategories
} from '@hospy/util-api';

export const createGuestReport = createAsyncThunk(
	'guest-report/createGuestReport',
	async (payload: unknown, { rejectWithValue }) => {
		try {
			const { data, errors } = await postGuestReport(payload);
			if (errors) throw new Error(errors);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue('Error al crear reporte');
		}
	}
);

export const searchGuestReports = createAsyncThunk(
	'guest-report/searchGuestReports',
	async (
		filter: { query?: string; page?: number; propertyId: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, meta, errors } = await getGuestReports(filter);
			if (errors) throw new Error(errors);
			return { data, meta };
		} catch (error) {
			console.error(error);
			return rejectWithValue('Error al buscar reportes');
		}
	}
);

export const deleteGuestReport = createAsyncThunk(
	'guest-report/deleteGuestReport',
	async (id: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await deleteGuestReportRequest(id);
			if (errors) throw new Error(errors);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue('Error al eliminar reporte');
		}
	}
);

export const searchGuestReportCategories = createAsyncThunk(
	'guest-report/searchGuestReportCategories',
	async (query: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await getGuestReportCategories();
			if (errors) throw new Error(errors);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue('Error al buscar categories');
		}
	}
);
