import { Async } from "../../common/common.interfaces";
import {  CustomerInitialState } from "./interface";

export const CustomerSliceName = 'partner-customers';

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
}

export const initialState: CustomerInitialState = {
	createCustomer: initialAsync,
	updateCustomer: initialAsync,
	customers: initialAsync
}