import { Button } from '@hospy/hospy-ui';
import { useState } from 'react';
import styled from 'styled-components';
import { MaintenanceModal } from '../../../components/maintenance-modal';

const AppStatus = () => {
	const [modalOpened, setModalOpened] = useState<boolean>(false);

	return (
		<>
			<CustomButton
				type="primary"
				onClick={() => {
					setModalOpened(true);
				}}
				size="large"
				shape="round"
			>
				<Circle className="blob red" />
				Conexión inestable
			</CustomButton>
			<MaintenanceModal
				onClose={() => setModalOpened(false)}
				open={modalOpened}
			/>
		</>
	);
};

const CustomButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
	color: red;
	font-size: ${({ theme }) => theme.text.size.large}px;
	background-color: #fff4f1;
	border: 1px solid red;
	gap: 20px;

	&.ant-btn:hover:not(:disabled),
	&.ant-btn:active:not(:disabled),
	&.ant-btn:focus:not(:disabled) {
		color: red;
		border-color: red;
		background-color: #fed8ce;
	}
`;

const Circle = styled.div`
	background: rgba(255, 82, 82, 1);
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	margin: 10px;
	height: 20px;
	width: 20px;
	transform: scale(1);
	animation: pulse-red 2s infinite;

	@keyframes pulse-red {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
		}
	}
`;

export default AppStatus;
