import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './start.constants';
import {
	activateApp,
	fetchApp,
	fetchApps,
	requestClientSecret,
	savePayment
} from './start.thunks';

export const startSlice = createSlice({
	name: 'start',
	initialState,
	reducers: {
		setShowPropertyModal: (state, action) => {
			state.showPropertyModal = action.payload;
		},
		cleanActiveApp: (state) => {
			state.activateApp.loading = 'idle';
			state.error = initialState.error;
			state.app = initialState.app;
		},
		cleanPayment: (state) => {
			state.stripePayment = initialState.stripePayment;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchApps.fulfilled, (state, action) => {
			state.apps = action.payload;
		});
		builder.addCase(fetchApp.pending, (state, action) => {
			state.loadingApp = 'pending';
		});
		builder.addCase(fetchApp.fulfilled, (state, action) => {
			if (action.payload) {
				state.app = action.payload;
				state.loadingApp = 'succeeded';
			} else {
				state.error = {
					...state.error,
					app: true
				};
			}
		});

		builder.addCase(activateApp.pending, (state, action) => {
			state.activateApp.loading = 'pending';
		});
		builder.addCase(activateApp.fulfilled, (state, action) => {
			state.activateApp.loading = 'succeeded';
		});
		builder.addCase(activateApp.rejected, (state, action) => {
			state.activateApp.loading = 'failed';
			state.activateApp.error = String(action.payload);
		});

		builder.addCase(requestClientSecret.pending, (state, action) => {
			state.stripeClientSecret.loading = 'pending';
		});
		builder.addCase(requestClientSecret.fulfilled, (state, { payload }) => {
			state.stripeClientSecret.loading = 'succeeded';
			state.stripeClientSecret.data = payload.secret;
		});
		builder.addCase(requestClientSecret.rejected, (state, { payload }) => {
			state.stripeClientSecret.error = String(payload);
			state.stripeClientSecret.loading = 'failed';
		});

		builder.addCase(savePayment.pending, (state, action) => {
			state.stripePayment.loading = 'pending';
		});
		builder.addCase(savePayment.fulfilled, (state, { payload }) => {
			state.stripePayment.data = payload;
			state.stripePayment.loading = 'succeeded';
		});
		builder.addCase(savePayment.rejected, (state, { payload }) => {
			state.stripePayment.error = String(payload);
			state.stripePayment.loading = 'failed';
		});
	}
});

export const { setShowPropertyModal, cleanActiveApp, cleanPayment } =
	startSlice.actions;
