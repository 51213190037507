/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import useAddPhoneChannelPropertyForm, {
	useAddPhoneChannelPropertyFormProps
} from './use-add-phone-channel-property-form';

type AddPhoneChannelPropertyFormProps = useAddPhoneChannelPropertyFormProps;

const AddPhoneChannelPropertyForm = (
	props: AddPhoneChannelPropertyFormProps
) => {
	const intl = useIntl();

	const { form, loading, onSubmit, country } =
		useAddPhoneChannelPropertyForm(props);

	return (
		<Form form={form} onFinish={onSubmit} autoComplete="off">
			<Row gutter={30}>
				<Col xs={24}>
					<div>
						<Form.Item
							name="phone"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'airbnbForm.requiredField',
										defaultMessage: 'Campo requerido'
									})
								}
							]}
						>
							<PhoneInput
								country={country}
								enableSearch={true}
								inputStyle={{
									borderRadius: 0,
									width: '100%',
									height: 50,
									fontSize: 20
								}}
								inputProps={{
									name: 'phone',
									required: true
								}}
								searchStyle={{
									borderRadius: 0,
									height: 50,
									width: 'calc(100% - 15px)'
								}}
								dropdownStyle={{
									padding: 0,
									marginTop: 2,
									minWidth: 320
								}}
								searchPlaceholder="Buscar"
								disabled={loading}
								containerClass={loading ? 'disabled' : ''}
							/>
						</Form.Item>
					</div>
				</Col>
				<Col span={24}>
					<Button
						htmlType="submit"
						style={{ marginTop: 25 }}
						type="primary"
						block
						size="large"
						loading={loading}
					>
						<FormattedMessage
							id="property-modal.updatePropertyBtn"
							defaultMessage="Actualizar negocio"
						/>
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default AddPhoneChannelPropertyForm;
