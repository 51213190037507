/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCountryService } from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	IReservationPaymentCollect,
	ISavePaymentIntegrations
} from './interfaces';
import { paymentCollectApi } from './paymentCollectAPI';

export const fetchPartnerPaymentsIntegrations = createAsyncThunk(
	'paymentCollect/getPartnerIntegrations',
	async () => {
		const response = await paymentCollectApi.getPartnersIntegrations();
		return response.data;
	}
);

export const fetchPaymentIntegrations = createAsyncThunk(
	'paymentCollect/getPaymentIntegrations',
	async (_, { getState }) => {
		const state: any = getState();
		const response = await paymentCollectApi.getPaymentIntegrations(
			state.user.currentProperty
		);
		return response.data;
	}
);

export const savePaymentIntegration = createAsyncThunk(
	'paymentCollect/savePartnerIntegrations',
	async (savePaymentIntegrations: ISavePaymentIntegrations, { getState }) => {
		const state: any = getState();
		const response = await paymentCollectApi.savePaymentIntegration(
			state.paymentCollect.selectedPartnerIntegration,
			savePaymentIntegrations.publicKey,
			savePaymentIntegrations.secretKey,
			state.user.currentProperty
		);

		return response?.status === 'success' || response;
	}
);

export const deletePaymentIntegration = createAsyncThunk(
	'paymentCollect/deletePartnerIntegrations',
	async (paymentIntegrationId: string): Promise<any> => {
		const response = await paymentCollectApi.deletePaymentIntegration(
			paymentIntegrationId
		);

		return response?.data?._id !== undefined;
	}
);

export const fetchChannelsReservations = createAsyncThunk(
	'paymentCollect/getChannelsReservations',
	async () => {
		const response = await paymentCollectApi.getChannelsReservations();
		return response.data;
	}
);

export const fetchReservations = createAsyncThunk(
	'paymentCollect/getReservations',
	async (filters: any, { getState }) => {
		const state: any = getState();

		const response = await paymentCollectApi.getReservations(
			state.user.currentProperty,
			filters
		);
		return response.data;
	}
);

export const fetchCountries = createAsyncThunk(
	'paymentCollect/getCountries',
	async (filter?: string) => {
		const { data } = await getCountryService(filter);
		return data;
	}
);

export const createReservationPaymentCollect = createAsyncThunk(
	'paymentCollect/createReservationPaymentCollect',
	async (reservation: IReservationPaymentCollect) => {
		const response = await paymentCollectApi.saveReservation(reservation);
		if (response.errors) return response.errors;
		return true;
	}
);

export const deleteReservationPaymentCollect = createAsyncThunk(
	'paymentCollect/deleteReservationPaymentCollect',
	async (reservationId: string) => {
		const response = await paymentCollectApi.deleteReservation(
			reservationId
		);
		if (response.errors) return response.errors;
		return true;
	}
);

export const fetchReservation = createAsyncThunk(
	'paymentCollect/getReservation',
	async (reservationId: string, { getState }) => {
		const state: any = getState();

		const response = await paymentCollectApi.getReservation(
			state.user.currentProperty,
			reservationId
		);

		if (response.data && response.data.length > 0) return response.data[0];
		return null;
	}
);

export const updateReservationPaymentCollect = createAsyncThunk(
	'paymentCollect/updateReservationPaymentCollect',
	async (reservation: IReservationPaymentCollect) => {
		const response = await paymentCollectApi.updateReservation(reservation);
		if (response.errors) return response.errors;
		return true;
	}
);
