/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponseFailure, ResponseSuccess } from './responses';
import { ApiURL } from './util-url';
interface IFetch {
	headers?: { [key: string]: string };
}

/**
 *
 * @param method strict use RequestFindAll:GET, RequestFindOne:GET, RequestUpdate:PUT, RequestRemove:DELETE & RequestCreate:POST
 * @param path endpoint e.g api/common/customers/leads
 * @param params use interfaces RequestCreate, RequestFindAll, RequestFindOne, RequestRemove, RequestUpdate
 * @param props use interfaces RequestCreate, RequestFindAll, RequestFindOne, RequestRemove, RequestUpdate
 * @returns ResponseSuccess<any> | ResponseFailure
 */
export const Fetch = async (
	method: string,
	path: string,
	params: any,
	props?: IFetch
): Promise<ResponseSuccess<any> | ResponseFailure> => {
	const accessToken = localStorage.getItem('Hospy-idToken');
	const requestOptions: any = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		}
	};

	if (props?.headers) {
		requestOptions['headers'] = {
			...requestOptions['headers'],
			...props?.headers
		};
	}

	if (
		/RequestFindAll/.test(method) ||
		String(method).toLowerCase() == 'get'
	) {
		requestOptions['method'] = 'GET';
	} else if (
		/RequestFindOne/.test(method) ||
		String(method).toLowerCase() == 'get'
	) {
		requestOptions['method'] = 'GET';
	} else if (
		/RequestUpdate/.test(method) ||
		String(method).toLowerCase() == 'put'
	) {
		requestOptions['method'] = 'PUT';
		if (typeof params.data === 'string') {
			requestOptions['body'] = String(params.data);
		} else if (typeof params.data === 'object') {
			requestOptions['body'] = JSON.stringify(params.data);
		}
	} else if (
		/RequestUpdate/.test(method) ||
		String(method).toLowerCase() == 'patch'
	) {
		requestOptions['method'] = 'PATCH';
		if (typeof params.data === 'string') {
			requestOptions['body'] = String(params.data);
		} else if (typeof params.data === 'object') {
			requestOptions['body'] = JSON.stringify(params.data);
		}
	} else if (
		/RequestRemove/.test(method) ||
		String(method).toLowerCase() == 'delete'
	) {
		requestOptions['method'] = 'DELETE';
	} else if (
		/RequestCreate/.test(method) ||
		String(method).toLowerCase() == 'post'
	) {
		requestOptions['method'] = 'POST';
		if (typeof params.data === 'string') {
			requestOptions['body'] = String(params.data);
		} else if (typeof params.data === 'object') {
			if (
				requestOptions['headers']['Content-Type'] ===
				'multipart/form-data'
			) {
				const formParams = new FormData();
				for (const key of Object.keys(params.data)) {
					let formData = params.data[key];
					if (
						!/file/.test(key) &&
						typeof params.data[key] === 'object'
					) {
						formData = JSON.stringify(params.data[key]);
					}
					formParams.append(key, formData);
				}
				delete requestOptions['headers']['Content-Type'];
				requestOptions['body'] = formParams;
			} else {
				requestOptions['body'] = JSON.stringify(params.data);
			}
		}
	}

	if (typeof params?.pathParams == 'string') {
		let _id = params?.pathParams;
		if (params?.isThirdParty) {
			_id = 'tp_' + _id;
		}
		path = path.replace(':id', _id);
	} else if (typeof params?.pathParams == 'object') {
		const _isThirdParty =
			typeof params?.isThirdParty === 'object'
				? params?.isThirdParty
				: {};
		for (const item of Object.keys(params?.pathParams)) {
			const _id = _isThirdParty[item]
				? 'tp_' + params?.pathParams[item]
				: params?.pathParams[item];

			path = path.replace(':' + item, _id);
		}
	}

	try {
		let query = '?';

		if (typeof params.filter === 'string') {
			query += '&filter=' + params.filter;
		} else if (typeof params.filter === 'object') {
			query += '&filter=' + JSON.stringify(params.filter);
		}
		if (typeof params.sort === 'string') {
			query += '&sort=' + params.sort;
		} else if (typeof params.sort === 'object') {
			query += '&sort=' + JSON.stringify(params.sort);
		}
		if (typeof params.flags === 'string') {
			query += '&flags=' + params.flags;
		} else if (typeof params.flags === 'object') {
			query += '&flags=' + JSON.stringify(params.flags);
		}
		if (params?.page) {
			query += '&page=' + params.page;
		}
		if (params?.limit) {
			query += '&limit=' + params.limit;
		}
		if (params?.propertyId) {
			query += '&propertyId=' + params.propertyId;
		}
		if (params?.appId) {
			query += '&appId=' + params.appId;
		}
		if (params?.partnerIntegrationId) {
			query += '&partnerIntegrationId=' + params.partnerIntegrationId;
		}
		if (params?.partnerIntegrationIdTo) {
			query += '&partnerIntegrationIdTo=' + params.partnerIntegrationIdTo;
		}

		query += '&filterMethod=' + params?.filterMethod || 'std';
		query += '&requestMethod=' + params?.requestMethod || 'std';
		query += '&responseMethod=' + params?.responseMethod || 'std';

		const url = path + query;
		const res = await fetch(ApiURL(url), requestOptions);
		let paylaodJson;
		let paylaodText;
		let message = 'Ocurrió un problema al ejecutar la solicitud';
		try {
			paylaodJson = await res.json();
		} catch (error) {
			message =
				'Ocurrio un problema al transformar la información recibida';
			paylaodText = await res.text();
		}

		if (res.status > 199 && res.status < 300) {
			// Successful
			paylaodJson = paylaodJson as ResponseSuccess<any>;
			paylaodJson.status = 'success';
			paylaodJson.statusCode = paylaodJson.statusCode || res.status;
			return paylaodJson;
		} else if (res.status > 399 && res.status < 500) {
			// Client error

			paylaodJson.status = 'failure';
			paylaodJson.statusCode = paylaodJson.statusCode || res.status;

			// Endpint not exists
			if (paylaodJson?.currentRoute) {
				paylaodJson.message = paylaodJson?.error;
			}

			return paylaodJson;
		} else if (res.status > 499 && res.status < 600) {
			// Server error
			const x500: any = {
				500: 'Internal Server Error',
				501: 'Not Implemented',
				502: 'Bad Gateway',
				503: 'Service Unavailable',
				504: 'Gateway Timeout',
				505: 'HTTP Version Not Supported',
				506: 'Variant Also Negotiates',
				507: 'Insufficient Storage',
				508: 'Loop Detected',
				510: 'Not Extended',
				511: 'Network Authentication Required'
			};
			return {
				statusCode: res.status,
				status: 'failure',
				message: 'Error interno del servidor',
				description: paylaodText,
				// code: ''
				errors: [
					{
						code: String(res.status),
						message: x500[res.status] || 'Error desconcido',
						type: String(res.status)
					}
				]
			} as ResponseFailure;
		}
		return {
			statusCode: res.status,
			status: 'failure',
			message,
			description: paylaodText
			// code: ''
			// errors: []
		} as ResponseFailure;
	} catch (error) {
		return {
			statusCode: 500,
			status: 'failure',
			message: 'Error interno del cliente',
			description: String(error)
			// code: ''
			// errors: []
		} as ResponseFailure;
	}
};
