import { Typography } from 'antd';
import styled, { css } from 'styled-components';

export interface CardProps {
	title?: string;
	children?: React.ReactElement | React.ReactElement[];
	headerActions?: React.ReactElement;
	headerSection?: React.ReactElement;
	style?: React.CSSProperties;
	bodyStyle?: React.CSSProperties;
	titleStyle?: React.CSSProperties;
	counter?: number;
}

export function Card({
	children,
	title,
	headerActions,
	headerSection,
	bodyStyle,
	titleStyle,
	counter,
	...rest
}: CardProps) {
	return (
		<Container {...rest}>
			<Header>
				{title && (
					<Title style={{ ...titleStyle }}>
						{title} {counter && <Counter>{counter}</Counter>}
					</Title>
				)}
				{headerActions}
			</Header>
			<Body style={{ ...bodyStyle }}>
				{headerSection}
				{children}
			</Body>
		</Container>
	);
}

export default Card;

const Counter = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 300;
	font-size: ${({ theme }) => theme.text.size.small}px;
	background-color: ${({ theme }) => theme.colors.primary.lighten['90%']};
	border-radius: 20px;
	min-width: 25px;
	height: 25px;
	line-height: 0;
	padding: 0;
`;

const Container = styled.div`
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
	padding-top: 30px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
	margin-bottom: 15px;
	${({theme}) => theme.screens.xs && css`
		flex-direction: column;
		align-content: space-between;
		flex-wrap: wrap;
		align-items: flex-end;
	`}
`;

const Body = styled.div`
	padding: 0 15px;
	padding-bottom: 30px;
`;

const Title = styled(Typography.Text)`
	align-items: center;
	display: flex;
	font-size: ${({ theme }) => theme.title.size.small}px;
	gap: 10px;
	${({theme}) => theme.screens.xs && css`
		justify-content: space-between;
		font-size: ${({ theme }) => theme.title.size.small * 0.8}px;
		margin-bottom: 15px;
		width: 100%;
	`}
`;
