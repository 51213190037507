import { IReservationState } from './interfaces';

export const ReservationSliceName = 'reservation';

export const initialState = {
	theme: 'light',
	loading: 'idle',
	reservations: undefined,
	success: {
		createReservation: false,
		findReservation: false,
		findReservations: false
	},
	error: {
		createReservation: undefined,
		findReservation: undefined
	},
	notes: {
		loading: 'idle',
		data: null
	}
} as IReservationState;
