import { TermsAndConditionsInitialState } from './interface';

export const termsAndConditionsInitialState: TermsAndConditionsInitialState = {
	termsAndConditions: {
		loading: 'idle',
		error: undefined,
		data: null
	},
	create: {
		loading: 'idle',
		error: undefined,
		data: null
	},
	update: {
		loading: 'idle',
		error: undefined,
		data: null
	},
	deleteTerms: {
		loading: 'idle',
		error: undefined,
		data: null
	}
};
