/* eslint-disable @typescript-eslint/no-explicit-any */
import { Async } from '../../common/common.interfaces';
import { GuestReservation, Loading } from '../../common/common.types';
import { IReservationInvoice } from '../../siigo-connect/interface';
import { CrmUpdateCustomer } from '../customers/interface';

export interface CloudbedsTransaction {
	description: string;
	transactionCategory: string;
	transactionCode: string;
	itemCategoryName: string;
	transactionID: string;
	transactionIDs: string[];
	isDeleted: boolean;
	parentTransactionID: string;
	roomTypeID: string;
	roomTypeName: string;
	roomName: string;
	amount: number;
	transactionType: string;
	quantity: number;
	category: string;
	transactionDateTime?: string;
	currency?: string;
	notes?: string;
}

export interface Adjustment extends CloudbedsTransaction {
	applied?: boolean;
}

interface PaymentMethods {
	loading: Loading;
	error?: string;
	data: any[];
}

export interface InitialState {
	paymentMethodsInternal: PaymentMethods;
	paymentMethodsExternal: PaymentMethods;
	paymentMethods: Async;
	invoice: Async;
	updatedInvoice: Async;
	deletedInvoice: Async;
	taxes: Async;
	destinyTaxes: Async;
	joinItems: Async;
	stamp: Async;
	reservationToBulk: IReservationInvoice[];
	stampBulk: Async;
}

// export interface Find {
// 	partnerIntegrationId: string;
// 	appId: string;
// 	propertyId: string;
// 	filter?: any;
// 	page?: string;
// 	limit?: string;
// }

export interface PayloadBaseInvoice<Payload> {
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;

	reservationId?: string;
	thirdPartyId?: string;
	payload?: Payload;
	name?: string;
}

export interface InvoiceCustomer {
	name: string;
	lastName: string;
	companyName: string;
	email: string;
	documentNumber: string;
	documentTypeName: string;
	documentTypeId: string;
	phone: string;
	wasSaved?: boolean;
	customerReference?: string;
	/**
	 * @deprecated Use thirdPartyId as global external id or reference
	 */
	guestId?: string;
	thirdPartyId?: string;
	personType?: string;
	//MX
	rfc?: string;
	fiscalRegime?: string;
	postalCode?: string;
	cfdiUse?: string;
	//ARG
	taxTreatment?: string;
	taxTreatmentName?: string;
	saveAsDefault?: boolean;
	defaultCustomer?: {
		siigoId: string;
		thirdPartyId: string;
		documentNumber: string;
		documentType: string;
		name: string;
	};
}

export interface InvoiceAddress {
	countryName: string;
	countryId: string;
	countryCode: string;
	stateId: string;
	stateName: string;
	cityId: string;
	cityName: string;
	line: string;
	zipCode?: string;
	invalidCountry?: boolean;
}

export interface IItemsTax {
	id: string;
	name: string;
	amountType: string;
	amount: number;
	active: boolean;
	included: boolean;
	_id?: string;
	code?: string;
	mapping?: IMapping[];
}

export interface IMapping {
	partnerTo: string;
	partnerFrom: string;
	value: string;
	name: string;
	type?:
		| 'taxable'
		| 'non-taxable'
		| 'accounts'
		| 'source-funds'
		| 'payment-way';
	amount?: number;
	due_date?: string;
}

export enum ItemBillingStatus {
	BILLED = 'billed',
	NOT_BILLED = 'not-billed',
	PENDING = 'pending',
	FAILED = 'failed'
}

export interface InvoiceItems {
	_id: string;
	code: string;
	name: string;
	quantity: number;
	price: number;
	subtotal: number;
	taxes: IItemsTax[];
	total: number;
	type: string;
	currency: string;
	active: boolean;
	mapping?: IMapping[];
	unit?: IMapping;
	projected: boolean;
	/**
	 * @deprecated Use thirdPartyId as global external id or reference
	 */
	transactionID?: string;
	thirdPartyId?: string;
	billingStatus?: ItemBillingStatus | string;
	billingDetails?: {
		id: string;
		billingStatus?: ItemBillingStatus | string;
		billingErrors?: {
			errors: any[];
		};
		thirdPartyId?: string;
	};
	selectedToBill?: boolean;
	group?: string;
}

export interface InvoicePayments {
	_id: string;
	status: string;
	paymentTypeId: string;
	paymentTypeName: string;
	amount: number;
	currency: string;
	createdAt: string;
	date: string;
	active: boolean;
	selectedToBill?: boolean;
	mapping?: IMapping[];
	notes?: string;
	id?: string;
	thirdPartyId?: string;
	billingStatus?: ItemBillingStatus | string;
	conversionEnabled?: boolean;
}

interface Stamp {
	documentType: string;
	status: string;
	userId: string;
	userName: string;
}

export interface ThirdPartyPayload {
	Date: string;
	date: string;
	id?: string;
	name?: string;
	prefix?: string;
	Serie?: string;
	number?: string;
	Folio?: string;
	metadata?: {
		created: string;
	};
	customer?: any;
	MensajeAFIP?: string;
	TipoComprobante?: string;
	CAE?: number;
	Total?: number;
	Receiver?: {
		Name: string;
	};
	items: any[];
	URLPDF?: string;
}

interface InvoiceExtras {
	slug: string;
	value: string;
}

export interface Invoice {
	_id?: string;
	//
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	propertyId: string;
	appId: string;
	//
	total: number;
	status: string;
	thirdPartyId: string;
	adults: number;
	children?: number;
	startDate: string;
	endDate: string;
	//
	customer: InvoiceCustomer;
	address: InvoiceAddress;
	adjustments: Adjustment[];
	items: InvoiceItems[];
	payments: InvoicePayments[];
	notes?: string;
	//
	extras?: InvoiceExtras[];
	//
	stamp?: Stamp;
	//
	state:
		| ''
		| 'draft'
		| 'failure'
		| 'success'
		| 'pre-processing'
		| 'processing';
	active: boolean;
	checked: boolean;
	createdAt?: string;
	updatedAt?: string;
	//
	thirdPartyPayload?: ThirdPartyPayload;
	pdfUrl: string;
	//
	mainRoom?: InvoiceItems;
	currencyRate?: number;
	stampDate?: string;
	// Front uses
	joinItems?: boolean;
	propertyCurrency?: string;
	//
	id?: string;
	partnerBlocked?: boolean;
	lastPartnerInteractionDate?: string;
	sendEmail?: boolean;
	//
	currency?: {
		currencyCode: string;
		currencyExchangeRate: number;
	};
	invoices?: InvoicesStamp[];
	costCenter?: {
		name?: string;
		code?: string;
		thirdPartyId?: string;
		ignore?: boolean;
	};
	paymentWay?: {
		name?: string;
		code?: string;
		thirdPartyId?: string;
		due_date?: boolean;
	};
	documentTypeBill?: {
		code: string;
		name: string;
		thirdPartyId: string;
		costCenterMandatory: boolean;
	};
	guests?: GuestReservation[];
	defaultTaxAmount?: {
		code: string;
		name: string;
		amount: number;
	};
	channelName: string;
	channelSlug: string;
	hasThirdPartyIncomes: boolean;
	roomName: string;
}

export interface InvoicesStamp {
	_id: string;
	stamp: Stamp;
	pdfUrl: string;
	items: InvoiceItems[];
	lastPartnerInteractionDate: string;
	thirdPartyPayload: ThirdPartyPayload;
	state: '' | 'failure' | 'success' | 'processing';
}

export interface InvoiceStamp {
	propertyId: string;
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	invoiceId: string;
	invoice: Invoice;
}

export interface InvoiceStampAsync extends InvoiceStamp {
	propertyId: string;
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	invoiceId: string;
	invoice: Invoice;
	customer: CrmUpdateCustomer;
	wasEdited: boolean;
}

export interface InvoiceUpdateStampAsync {
	propertyId: string;
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	invoice: Invoice;
}
