import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	deleteCollaborator,
	fetchWorkteamApps,
	fetchUsers,
	saveWorkTeam,
	updateWorkTeam
} from './thunks';

export const workTeamSlice = createSlice({
	name: 'workteam',
	initialState,
	reducers: {
		clearGetUsers: (state) => {
			state.success.getUsers = initialState.success.getUsers;
			state.error.getUsers = initialState.error.getUsers;
			state.users = initialState.users;
		},
		clearDeleteCollaborator: (state) => {
			state.success.deleteCollaborator =
				initialState.success.deleteCollaborator;
			state.error.deleteCollaborator =
				initialState.error.deleteCollaborator;
		},
		clearAddTeam: (state) => {
			state.success.saveTeam = initialState.success.saveTeam;
			state.error.saveTeam = initialState.error.saveTeam;
		},
		clearUpdateTeam: (state) => {
			state.success.updateTeam = initialState.success.updateTeam;
			state.error.updateTeam = initialState.error.updateTeam;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUsers.pending, (state) => {
			state.error = {
				...state.error,
				getUsers: false
			};
			state.success = {
				...state.success,
				getUsers: false
			};
			state.loading = 'pending';
		});

		builder.addCase(fetchUsers.fulfilled, (state, action) => {
			state.users = action.payload ? action.payload : null;
			state.loading = 'idle';
			state.success.getUsers = true;
		});

		builder.addCase(deleteCollaborator.pending, (state) => {
			state.error = {
				...state.error,
				deleteCollaborator: false
			};
			state.success = {
				...state.success,
				deleteCollaborator: false
			};
			state.deleteLoading = 'pending';
		});

		builder.addCase(deleteCollaborator.fulfilled, (state, action) => {
			state.deleteLoading = 'idle';
			if (action.payload === true)
				state.success.deleteCollaborator = action.payload;
			else state.error.deleteCollaborator = action.payload;
		});

		builder.addCase(fetchWorkteamApps.pending, (state) => {
			state.getAppsLoading = 'pending';
		});

		builder.addCase(fetchWorkteamApps.fulfilled, (state, action) => {
			state.getAppsLoading = 'idle';
			if (action.payload) {
				state.apps = action.payload;
				state.success.getApps = true;
			} else {
				state.error.deleteCollaborator = action.payload;
			}
		});

		builder.addCase(saveWorkTeam.pending, (state) => {
			state.saveWorkTeamLoading = 'pending';
		});

		builder.addCase(saveWorkTeam.fulfilled, (state, action) => {
			state.saveWorkTeamLoading = 'idle';
			if (action.payload) {
				state.success.saveTeam = true;
			} else {
				state.error.saveTeam = action.payload;
			}
		});

		builder.addCase(updateWorkTeam.pending, (state) => {
			state.updateWorkTeamLoading = 'pending';
		});

		builder.addCase(updateWorkTeam.fulfilled, (state, action) => {
			state.updateWorkTeamLoading = 'idle';
			if (action.payload) {
				state.success.updateTeam = true;
			} else {
				state.error.updateTeam = action.payload;
			}
		});
	}
});

export const {
	clearGetUsers,
	clearDeleteCollaborator,
	clearAddTeam,
	clearUpdateTeam
} = workTeamSlice.actions;
