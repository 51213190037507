import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './airbnb-reservation.constants';
import {
	getAirbnbReservation,
	getAirbnbReservationProperty,
	updateAirbnbReservation
} from './airbnb-reservation.thunks';

export const airbnbReservationSlice = createSlice({
	name: 'airbnbReservation',
	initialState,
	reducers: {
		setReservationDetail: (state, { payload }) => {
			if (state.reservation) {
				state.reservation = {
					...state.reservation,
					data: {
						...state.reservation?.data,
						...payload
					}
				};
			}
		},
		setAirbnbReservation: (state, { payload }) => {
			state.reservation = payload;
		},
		cleanUpdateReservation: (state) => {
			state.updatedReservation = initialState.updatedReservation;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				getAirbnbReservationProperty.pending,
				(action, { payload }) => {
					action.property = {
						loading: 'pending'
					};
				}
			)
			.addCase(
				getAirbnbReservationProperty.rejected,
				(action, { payload }) => {
					action.property = {
						loading: 'failed',
						error: String(payload)
					};
				}
			)
			.addCase(
				getAirbnbReservationProperty.fulfilled,
				(action, { payload }) => {
					action.property = {
						loading: 'succeeded',
						data: payload
					};
				}
			);

		builder
			.addCase(getAirbnbReservation.pending, (action, { payload }) => {
				action.reservation = {
					loading: 'pending'
				};
			})
			.addCase(getAirbnbReservation.rejected, (action, { payload }) => {
				action.reservation = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(getAirbnbReservation.fulfilled, (action, { payload }) => {
				action.reservation = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(updateAirbnbReservation.pending, (action, { payload }) => {
				action.updatedReservation = {
					loading: 'pending'
				};
			})
			.addCase(
				updateAirbnbReservation.rejected,
				(action, { payload }) => {
					action.updatedReservation = {
						loading: 'failed',
						error: String(payload)
					};
				}
			)
			.addCase(
				updateAirbnbReservation.fulfilled,
				(action, { payload }) => {
					action.updatedReservation = {
						loading: 'succeeded',
						data: payload
					};
				}
			);
	}
});

export const {
	setAirbnbReservation,
	cleanUpdateReservation,
	setReservationDetail
} = airbnbReservationSlice.actions;
