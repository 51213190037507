import styled from 'styled-components';
import { Button, Icon } from '@hospy/hospy-ui';
import FilterModal from './filter-modal';
import { useContext, useState } from 'react';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';

const AdditionalFilters = () => {
	const { selectedAdditionalFilters, loading } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;

	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const openFilters = () => {
		setModalOpened(true);
	};
	return (
		<div>
			<FilterButton
				onClick={openFilters}
				shape="round"
				disabled={loading}
			>
				{selectedAdditionalFilters.length > 0 && (
					<Badge>{selectedAdditionalFilters.length}</Badge>
				)}
				<Icon material="filter_alt" />
			</FilterButton>
			<FilterModal
				open={modalOpened}
				onClose={() => setModalOpened(false)}
			/>
		</div>
	);
};

const FilterButton = styled(Button)`
	position: relative;
	padding-inline: 14px !important;

	svg {
		font-size: 22px;
	}
`;

const Badge = styled.span`
	position: absolute !important;
	right: -5px;
	bottom: 66%;
	background-color: black;
	color: white;
	width: 25px;
	height: 25px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
`;

export default AdditionalFilters;
