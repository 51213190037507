import {  AsyncV2 } from '@hospy/store/common/common.interfaces';
import { IAdminLeadsInitialState } from './interface';

export const AdminLeadsSliceName = 'adminLeads'

const initialAsync: AsyncV2<any> = {
	loading: 'idle',
	error: undefined,
	payload: undefined
}; 
export const AdminLeadsInitialState: IAdminLeadsInitialState = {
	findAllLeads: initialAsync,
	updateLeads: initialAsync,
	removeLeads: initialAsync,
	importLeads: initialAsync,
	whatsappTemplates: initialAsync,
	leadNotifications: initialAsync

};
