import { createSlice } from "@reduxjs/toolkit";
import { initialState } from './constants';
import { createCompanies, getCompanies } from "./thunks";

export const partnerCompaniesSlice = createSlice({
    name: 'partner-companies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getCompanies.pending, (state) => {
            state.companies.loading = 'pending';
        })
        .addCase(getCompanies.rejected, (state, payload) => {
            state.companies.loading = 'idle';
            state.companies.error = payload;
        })
        .addCase(getCompanies.fulfilled, (state, { payload }) => {
            state.companies.data = payload;
            state.companies.loading = 'idle';
        })

        .addCase(createCompanies.pending, (state) => {
            state.createCompany.loading = 'pending';
        })
        .addCase(createCompanies.rejected, (state, payload) => {
            state.createCompany.loading = 'idle';
            state.createCompany.error = payload;
        })
        .addCase(createCompanies.fulfilled, (state, { payload }) => {
            state.createCompany.data = payload;
            state.createCompany.loading = 'idle';
        })
    }
})