import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  HubspotGetFieldMappingProp,
  HubspotGetLogsProps,
  HubspotGetPropertiesProp
} from './interface';
import {
  getFieldMappingService,
  hubspotGetLogsService,
  hubspotGetPropertiesService,
  hubspotGetPipelinesService,
  updateFieldMappingService
} from '@hospy/util-api';

export const hubspotGetFieldMapping = createAsyncThunk(
  'hubspot/get-field-mapping',
  async (
    {
      propertyId,
      partnerIntegrationId,
      appId,
      propertyIntegrationId,
      propertyIntegrationIdTo
    }: HubspotGetFieldMappingProp,
    { rejectWithValue }
  ) => {
    try {
      const { data, errors } = await getFieldMappingService(
        `{ propertyId: "${propertyId}", partnerIntegrationId: "${partnerIntegrationId}", appId: "${appId}"}`
      );
      if (errors) return rejectWithValue(errors);
      return data[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

interface IUpdateFieldMapping {
  id: string;
  data: any;
  arrayFilters?: any[];
  updateMode?: 'pull' | 'push';
  items: any[];
}

export const hubspotUpdateFieldMapping = createAsyncThunk(
  'hubspot/update-field-mapping',
  async (
    { id, data, arrayFilters, updateMode, items }: IUpdateFieldMapping,
    { rejectWithValue }
  ) => {
    const response = await updateFieldMappingService(
      id,
      data,
      `filter=${JSON.stringify({ arrayFilters, updateMode })}`
    );

    if (response.statusCode !== 200) {
      return rejectWithValue(
        response.error || 'No se pudo iniciar el onboarding'
      );
    }

    if (updateMode === 'push') {
      return {
        items: response.data,
        updateMode
      };
    }

    return { items, updateMode };
  }
);

export const hubspotGetPropertyDeals = createAsyncThunk(
  'hubspot/get-property-deals',
  async (params: HubspotGetPropertiesProp, { rejectWithValue }) => {
    try {
      const { data, errors } = await hubspotGetPropertiesService(params);
      if (errors) return rejectWithValue(errors);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const hubspotGetProperties = createAsyncThunk(
  'hubspot/get-properties',
  async (params: HubspotGetPropertiesProp, { rejectWithValue }) => {
    try {
      const { data, errors } = await hubspotGetPropertiesService(params);
      if (errors) return rejectWithValue(errors);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const hubspotGetPipelines = createAsyncThunk(
  'hubspot/get-pipelines',
  async (params: HubspotGetPropertiesProp, { rejectWithValue }) => {
    try {
      const { data, errors } = await hubspotGetPipelinesService(params);
      if (errors) return rejectWithValue(errors);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const hubspotGetLogs = createAsyncThunk(
  'hubspot/get-logs',
  async (params: HubspotGetLogsProps, { rejectWithValue }) => {
    try {
      const response = await hubspotGetLogsService(params);
      if (response.errors) return rejectWithValue(response.errors);
      else if (response.statusCode === 500)
        return rejectWithValue(
          'Ocurrió un problema, inténtalo más tarde'
        );

      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
