/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseCircleFilled } from '@ant-design/icons';
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import styled, { css } from 'styled-components';

type modalSizeType = 'small' | 'middle' | 'large';

export interface ModalProps extends AntModalProps {
	open: boolean;
	onClose: () => void;
	children?: any;
	size?: modalSizeType;
	title?: string | React.ReactElement | React.ReactElement[];
	style?: React.CSSProperties;
	internal?: boolean;
	padding?: string;
	borderRadius?: string;
	darkMode?: boolean;
	maxWidth?: number;
}

export const Modal = (props: ModalProps) => {
	const { children, open, onClose, size, darkMode, maxWidth, ...rest } =
		props;

	return (
		<ModalStyle
			centered
			open={open}
			onCancel={(e) => {
				e.stopPropagation();
				onClose();
			}}
			footer={null}
			closeIcon={
				<CloseCircleFilled
					style={{ color: darkMode ? '#fff' : '#000', fontSize: 20 }}
				/>
			}
			maxWidth={maxWidth ? maxWidth : modalMaxWidth[size || 'middle']}
			{...rest}
		>
			{children}
		</ModalStyle>
	);
};

export default Modal;

const modalMaxWidth = {
	small: 550,
	middle: 800,
	large: 1200
};
type ModalStyleProp = {
	size?: modalSizeType;
	padding?: string;
	borderRadius?: string;
	maxWidth: number;
};
const ModalStyle = styled(AntModal)<ModalStyleProp>`
	&.ant-modal {
		/* width: auto !important; */
		width: calc(100% - 30px) !important;
		max-width: ${({ maxWidth }) => maxWidth}px !important;
		margin-block: 50px;
		margin-inline: 15px;
	}

	.ant-modal-body {
		${({ padding }) =>
			padding &&
			css`
				padding: ${padding};
			`};
	}

	.ant-modal-content {
		${({ borderRadius }) =>
			borderRadius &&
			css`
				border-radius: ${borderRadius};
			`};
	}
`;
