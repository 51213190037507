import { Button, Icon, Modal } from '@hospy/hospy-ui';
import { Skeleton, Typography, Image } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import usePartnerConnect, {
  usePartnerConnectProp
} from './use-partner-connection';

interface PartnerConnectionProps extends usePartnerConnectProp {
  partnerName?: string;
  createdAt?: string;
  momentLocale?: 'es' | 'en';

  partnerIntegration?: any; // parnter fulldata
  disableDisconnect?: boolean;
  reconnect?: {
    onReconnect: any;
  };

}
const PartnerConnection: React.FC<PartnerConnectionProps> = ({
  partnerName,
  createdAt,
  momentLocale = 'es',
  onDisconnect,
  onLoadBeforeDelete,
  disableDisconnect,
  reconnect,
  partnerIntegration
}) => {
  const theme: any = useTheme();

  const {
    open,
    toggleModal,
    handleDisconnect,
    loading,
    loadingBeforeDelete
  } = usePartnerConnect({
    onDisconnect,
    onLoadBeforeDelete
  });

  useEffect(() => {
    moment.locale(momentLocale);
  }, []);

  return (
    <>
      <Container>
        <Content>
          {partnerIntegration?.icon &&
            <Image style={{ paddingTop: '5px' }} width={'42px'} src={partnerIntegration.icon} alt="logo" />
          }
          <Label>
            {partnerName ? (
              <>
                <span>
                  Conexión con {capitalize(partnerName)}{' '}
                  activa{' '}
                  <CheckIcon
                    material="verified"
                    style={{ verticalAlign: -4 }}
                  />
                </span>

                <LabelDate>
                  Conectado desde:{' '}
                  {moment(createdAt).format('LL')}
                </LabelDate>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8
                }}
              >
                <Skeleton.Input
                  block
                  active
                  size="small"
                  style={{
                    fontSize: 24,
                    height: 24,
                    maxWidth: 400
                  }}
                />
                <Skeleton.Input
                  active
                  block
                  size="small"
                  style={{
                    fontSize: 16,
                    height: 16,
                    maxWidth: 300
                  }}
                />
              </div>
            )}
          </Label>
          {reconnect &&
            <Button
              type="primary"
              onClick={reconnect?.onReconnect}
              block={theme.screens.xs}
              disabled={!Boolean(partnerName && createdAt)}
              loading={loadingBeforeDelete === 'pending'}
            >
              Reconectar
            </Button>
          }
          {!disableDisconnect &&
            <Button
              type="primary"
              danger
              onClick={() => toggleModal(true)}
              block={theme.screens.xs}
              disabled={!Boolean(partnerName && createdAt)}
              loading={loadingBeforeDelete === 'pending'}
            >
              Desconectar aplicación
            </Button>
          }
        </Content>
      </Container>
      <Modal open={open} onClose={() => toggleModal(false)} size="small">
        <Title>Desconectar aplicación</Title>
        <Info>¿Estas seguro en realizar esta operación?</Info>
        <ButtonContainer>
          <Button
            size="middle"
            type="text"
            onClick={() => toggleModal(false)}
            disabled={loading === 'pending'}
          >
            Cancelar
          </Button>
          <Button
            size="middle"
            type="primary"
            danger
            onClick={handleDisconnect}
            loading={loading === 'pending'}
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};
export default PartnerConnection;

const Container = styled.div`
	padding: 20px 15px;
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten['50%']};
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	${({ theme }) =>
    theme.screens.xs &&
    css`
			flex-direction: column;
		`}
`;

const Title = styled(Typography.Text)`
	display: block;
	font-size: ${({ theme }) => theme.title.size.middle}px;
	/* ${({ theme }) =>
    theme.screens.xs &&
    css`
			font-size: ${({ theme }) => theme.title.size.small}px;
		`} */
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 15px;
	${({ theme }) =>
    theme.screens.xs &&
    css`
			flex-direction: column;
		`}
`;

const Label = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 10px;
	align-items: center;
	font-size: ${({ theme }) => theme.text.size.large}px;
	flex: 1 auto;
`;

const CheckIcon = styled(Icon)`
	color: ${({ theme }) => theme.colors.primary.base};
	font-size: ${({ theme }) => theme.text.size.large}px;
`;

const LabelDate = styled.div`
	grid-column-start: 1;
	font-size: ${({ theme }) => theme.text.size.small}px;
`;

const Info = styled.div`
	font-size: ${({ theme }) => theme.text.size.large}px;
	margin-bottom: 50px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`;
