/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';

export class CreditApi {
	static getCredits = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions: any = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');
			fetch(ApiURL('api/common/credits/current'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static update = ({ id, data }: { id: string; data: any }): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions = {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL(`api/common/credits/${id}`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static createInvoicedCredit = (data: any): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(data)
			};
			fetch(ApiURL(`api/common/invoices/credit`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static getCreditsByProperty = (propertyId: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			};
			fetch(
				ApiURL(`api/common/credits/current/${propertyId}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
