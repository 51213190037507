import { ICheckinState } from './interfaces';

export const initialState = {
	theme: 'light',
	loading: 'idle',
	checkInIntegrations: undefined,
	checkInRequiredFields: undefined,
	success: {
		createCheckInIntegration: false,
		createCheckInRequiredFields: false,
		findCheckInRequiredFields: false
	},
	error: {
		createCheckInIntegration: undefined,
		createCheckInRequiredFields: undefined,
		findCheckInRequiredFields: undefined
	}
} as ICheckinState;
