/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';
import { IRequestPaymentSecret, ISavePayment } from './start.interfaces';

export class hospyApi {
	static fetchApps = (accessToken: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(
				ApiURL(
					'api/common/apps?filter={"status":{"$in":["live","dev"]}}'
				),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchApp = (appId: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};
			fetch(
				ApiURL(`api/common/apps?filter={"_id": ObjectId("${appId}")}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchClientSecret = (
		{ email, name }: IRequestPaymentSecret,
		appId: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					email,
					name
				})
			};

			fetch(
				ApiURL(`api/third-party/stripe/customer/${appId}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static postSavePayment = (
		payment: ISavePayment,
		appId: string,
		propertyId: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({ ...payment, propertyId })
			};

			fetch(
				ApiURL(`api/third-party/stripe/payment/${appId}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
