/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetPlayslistsProps } from './interface';
import { getPlaylistsService, getPlaylistVideoService } from '@hospy/util-api';

export const getPlaylists = createAsyncThunk(
	'common/get-playlists',
	async (params: IGetPlayslistsProps, { rejectWithValue }) => {
		try {
			const response = await getPlaylistsService(params);
			if (response.status === 'success') return response;
			return rejectWithValue(response);
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const getPlaylistVideo = createAsyncThunk(
	'common/get-playlist-video',
	async (params: { _id: string }, { rejectWithValue }) => {
		try {
			const response = await getPlaylistVideoService({
				pathParams: params
			});
			if (response.status === 'success') return response;
			return rejectWithValue(response);
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
