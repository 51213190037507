/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon, Modal } from '@hospy/hospy-ui';
import { Form, Select, Typography } from 'antd';
import styled from 'styled-components';
import useFirstTimeTax from '../hooks/use-first-time-tax';

interface Props {
	open: boolean;
	closable: boolean;
	onClose: () => void;
	onFinish?: () => void;
}

export const FirstTimeTaxModal = ({
	onClose,
	open,
	closable,
	onFinish
}: Props) => {
	const {
		taxes,
		loadingTaxes,
		getDestinyTaxes,
		availableRetry,
		updatedInvoice,
		form,
		onSubmit,
		updatedFieldMapping
	} = useFirstTimeTax({ close: onClose, onFinish });

	return (
		<Modal onClose={onClose} open={open} size="small" closable={closable}>
			<ModalWrapper>
				<Title>Sincronizar impuestos con Siigo</Title>
				<FormWrapper
					onFinish={(values: any) => {
						onSubmit(values.tax);
					}}
					form={form}
				>
					<label>
						Selecciona el impuesto que usarás para las facturas en
						Siigo
					</label>
					<RetrySection>
						<Form.Item
							name="tax"
							rules={[
								{
									required: true,
									message: 'Campo requerido'
								}
							]}
						>
							<Select
								size="large"
								options={taxes as any[]}
								style={{ width: '100%' }}
								placeholder="Seleccionar el impuesto"
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '')
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								disabled={taxes?.length === 0 || loadingTaxes}
								loading={loadingTaxes}
							/>
						</Form.Item>
						{availableRetry.taxes && (
							<Button
								type="text"
								loading={loadingTaxes}
								onClick={() => getDestinyTaxes()}
							>
								{!loadingTaxes && (
									<Icon ant materialOutlined="sync" />
								)}
							</Button>
						)}
					</RetrySection>

					<ButtonSection>
						<Button
							block
							disabled={loadingTaxes}
							loading={
								updatedInvoice.loading === 'pending' ||
								updatedFieldMapping.loading === 'pending'
							}
							type="primary"
							htmlType="submit"
						>
							Guardar
						</Button>
					</ButtonSection>
				</FormWrapper>
			</ModalWrapper>
		</Modal>
	);
};

const FormWrapper = styled(Form)`
	input {
		padding: 0px;
		border: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.9);
	}
`;

const ButtonSection = styled.div`
	margin-top: 20px;
	width: 100%;
	padding-inline: 20%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

const RetrySection = styled.div`
	display: flex;
	width: 100%;
	> div:nth-child(1) {
		width: 100%;
	}

	.ant-form-item-explain-error {
		margin-top: 5px;
		margin-bottom: 40px;
	}

	button {
		width: 10%;
	}
`;

const Title = styled(Typography.Text)`
	text-align: center;
	font-size: ${({ theme }) => theme.title.size.middle}px;
`;

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export default FirstTimeTaxModal;
