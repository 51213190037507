import React from 'react';
import styled from 'styled-components';

interface IndeterminateLoading {
	style?: React.CSSProperties;
}
const IndeterminateLoading = ({ style }: IndeterminateLoading) => {
	return (
		<Container style={{ ...style }}>
			<Indeterminate />
		</Container>
	);
};
export default IndeterminateLoading;

const Container = styled.div`
	overflow: hidden;
	width: 100%;
	height: 4px;
	background-color: ${({ theme }) => theme.colors.primary.lighten['90%']};
`;

const Indeterminate = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		background-color: ${({ theme }) => theme.colors.primary.lighten['50%']};};
		animation: indeterminate_first 1.5s infinite ease-out;
	}

	&:after {
		content: '';
		position: absolute;
		height: 100%;
		background-color: ${({ theme }) => theme.colors.primary.lighten['60%']};
		animation: indeterminate_second 1.5s infinite ease-in;
	}

	@keyframes indeterminate_first {
		0% {
			left: -100%;
			width: 100%;
		}
		100% {
			left: 100%;
			width: 10%;
		}
	}

	@keyframes indeterminate_second {
		0% {
			left: -150%;
			width: 100%;
		}
		100% {
			left: 100%;
			width: 10%;
		}
	}
`;
