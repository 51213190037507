import lazyWithRetry from '../components/lazy-with-retry';

const Login = lazyWithRetry(() => import('../pages/login'));
const Register = lazyWithRetry(() => import('../pages/register'));
const VerificationCode = lazyWithRetry(
	() => import('../pages/verification-code')
);
const RecoverPassword = lazyWithRetry(
	() => import('../pages/recover-password')
);
const SocialResponse = lazyWithRetry(() => import('../pages/social-response'));
const ExpiredPassword = lazyWithRetry(
	() => import('../pages/expired-password')
);
const Account = lazyWithRetry(() => import('../pages/account'));
const WaitList = lazyWithRetry(() => import('../pages/wait-list'));
const ManageProperties = lazyWithRetry(
	() => import('../pages/manage-properties')
);
const WorkTeam = lazyWithRetry(() => import('../pages/workteam'));
const CreateSubscription = lazyWithRetry(
	() => import('../pages/suscription-active')
);
const Subscriptions = lazyWithRetry(() => import('../pages/subscriptions'));
const PaymentsMethods = lazyWithRetry(
	() => import('../pages/subscriptions/pages/payments-methods')
);
const Expired = lazyWithRetry(() => import('../pages/expired'));
const Blocked = lazyWithRetry(() => import('../pages/blocked'));

const UpdateDefaultPaymentMethod = lazyWithRetry(
	() => import('../pages/subscriptions/pages/payments-methods/update')
);

const CreateCredits = lazyWithRetry(() => import('../pages/credits-add'));
const CreditsPaymetsMethods = lazyWithRetry(
	() => import('../pages/credits-add/pages/add-method')
);
const Credits = lazyWithRetry(() => import('../pages/credits'));

export const accountRoute = [
	{
		path: 'login',
		component: Login
	},
	{
		path: 'register',
		component: Register
	},
	{
		path: 'verification-code/:username',
		component: VerificationCode
	},
	{
		path: 'recover-password',
		component: RecoverPassword
	},
	{
		path: 'social/google/:processType',
		component: SocialResponse
	}
];

export const internalRoutes = [
	{
		path: 'expired-password',
		component: ExpiredPassword
	},
	{
		path: 'account',
		component: Account
	},
	// {
	// 	path: 'wait-list',
	// 	component: WaitList
	// },
	{
		path: 'manage-properties',
		component: ManageProperties
	},
	{
		path: 'workteam',
		component: WorkTeam
	},
	{
		path: 'subscriptions',
		component: Subscriptions
	},
	{
		path: 'subscriptions/create',
		component: CreateSubscription
	},
	{
		path: 'subscriptions/payments-methods/create',
		component: PaymentsMethods
	},
	{
		path: 'expired',
		component: Expired
	},
	{
		path: 'blocked',
		component: Blocked
	},
	{
		path: 'subscriptions/payments-methods/update',
		component: UpdateDefaultPaymentMethod
	},
	{
		path: 'credits/create',
		component: CreateCredits
	},
	{
		path: 'credits',
		component: Credits
	},
	{
		path: 'credits/payments-methods/create',
		component: CreditsPaymetsMethods
	}
];
