/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
  hubspotGetFieldMapping,
  hubspotGetLogs,
  hubspotGetProperties,
  hubspotUpdateFieldMapping,
  hubspotGetPipelines,
  hubspotGetPropertyDeals
} from './thunks';

export const hubspotSlice = createSlice({
  name: 'hubspot',
  initialState: initialState,
  reducers: {
    hubspotSetFieldMapping: (state, { payload }) => {
      state.fieldMapping = {
        loading: 'succeeded',
        error: undefined,
        data: payload
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(hubspotGetFieldMapping.pending, (state) => {
        state.fieldMapping = {
          loading: 'pending',
          error: undefined,
          data: undefined
        };
      })
      .addCase(hubspotGetFieldMapping.rejected, (state, { payload }) => {
        state.fieldMapping = {
          loading: 'failed',
          error: String(payload),
          data: undefined
        };
      })
      .addCase(hubspotGetFieldMapping.fulfilled, (state, { payload }) => {
        state.fieldMapping = {
          loading: 'succeeded',
          error: undefined,
          data: payload
        };
      });

    builder
      .addCase(hubspotUpdateFieldMapping.pending, (state) => {
        state.updatedFieldMapping = {
          loading: 'pending',
          error: undefined
        };
      })
      .addCase(
        hubspotUpdateFieldMapping.rejected,
        (state, { payload }) => {
          state.updatedFieldMapping = {
            loading: 'failed',
            error: payload as string
          };
        }
      )
      .addCase(
        hubspotUpdateFieldMapping.fulfilled,
        (state, { payload }) => {
          if (state.fieldMapping.data?.fields) {
            let fields = current(state.fieldMapping?.data.fields);
            if (payload.updateMode === 'pull') {
              fields = fields.filter(
                (e: any) => !payload.items.includes(e._id)
              );
            } else if (payload.updateMode === 'push') {
              fields = payload.items.fields;
            } else {
              fields = fields.map((e: any) => {
                const el = payload.items.find(
                  (i: any) => i._id === e._id
                );
                if (el) {
                  return { ...e, ...el };
                }
                return { ...e };
              });
            }
            state.fieldMapping.data.fields = fields;
            state.updatedFieldMapping = {
              loading: 'succeeded',
              error: undefined
            };
          }
        }
      );
    builder
      .addCase(hubspotGetProperties.pending, (state) => {
        state.properties = {
          loading: 'pending',
          error: undefined,
          data: undefined
        };
      })
      .addCase(hubspotGetProperties.rejected, (state, { payload }) => {
        state.properties = {
          loading: 'failed',
          error: String(payload),
          data: undefined
        };
      })
      .addCase(hubspotGetProperties.fulfilled, (state, { payload }) => {
        state.properties = {
          loading: 'succeeded',
          error: undefined,
          data: payload
        };
      });

    builder
      .addCase(hubspotGetPropertyDeals.pending, (state) => {
        state.properties = {
          loading: 'pending',
          error: undefined,
          data: undefined
        };
      })
      .addCase(hubspotGetPropertyDeals.rejected, (state, { payload }) => {
        state.propertyDeals = {
          loading: 'failed',
          error: String(payload),
          data: undefined
        };
      })
      .addCase(hubspotGetPropertyDeals.fulfilled, (state, { payload }) => {
        state.propertyDeals = {
          loading: 'succeeded',
          error: undefined,
          data: payload
        };
      });
    builder
      .addCase(hubspotGetPipelines.pending, (state) => {
        state.pipelines = {
          loading: 'pending',
          error: undefined,
          data: undefined
        };
      })
      .addCase(hubspotGetPipelines.rejected, (state, { payload }) => {
        state.pipelines = {
          loading: 'failed',
          error: String(payload),
          data: undefined
        };
      })
      .addCase(hubspotGetPipelines.fulfilled, (state, { payload }) => {
        state.pipelines = {
          loading: 'succeeded',
          error: undefined,
          data: payload
        };
      });

    builder
      .addCase(hubspotGetLogs.pending, (state) => {
        state.logs = {
          loading: 'pending',
          error: undefined,
          data: undefined
        };
      })
      .addCase(hubspotGetLogs.rejected, (state, { payload }) => {
        state.logs = {
          loading: 'failed',
          error: String(payload),
          data: undefined
        };
      })
      .addCase(hubspotGetLogs.fulfilled, (state, { payload }) => {
        state.logs = {
          loading: 'succeeded',
          error: undefined,
          data: payload
        };
      });
  }
});

export const { hubspotSetFieldMapping } = hubspotSlice.actions;
