/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { initialState } from './onboarding.constants';
import {
	createOnboarding,
	deleteOnboarding,
	getFieldMapping,
	getOnboarding,
	initOnboarding,
	resetOnboarding,
	updateFieldMapping,
	updateFieldMappingV2,
	updateOnboarding
} from './onboarding.thunks';

export const onboardingSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		clearDiscounts: (state) => {
			state.onboardings = initialState.onboardings;
		},
		clearUpdateFieldMapping: (state) => {
			state.updatedFieldMapping = initialState.updatedFieldMapping;
		},
		clearFieldMapping: (state) => {
			state.updatedFieldMapping = initialState.updatedFieldMapping;
			state.fieldMapping = initialState.fieldMapping;
		},
		clearResetedOnboarding: (state) => {
			state.resetedOnboarding = initialState.resetedOnboarding;
		},
		clearOnboarding: (state) => {
			state.onboardings = initialState.onboardings;
		},
		clearOthersFieldMapping: (state) => {
			if (!state.fieldMapping.data) return;
			state.fieldMapping.data.fields =
				state.fieldMapping.data.fields.filter(
					(fieldMapping) => fieldMapping.groupName !== 'others'
				);
		},
		setManualFieldMapping: (state, { payload }) => {
			state.fieldMapping.data = payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getOnboarding.pending, (state, action) => {
				state.onboardings.loading = 'pending';
			})
			.addCase(getOnboarding.fulfilled, (state, { payload }) => {
				state.onboardings.loading = 'succeeded';
				state.onboardings.data = payload;
			})
			.addCase(getOnboarding.rejected, (state, { payload }) => {
				state.onboardings.loading = 'failed';
				state.onboardings.error = payload as string;
			});

		builder
			.addCase(createOnboarding.pending, (state, action) => {
				state.newOnboarding.loading = 'pending';
			})
			.addCase(createOnboarding.fulfilled, (state, { payload }) => {
				state.newOnboarding.loading = 'succeeded';
				state.newOnboarding.data = payload;
				state.onboardings.data = payload;
			})
			.addCase(createOnboarding.rejected, (state, { payload }) => {
				state.newOnboarding.loading = 'failed';
				state.newOnboarding.error = payload as string;
			});

		builder
			.addCase(updateOnboarding.pending, (state, action) => {
				state.updatedOnboarding.loading = 'pending';
			})
			.addCase(updateOnboarding.fulfilled, (state, { payload }) => {
				state.updatedOnboarding.loading = 'succeeded';
				state.updatedOnboarding.data = payload;
				state.onboardings.data = payload;
			})
			.addCase(updateOnboarding.rejected, (state, { payload }) => {
				state.updatedOnboarding.loading = 'failed';
				state.updatedOnboarding.error = payload as string;
			});

		builder
			.addCase(resetOnboarding.pending, (state, action) => {
				state.resetedOnboarding.loading = 'pending';
			})
			.addCase(resetOnboarding.fulfilled, (state, { payload }) => {
				state.resetedOnboarding.loading = 'succeeded';
				state.resetedOnboarding.data = payload;
				state.onboardings.data = payload;
			})
			.addCase(resetOnboarding.rejected, (state, { payload }) => {
				state.resetedOnboarding.loading = 'failed';
				state.resetedOnboarding.error = payload as string;
			});

		builder
			.addCase(getFieldMapping.pending, (state, action) => {
				state.fieldMapping.loading = 'pending';
			})
			.addCase(getFieldMapping.fulfilled, (state, { payload }) => {
				state.fieldMapping.loading = 'succeeded';
				state.fieldMapping.data = payload;
			})
			.addCase(getFieldMapping.rejected, (state, { payload }) => {
				state.fieldMapping.loading = 'failed';
				state.fieldMapping.error = payload as string;
			});

		builder
			.addCase(updateFieldMapping.pending, (state) => {
				state.updatedFieldMapping.loading = 'pending';
			})
			.addCase(updateFieldMapping.fulfilled, (state, { payload }) => {
				state.updatedFieldMapping.loading = 'succeeded';
				state.updatedFieldMapping.data = payload;
				if (state.fieldMapping.data?.fields)
					state.fieldMapping.data.fields = payload.fields;
			})
			.addCase(updateFieldMapping.rejected, (state, { payload }) => {
				state.updatedFieldMapping.loading = 'failed';
				state.updatedFieldMapping.error = payload as string;
			});

		builder
			.addCase(updateFieldMappingV2.pending, (state) => {
				state.updatedFieldMapping.loading = 'pending';
			})
			.addCase(updateFieldMappingV2.fulfilled, (state, { payload }) => {
				if (state.fieldMapping.data?.fields) {
					let fields = current(state.fieldMapping?.data.fields);
					if (payload.updateMode === 'pull') {
						fields = fields.filter(
							(e) =>
								!payload.items
									.map((e: any) => e._id)
									.includes(e._id)
						);
					} else if (payload.updateMode === 'push') {
						fields = payload.items.fields;
					} else {
						if (payload.items[0]?._parentId) {
							fields = fields.map((e) => {
								const el = payload.items.find(
									(i: any) => i._parentId === e._id
								);

								if (el) {
									const newExtras = e.extras?.map(
										(i: any) => {
											if (el._id === i._id) {
												delete el._parentId;
												return { ...i, ...el };
											}

											return { ...i };
										}
									);
									return { ...e, ...el, extras: newExtras };
								}
								return { ...e };
							});
						} else {
							fields = fields.map((e) => {
								const el = payload.items.find(
									(i: any) => i._id === e._id
								);

								if (el) {
									return { ...e, ...el };
								}
								return { ...e };
							});
						}
					}
					state.fieldMapping.data.fields = fields;
					state.updatedFieldMapping.loading = 'succeeded';
				}
			})
			.addCase(updateFieldMappingV2.rejected, (state, { payload }) => {
				state.updatedFieldMapping.loading = 'failed';
				state.updatedFieldMapping.error = payload as string;
			});

		builder
			// .addCase(deleteOnboarding.pending, (state) => {})
			// .addCase(deleteOnboarding.rejected, (state) => {})
			.addCase(deleteOnboarding.fulfilled, (state) => {
				state.onboardings = {
					loading: 'idle',
					data: undefined,
					error: undefined
				};
			});

		builder
			.addCase(initOnboarding.pending, (state) => {
				state.onboardings.loading = 'pending';
			})
			.addCase(initOnboarding.fulfilled, (state, { payload }) => {
				state.onboardings.loading = 'succeeded';
				state.onboardings.data = payload;
			})
			.addCase(initOnboarding.rejected, (state, { payload }) => {
				state.onboardings.loading = 'failed';
				state.onboardings.error = payload as string;
			});
	}
});

export const {
	clearDiscounts: clearGetOnboarding,
	clearResetedOnboarding,
	clearOnboarding,
	clearFieldMapping,
	clearUpdateFieldMapping,
	clearOthersFieldMapping,
	setManualFieldMapping
} = onboardingSlice.actions;
