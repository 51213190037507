/* eslint-disable @typescript-eslint/no-explicit-any */
import { defaultLanguage } from '@hospy/store';

interface ILanguageRender {
	locale: string;
	name: any | string;
}

export const LanguageTextRender = ({ locale, name }: ILanguageRender) => {
	if (!name) return '';
	if (typeof name === 'string') return name;

	if (name[locale]) return name[locale];

	return name[defaultLanguage];
};
