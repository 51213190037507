/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommonState } from './common.interfaces';

export const initialState = {
	appData: undefined,
	fullWidth: false,
	realtime: {
		app: null,
		client: null,
		user: null
	},
	currencyRate: {
		loading: 'idle',
		data: undefined
	},
	currencyRates: {
		loading: 'idle',
		data: undefined
	},
	countries: {
		loading: 'idle'
	},
	quotation: {
		loading: 'idle'
	}
} as ICommonState;

export const sireDocumentTypes = [
	{
		value: '52',
		label: 'Permiso por protección temporal'
	},
	{
		value: '1',
		label: 'Cédula de ciudadanía'
	},
	{
		value: '5',
		label: 'Cédula de extranjería'
	},
	{
		value: '46',
		label: 'Carné diplomático'
	},
	{
		value: '3',
		label: 'Pasaporte'
	},
	{
		value: '55',
		label: 'PEP'
	},
	{
		value: '10',
		label: 'Documento extranjero'
	}
];

export const LngToCountryIso2: any = {
	es: 'es',
	pt: 'pt',
	en: 'us'
};

export const propertyType = [
	{ value: 'hotel', label: 'Hotel / Hostal' },
	{ value: 'short rental', label: 'Alquiler vacacional' }
];

export const appStatus = [
	{ label: 'Suscrito', value: 'subscribed' },
	{ label: 'Bloqueado', value: 'block' }
];
