/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { SAT_INVOICE_DETAIL_SECTIONS, satInitialState } from './sat.constants';
import {
	satGetAccommodations,
	satGetFieldMapping,
	satGetInvoiceReport,
	satGetPaymentMethods,
	satGetProducts,
	satGetPublicStamp,
	satGetStamp,
	satGetStampErrors,
	satGetSummaryReport,
	satGetTaxes,
	satGetUnits,
	satInvoiceCancelStamp,
	satInvoiceStamp,
	satSearchPublicReservation,
	satSelfInvoiceStamp,
	satUpdateFieldMapping,
	satUpdateInvoiceError,
	satUpdateInvoiceStamp
} from './sat.thunks';

export const satSlice = createSlice({
	name: 'sat',
	initialState: satInitialState,
	reducers: {
		satResetFieldMapping: (state) => {
			state.fieldMapping = satInitialState.fieldMapping;
		},
		satSetFieldMapping: (state, { payload }) => {
			state.fieldMapping = {
				loading: 'succeeded',
				error: undefined,
				data: payload
			};
		},
		satUpdateInvoiceValidation: (state, { payload }) => {
			state.invoiceValidation = payload;
		},
		satResetInvoiceValidation: (state) => {
			state.invoiceValidation = SAT_INVOICE_DETAIL_SECTIONS;
		},
		satResetUpdateStampInvoice: (state) => {
			state.updateStampInvoice = {
				loading: 'idle',
				error: undefined
			};
		},
		satSetStamp: (state, { payload }) => {
			state.stamp = payload;
		},
		satSetPublicInvoice: (state, { payload }) => {
			state.publicReservation.data = payload;
		},
		satResetInvoiceCancelStamp: (state) => {
			state.cancelStampInvoice = satInitialState.cancelStampInvoice;
		},
		satRestarPublicInvoice: (state) => {
			state.publicReservation = satInitialState.publicReservation;
		},
		satRestartUpdateFieldMapping: (state) => {
			state.updatedFieldMapping.loading =
				satInitialState.updatedFieldMapping.loading;
		},
		satResetStamp: (state) => {
			state.stamp = satInitialState.stamp;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(satGetPaymentMethods.pending, (state, { meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'paymentMethodsInternal'
						: 'paymentMethodsExternal'
				] = {
					loading: 'pending',
					error: undefined,
					data: []
				};
			})
			.addCase(
				satGetPaymentMethods.rejected,
				(state, { payload, meta }) => {
					state[
						meta.arg.type === 'internal'
							? 'paymentMethodsInternal'
							: 'paymentMethodsExternal'
					] = {
						loading: 'failed',
						error: String(payload),
						data: []
					};
				}
			)
			.addCase(
				satGetPaymentMethods.fulfilled,
				(state, { payload, meta }) => {
					state[
						meta.arg.type === 'internal'
							? 'paymentMethodsInternal'
							: 'paymentMethodsExternal'
					] = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(satGetTaxes.pending, (state, { meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'taxesInternal'
						: 'taxesExternal'
				] = {
					loading: 'pending',
					error: undefined,
					data: []
				};
			})
			.addCase(satGetTaxes.rejected, (state, { payload, meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'taxesInternal'
						: 'taxesExternal'
				] = {
					loading: 'failed',
					error: String(payload),
					data: []
				};
			})
			.addCase(satGetTaxes.fulfilled, (state, { payload, meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'taxesInternal'
						: 'taxesExternal'
				] = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(satGetAccommodations.pending, (state, { meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'accommodationsInternal'
						: 'accommodationsExternal'
				] = {
					loading: 'pending',
					error: undefined,
					data: []
				};
			})
			.addCase(
				satGetAccommodations.rejected,
				(state, { payload, meta }) => {
					state[
						meta.arg.type === 'internal'
							? 'accommodationsInternal'
							: 'accommodationsExternal'
					] = {
						loading: 'failed',
						error: String(payload),
						data: []
					};
				}
			)
			.addCase(
				satGetAccommodations.fulfilled,
				(state, { payload, meta }) => {
					state[
						meta.arg.type === 'internal'
							? 'accommodationsInternal'
							: 'accommodationsExternal'
					] = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(satGetProducts.pending, (state, { meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'productsInternal'
						: 'productsExternal'
				] = {
					loading: 'pending',
					error: undefined,
					data: []
				};
			})
			.addCase(satGetProducts.rejected, (state, { payload, meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'productsInternal'
						: 'productsExternal'
				] = {
					loading: 'failed',
					error: String(payload),
					data: []
				};
			})
			.addCase(satGetProducts.fulfilled, (state, { payload, meta }) => {
				const filterTypes: Record<string, any> = {
					internal: 'productsInternal',
					external: 'productsExternal',
					all: 'productsAll'
				};
				const filterType: keyof typeof satInitialState =
					filterTypes[meta.arg.type || 'all'];

				state[filterType] = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				} as any;
			});

		builder
			.addCase(satGetFieldMapping.pending, (state) => {
				state.fieldMapping = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(satGetFieldMapping.rejected, (state, { payload }) => {
				state.fieldMapping = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(satGetFieldMapping.fulfilled, (state, { payload }) => {
				state.fieldMapping = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(satUpdateFieldMapping.pending, (state) => {
				state.updatedFieldMapping = {
					loading: 'pending',
					error: undefined
				};
			})
			.addCase(satUpdateFieldMapping.rejected, (state, { payload }) => {
				state.updatedFieldMapping = {
					loading: 'failed',
					error: payload as string
				};
			})
			.addCase(satUpdateFieldMapping.fulfilled, (state, { payload }) => {
				if (state.fieldMapping.data?.fields) {
					let fields = current(state.fieldMapping?.data.fields);
					if (payload.updateMode === 'pull') {
						fields = fields.filter(
							(e: any) => !payload.items.includes(e._id)
						);
					} else if (payload.updateMode === 'push') {
						fields = payload.items.fields;
					} else {
						fields = fields.map((e: any) => {
							const el = payload.items.find(
								(i: any) => i._id === e._id
							);
							if (el) {
								return { ...e, ...el };
							}
							return { ...e };
						});
					}
					state.fieldMapping.data.fields = fields;
					state.updatedFieldMapping = {
						loading: 'succeeded',
						error: undefined
					};
				}
			});

		builder
			.addCase(satGetStamp.pending, (state) => {
				state.stamp.loading = 'pending';
			})
			.addCase(satGetStamp.rejected, (state, { payload }) => {
				state.stamp.loading = 'failed';
				state.stamp.error = payload;
			})
			.addCase(satGetStamp.fulfilled, (state, { payload }) => {
				state.stamp.loading = 'succeeded';
				state.stamp.data = payload;
			});

		builder
			.addCase(satInvoiceStamp.pending, (state) => {
				state.stamp.loading = 'pending';
			})
			.addCase(satInvoiceStamp.rejected, (state, { payload }) => {
				state.stamp.loading = 'failed';
				state.stamp.error = payload;
			})
			.addCase(satInvoiceStamp.fulfilled, (state, { payload }) => {
				state.stamp.loading = 'succeeded';
				state.stamp.data = payload;
			});

		builder
			.addCase(satUpdateInvoiceStamp.pending, (state) => {
				state.updateStampInvoice = {
					loading: 'pending',
					error: undefined
				};
			})
			.addCase(satUpdateInvoiceStamp.rejected, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(satUpdateInvoiceStamp.fulfilled, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'succeeded',
					error: undefined
				};
			});

		builder
			.addCase(satGetStampErrors.pending, (state) => {
				state.stampErrors = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(satGetStampErrors.rejected, (state, { payload }) => {
				state.stampErrors = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(satGetStampErrors.fulfilled, (state, { payload }) => {
				state.stampErrors = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(satUpdateInvoiceError.pending, (state) => {
				state.updateStampInvoice = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(satUpdateInvoiceError.rejected, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(satUpdateInvoiceError.fulfilled, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(satInvoiceCancelStamp.pending, (state) => {
				state.cancelStampInvoice = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(satInvoiceCancelStamp.rejected, (state, { payload }) => {
				state.cancelStampInvoice = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(satInvoiceCancelStamp.fulfilled, (state, { payload }) => {
				state.cancelStampInvoice = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(satSearchPublicReservation.pending, (state) => {
				state.publicReservation = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				satSearchPublicReservation.rejected,
				(state, { payload }) => {
					state.publicReservation = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				satSearchPublicReservation.fulfilled,
				(state, { payload }) => {
					state.publicReservation = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(satSelfInvoiceStamp.pending, (state) => {
				state.selfStamp = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(satSelfInvoiceStamp.rejected, (state, { payload }) => {
				state.selfStamp = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(satSelfInvoiceStamp.fulfilled, (state, { payload }) => {
				state.selfStamp = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
				state.publicReservation.data = payload;
			});

		builder
			.addCase(satGetPublicStamp.pending, (state) => {
				state.publicReservation = {
					loading: 'pending',
					error: state.publicReservation.error,
					data: state.publicReservation.data
				};
			})
			.addCase(satGetPublicStamp.rejected, (state, { payload }) => {
				state.publicReservation = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(satGetPublicStamp.fulfilled, (state, { payload }) => {
				state.publicReservation = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(satGetInvoiceReport.pending, (state) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'pending'
				};
			})
			.addCase(satGetInvoiceReport.rejected, (state, { payload }) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(satGetInvoiceReport.fulfilled, (state, { payload }) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'succeeded',
					data: payload?.paginating
						? [
								...state.invoiceReports.data,
								...(payload?.data || [])
						  ]
								.filter((item, index, self) => {
									return (
										self.findIndex(
											(i) => i._id === item._id
										) === index
									);
								})
								.sort((a, b) => {
									const fechaA = new Date(
										a.thirdPartyPayload.Date
									).getTime();
									const fechaB = new Date(
										b.thirdPartyPayload.Date
									).getTime();

									if (fechaA > fechaB) {
										return -1;
									} else if (fechaA < fechaB) {
										return 1;
									} else {
										return 0;
									}
								})
						: payload?.data,
					meta: payload?.meta
				};
			});

		builder
			.addCase(satGetSummaryReport.pending, (state) => {
				state.summaryReports = {
					...state.summaryReports,
					loading: 'pending'
				};
			})
			.addCase(satGetSummaryReport.rejected, (state, { payload }) => {
				state.summaryReports = {
					...state.summaryReports,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(satGetSummaryReport.fulfilled, (state, { payload }) => {
				state.summaryReports = {
					loading: 'succeeded',
					data: payload?.data,
					meta: payload?.meta
				};
			});

		builder
			.addCase(satGetUnits.pending, (state, { meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'unitsInternal'
						: 'unitsExternal'
				] = {
					loading: 'pending',
					error: undefined,
					data: []
				};
			})
			.addCase(satGetUnits.rejected, (state, { payload, meta }) => {
				state[
					meta.arg.type === 'internal'
						? 'unitsInternal'
						: 'unitsExternal'
				] = {
					loading: 'failed',
					error: String(payload),
					data: []
				};
			})
			.addCase(satGetUnits.fulfilled, (state, { payload, meta }) => {
				const filterTypes: Record<string, any> = {
					internal: 'unitsInternal',
					external: 'unitsExternal',
					all: 'unitsAll'
				};
				const filterType: keyof typeof satInitialState =
					filterTypes[meta.arg.type || 'all'];

				state[filterType] = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				} as any;
			});
	}
});

export const {
	satSetFieldMapping,
	satUpdateInvoiceValidation,
	satResetInvoiceValidation,
	satResetUpdateStampInvoice,
	satSetStamp,
	satSetPublicInvoice,
	satResetInvoiceCancelStamp,
	satRestarPublicInvoice,
	satRestartUpdateFieldMapping,
	satResetStamp,
	satResetFieldMapping
} = satSlice.actions;
