/* eslint-disable @typescript-eslint/no-explicit-any */
import { logOut, useAppDispatch, useAppSelector } from '@hospy/store';
import { validateEmailExist } from '@hospy/util-api';
import { cognitoService } from '@hospy/util-auth';
import { message } from 'antd';
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLogin from '../../login/hook/use-login';
import useRegister from '../../register/hook/use-register';
import { userApi } from '../../../../../../store/src/lib/user/userAPI';

export type ProcessType = 'login' | 'register' | 'logout' | undefined;

const key = CryptoJS.enc.Hex.parse('36ebe205bcdfc499a25e6923f4450fa8');
const iv = CryptoJS.enc.Hex.parse('be410fea41df7162a679875ec131cf2c');

export interface IUseSocialResponse {
	getToken: (code: string, processType: string) => Promise<any>;
	getUserInfo: (access_token: string) => Promise<any>;
	processAccessWithGoogle: (process: ProcessType) => Promise<void>;
	logOutSocial: () => void;
	linkedUser: boolean;
	errorDescription: string;
}

let processed = false;

interface Props {
	processType?: ProcessType;
}

const defaultProps: Props = {
	processType: undefined
};

const useSocialResponse = ({
	processType
}: Props | undefined = defaultProps): IUseSocialResponse => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { register } = useRegister();
	const { login } = useLogin();

	const { search, state } = useLocation();
	const data = React.useMemo(() => new URLSearchParams(search), [search]);
	const { appName } = useAppSelector(({ user }) => user);

	const [linkedUser, setLinkedUser] = useState(false);
	const [errorDescription, setErrorDescription] = useState('');

	useEffect(() => {
		if (processType === undefined) return;
		if (processType === 'logout') {
			const fetch = async () => {
				dispatch(logOut());
				await cognitoService.signOut();
				if (appName === 'MAIN') {
					navigate('/', { replace: true });
				} else {
					navigate('/login', { replace: true });
				}
			};
			fetch();
			return;
		} else {
			const code: string = data.get('code') || '';
			const state: string = data.get('state') || '';

			const _err_description = data.get('error_description') || '';
			if (_err_description.includes('Linked User')) {
				setLinkedUser(true);
				return;
			} else {
				setErrorDescription(_err_description);
			}

			if (code && !processed) {
				fnProcess(code, processType, state);
				processed = true;
			}
		}
	}, []);

	const fnProcess = async (
		code: string,
		processType: string,
		state?: string
	) => {
		try {
			const { access_token, refresh_token } = await getToken(
				code,
				processType
			);
			const {
				email,
				username,
				sub: userSub,
				name
			} = await getUserInfo(access_token);

			await cognitoService.generateSessionByGoogleAuth(
				refresh_token,
				email
			);

			const isSiteAdmin = process.env['NX_APP_ADMIN']?.includes(
				window.location.origin
			);
			const isSiteDeveloper = process.env['NX_APP_DEVELOPER']?.includes(
				window.location.origin
			);

			if (isSiteAdmin || isSiteDeveloper) {
				const prefix = isSiteAdmin ? 'admin' : 'developer';
				const isValidRol = await cognitoService.isRolUser({
					Name: `custom:${prefix}-app-acl`,
					Value: prefix
				});

				if (!isValidRol) {
					message.error(`No tiene accesos de ${prefix}`);
					await cognitoService.signOut();
					navigate('/login');
					return;
				}
			}

			const user = await validateEmailExist(email);

			let stateDataDecrypt: any = {};
			if (state) {
				const decrypted = CryptoJS.AES.decrypt(
					decodeURIComponent(state),
					key,
					{
						iv,
						mode: CryptoJS.mode.CBC,
						padding: CryptoJS.pad.Pkcs7
					}
				);
				stateDataDecrypt = JSON.parse(
					decrypted.toString(CryptoJS.enc.Utf8)
				);
			}

			if (!user) {
				await register({
					name,
					email,
					userConfirmed: true,
					userSub,
					cognitoUsername: username
				});
				message.success(
					'Tu cuenta se creó exitosamente con tus credenciales de Google'
				);
			}

			if (user?.temporalPassword && user.userSub === '') {
				await userApi.fetchUpdatePreSavedUser({
					email: email,
					userConfirmed: true,
					userSub
				});
			}

			await login(stateDataDecrypt);
		} catch (error) {
			console.error(
				'🚀 ~ file: index.tsx ~ line 49 ~ fnProcess ~ error',
				error
			);
			navigate('/register');
		}
	};

	const getToken = (code: string, processType: string) =>
		new Promise<any>((resolve: any, reject: any) => {
			const code_verifier = localStorage.getItem('code_verifier');
			localStorage.removeItem('code_verifier');
			const formData: any = {
				grant_type: 'authorization_code',
				client_id: process.env['NX_COGNITO_CLIENT_ID'] || '',
				redirect_uri: `${window.location.protocol}//${window.location.host}/social/google/${processType}`,
				code_verifier,
				code,
				scope: 'openid profile phone aws.cognito.signin.user.admin'
			};

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: Object.keys(formData)
					.map(
						(key) =>
							encodeURIComponent(key) +
							'=' +
							encodeURIComponent(formData[key])
					)
					.join('&')
			};

			fetch(
				`https://${
					process.env['NX_COGNITO_APP_DOMAIN'] || ''
				}/oauth2/token`,
				requestOptions
			)
				.then((response) => response.json())
				.then((rs) => resolve(rs))
				.catch((err) => reject(err));
		});

	const getUserInfo = (access_token: string) =>
		new Promise<any>((resolve: any, reject: any) => {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`
				}
			};

			fetch(
				`https://${
					process.env['NX_COGNITO_APP_DOMAIN'] || ''
				}/oauth2/userInfo`,
				requestOptions
			)
				.then((response) => response.json())
				.then((rs) => resolve(rs))
				.catch((err) => reject(err));
		});

	const makeid = (length: number) => {
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	};

	const PROTOCOL = 'SHA-256';
	const encryptStringWithSHA256 = async (str: any) => {
		return crypto.subtle.digest(PROTOCOL, new TextEncoder().encode(str));
	};

	const hashToBase64url = (arrayBuffer: any) => {
		const items = new Uint8Array(arrayBuffer);
		const stringifiedArrayHash = items.reduce(
			(acc, i) => `${acc}${String.fromCharCode(i)}`,
			''
		);
		const decodedHash = btoa(stringifiedArrayHash);
		const base64URL = decodedHash
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=+$/, '');
		return base64URL;
	};

	const processAccessWithGoogle = async (processType: ProcessType) => {
		const code_verifier = makeid(32);
		const arrayHash = await encryptStringWithSHA256(code_verifier);
		const code_challenge = hashToBase64url(arrayHash);

		localStorage.setItem(`Hospy-isAuth`, 'true');
		localStorage.setItem('type_login', 'GOOGLE');
		localStorage.setItem('code_verifier', code_verifier);

		const params = [
			`response_type=code`,
			`client_id=${process.env['NX_COGNITO_CLIENT_ID'] || ''}`,
			`redirect_uri=${window.location.protocol}//${window.location.host}/social/google/${processType}`,
			`identity_provider=Google`,
			`code_challenge_method=S256`,
			`code_challenge=${code_challenge}`,
			`scope=openid+profile+phone+aws.cognito.signin.user.admin`
		];

		if (state?.activateApp) {
			const { activateApp, pathname, search } = state;
			const d: string = JSON.stringify({ activateApp, pathname, search });
			const encrypted = CryptoJS.AES.encrypt(d, key, {
				iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			});

			params.push(`state=${encodeURIComponent(encrypted.toString())}`);
		}

		window.location.href = `https://${
			process.env['NX_COGNITO_APP_DOMAIN'] || ''
		}/oauth2/authorize?${params.join('&')}`;
	};

	const logOutSocial = () => {
		localStorage.setItem(`Hospy-isAuth`, 'false');

		const params = [
			`client_id=${process.env['NX_COGNITO_CLIENT_ID'] || ''}`,
			`logout_uri=${window.location.protocol}//${window.location.host}/social/google/logout`
		];
		window.location.href = `https://${
			process.env['NX_COGNITO_APP_DOMAIN'] || ''
		}/logout?${params.join('&')}`;
	};

	return {
		getToken,
		getUserInfo,
		processAccessWithGoogle,
		logOutSocial,
		linkedUser,
		errorDescription
	};
};
export default useSocialResponse;
