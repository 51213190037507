import { Image } from 'antd';

import IconColor from './../../../../assets/images/icon.png';
import IconWhite from './../../../../assets/images/icon_white.png';

import logoDark from './../../../../assets/logos/logo-hospy-dark.png';
import logoLight from './../../../../assets/logos/logo-hospy-light.png';

export function LogoIcon(props: any) {
	// const { currentTheme } = useThemeSwitcher();
	const currentTheme = props?.theme || 'light';

	return (
		<Image
			width={120}
			{...props}
			preview={false}
			src={currentTheme === 'light' ? logoLight : logoDark}
		/>
	);
}

export const HospyIcon = (props: any) => (
	<Image
		{...props}
		preview={false}
		src={props.color ? IconColor : IconWhite}
	/>
);
