import { useAppSelector } from '@hospy/store';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { internalRoutes } from '../routes';

export const useValidateOnboardingApps = () => {
	const navigate = useNavigate();
	const {
		propertyIntegrationsState,
		property,
		loading: loadingProperty
	} = useAppSelector(({ user }) => user);
	const { appData } = useAppSelector(({ common }) => common);
	const { pathname } = useLocation();

	useEffect(() => {
		if (propertyIntegrationsState.loading === 'succeeded') {
			validate();
		}
	}, [
		propertyIntegrationsState.loading,
		pathname,
		property?._id,
		loadingProperty,
		appData?._id
	]);

	const validate = useCallback(() => {
		if (pathname === 'expired' || pathname === 'blocked') return;
		if (internalRoutes.find((e) => pathname.match(new RegExp(e.path)))) {
			return;
		}

		if (propertyIntegrationsState.loading !== 'succeeded') return;

		//TODO: Validar que la integración sea la del app consultado
		if ((propertyIntegrationsState?.data || [])?.length === 0) {
			navigate('/onboarding');
			return;
		}

		if (property && appData) {
			const { apps } = property;
			const app = apps.find((e) => e._id === appData._id);
			const isIntegrationComplete = app?.integrationMode !== 'complete';

			if (isIntegrationComplete) {
				navigate('/onboarding');
				return;
			}

			if (pathname === '/onboarding') {
				navigate('/');
				return;
			}
		}
	}, [
		propertyIntegrationsState.loading,
		pathname,
		property?._id,
		appData?._id,
		loadingProperty
	]);

	return {
		validate,
		loading: propertyIntegrationsState.loading
	};
};
