/*

<colorName>: {
    base: <colorBase>,
    lighten: {
        '<percentage>%': <baseColorWithPercentage>,
        '<percentage>%': <baseColorWithPercentage>,
        '<percentage>%': <baseColorWithPercentage>,
    },
    darken: {
        '<percentage>%': <baseColorWithPercentage>,
        '<percentage>%': <baseColorWithPercentage>,
        '<percentage>%': <baseColorWithPercentage>,
    }
}

*/

type ColorPercentage = {
	'10%'?: string;
	'20%'?: string;
	'30%'?: string;
	'40%'?: string;
	'50%'?: string;
	'60%'?: string;
	'70%'?: string;
	'80%'?: string;
	'90%'?: string;
};

export type Color = {
	base: string;
	lighten?: ColorPercentage;
	darken?: ColorPercentage;
};

type AppsColor = {
	front: string;
	back: string;
	free: string;
};

const highlighter: Color = {
	base: '#ffffc3'
};

const black: Color = {
	base: '#000000',
	lighten: {
		'10%': '#191919',
		'20%': '#303030',
		'30%': '#474747',
		'40%': '#616161',
		'50%': '#707070',
		'60%': '#969696',
		'70%': '#b0b0b0',
		'80%': '#d1d1d1',
		'90%': '#e8e8e8'
	}
};

const white: Color = {
	base: '#ffffff'
};

const success: Color = {
	base: '#52c41a',
	lighten: {
		'10%': '#62c92f',
		'20%': '#75d048',
		'30%': '#83d55a',
		'40%': '#93da6f',
		'50%': '#a6e088',
		'60%': '#b9e7a1',
		'70%': '#c7ecb3',
		'80%': '#daf2cc',
		'90%': '#e6f6dc'
	},
	darken: {
		'10%': '#48ac17',
		'20%': '#419a15',
		'30%': '#398712',
		'40%': '#327510',
		'50%': '#285e0d',
		'60%': '#214e0b',
		'70%': '#183908',
		'80%': '#112906',
		'90%': '#0b1904'
	}
};

const warning: Color = {
	base: '#faad14',
	lighten: {
		'10%': '#fbb730',
		'20%': '#fbbf48',
		'30%': '#fccb6b',
		'40%': '#fdd587',
		'50%': '#fee1a8',
		'60%': '#feecc7',
		'70%': '#fef0d3',
		'80%': '#fef6e3',
		'90%': '#fefaf0'
	},
	darken: {
		'10%': '#e69f12',
		'20%': '#d59311',
		'30%': '#bc820f',
		'40%': '#a5720d',
		'50%': '#6e4c09',
		'60%': '#563b07',
		'70%': '#402c05',
		'80%': '#2d1f04',
		'90%': '#1c1302'
	}
};

const danger: Color = {
	base: '#ff4d4f',
	lighten: {
		'10%': '#ff5d5f',
		'20%': '#ff696b',
		'30%': '#ff7b7d',
		'40%': '#ff8d8f',
		'50%': '#ff9d9f',
		'60%': '#ffabad',
		'70%': '#ffbbbd',
		'80%': '#ffc9cb',
		'90%': '#ffddde'
	},
	darken: {
		'10%': '#e34546',
		'20%': '#c93d3e',
		'30%': '#aa3435',
		'40%': '#8e2b2c',
		'50%': '#722323',
		'60%': '#561a1a',
		'70%': '#381111',
		'80%': '#190808',
		'90%': '#0e0404'
	}
};

const info: Color = {
	base: '#3494e0',
	lighten: {
		'10%': '#469ee3',
		'20%': '#58a8e6',
		'30%': '#66afe8',
		'40%': '#76b8ea',
		'50%': '#8fc5ee',
		'60%': '#add4f3',
		'70%': '#c4e0f6',
		'80%': '#d8ebf9',
		'90%': '#eaf4fc'
	},
	darken: {
		'10%': '#2f85ca',
		'20%': '#2b7bba',
		'30%': '#24689d',
		'40%': '#1e5884',
		'50%': '#194b70',
		'60%': '#133853',
		'70%': '#0f2c41',
		'80%': '#091b28',
		'90%': '#06121b'
	}
};

const gray: Color = {
	base: '#d4d4d4',
	lighten: {
		'10%': '#d9d9d9',
		'20%': '#dddddd',
		'30%': '#e1e1e1',
		'40%': '#e5e5e5',
		'50%': '#e9e9e9',
		'60%': '#eeeeee',
		'70%': '#f1f1f1',
		'80%': '#f6f6f6',
		'90%': '#f9f9f9'
	},
	darken: {
		'10%': '#b8b8b8',
		'20%': '#9d9d9d',
		'30%': '#888888',
		'40%': '#737373',
		'50%': '#5c5c5c',
		'60%': '#444444',
		'70%': '#2f2f2f',
		'80%': '#1c1c1c',
		'90%': '#0e0e0e'
	}
};

const primary: Color = {
	base: '#3494e0',
	lighten: {
		'10%': '#469ee3',
		'20%': '#58a8e6',
		'30%': '#66afe8',
		'40%': '#76b8ea',
		'50%': '#8fc5ee',
		'60%': '#add4f3',
		'70%': '#c4e0f6',
		'80%': '#d8ebf9',
		'90%': '#eaf4fc'
	},
	darken: {
		'10%': '#2f85ca',
		'20%': '#2b7bba',
		'30%': '#24689d',
		'40%': '#1e5884',
		'50%': '#194b70',
		'60%': '#133853',
		'70%': '#0f2c41',
		'80%': '#091b28',
		'90%': '#06121b'
	}
};

const yellow: Color = {
	base: '#fefb64',
	lighten: {
		'10%': '#fefb72',
		'20%': '#fefb7b',
		'30%': '#fefb86',
		'40%': '#fefb92',
		'50%': '#fefb9d',
		'60%': '#fefba8',
		'70%': '#fefcb8',
		'80%': '#fefdd7',
		'90%': '#fefee3'
	},
	darken: {
		'10%': '#e5e25a',
		'20%': '#ccc950',
		'30%': '#b5b247',
		'40%': '#9c993d',
		'50%': '#8d8a37',
		'60%': '#7b7830',
		'70%': '#5f5d25',
		'80%': '#4a491d',
		'90%': '#303013'
	}
};

const apps: AppsColor = {
	front: '#3494e0',
	back: '#2ea2ad',
	free: '#2ed1b0'
};

export type Colors = {
	highlighter: Color;
	black: Color;
	white: Color;
	gray: Color;
	primary: Color;
	containerBgColor: string;
	apps: AppsColor;
	success: Color;
	warning: Color;
	danger: Color;
	info: Color;
	yellow: Color;
	text?: any;
};

export const colors: Colors = {
	highlighter,
	black,
	white,
	gray,
	primary,
	containerBgColor: '#fff',
	apps,
	success,
	warning,
	danger,
	info,
	yellow
};

export const frontAppsColor: Colors = {
	...colors,
	primary: {
		base: '#2298e4',
		lighten: {
			'10%': '#3aa3e7',
			'20%': '#4eacea',
			'30%': '#6dbbee',
			'40%': '#8cc9f2',
			'50%': '#a2d3f5',
			'60%': '#bde0f8',
			'70%': '#d1e9fa',
			'80%': '#e0f0fc',
			'90%': '#eef7fd'
		},
		darken: {
			'10%': '#1e86c9',
			'20%': '#1b77b2',
			'30%': '#18699d',
			'40%': '#145884',
			'50%': '#124d74',
			'60%': '#0e3c5b',
			'70%': '#0b2d44',
			'80%': '#071d2b',
			'90%': '#041119'
		}
	},
	text: {
		secondary: '#F7E159'
	}
};

export const backAppsColors: Colors = {
	...colors,
	primary: {
		base: '#2298e4',
		lighten: {
			'10%': '#3aa3e7',
			'20%': '#4eacea',
			'30%': '#6dbbee',
			'40%': '#8cc9f2',
			'50%': '#a2d3f5',
			'60%': '#bde0f8',
			'70%': '#d1e9fa',
			'80%': '#e0f0fc',
			'90%': '#eef7fd'
		},
		darken: {
			'10%': '#1e86c9',
			'20%': '#1b77b2',
			'30%': '#18699d',
			'40%': '#145884',
			'50%': '#124d74',
			'60%': '#0e3c5b',
			'70%': '#0b2d44',
			'80%': '#071d2b',
			'90%': '#041119'
		}
	}
};

export const adminColors: Colors = {
	...colors,
	primary: {
		base: '#114b7a',
		lighten: {
			'10%': '#245985',
			'20%': '#245985',
			'30%': '#376890',
			'40%': '#4b779b',
			'50%': '#648aa9',
			'60%': '#7b9bb5',
			'70%': '#99b2c6',
			'80%': '#c0cfdc',
			'90%': '#cdd9e3'
		},
		darken: {
			'10%': '#104671',
			'20%': '#0f4067',
			'30%': '#0d3758',
			'40%': '#0b304d',
			'50%': '#092840',
			'60%': '#072033',
			'70%': '#051725',
			'80%': '#030f19',
			'90%': '#020b13'
		}
	}
};

export const developerColors: Colors = {
	...colors,
	primary: {
		base: '#444444',
		lighten: {
			'10%': '#595959',
			'20%': '#6f6f6f',
			'30%': '#838383',
			'40%': '#939393',
			'50%': '#a5a5a5',
			'60%': '#b7b7b7',
			'70%': '#c9c9c9',
			'80%': '#d7d7d7',
			'90%': '#e9e9e9'
		},
		darken: {
			'10%': '#3d3d3d',
			'20%': '#343434',
			'30%': '#2c2c2c',
			'40%': '#252525',
			'50%': '#3494e0',
			'60%': '#1b1b1b',
			'70%': '#151515',
			'80%': '#101010',
			'90%': '#080808'
		}
	},
	text: {
		secondary: '#F7E159'
	}
};

export default colors;
