import { FieldMapping } from '@hospy/store';
import {
	createFieldMappingService,
	getFieldMappingService,
	removeFieldMappingService
} from '@hospy/util-api';

const useFieldMapping = () => {
	const create = async (data: FieldMapping) => {
		return await createFieldMappingService(data);
	};

	const remove = async (id: string) => {
		return await removeFieldMappingService(id);
	};

	const get = async (propertyId: string, partnerIntegrationId: string) => {
		return await getFieldMappingService(
			`{propertyId:'${propertyId}', partnerIntegrationId:'${partnerIntegrationId}'}`
		);
	};

	return {
		create,
		remove,
		get
	};
};
export default useFieldMapping;
