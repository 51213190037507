/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	addMeWaitingList,
	getAppsService,
	getAppStatusService,
	getCategoryService,
	getConnectionService,
	getCountryService,
	getPaymentMethodsService,
	removeMeWaitingList,
	updateAppService,
	userWaitingList
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchGetCategory = createAsyncThunk(
	'spa/fetchGetCategory',
	async (queryParams: string[], { rejectWithValue }) => {
		try {
			const { data } = await getCategoryService(queryParams);
			if (!data)
				return rejectWithValue(
					'Error al cargar la lista de categorías'
				);
			return data;
		} catch (error: any) {
			console.error('fetchGetCategory:', error);
			return rejectWithValue('Error al cargar la lista de categorías');
		}
	}
);

export const fetchGetConnection = createAsyncThunk(
	'spa/fetchGetConnection',
	async (queryParams: string[], { rejectWithValue }) => {
		try {
			const { data } = await getConnectionService(queryParams);
			if (!data)
				return rejectWithValue(
					'Error al cargar la lista de conexiones'
				);
			return data;
		} catch (error: any) {
			console.error('fetchGetConnection:', error);
			return rejectWithValue('Error al cargar la lista de conexiones');
		}
	}
);

export const fetchGetAppStatus = createAsyncThunk(
	'spa/fetchGetAppStatus',
	async (queryParams: string[], { rejectWithValue }) => {
		try {
			const { data } = await getAppStatusService(queryParams);
			if (!data)
				return rejectWithValue(
					'Error al cargar la lista de estados de las aplicaciones'
				);
			return data;
		} catch (error: any) {
			console.error('fetchGetAppStatus:', error);
			return rejectWithValue(
				'Error al cargar la lista de estados de las aplicaciones'
			);
		}
	}
);

export const fetchGetPaymentMethods = createAsyncThunk(
	'spa/fetchGetPaymentMethods',
	async (queryParams: string[], { rejectWithValue }) => {
		try {
			const { data } = await getPaymentMethodsService(queryParams);
			if (!data)
				return rejectWithValue(
					'Error al cargar la lista de métodos de pagos'
				);
			return data;
		} catch (error: any) {
			console.error('fetchGetPaymentMethods:', error);
			return rejectWithValue(
				'Error al cargar la lista de métodos de pagos'
			);
		}
	}
);

export const fetchGetApps = createAsyncThunk(
	'spa/fetchGetApps',
	async (queryParams: string[], { rejectWithValue }) => {
		try {
			const rs = await getAppsService(queryParams);
			return rs;
		} catch (error: any) {
			console.error('fetchGetApps:', error);
			return rejectWithValue('Error al cargar la lista de apps');
		}
	}
);

export const fetchPatchApp = createAsyncThunk(
	'spa/fetchPatchApps',
	async (body: any, { rejectWithValue, getState }) => {
		try {
			const rs = await updateAppService(body._id, body);
			const state: any = getState();
			const indexUpdate = state.spa.filterApps.data.findIndex(
				(item: any) => item._id === body._id
			);

			return { index: indexUpdate, data: rs.data };
		} catch (error: any) {
			console.error('fetchPatchApps:', error);
			return rejectWithValue('Error al actualizar un app');
		}
	}
);

export const fetchUserWaitList = createAsyncThunk(
	'spa/fetchUserWaitList',
	async (_, { rejectWithValue }) => {
		try {
			const { data } = await userWaitingList();
			if (data && data.length > 0) return data[0].apps || [];
			return rejectWithValue('Error al cargar la lista de espera');
		} catch (error: any) {
			console.error('fetchGetApps:', error);
			return rejectWithValue('Error al cargar la lista de espera');
		}
	}
);

export const fetchAddWaitList = createAsyncThunk(
	'spa/fetchAddWaitList',
	async (appId: string, { rejectWithValue }) => {
		try {
			const { data } = await addMeWaitingList(appId);
			if (!data)
				return rejectWithValue(
					'Error al intentar agregar la aplicación a la lista de espera'
				);
			return data;
		} catch (error: any) {
			console.error('fetchAddWaitList:', error);
			return rejectWithValue(
				'Error al intentar agregar la aplicación a la lista de espera'
			);
		}
	}
);

export const fetchRemoveWaitList = createAsyncThunk(
	'spa/fetchRemoveWaitList',
	async (appId: string, { rejectWithValue }) => {
		try {
			const { data } = await removeMeWaitingList(appId);
			if (!data)
				return rejectWithValue(
					'Error al intentar eliminar la aplicación de la lista de espera'
				);
			return data;
		} catch (error: any) {
			console.error('fetchAddWaitList:', error);
			return rejectWithValue(
				'Error al intentar eliminar la aplicación de la lista de espera'
			);
		}
	}
);

export const fetchLatamCountries = createAsyncThunk(
	'spa/getCountries',
	async () => {
		const { data } = await getCountryService(
			'fields=["_id","name","iso2"]&filter={"iso3": { "$in": [  "ARG",   "BRA",  "CHL",  "COL",  "CRI",  "DOM",  "ECU",  "MEX",  "PAN",  "PER",  "URY"]}}'
		);
		return data;
	}
);
