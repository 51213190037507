import { Input } from 'antd';
import styled, { css } from 'styled-components';

export interface TextAreaProps {
  value?: string;
  style?: React.CSSProperties;
  maxRows?: number;
  minRows?: number;
}

export interface AcceptAgreementProps extends TextAreaProps {
  style?: React.CSSProperties;
}



export function AcceptAgreement({
  ...props
}: AcceptAgreementProps) {
  return (
    <StyledTextArea
      autoSize={{ minRows: props.minRows || 4, maxRows: props.maxRows || 20 }}
      readOnly
      {...props}
    />
  );
}

export default AcceptAgreement;

//more default styles here
const StyledTextArea = styled(Input.TextArea)`
  overflow-y: auto;

`;