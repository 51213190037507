import { useIntercom } from 'react-use-intercom';

export const setIntercomUser = (user: any) => {
	const { boot } = useIntercom();

	const subscribed = (user?.properties || []).map((p: any) =>
		(p?.apps || []).filter((a: any) =>
			['lifetime', 'subscribed'].includes(a?.subscription || '')
		)
	);
	const isSubscribed = (subscribed || []).filter((s: any) => s?.length);
	if (isSubscribed?.length) {
		const property = user?.properties.find(
			(x: any) => x?._id == user?.currentProperty
		);
		boot({
			name: user?.name,
			email: user?.email,
			userId: user?._id?.toString(),
			company: {
				companyId: property?._id,
				name: property?.name
			}
		});
	}
};
