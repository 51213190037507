/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestFindAllLeads,
	RequestRemoveLeads,
	RequestUpdateLeads,
	RequestCreateLeadsImports, RequestCreateLeadsNotifications
} from '@hospy/util-api/service/admin-leads';
import { RequestFindAllWhatsappTemplates } from '@hospy/util-api/notification/whatsapp';
import {
	RequestCreate,
	RequestFindAll,
	RequestRemove,
	RequestUpdate
} from '@hospy/util-api/requests';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminLeadsSliceName } from './constants';

export const ThunkFindAllLeads = createAsyncThunk(
	`${AdminLeadsSliceName}/${RequestFindAllLeads.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllLeads(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
export const ThunkUpdateLeads = createAsyncThunk(
	`${AdminLeadsSliceName}/${RequestUpdateLeads.name}`,
	async (params: RequestUpdate, { rejectWithValue }) => {
		const response = await RequestUpdateLeads(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
export const ThunkRemoveLeads = createAsyncThunk(
	`${AdminLeadsSliceName}/${RequestRemoveLeads.name}`,
	async (params: RequestRemove, { rejectWithValue }) => {
		const response = await RequestRemoveLeads(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateLeadsImports = createAsyncThunk(
	`${AdminLeadsSliceName}/${RequestCreateLeadsImports.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateLeadsImports(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkFindAllWhatsappTemplates = createAsyncThunk(
	`${AdminLeadsSliceName}/${RequestFindAllWhatsappTemplates.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllWhatsappTemplates(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateLeadsNotifications = createAsyncThunk(
	`${AdminLeadsSliceName}/${RequestCreateLeadsNotifications.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateLeadsNotifications(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
