/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConnectionVector, Icon } from '@hospy/hospy-ui';
import { Row, Col, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

 

export const LoadingRedirectWhatsapp = () => {
	 

	return (
		<div
			style={{
				width:  '100%',
				height:   '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				gap: 15,
				marginBottom: 15,
				alignItems: 'center',
				position: 'fixed',
				zIndex: 60,
				top: 0,
				left: 0,
				backdropFilter: 'blur(3px)',
				background: '#ffffff54'
			}}
		>
			 <Row gutter={[0,0]} justify={'center'}>
				<Col span={2} >
					<img
						alt="icon whatsapp"
						src={
							'https://cdn.hospy.co/images/favicon.png'
						}
						style={{ maxWidth: 44}}
					/>
				</Col>
				<Col span={14} xs={{offset: 6}} sm={{offset: 6}}>
					<ConnectionVector style={ { width: '100px' }}/>
				</Col>
				<Col span={2} xs={{offset: 0}} sm={{offset: 0}}>
					<img
						alt="icon whatsapp"
						src={
							'https://cdn.hospy.co/third-party-app-icons/whatsapp.png'
						}
						style={{ maxWidth: 48}}
						/>
				</Col>
			 </Row>
			
			

		</div>
	);
};

 
const ConnectionVectorContainer = styled.div`
	height: 50px;
	flex: 1 auto;
`;