import styled from 'styled-components'
import { Spin } from 'antd';


interface Props {
  children: React.ReactNode | React.ReactNode[];
  blocking?: boolean;
}

export const BlockLoading: React.FC<Props> = ({ children, blocking = false }) => {

  return (
    <Block blocking={blocking} >
      <span style={{ opacity: blocking ? 0.3 : undefined }} > {children} </span>
      <BlockContainer blocking={blocking} tabIndex={0} >
        <BlockOverlay blocking={blocking} />
        <BlockMessageContainer blocking={blocking} >
          <BlockMessage blocking={blocking} >
            {blocking && <Spin style={{ opacity: 1 }} size='large' />}
          </BlockMessage>
        </BlockMessageContainer>
      </BlockContainer>
    </Block>
  )
}


interface styledProps {
  blocking: boolean
}

const Block = styled.div<styledProps>`
  position: ${p => p.blocking ? 'relative' : undefined} ;
  min-height: ${p => p.blocking ? '3em' : undefined} ;
`

const BlockContainer = styled.div<styledProps>`
  position: ${p => p.blocking ? 'absolute' : undefined};
  z-index: ${p => p.blocking ? '1010' : undefined};
  top: ${p => p.blocking ? '0' : undefined};
  right: ${p => p.blocking ? '0' : undefined};
  bottom: ${p => p.blocking ? '0' : undefined};
  left: ${p => p.blocking ? '0' : undefined};
  height: ${p => p.blocking ? '100%' : undefined};
  min-height: ${p => p.blocking ? '2em' : undefined};
  cursor: ${p => p.blocking ? 'wait' : undefined};
  overflow: ${p => p.blocking ? 'hidden' : undefined};
`

const BlockOverlay = styled.div<styledProps>`
  width: ${p => p.blocking ? '100%' : undefined};
  heigth: ${p => p.blocking ? '100%' : undefined};
  opacity: ${p => p.blocking ? '0.5' : undefined};
  filter: ${p => p.blocking ? 'alpha(opacity=50)' : undefined};
`

const BlockMessageContainer = styled.div<styledProps>`
  position: ${p => p.blocking ? 'absolute' : undefined};
  top: ${p => p.blocking ? '50%' : undefined};
  left: ${p => p.blocking ? '0' : undefined};
  right: ${p => p.blocking ? '0' : undefined};
  text-align: ${p => p.blocking ? 'center' : undefined};
  transform: ${p => p.blocking ? 'translateY(-50%)' : undefined};
  z-index: ${p => p.blocking ? '10001' : undefined};
  opacity: 1;
`

const BlockMessage = styled.div<styledProps>`
  color: ${p => p.blocking ? '#333' : undefined};
  background: ${p => p.blocking ? 'none' : undefined};
  z-index: ${p => p.blocking ? '1011' : undefined};

`
