/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	getAutoBillInfoService,
	getPropertiesService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchGetProperties = createAsyncThunk(
	'property/fetchGetProperties',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await getPropertiesService(payload);
			if (!data)
				return rejectWithValue('Error al cargar la lista de negocios');
			return data;
		} catch (error: any) {
			console.error('getPropertyById:', error);
			return rejectWithValue('Error al cargar la lista de negocios');
		}
	}
);

export const fetchGetAutoBillInfo = createAsyncThunk(
	'property/fetchGetPropertyAutoBillInfo',
	async (payload: any, { rejectWithValue }) => {
		try {
			const { data } = await getAutoBillInfoService(payload);
			
			if (!data)
				return rejectWithValue('Error al obtener los ajustes de la propiedad');
			return data;
		} catch (error: any) {
			console.error('getPropertyById:', error);
			return rejectWithValue('Error al cargar la lista de negocios');
		}
	}
);
