export const validateIdDocumentDataService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};
		fetch(
			'https://db5c9x2vkc.execute-api.us-east-1.amazonaws.com/dev/api/id-documents/object-detections',
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const validateIdDocumentDataServiceV2 = (data: {
	appId: string; // "63703fd5b7a852979f10e0d1",
	partnerIntegrationId: string; // "63f9340af275a92dcc6464c2",
	propertyId: string; // "64ac78553c2ba73443252c3d",
	resource: string; // "reservation",
	resourceId: string;
	name: string; // "demo.png",
	contentType: string; // "image/png",
	expectedDocument: string; // "mx-passport",
	data: string; //"",
	rekognition?: boolean;
	textract?: boolean;
	saveLog?: boolean;
	rekognitionVersion?: string;
	enhance?: boolean;
	confidence?: number;
	eventType?: string;
	eventName?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		let saveLog = data?.saveLog;
		if (data?.saveLog === undefined) {
			saveLog = true;
		}
		const rekognitionVersion = 'v3';

		if (data.enhance === undefined) {
			data.enhance = true;
		}
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				...data,
				saveLog,
				rekognitionVersion
			})
		};

		const env = process.env['NX_APP_SPA'] || '';
		let url =
			'https://db5c9x2vkc.execute-api.us-east-1.amazonaws.com/dev/api/id-documents/metadata';
		if (/https:\/\/hospy\.co/.test(env)) {
			url =
				'https://sc7em35apc.execute-api.us-east-1.amazonaws.com/prod/api/id-documents/metadata';
		}
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const validateIdPhotoMatchService = (data: {
	appId: string;
	partnerIntegrationId: string;
	propertyId: string;
	resource: string; // "reservations"
	resourceId: string;
	eventType?: string; // "match-id"
	eventName?: string; //"Match ID  -  Pasaporte(Mexico)"
	source: string; //validation result path
	target: string; //selfie base64
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const env = process.env['NX_APP_SPA'] || '';
		let url =
			'https://db5c9x2vkc.execute-api.us-east-1.amazonaws.com/dev/api/id-documents/match';
		if (/https:\/\/hospy\.co/.test(env)) {
			url =
				'https://sc7em35apc.execute-api.us-east-1.amazonaws.com/prod/api/id-documents/match';
		}

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface processDocumentProps {
	partnerIntegrationId: string;
	propertyId: string;
	reservationId: string;
	expectedDocument: string;
	eventType: string;
	eventName: string;
	data: string;
	contentType: string;
}
export const processDocumentService = (
	props: processDocumentProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const url =
			'https://hospy-scan-id-559814754656.us-central1.run.app/process-document';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(props)
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
