import { PARTNER, useAppSelector } from '@hospy/store';
import { useEffect, useState } from 'react';

const usePartnerConnectionValidator = () => {
	const { propertyIntegrationsState } = useAppSelector(({ user }) => user);
	const { appData } = useAppSelector(({ common }) => common);
	const [partnerConnectionRevoked, setPartnerConnectionRevoked] =
		useState<boolean>(false);

	useEffect(() => {
		const hasCloudbeds = propertyIntegrationsState.data?.find(
			(integration) =>
				integration.partnerIntegrationId === PARTNER.CLOUDBEDS &&
				integration.appId === appData?._id
		);
		if (hasCloudbeds && hasCloudbeds.accessTokenRevoked) {
			setPartnerConnectionRevoked(true);
			console.log({ hasCloudbeds });
		} else {
			setPartnerConnectionRevoked(false);
		}
	}, [
		propertyIntegrationsState.loading,
		appData?._id,
		JSON.stringify(propertyIntegrationsState.data)
	]);

	return {
		partnerConnectionRevoked
	};
};

export default usePartnerConnectionValidator;
