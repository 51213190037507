import { CustomFormInitialState } from './interface';

export const customFormInitialState: CustomFormInitialState = {
	forms: {
		loading: 'idle',
		error: undefined,
		data: null
	},
	createForm: {
		loading: 'idle',
		error: undefined,
		data: null
	},
	updateForm: {
		loading: 'idle',
		error: undefined,
		data: null
	},
	deleteForm: {
		loading: 'idle',
		error: undefined,
		data: null
	}
};
