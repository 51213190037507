/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	fetchGetPropertyById, fetchGetUserWaitListById,
} from './thunks';

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		cleanLoadingApp: (state: any, action) => {
			state.loading[action.payload.state] = action.payload.value;
		},
		cleanSuccessApp: (state: any, action) => {
			state.success[action.payload.state] = action.payload.value;
		},
		cleanErrorApp: (state: any, action) => {
			state.error[action.payload.state] = action.payload.value;
		},
		setStateApp: (state: any, action) => {
			state[action.payload.state] = action.payload.value;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGetPropertyById.pending, (state) => {
				state.loading.getPropertyById = 'pending';
			})
			.addCase(fetchGetPropertyById.rejected, (state) => {
				state.loading.getPropertyById = 'idle';
			})
			.addCase(fetchGetPropertyById.fulfilled, (state, { payload }) => {
				state.properties = payload;
				state.loading.getPropertyById = 'idle';
			})

			.addCase(fetchGetUserWaitListById.pending, (state) => {
				state.loading.getUserWaitListById = 'pending';
			})
			.addCase(fetchGetUserWaitListById.rejected, (state) => {
				state.loading.getUserWaitListById = 'idle';
			})
			.addCase(fetchGetUserWaitListById.fulfilled, (state, { payload }) => {
				state.userWaitList = payload;
				state.loading.getUserWaitListById = 'idle';
			})
	}
});

export const {
	cleanSuccessApp,
	cleanErrorApp,
	cleanLoadingApp,
	setStateApp
} = appSlice.actions;
