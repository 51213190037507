import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './id-document.constants';
import { validateIdDocument } from './id-documnet.thunks';

export const idDocumentSlice = createSlice({
	name: 'IdDocument',
	initialState,
	reducers: {
		setIdDocument: (state, { payload }) => {
			state.idDocument = payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(validateIdDocument.pending, (action, { payload }) => {
				action.idDocument = {
					loading: 'pending'
				};
			})
			.addCase(validateIdDocument.rejected, (action, { payload }) => {
				action.idDocument = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(validateIdDocument.fulfilled, (action, { payload }) => {
				action.idDocument = {
					loading: 'succeeded',
					data: payload
				};
			});
	}
});

export const { setIdDocument } = idDocumentSlice.actions;
