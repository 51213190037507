/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';

export interface HubspotTestParameters {
  propertyIntegrationId: string;
  reservationId: string;
  propertyId: string;
  [key: string]: any;
}

export enum Status {
  SUCCESS = 'success',
  FAILURE = 'failure',
  PENDING = 'pending',
  PROCESSING = 'processing',
  NA = 'n/a'
}

export interface HubspotGetPropertiesParameters {
  propertyIntegrationId: string;
  objectType: string;
}

export interface HubspotGetLogsParameters {
  //propertyIntegrationId: string;
  appId: string;
  partnerIntegrationId: string;
  propertyId: string;
  filter: string;
  //partnerIntegrationIdTo: string;
  page: string;
  limit: string;
  sort: string;
}
export interface CreateEventLog {
  active: boolean;
  propertyId: string;
  partnerIntegrationId: string;
  reservationId: string;
  eventFrom: string;
  resourceFromId: string;
  resourceFromName: string;
  eventTo: string;
  resourceToId: string;
  resourceToName: string;
  status: Status;
  payloadTo: {
    data: Record<string, any>;
  };
  log?: {
    messageType: string;
    subject: string;
    bodyFormat: string;
    body: string;
  };
}

export const hubspotCreateContactTest = ({
  propertyIntegrationId,
  propertyId,
  reservationId,
  ...data
}: HubspotTestParameters): Promise<any> =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        propertyIntegrationId,
        propertyId,
        reservationId,
        data
      })
    };

    const url = ApiURL(`/api/third-party/hubspot/integration/test`);

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });

export const hubspotGetPropertiesService = ({
  objectType,
  propertyIntegrationId
}: HubspotGetPropertiesParameters): Promise<any> =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const url = ApiURL(
      `/api/third-party/hubspot/integration/properties?propertyIntegrationId=${propertyIntegrationId}&objectType=${objectType}`
    );

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });

export const hubspotGetPipelinesService = ({
  propertyIntegrationId, objectType
}: HubspotGetPropertiesParameters): Promise<any> =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const url = ApiURL(
      `/api/third-party/hubspot/integration/pipelines?propertyIntegrationId=${propertyIntegrationId}&objectType=${objectType}`
    );

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });

export const hubspotGetLogsService = ({
  propertyId,
  appId,
  filter,
  partnerIntegrationId,
  //partnerIntegrationIdTo,
  limit,
  page,
  sort
}: HubspotGetLogsParameters): Promise<any> =>
  new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem('Hospy-idToken');
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    const url = ApiURL(
      `/api/hubspot/events?propertyId=${propertyId}&appId=${appId}&filter=${filter}&partnerIntegrationId=${partnerIntegrationId}&limit=${limit}&page=${page}&sort=${sort}`
    );

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });

export const hubspotCreateLog = (data: CreateEventLog): Promise<any> =>
  new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem('Hospy-idToken');
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    };

    const url = ApiURL(
      `/api/hubspot/events`
    );

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
