import { cloudbedsActiveWebhookService } from "@hospy/util-api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const cloudbedsActiveWebhook = createAsyncThunk(
    'third-party-cloudbeds/activeWebhook',
    async (propertyIntegration: string, { rejectWithValue }) => {
        try {
            const response = await cloudbedsActiveWebhookService(propertyIntegration);
            return response;
        } catch (error: any) {
            console.error(error);
            rejectWithValue("Error")
        }
    }
)