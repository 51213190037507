import { accountRoute, lazyWithRetry } from '@hospy/shared-components';
import { useRoutes } from 'react-router-dom';

const OAuthPopup = lazyWithRetry(
	() => import('@siigo-connect-pages/oauth-popup')
);
const PrivateLayout = lazyWithRetry(
	() => import('@siigo-connect/components/layouts/private-layout')
);

const Dashboard = lazyWithRetry(
	() => import('@siigo-connect/components/layouts/dashboard')
);

const AppRoute = () => {
	const routes = useRoutes([
		...accountRoute.map(({ path, component: Cmp }) => ({
			path,
			element: <Cmp />
		})),
		{
			path: 'partner/oauth',
			element: <OAuthPopup />
		},
		{
			element: <PrivateLayout />,
			children: [
				{
					path: '*',
					element: <Dashboard />
				}
			]
		}
	]);
	return routes;
};

export default AppRoute;
