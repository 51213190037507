import { validateIdDocumentDataService } from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface ValidateIdDocumentProps {
	b64?: string;
	url?: string;
}
export const validateIdDocument = createAsyncThunk(
	'hospy-ml/validate-id-document',
	async (props: ValidateIdDocumentProps, { rejectWithValue }) => {
		try {
			return await validateIdDocumentDataService(props);
		} catch (error) {
			console.error({ error });
			return rejectWithValue(`Error al validar el documento, ${error}`);
		}
	}
);
