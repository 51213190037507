/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon } from '@hospy/hospy-ui';
import { useAppSelector } from '@hospy/store';
import { DatePicker, Form, Input } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';
import AdditionalFilters from './additional-filters';

export interface Props {
	showCreateInvoiceButton?: boolean;
}

const InvoiceFilterForm = ({ showCreateInvoiceButton }: Props) => {
	const {
		actionCloseDetail,
		loading,
		selectedSource,
		updateSelectedDates,
		refresh,
		searchValue,
		searchByValue,
		dates
	} = useContext(InvoiceFilterContext) as InvoiceFilterContextType;

	const navigate = useNavigate();

	const { credits } = useAppSelector(({ credits }) => credits);

	const hasStamps = (credits?.data?.stamps || 0) > 0;

	return (
		<InvoiceForm>
			{!selectedSource && (
				<>
					<TypeTitle>Fecha de creación</TypeTitle>
					<Form>
						<DateSection detailOpened={selectedSource !== null}>
							<DatePicker.RangePicker
								allowClear
								value={
									dates[0] && dates[1]
										? [moment(dates[0]), moment(dates[1])]
										: undefined
								}
								onChange={(value) => {
									actionCloseDetail(() => {
										if (value) {
											const baseDay =
												moment().toISOString();
											updateSelectedDates(
												value[0]?.toISOString() ||
													baseDay,
												value[1]?.toISOString() ||
													baseDay
											);
										} else {
											updateSelectedDates(undefined, undefined);
										}
									});
								}}
								size="large"
								locale={locale}
								format={'DD [de] MMMM'}
								bordered={false}
								suffixIcon={<Icon material="arrow_drop_down" />}
								separator={'-'}
								disabled={loading}
							/>
							<Button
								size="small"
								disabled={loading}
								onClick={() => actionCloseDetail(refresh)}
							>
								<Icon materialOutlined="sync" />
							</Button>
						</DateSection>
						<SecondSection>
							<ListForm detailOpened={selectedSource !== null}>
								<Form.Item>
									<Input
										disabled={loading}
										prefix={<Icon material="search" />}
										size="large"
										allowClear
										value={searchValue || ''}
										onChange={(value) => {
											actionCloseDetail(() => {
												searchByValue(
													value.target.value
												);
											});
										}}
										placeholder="Buscar por nombre"
									/>
								</Form.Item>
								<AdditionalFilters />
								{showCreateInvoiceButton && (
									<HospyBtn
										size="small"
										type="primary"
										shape="circle"
										disabled={loading || !hasStamps}
										onClick={() =>
											navigate('/invoices/create')
										}
										tooltip={
											!hasStamps
												? 'No tienes timbres disponibles'
												: 'Crear factura de forma manual'
										}
									>
										<Icon material="add" />
									</HospyBtn>
								)}
							</ListForm>
						</SecondSection>
					</Form>
				</>
			)}
		</InvoiceForm>
	);
};

const InvoiceForm = styled.div`
	width: 100%;
	/* padding-right: 40px; */
	.ant-form-item {
		margin: 0;
	}

	.ant-picker-suffix {
		color: black;
	}
`;

const DateSection = styled(({ detailOpened, ...props }) => <div {...props} />)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 340px;

	${({ theme, detailOpened }) =>
		theme.screens.sm &&
		!detailOpened &&
		css`
			width: 54%;
		`}

	${({ theme, detailOpened }) =>
		theme.screens.lg &&
		!detailOpened &&
		css`
			width: 36%;
		`}

	.ant-picker-large {
		input {
			font-weight: bold;
			font-size: ${({ theme }) => theme.text.size.middle}px;
		}
		padding: 0;
	}

	button {
		margin-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${({ theme }) => theme.colors.primary.base};
		border-radius: 25px;
		height: 35px;
		width: 35px;
		border: none;
		svg {
			color: white;
			font-size: 1.5rem;
		}
		:disabled {
			border-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']} !important;
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']} !important;
		}
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #fff;
			border-color: ${({ theme }) => theme.colors.primary.lighten['10%']};
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']};
		}
		&.ant-btn:hover,
		&.ant-btn:active,
		&.ant-btn:focus {
			color: #fff;
			border-color: ${({ theme }) => theme.colors.primary.lighten['10%']};
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['10%']};
		}
	}
`;

const SecondSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
	flex-wrap: wrap;
	width: 100%;

	button {
		font-size: ${({ theme }) => theme.text.size.middle}px;
		display: flex;
		gap: 20px;
	}
`;

const ListForm = styled(({ detailOpened, ...props }) => <div {...props} />)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	width: 100%;
	max-width: 700px;

	.ant-input-affix-wrapper {
		height: 50px;
		border: 1px solid #d6d6d6;
		border-radius: 25px;
		background-color: #f7f6f6;
		> input {
			font-size: ${({ theme }) => theme.text.size.large}px;
			background-color: #f7f6f6;
		}
	}

	.ant-row,
	.ant-form-item {
		width: 100%;
	}
`;

const HospyBtn = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	svg {
		color: white;
		font-size: 1.5rem;
	}
`;

const TypeTitle = styled.span`
	font-size: ${({ theme }) => theme.text.size.middle}px;
	font-weight: bold;
`;

export default InvoiceFilterForm;
