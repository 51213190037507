import { IThirdPartyRntReportsState } from './interfaces';

export const initialState = {
	theme: 'light',
	data: {
		postReportsBulk: undefined
	},
	loading: {
		postReportsBulk: 'idle'
	},
	success: {
		postReportsBulk: false
	},
	error: {
		postReportsBulk: undefined
	}
} as IThirdPartyRntReportsState;
