/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { fetchLanguageMessages } from './thunks';

export const languageSlice = createSlice({
	name: 'language',
	initialState,
	reducers: {
		setLanguage: (state, action) => {
			state.language = action.payload;
			sessionStorage.setItem('language', action.payload);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchLanguageMessages.fulfilled, (state, action) => {
			if (action.payload) {
				state.success.getLanguageMessages = true;
				state.messages = action.payload;
			} else {
				state.error.getLanguageMessages = action.payload;
			}
			state.loading = 'idle';
		});

		builder.addCase(fetchLanguageMessages.pending, (state) => {
			state.error = {
				...state.error,
				getLanguageMessages: false
			};
			state.success = {
				...state.success,
				getLanguageMessages: false
			};
			state.loading = 'pending';
		});
	}
});

export const { setLanguage } = languageSlice.actions;
