/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ReactNode } from 'react';

interface NumericInputProps {
	style?: React.CSSProperties;
	value: number;
	onChange: (value: number) => void;
	prefix?: ReactNode;
	suffix?: ReactNode;
	size?: SizeType;
	placeholder?: string | undefined;
	ref?: any;
	disabled?: boolean;
}

export const CurrencyInput = (props: NumericInputProps) => {
	const { onChange } = props;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = e.target;

		const reg = /^-?\d*(\.\d*)?$/;

		if ((reg.test(inputValue) || inputValue === '') && inputValue !== '-') {
			const pointIndexOf = inputValue.indexOf('.');
			if (pointIndexOf > -1 && inputValue.length > pointIndexOf + 3)
				onChange(+(+inputValue).toFixed(2));
			else {
				if (pointIndexOf === inputValue.length - 1) {
					onChange(inputValue as any);
					return;
				}
				onChange(+inputValue);
			}
		}
	};

	return <Input {...props} onChange={handleChange} maxLength={16} />;
};
