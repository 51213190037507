import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { updateAppFees, updateAppTrial } from './thunks';

export const adminBillingSlice = createSlice({
	name: 'admin-billing',
	initialState,
	reducers: {
		clearUpdateAppFees: (state) => {
			state.success.savedRange = initialState.success.savedRange;
			state.error.savedRange = initialState.error.savedRange;
		},
		clearUpdateTrial: (state) => {
			state.success.trialUpdate = initialState.success.trialUpdate;
			state.error.trialUpdate = initialState.error.trialUpdate;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(updateAppFees.pending, (state) => {
			state.loading = 'pending';
		});

		builder.addCase(updateAppFees.fulfilled, (state, action) => {
			state.loading = 'idle';
			if (action.payload) {
				state.success.savedRange = true;
			} else {
				state.error.savedRange = action.payload;
			}
		});

		builder.addCase(updateAppTrial.pending, (state) => {
			state.trialUpdateLoading = 'pending';
		});

		builder.addCase(updateAppTrial.fulfilled, (state, action) => {
			state.trialUpdateLoading = 'idle';
			if (action.payload) {
				state.success.trialUpdate = true;
			} else {
				state.error.trialUpdate = action.payload;
			}
		});
	}
});

export const { clearUpdateAppFees, clearUpdateTrial } =
	adminBillingSlice.actions;
