import { PartnerIntegration } from '@hospy/store';
import { Spin } from 'antd';
import { motion } from 'framer-motion';
import { AppData } from 'libs/store/src/lib/common/common.types';
import styled from 'styled-components';
import AuthPartner from './components/auth-partner';
import SelectPartner from './components/select-partner';
import StepTitle from './components/step-title';
import useConnectPartner from './hooks/use-connect-partner';

export type OnFinish = ({
	partnerIntegration,
	createPropertyIntegrationResponse,
	validateCredentialsResponse
}: {
	partnerIntegration: PartnerIntegration;
	createPropertyIntegrationResponse: any;
	validateCredentialsResponse: any;
}) => void;

type OnValidationResponse = {
	valid: Boolean;
	error?: string | React.ReactElement;
};
export type OnValidationPreConfirm = (
	data: any
) => Promise<OnValidationResponse>;

export type OnValidateCredentials = (
	partnerIntegration: PartnerIntegration,
	data: any
) => Promise<OnValidationResponse>;

interface Props {
	propertyId: string;
	appData: AppData;
	onFinish: OnFinish;
	onValidationPreConfirm?: OnValidationPreConfirm;
	onValidateCredentials?: OnValidateCredentials;
}
const ConnectPartner = ({
	propertyId,
	appData,
	onFinish,
	onValidationPreConfirm,
	onValidateCredentials
}: Props) => {
	const {
		step,
		auth,
		partnerIntegrations,
		partnerIntegration,
		onSubmit,
		direction,
		onBack,
		loading,
		error,
		loadingPreload
	} = useConnectPartner({
		propertyId,
		appData,
		onFinish,
		onValidationPreConfirm,
		onValidateCredentials
	});

	const stepProps = { step, direction };
	const selectPmsProps = {
		partnerIntegrations,
		partnerIntegration,
		onSubmit,
		knowledgeBaseUrl: appData?.knowledgeBaseUrl
	};
	const authPartnerPros = {
		onBack,
		auth,
		partnerIntegration,
		onSubmit,
		loading,
		error
	};

	if (loadingPreload !== 'succeeded') {
		return (
			<div style={{ textAlign: 'center' }}>
				<Spin size="large" />
			</div>
		);
	}

	return (
		<Container>
			<StepTitle {...stepProps} />
			<motion.div
				key={step}
				initial={{ x: direction == 1 ? 10 : -10, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: direction === 1 ? -10 : 10, opacity: 0 }}
			>
				{step === 'SELECT_PARTNER' && (
					<SelectPartner {...selectPmsProps} type="radio" />
				)}
				{step === 'AUTHORIZATION' && (
					<AuthPartner {...authPartnerPros} />
				)}
			</motion.div>
		</Container>
	);
};

const Container = styled(motion.div)``;

export default ConnectPartner;
