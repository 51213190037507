import { ApiURL } from '@hospy/util-api/util-url';

interface smartlockAuthServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	data: any;
}
export const smartlockAuthService = ({
	propertyId,
	partnerIntegrationId,
	partnerIntegrationIdTo,
	appId,
	data
}: smartlockAuthServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`/api/third-party/smartlock/auth?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&partnerIntegrationIdTo=${partnerIntegrationIdTo}&appId=${appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface smartlockListServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	page?: number;
}
export const smartlockListService = ({
	propertyId,
	partnerIntegrationId,
	partnerIntegrationIdTo,
	appId,
	page
}: smartlockListServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/third-party/smartlock/lock-list?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&partnerIntegrationIdTo=${partnerIntegrationIdTo}&appId=${appId}&page=${
					page || 1
				}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface smartlockDeleteServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	data: any;
}
export const smartlockDeleteService = ({
	propertyId,
	partnerIntegrationId,
	partnerIntegrationIdTo,
	appId,
	data
}: smartlockDeleteServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`/api/third-party/smartlock/bulk?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&partnerIntegrationIdTo=${partnerIntegrationIdTo}&appId=${appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface smartlockUpdateServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	data: any;
}
export const smartlockUpdateService = ({
	propertyId,
	partnerIntegrationId,
	partnerIntegrationIdTo,
	appId,
	data
}: smartlockUpdateServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`/api/third-party/smartlock/bulk?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&partnerIntegrationIdTo=${partnerIntegrationIdTo}&appId=${appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface smartlockCreateServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	data: any;
}
export const smartlockCreateService = ({
	propertyId,
	partnerIntegrationId,
	partnerIntegrationIdTo,
	appId,
	data
}: smartlockDeleteServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`/api/third-party/smartlock/bulk?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&partnerIntegrationIdTo=${partnerIntegrationIdTo}&appId=${appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface smartlockUpdateOneServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	//partnerIntegrationIdTo: string;
	appId: string;
	data: any;
}
export const smartlockUpdateOneService = ({
	propertyId,
	partnerIntegrationId,
	//partnerIntegrationIdTo,
	appId,
	data
}: smartlockUpdateOneServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`/api/third-party/smartlock/smart-codes?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&appId=${appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
