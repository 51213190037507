/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { workTeamApi } from './api';
import { IUserWorkTeam } from './interfaces';

export const fetchUsers = createAsyncThunk(
	'workteam/getUsers',
	async (filters?: any) => {
		const response = await workTeamApi.getUsers(filters);

		return (
			response.data && response.data.length > 0 && response.data[0].teams
		);
	}
);

export const deleteCollaborator = createAsyncThunk(
	'workteam/deleteCollaborator',
	async (id: string) => {
		const response = await workTeamApi.deleteCollaborator(id);

		return response.statusCode === 202;
	}
);

export const fetchWorkteamApps = createAsyncThunk(
	'workteam/getApps',
	async () => {
		const response = await workTeamApi.fetchApps();

		return response.data;
	}
);

export const saveWorkTeam = createAsyncThunk(
	'workteam/saveWorkTeam',
	async (user: IUserWorkTeam) => {
		const response = await workTeamApi.saveWorkTeam(user);

		return response.statusCode === 201;
	}
);

export const updateWorkTeam = createAsyncThunk(
	'workteam/updateWorkTeam',
	async (user: IUserWorkTeam) => {
		const response = await workTeamApi.updateAccess(user);

		return response.statusCode === 200;
	}
);
