/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPaymentCollectState } from './interfaces';

export const initialState = {
	loading: 'idle',
	countryLoading: 'idle',
	saveReservationLoading: 'idle',
	reservationLoading: 'idle',
	updateReservationLoading: 'idle',
	hasReservations: false,
	error: {},
	success: {}
} as IPaymentCollectState;
