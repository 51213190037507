import { ApiURL } from '../util-url';

export const getMeService = (accessToken: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL('api/auth/me'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const patchMeService = (accessToken: string, data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL('api/auth/me'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const validateEmailExist: any = async (email: string) => {
	const params = new URLSearchParams({ email }).toString();
	const url = ApiURL('/api/auth/find-user?' + params);

	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	};

	return new Promise((resolve, reject) => {
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
};

export const fetchUpdatePassword = (accessToken: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL('api/auth/updatePassword'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchIsGoogleUser = (email: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ email })
		};

		fetch(ApiURL('api/auth/isGoogleUser'), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const updateUserService = (
	accessToken: string,
	_id: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`api/auth/users/${_id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
