import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { getSchedules } from './thunks';

export const schedulesSlice = createSlice({
	name: 'schedules',
	initialState,
	reducers: {
		cleanSchedules: (state) => {
			state.schedules = initialState.schedules;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSchedules.pending, (state) => {
				state.schedules = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getSchedules.rejected, (state, { payload }) => {
				state.schedules = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(getSchedules.fulfilled, (state, { payload }) => {
				state.schedules = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});
	}
});

export const { cleanSchedules } = schedulesSlice.actions;
