/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface ILoading {
	size?: string;
	texts?: string[];
}

export const Loading = ({ size, texts }: ILoading) => {
	const [text, setText] = useState<string | null>();

	useEffect(() => {
		if (!texts || texts.length === 0) return;
		setTimeout(() => {
			const textIndex = texts.findIndex(
				(textIterator) => textIterator === text
			);

			if (textIndex < 0 || textIndex >= texts.length - 1) {
				return setText(texts[0]);
			}
			setText(texts[textIndex + 1]);
		}, 6000);

		return;
	}, [texts, text]);

	return (
		<div
			style={{
				width: size || '100%',
				height: size || '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				gap: 15,
				marginBottom: 15,
				alignItems: 'center',
				position: 'fixed',
				zIndex: 60,
				top: 0,
				left: 0,
				backdropFilter: 'blur(3px)',
				background: '#ffffff54'
			}}
		>
			{text && <TitleLoading>{text}</TitleLoading>}
			<Spin />
		</div>
	);
};

const TitleLoading = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.middle}px;
`;
