import { fetchCreateCompany, fetchGetCompanies } from "@hospy/util-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Find } from "./interface";

export const getCompanies = createAsyncThunk(
    'partner-companies/getCompanies',
    async (find: Find, { rejectWithValue }) => {
        try {
            const response = await fetchGetCompanies(find);
            return response;
        } catch (error: any) {
            console.error(error);
            rejectWithValue("Error")
        }
    }
)

export const createCompanies = createAsyncThunk(
    'partner-companies/createCompanies',
    async (data, { rejectWithValue }) => {
        try {
            const response = await fetchCreateCompany(data);
            return response;
        } catch (error: any) {
            console.error(error);
            rejectWithValue("Error")
        }
    }
)