import {
	setCurrentProperty,
	setPropertyId,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect, useState } from 'react';
import useLogin from '../../pages/login/hook/use-login';
import { LoadingAppView } from '../loading-app-view';

type Props = {
	children: React.ReactElement | React.ReactElement[];
};

export const ValidateAuth = ({ children }: Props) => {
	const dispatch = useAppDispatch();

	const { user } = useAppSelector(({ user }) => user);
	const { currentProperty } = useAppSelector(({ user }) => user);
	// TODO: En este escenario el hook useLogin se le pasa la prop 'disableUseNavigate',
	// TODO: ya que este componente se utiliza fuera del contexto de las rutas.
	const { loadSessionInfo } = useLogin({ disableUseNavigate: true });
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetch = async () => {
			//TODO Revisar que se ejecuta dos veces despues del logout
			await loadSessionInfo();

			window.addEventListener(
				'storage',
				({ key, newValue }: any) => {
					const { host } = window.location;
					if (key === `${host}-currentProperty`) {
						if (localStorage.getItem('userIdView')) {
							window.location.reload();
							return;
						}
						dispatch(setCurrentProperty(newValue));
						dispatch(setPropertyId(newValue));
					}
				},
				false
			);

			setLoading(false);
		};
		fetch();

		return () => {
			window.removeEventListener('storage', () => {});
		};
	}, []);

	useEffect(() => {
		if (!currentProperty && user?.currentProperty) {
			const { host } = window.location;
			if (!localStorage.getItem(`${host}-currentProperty`)) {
				localStorage.setItem(
					`${host}-currentProperty`,
					user?.currentProperty
				);
			}
			let _propertyId = localStorage.getItem(`${host}-currentProperty`);
			dispatch(setCurrentProperty(_propertyId));
			dispatch(setPropertyId(_propertyId));
		}
	}, [currentProperty, user?.currentProperty]);

	if (loading) return <LoadingAppView />;

	return <>{children}</>;
};
