import { createSlice, current } from '@reduxjs/toolkit';
import {
	AFIP_INVOICE_DETAIL_SECTIONS,
	afipInitialState
} from './afip.constants';
import {
	colppyGetFieldMapping,
	colppyGetDiaryAccounts,
	afipGetFieldMapping,
	afipGetInvoiceReport,
	afipGetStamp,
	afipGetSummaryReport,
	afipInvoiceCancelStamp,
	afipInvoiceStamp,
	afipUpdateFieldMapping,
	afipUpdateInvoiceStamp,
	afipGetAccountingPaymentMethods,
	afipGetAccommodations,
	afipGetOriginProducts
} from './afip.thunks';

export const afipSlice = createSlice({
	name: 'afip',
	initialState: afipInitialState,
	reducers: {
		afipResetInvoiceValidation: (state) => {
			state.invoiceValidation = AFIP_INVOICE_DETAIL_SECTIONS;
		},
		afipUpdateInvoiceValidation: (state, { payload }) => {
			state.invoiceValidation = payload;
		},
		afipSetStamp: (state, { payload }) => {
			state.stamp = payload;
		},
		afipResetInvoiceCancelStamp: (state) => {
			state.cancelStampInvoice = {
				loading: 'idle'
			};
		},
		afipResetUpdateStampInvoice: (state) => {
			state.updateStampInvoice = {
				loading: 'idle',
				error: undefined
			};
		},
		afipRestColppyFieldMapping: (state) => {
			state.colppyFieldMapping = {
				loading: 'idle',
				error: undefined,
				data: undefined
			};
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(afipInvoiceStamp.pending, (state) => {
				state.stamp = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(afipInvoiceStamp.rejected, (state, { payload }) => {
				state.stamp = {
					loading: 'failed',
					error: payload,
					data: undefined
				};
			})
			.addCase(afipInvoiceStamp.fulfilled, (state, { payload }) => {
				state.stamp = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(afipUpdateInvoiceStamp.pending, (state) => {
				state.updateStampInvoice = {
					loading: 'pending',
					error: undefined
				};
			})
			.addCase(afipUpdateInvoiceStamp.rejected, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(afipUpdateInvoiceStamp.fulfilled, (state, { payload }) => {
				state.updateStampInvoice = {
					loading: 'succeeded',
					error: undefined
				};
			});

		builder
			.addCase(afipInvoiceCancelStamp.pending, (state) => {
				state.cancelStampInvoice = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(afipInvoiceCancelStamp.rejected, (state, { payload }) => {
				state.cancelStampInvoice = {
					loading: 'failed',
					error: payload,
					data: undefined
				};
			})
			.addCase(afipInvoiceCancelStamp.fulfilled, (state, { payload }) => {
				state.cancelStampInvoice = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(afipGetStamp.pending, (state) => {
				state.stamp.loading = 'pending';
			})
			.addCase(afipGetStamp.rejected, (state, { payload }) => {
				state.stamp.loading = 'failed';
				state.stamp.error = payload;
			})
			.addCase(afipGetStamp.fulfilled, (state, { payload }) => {
				state.stamp.loading = 'succeeded';
				state.stamp.data = payload;
			});

		builder
			.addCase(afipGetFieldMapping.pending, (state) => {
				state.fieldMapping = {
					loading: 'pending'
				};
			})
			.addCase(afipGetFieldMapping.rejected, (state, { payload }) => {
				state.fieldMapping = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(afipGetFieldMapping.fulfilled, (state, { payload }) => {
				state.fieldMapping = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(colppyGetFieldMapping.pending, (state) => {
				state.colppyFieldMapping = {
					loading: 'pending'
				};
			})
			.addCase(colppyGetFieldMapping.rejected, (state, { payload }) => {
				state.colppyFieldMapping = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(colppyGetFieldMapping.fulfilled, (state, { payload }) => {
				state.colppyFieldMapping = {
					loading: 'succeeded',
					data: payload
				};
			});

		builder
			.addCase(afipGetAccommodations.pending, (state) => {
				state.afipAccommodations.loading = 'pending';
			})
			.addCase(afipGetAccommodations.rejected, (state, { payload }) => {
				state.afipAccommodations.loading = 'failed';
				state.afipAccommodations.error = String(payload);
			})
			.addCase(afipGetAccommodations.fulfilled, (state, { payload }) => {
				state.afipAccommodations.data = payload;
				state.afipAccommodations.loading = 'succeeded';
			});

		builder
			.addCase(afipUpdateFieldMapping.pending, (state) => {
				state.updatedColppyFieldMapping = {
					loading: 'pending',
					error: undefined
				};
			})
			.addCase(afipUpdateFieldMapping.rejected, (state, { payload }) => {
				state.updatedColppyFieldMapping = {
					loading: 'failed',
					error: payload as string
				};
			})
			.addCase(afipUpdateFieldMapping.fulfilled, (state, { payload }) => {
				if (state.colppyFieldMapping.data?.fields) {
					let fields = current(state.colppyFieldMapping?.data.fields);
					if (payload.updateMode === 'pull') {
						fields = fields.filter(
							(e: any) => !payload.items.includes(e._id)
						);
					} else if (payload.updateMode === 'push') {
						fields = payload.items.fields;
					} else {
						fields = fields.map((e: any) => {
							const el = payload.items.find(
								(i: any) => i._id === e._id
							);
							if (el) {
								return { ...e, ...el };
							}
							return { ...e };
						});
					}
					state.colppyFieldMapping.data.fields = fields;
					state.updatedColppyFieldMapping = {
						loading: 'succeeded',
						error: undefined
					};
				}
			});

		builder
			.addCase(
				afipGetAccountingPaymentMethods.pending,
				(state, { meta }) => {
					const stateName =
						meta.arg.type === 'internal'
							? 'afipPaymentMethodsInternal'
							: 'afipPaymentMethodsExternal';

					state[stateName] = {
						loading: 'pending',
						error: undefined,
						data: []
					};
				}
			)
			.addCase(
				afipGetAccountingPaymentMethods.rejected,
				(state, { payload, meta }) => {
					const stateName =
						meta.arg.type === 'internal'
							? 'afipPaymentMethodsInternal'
							: 'afipPaymentMethodsExternal';
					state[stateName] = {
						loading: 'failed',
						error: String(payload),
						data: []
					};
				}
			)
			.addCase(
				afipGetAccountingPaymentMethods.fulfilled,
				(state, { payload, meta }) => {
					const stateName =
						meta.arg.type === 'internal'
							? 'afipPaymentMethodsInternal'
							: 'afipPaymentMethodsExternal';
					state[stateName] = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(afipGetOriginProducts.pending, (state) => {
				state.originProducts.loading = 'pending';
			})
			.addCase(afipGetOriginProducts.rejected, (state, payload: any) => {
				state.originProducts.loading = 'failed';
				state.originProducts.error = payload;
			})
			.addCase(
				afipGetOriginProducts.fulfilled,
				(state, { payload }: any) => {				
					state.originProducts.data = payload;
					state.originProducts.loading = 'succeeded';
				}
			);

		builder
			.addCase(colppyGetDiaryAccounts.pending, (state) => {
				state.colppyDiaryAccounts.loading = 'pending';
			})
			.addCase(colppyGetDiaryAccounts.rejected, (state, { payload }) => {
				state.colppyDiaryAccounts.loading = 'failed';
				state.colppyDiaryAccounts.error = String(payload);
			})
			.addCase(colppyGetDiaryAccounts.fulfilled, (state, { payload }) => {
				console.log(payload);
				state.colppyDiaryAccounts.data = payload;
				state.colppyDiaryAccounts.loading = 'succeeded';
			});

		builder
			.addCase(afipGetInvoiceReport.pending, (state) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'pending'
				};
			})
			.addCase(afipGetInvoiceReport.rejected, (state, { payload }) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(afipGetInvoiceReport.fulfilled, (state, { payload }) => {
				state.invoiceReports = {
					...state.invoiceReports,
					loading: 'succeeded',
					data: payload?.paginating
						? [
								...state.invoiceReports.data,
								...(payload?.data || [])
						  ]
						: payload?.data,
					meta: {
						...payload?.meta
					}
				};
			});

		builder
			.addCase(afipGetSummaryReport.pending, (state) => {
				state.summaryReports = {
					...state.summaryReports,
					loading: 'pending'
				};
			})
			.addCase(afipGetSummaryReport.rejected, (state, { payload }) => {
				state.summaryReports = {
					...state.summaryReports,
					loading: 'failed',
					error: payload
				};
			})
			.addCase(afipGetSummaryReport.fulfilled, (state, { payload }) => {
				state.summaryReports = {
					loading: 'succeeded',
					data: payload?.data,
					meta: payload?.meta
				};
			});
	}
});

export const {
	afipResetInvoiceValidation,
	afipUpdateInvoiceValidation,
	afipSetStamp,
	afipResetInvoiceCancelStamp,
	afipResetUpdateStampInvoice,
	afipRestColppyFieldMapping
} = afipSlice.actions;
