import { Button, Modal } from '@hospy/hospy-ui';
import { useAppSelector } from '@hospy/store';
import { Typography } from 'antd';
import styled from 'styled-components';

interface Props {
	open: boolean;
	onClose: () => void;
}

export const MaintenanceModal = ({ open, onClose }: Props) => {
	const { appData } = useAppSelector(({ common }) => common);

	return (
		<Modal onClose={onClose} open={open} closable={false}>
			<ModalWrapper>
				<Title>Problemas de conexión con {appData?.name?.es}</Title>
				<Text>{appData?.maintenanceMessage}</Text>
				<Button onClick={onClose} size="large" type="primary">
					Entendido
				</Button>
			</ModalWrapper>
		</Modal>
	);
};

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	padding-inline: 80px;

	button {
		width: 190px;
		height: 60px;
	}
`;

const Title = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.large}px;
`;

const Text = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.large}px;
`;
