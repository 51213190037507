/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IReservationInvoice,
	setInvoiceToBulk,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { message } from 'antd';
import { Reorder } from 'framer-motion';
import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType, MAX_RESERVATIONS_TO_BULK
} from '../../../../context';
import InvoiceItemCardSkeleton from '../../skeleton';
import InvoiceItemCard from './card';

interface Props {
	selectReservation: (invoice: any, index: number) => void;
	reservations: any[];
	invoiceLoading: boolean;
	invoice: any;
	multipleInvoices?: boolean;
}

const MainInvoice = ({
	selectReservation,
	reservations,
	invoice,
	invoiceLoading,
	multipleInvoices
}: Props) => {
	const dispatch = useAppDispatch();
	const { actionCloseDetail, loading, selectedSource, partnerIntegrationId } =
		useContext(InvoiceFilterContext) as InvoiceFilterContextType;

	const [items, setItems] = useState<any[]>([0, 1, 2, 3]);

	const { reservationToBulk } = useAppSelector(
		({ partnerAccounting }) => partnerAccounting
	);

	const onSelectBulk = (reservation: IReservationInvoice) => {
		const existsIndex = reservationToBulk.findIndex(
			(r) => r.reservationID === reservation.reservationID
		);

		let reservationToBulkUpdated = [];

		if (existsIndex > -1) {
			reservationToBulkUpdated = reservationToBulk.filter(
				(r) => r.reservationID !== reservation.reservationID
			);
		} else {
			if (reservationToBulk.length >= MAX_RESERVATIONS_TO_BULK) {
				message.error('Solo puedes seleccionar hasta 9 reservas');
				return;
			}
			reservationToBulkUpdated = [...reservationToBulk, reservation];
		}

		dispatch(setInvoiceToBulk(reservationToBulkUpdated));
	};

	return (
		<MainInvoiceList maxColumn={selectedSource && 1}>
			{loading ? (
				<>
					{[0, 1, 2].map((e) => (
						<InvoiceItemCardSkeleton key={e} />
					))}
				</>
			) : (
				<>
					{selectedSource !== null && (
						<MainInvoiceListOpened
							axis="y"
							values={items}
							onReorder={setItems}
						>
							{reservations?.map(
								(reservation: any, index: number) => (
									<Reorder.Item
										key={`Invoice-card-key-${reservation.reservationID}`}
										value={reservation}
									>
										<InvoiceItemCard
											multipleInvoices={multipleInvoices}
											reservation={reservation}
											selectInvoice={() =>
												actionCloseDetail(() => {
													window.scroll({
														top: 0,
														left: 0,
														behavior: 'smooth'
													});

													selectReservation(
														reservation,
														index
													);
												})
											}
											detailOpened={
												selectedSource !== null
											}
											isSelected={
												(
													selectedSource as IReservationInvoice
												)?.reservationID ===
												reservation.reservationID
											}
											invoice={invoice}
											invoiceLoading={invoiceLoading}
											partnerIntegrationId={
												partnerIntegrationId
											}
											onSelectBulk={onSelectBulk}
											selectedToBulk={reservationToBulk?.some(
												(r) =>
													r.reservationID ===
													reservation.reservationID
											)}
											maxReservationsSelected={
												reservationToBulk.length > 8
											}
										/>
									</Reorder.Item>
								)
							)}
						</MainInvoiceListOpened>
					)}

					{selectedSource === null &&
						reservations?.map(
							(
								reservation: IReservationInvoice,
								index: number
							) => (
								<InvoiceItemCard
									key={`Invoice-card-key-${reservation.reservationID}`}
									reservation={reservation}
									selectInvoice={() =>
										actionCloseDetail(() => {
											selectReservation(
												reservation,
												index
											);
											window.scroll({
												top: 0,
												left: 0,
												behavior: 'smooth'
											});
										})
									}
									detailOpened={selectedSource !== null}
									isSelected={false}
									invoice={invoice}
									invoiceLoading={invoiceLoading}
									multipleInvoices={multipleInvoices}
									partnerIntegrationId={partnerIntegrationId}
									selectedToBulk={reservationToBulk?.some(
										(r) =>
											r.reservationID ===
											reservation.reservationID
									)}
									onSelectBulk={onSelectBulk}
									maxReservationsSelected={
										reservationToBulk.length >= MAX_RESERVATIONS_TO_BULK
									}
								/>
							)
						)}
				</>
			)}
		</MainInvoiceList>
	);
};

const MainInvoiceList = styled(({ maxColumn, ...props }) => <div {...props} />)`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	display: grid;
	gap: 30px;

	${({ theme }) =>
		theme.screens.lg &&
		css`
			grid-template-columns: repeat(2, 1fr);
		`}

	${({ theme }) =>
		theme.screens.xl &&
		css`
			grid-template-columns: repeat(
				auto-fill,
				clamp(380px, 100%/3 - 20px, 580px)
			);
		`}
		
	${({ maxColumn }) =>
		maxColumn &&
		css`
			grid-template-columns: repeat(${maxColumn}, 1fr);
		`}
`;

const MainInvoiceListOpened = styled(Reorder.Group)`
	display: grid;
	gap: 30px;
	grid-template-columns: 1fr;

	li {
		list-style: none;
	}
`;

export default MainInvoice;
