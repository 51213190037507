import { Async, InitialState } from './interface';

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const initialState: InitialState = {
	getProductsImports: initialAsync,
	createProductsImports: initialAsync,
	deleteProductsImports: initialAsync,
	mappingDynamic: initialAsync,
	deleteSync: initialAsync,
	propertyIntegrations: initialAsync
};
