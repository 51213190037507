import { Grid } from 'antd';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { colors } from './colors';

const { useBreakpoint } = Grid;

interface HospyThemeProviderProp {
	children: React.ReactElement | React.ReactElement[];
	colors?: any;
	theme?: 'light' | 'dark';
}

export const HospyThemeProvider = (props: HospyThemeProviderProp) => {
	const { children, colors: customColors } = props;
	const screens = useBreakpoint();
	const [theme, setTheme] = useState<DefaultTheme>({
		screens,
		colors: {
			...colors,
			...customColors
		},
		input: {
			height: {
				small: 34,
				middle: 42,
				large: 50
			}
		},
		title: {
			size: {
				small: 20,
				middle: 23,
				large: 25
			}
		},
		text: {
			size: {
				small: 12,
				middle: 15,
				large: 18
			}
		},
		boxShadow: {
			level1: (color?: string) =>
				`0px 2px 4px ${color || 'rgba(0, 0, 0, 0.1)'}`,
			level2: (color?: string) =>
				`0px 4px 7px ${color || 'rgba(0, 0, 0, 0.1)'}`,
			level3: (color?: string) =>
				`0px 8px 15px ${color || 'rgba(0, 0, 0, 0.1)'}`
		},
		maxWidth: 1264
	});

	useEffect(() => {
		const updateScreens = produce(theme, (draft: any) => {
			draft.screens = screens;
		});
		setTheme(updateScreens);
	}, [screens]);

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
