/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	getPropertyPublicService,
	getReservationDataService,
	updateReservationDataService,
	validateDocumentAirbnbReservationService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { Reservation } from '../common/common.types';

export const getAirbnbReservationProperty = createAsyncThunk(
	'airbnbReservation/property',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await getPropertyPublicService(propertyId);
			if (errors)
				return rejectWithValue(
					`Error al intentar cargar la información del negocio, ${errors}`
				);

			return data.length > 0 ? data[0] : undefined;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue(
				`Error al intentar cargar la información del negocio, ${error}`
			);
		}
	}
);

interface GetAirbnbReservationProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationCode?: string;
	reservationId?: string;
	fields?: string;
}
export const getAirbnbReservation = createAsyncThunk(
	'airbnbReservation/reservation',
	async (props: GetAirbnbReservationProps, { rejectWithValue }) => {
		try {
			const { data, errors } = await getReservationDataService(props);
			if (errors)
				return rejectWithValue(`Error al cargar la reserva, ${errors}`);
			if (props.reservationId)
				return {
					...data,
					checkInDate: moment(
						data.checkInDate.substring(0, 10)
					).toDate(),
					checkOutDate: moment(
						data.checkOutDate.substring(0, 10)
					).toDate()
				};

			return data.length > 0
				? {
						...data[0],
						checkInDate: moment(
							data[0].checkInDate.substring(0, 10)
						).toDate(),
						checkOutDate: moment(
							data[0].checkOutDate.substring(0, 10)
						).toDate()
				  }
				: undefined;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(`Error al cargar la reserva, ${error}`);
		}
	}
);

interface UpdateAirbnbReservationProps {
	id: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	data: Reservation | { specific: any };
	arrayFilters?: any[] | string;
	updateMode?: 'pull' | 'push';
	filter?: string;
}

export const updateAirbnbReservation = createAsyncThunk(
	'airbnbReservation/updateReservation',
	async (props: UpdateAirbnbReservationProps, { rejectWithValue }) => {
		try {
			const { data, errors } = await updateReservationDataService(
				props.id,
				{
					appId: props.appId,
					partnerIntegrationId: props.partnerIntegrationId,
					propertyId: props.propertyId,
					filter: props.filter
						? props.filter
						: JSON.stringify({
								arrayFilters: props.arrayFilters,
								updateMode: props.updateMode
						  })
				},
				props.data
			);
			let error = errors;
			if (errors && errors?.length) {
				error = errors[0]?.message || errors;
			}
			if (errors)
				return rejectWithValue(`Error al cargar la reserva, ${error}`);

			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(`Error al cargar la reserva, ${error}`);
		}
	}
);

interface ValidateDocumentAirbnbReservationProps {
	appId: string;
	partnerIntegrationId: string;
	propertyId: string;
	resource: string;
	expectedDocument: string;
	name: string;
	contentType: string;
	data: string;
}

export const validateDocumentAirbnbReservation = createAsyncThunk(
	'airbnbReservation/validateDocumentReservation',
	async (
		props: ValidateDocumentAirbnbReservationProps,
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } =
				await validateDocumentAirbnbReservationService(props);
			let error = errors;
			if (errors && errors?.length) {
				error = errors[0]?.message || errors;
			}
			if (errors)
				return rejectWithValue(
					`Error al cargar el documento, ${error}`
				);

			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(`Error al cargar el documento, ${error}`);
		}
	}
);
