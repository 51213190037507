import { ApiURL } from '../util-url';

export const postGuestReport = (data: unknown): Promise<any> => {
	const accessToken = localStorage.getItem('Hospy-idToken');
	const url = ApiURL(`api/guest-report/reports`);
	const requestOptions = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		}
	};
	return fetch(url, requestOptions).then((response) => response.json());
};

export const getGuestReports = (filter: {
	query?: string;
	propertyId: string;
	page?: number;
}): Promise<any> => {
	const { query, propertyId, page } = filter;
	const accessToken = localStorage.getItem('Hospy-idToken');
	const guestFullName = query
		? `,"guests.fullName":{"$regex":/${query}/i}`
		: '';
	const params = new URLSearchParams({
		limit: '10',
		page: `${page || '1'}`,
		sort: '{"createdAt":"-1"}',
		filter: `{"propertyId":"${propertyId}"${guestFullName}}`
	});
	const url = ApiURL(`api/guest-report/reports?`) + params;
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		}
	};
	return fetch(url, requestOptions).then((response) => response.json());
};

export const deleteGuestReport = (id: string): Promise<any> => {
	const accessToken = localStorage.getItem('Hospy-idToken');
	const url = ApiURL(`api/guest-report/reports/${id}`);
	const requestOptions = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		}
	};
	return fetch(url, requestOptions).then((response) => response.json());
};

export const getGuestReportCategories = (): Promise<any> => {
	const accessToken = localStorage.getItem('Hospy-idToken');
	const url = ApiURL(`api/guest-report/categories?`);
	const requestOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`
		}
	};
	return fetch(url, requestOptions).then((response) => response.json());
};
