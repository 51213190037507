/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	PARTNER,
	accountingGetDestinyProducts,
	getFieldMapping,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Form, UploadProps, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
	status: string;
	getIntegration: () => void;
	open: boolean;
}

interface PartnerResources {
	products?: boolean;
}

const useUploadReservationTotal = ({ status, getIntegration, open }: Props) => {
	const dispatch = useAppDispatch();

	const [form] = Form.useForm();

	const product = Form.useWatch('product-siigo', form);

	const [inputFile, setInputFile] = useState<File | null>(null);
	const [splitCleaning, setSplitCleaning] = useState<boolean>(false);
	const [availableRetry, setAvailableRetry] = useState<PartnerResources>({
		products: false
	});

	const { destinyProducts } = useAppSelector(
		({ siigoConnect }) => siigoConnect
	);
	const { fieldMapping } = useAppSelector(({ onboarding }) => onboarding);
	const { propertyId, propertyIntegrationsState } = useAppSelector(
		({ user }) => user
	);
	const { appData } = useAppSelector(({ common }) => common);

	const fullProduct = useMemo(() => {
		if (!product || !destinyProducts?.data) return undefined;
		return destinyProducts?.data.find(
			(destinyProduct: any) => destinyProduct.code === product
		);
	}, [product]);

	const propsUpload: UploadProps = {
		name: 'file',
		accept: 'text/csv',
		showUploadList: false,
		customRequest: ({ onSuccess }: any) => onSuccess(),
		beforeUpload: async (file, l) => {
			const isLt2M = file.size / 1024 / 1024 < 6;
			if (!isLt2M) {
				message.error('El archivo debe ser de máximo 6MB');
				return;
			}
			setInputFile(file);
			return false;
		}
	};

	const getDestinyProducts = useCallback(() => {
		if (!appData || !propertyId) return;

		dispatch(
			accountingGetDestinyProducts({
				appId: appData?._id,
				partnerIntegrationId: PARTNER.SIIGO,
				propertyId: propertyId
			})
		);
	}, [appData, propertyId]);

	useEffect(() => {
		if (!appData?._id || !propertyId || destinyProducts.loading !== 'idle')
			return;
		getDestinyProducts();
	}, [appData, propertyId]);

	useEffect(() => {
		if (status !== 'pending' || !open) return;
		getIntegration();
	}, [status, open]);

	useEffect(() => {
		if (!propertyIntegrationsState.data || fieldMapping.loading !== 'idle')
			return;
		const pmsIntegration = propertyIntegrationsState.data?.find(
			(integration) =>
				['pms', 'ota'].includes(integration.partnerIntegrationType) &&
				integration.appId === appData?._id
		);
		const financeIntegration = propertyIntegrationsState.data?.find(
			(integration) =>
				integration.partnerIntegrationType === 'finance' &&
				integration.appId === appData?._id
		);
		if (propertyId && pmsIntegration?._id && financeIntegration?._id) {
			dispatch(
				getFieldMapping({
					propertyIntegrationId: pmsIntegration._id || '',
					propertyIntegrationIdTo: financeIntegration._id || '',
					partnerIntegrationId: PARTNER.SIIGO,
					propertyId: propertyId
				})
			);
		}
	}, [
		propertyId,
		propertyIntegrationsState?.data?.length,
		fieldMapping.loading
	]);

	useEffect(() => {
		if (destinyProducts.loading === 'failed') {
			setAvailableRetry((state) => ({ ...state, products: true }));
		}

		if (destinyProducts.loading === 'succeeded' && destinyProducts.data) {
			setAvailableRetry((state) => ({ ...state, products: false }));
			const availableMappings = fieldMapping?.data?.fields.find(
				(field) => field.internalSlug === 'cleaning'
			);
			if (!availableMappings) {
				form.setFieldValue('product-siigo', undefined);
				return;
			}

			const valuesIsAnOptions = destinyProducts.data?.find(
				(product: any) =>
					product.code === availableMappings.externalSlug
			);

			form.setFieldsValue({
				'product-siigo': valuesIsAnOptions
					? valuesIsAnOptions.code
					: undefined
			});
		}
	}, [destinyProducts.loading, splitCleaning]);

	const saveDisabledTooltip = useMemo(() => {
		if (!inputFile) return 'No se ha seleccionado un archivo';
		if (splitCleaning && !fullProduct)
			return 'No se ha seleccionado un producto';
		return undefined;
	}, [fullProduct, inputFile, splitCleaning]);

	return {
		propsUpload,
		inputFile,
		setInputFile,
		splitCleaning,
		setSplitCleaning,
		products: destinyProducts.data?.map(
			(product: any) =>
				({
					label: product.name,
					value: product.code
				} as { label: string; value: string })
		),
		loadingProducts: destinyProducts.loading === 'pending',
		availableRetry,
		getDestinyProducts,
		form,
		fullProduct,
		saveDisabledTooltip,
		fieldMappingLoading: fieldMapping.loading === 'pending'
	};
};

export default useUploadReservationTotal;
