/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { Loading } from '../common/common.types';
import { initialState } from './user.constants';
import {
	addProperty,
	deleteProperty,
	fetchExportsCsvUsers,
	fetchFindUsers,
	fetchSelectedProperty,
	fetchUpdate,
	fetchUserInformation,
	getProperty,
	getPropertyIntegrations,
	getPropertyIntegrationsByContext,
	patchProperty,
	updateProperty,
	updatePropertyIntegration,
	updatePropertyIntegrationExtras,
	updateUserForAdmin,
	validateUserExists,
	verifyEmailOrPhoneUpdate
} from './user.thunks';

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setListingConfigTimeSubmit: (state, action) => {
			state.listingConfigTimeSubmit = action.payload;
		},
		setPropertyId: (state, action) => {
			state.propertyId = action.payload;
			const { host } = window.location;
			if (action.payload)
				localStorage.setItem(`${host}-currentProperty`, action.payload);
		},

		logIn: (state) => {
			state.isAuth = true;
			localStorage.setItem(`Hospy-isAuth`, 'true');
		},
		logOut: (state) => {
			state.user = undefined;
			state.isAuth = false;
			const { host } = window.location;
			localStorage.setItem(`Hospy-isAuth`, 'false');
			localStorage.removeItem(`${host}-currentProperty`);
			localStorage.removeItem('type_login');

			localStorage.removeItem('Hospy-idToken');
			localStorage.removeItem('Hospy-expiration');
			localStorage.removeItem('userIdView');
		},
		setUserLoading: (state, action: PayloadAction<Loading>) => {
			state.loading = action.payload;
		},
		cleanSuccess: (state: any, action) => {
			state.success[action.payload] = undefined;
		},
		cleanError: (state: any, action) => {
			state.error[action.payload] = undefined;
		},
		setStateUser: (state: any, { payload }) => {
			state[payload.key] = payload.newValue;
		},
		setCurrentProperty: (state: any, action) => {
			state.currentProperty = action.payload;
			const { host } = window.location;
			if (action.payload)
				localStorage.setItem(`${host}-currentProperty`, action.payload);
		},
		setActiveApp: (state: any, action) => {
			const user = current(state.user);
			const currentProperty = state.currentProperty;
			const { properties, ...rest } = user;
			const _currenProperty = {
				...properties.find((e: any) => e._id === currentProperty)
			};
			if (!_currenProperty.apps) _currenProperty.apps = [];
			_currenProperty.apps = [..._currenProperty.apps, action.payload];
			state.user = {
				...rest,
				properties: [
					...properties.filter((e: any) => e._id !== currentProperty),
					_currenProperty
				]
			};
		},
		setAppName: (state: any, action) => {
			state.appName = action.payload;
		},
		resetAddProperty: (state) => {
			state.addProperty = {
				loading: 'idle',
				error: ''
			};
		},
		resetDeleteProperty: (state) => {
			state.deleteProperty = {
				loading: 'idle',
				error: ''
			};
		},
		resetUpdateProperty: (state) => {
			state.updateProperty = {
				loading: 'idle',
				error: ''
			};
		},
		setPropertyIdToUpdate: (state, action) => {
			state.propertyIdToUpdate = action.payload;
		},
		resetPropertyIdToUpdate: (state) => {
			state.propertyIdToUpdate = undefined;
		},
		clearValidateUserExists: (state) => {
			state.error.validateUserExists =
				initialState.error.validateUserExists;
			state.success.validateUserExists =
				initialState.success.validateUserExists;

			state.foundUser = initialState.foundUser;
		},
		setPropertyIntegrationsData: (state, { payload }) => {
			let _propertyIntegrations = current(state.propertyIntegrations);

			if (!_propertyIntegrations) _propertyIntegrations = [];

			_propertyIntegrations = [..._propertyIntegrations, ...payload];

			state.propertyIntegrations = _propertyIntegrations;

			const _propertyIntegrationsState = current(
				state.propertyIntegrationsState
			);

			if (!_propertyIntegrationsState.data) {
				_propertyIntegrationsState.data = [];
			}

			state.propertyIntegrationsState = {
				loading: 'succeeded',
				data: [..._propertyIntegrationsState.data, ...payload],
				error: ''
			};
		},
		removePropertyIntegrations: (state, { payload }) => {
			const _propertyIntegrations = current(state.propertyIntegrations);
			if (_propertyIntegrations) {
				if (typeof payload === 'string') {
					state.propertyIntegrations = _propertyIntegrations.filter(
						({ _id }) => _id !== payload
					);
				} else {
					state.propertyIntegrations = _propertyIntegrations.filter(
						({ _id }) => !payload.includes(_id)
					);
				}
			}

			const _propertyIntegrationsState = current(
				state.propertyIntegrationsState
			);
			if (_propertyIntegrationsState.data) {
				if (typeof payload === 'string') {
					state.propertyIntegrationsState.data =
						_propertyIntegrationsState.data.filter(
							({ _id }) => _id !== payload
						);
				} else {
					state.propertyIntegrationsState.data =
						_propertyIntegrationsState.data.filter(
							({ _id }) => !payload.includes(_id)
						);
				}
			}
		},
		updatePropertyIntegrationManually: (state, { payload }) => {
			state.propertyIntegrations = payload;
			state.propertyIntegrationsState = {
				...state.propertyIntegrationsState,
				data: payload
			};
		},
		updatePropertyIntegrationManually2: (state, { payload }) => {
			state.propertyIntegrationsState = {
				...payload
			};
		},
		cleanPropertyIntegrations: (state) => {
			state.propertyIntegrations = initialState.propertyIntegrations;
			state.propertyIntegrationsState =
				initialState.propertyIntegrationsState;
		},
		cleanUpdatePropertyIntegration: (state) => {
			state.updatedPropertyIntegration =
				initialState.updatedPropertyIntegration;
		},
		replacePropertyIntegrations: (state, { payload }) => {
			state.propertyIntegrations = payload;

			state.propertyIntegrationsState = {
				loading: 'succeeded',
				data: payload,
				error: ''
			};
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getProperty.fulfilled, (state, action) => {
				state.loading = 'succeeded';
				state.property = action.payload;
			})
			.addCase(getProperty.pending, (state) => {
				state.property = undefined;
				state.propertyIntegrations = undefined;
			})

			.addCase(getPropertyIntegrations.pending, (state) => {
				state.propertyIntegrations = undefined;
				state.propertyIntegrationsState = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(getPropertyIntegrations.rejected, (state) => {
				state.propertyIntegrations = undefined;
				state.propertyIntegrationsState = {
					loading: 'failed',
					data: undefined,
					error: ''
				};
			})
			.addCase(
				getPropertyIntegrations.fulfilled,
				(state, { payload }) => {
					state.propertyIntegrations = payload;
					state.propertyIntegrationsState = {
						loading: 'succeeded',
						data: payload,
						error: ''
					};
				}
			)

			.addCase(fetchUserInformation.pending, (state) => {
				state.loading2 = 'pending';
			})
			.addCase(fetchUserInformation.fulfilled, (state, action) => {
				state.user = action.payload;
				state.loading2 = 'idle';
				if (!action.payload) state.isAuth = false;
			})
			.addCase(fetchSelectedProperty.fulfilled, (state, action) => {
				state.user = action.payload;
			})
			.addCase(verifyEmailOrPhoneUpdate.fulfilled, (state, action) => {
				const payload: any = action.payload;
				if (payload.response === 'SUCCESS')
					state.success = {
						...state.success,
						verifyEmailOrPhoneUpdate: payload.type
					};
				else {
					state.error = {
						...state.error,
						verifyEmailOrPhoneUpdate: payload.error
					};
				}
			})
			.addCase(validateUserExists.pending, (state, action) => {
				state.validateLoading = 'pending';
			})
			.addCase(validateUserExists.fulfilled, (state, action) => {
				state.validateLoading = 'succeeded';
				const { payload } = action;
				if (payload) {
					state.foundUser = payload;
					state.success = {
						...state.success,
						validateUserExists: true
					};
				} else {
					state.error = {
						...state.error,
						validateUserExists: true
					};
				}
			})
			.addCase(fetchUpdate.fulfilled, (state: any, action) => {
				const payload: any = action.payload;

				if (payload.data) {
					const updateKeys: Array<string> = [],
						removeKeys: Array<string> = [];
					payload.updateKeys.map((key: string) => {
						if (payload.data[key]) {
							updateKeys.push(key);
						} else {
							removeKeys.push(key);
						}
						state.user[key] = payload.data[key];
					});

					state.success = {
						...state.success,
						fetchUpdate: { updateKeys, removeKeys }
					};
				} else {
					state.error = {
						...state.error,
						fetchUpdate: payload.error
					};
				}
			})

			.addCase(addProperty.pending, (state) => {
				state.addProperty = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(addProperty.rejected, (state, { payload }) => {
				state.addProperty = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(addProperty.fulfilled, (state, { payload }) => {
				if (state.user) {
					state.user = {
						...state.user,
						properties: [
							...(state.user?.properties || []),
							{
								_id: payload._id,
								name: payload.name,
								rol: 'owner',
								country_iso: payload.location.country.iso,
								apps: []
							}
						]
					};
				}

				if (!state.currentProperty) {
					state.currentProperty = payload._id;
					state.propertyId = payload._id;
				}

				state.addProperty = {
					loading: 'succeeded',
					error: ''
				};
			})

			.addCase(updateProperty.pending, (state) => {
				state.updateProperty = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(updateProperty.rejected, (state, { payload }) => {
				state.updateProperty = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(updateProperty.fulfilled, (state, { payload }) => {
				state.updateProperty = {
					loading: 'succeeded',
					error: ''
				};

				state.property = payload;
			})

			.addCase(patchProperty.pending, (state) => {
				state.loading = 'pending';
				state.updateProperty = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(patchProperty.rejected, (state, { payload }) => {
				state.updateProperty = {
					loading: 'failed',
					error: String(payload)
				};
				state.loading = 'failed';
			})
			.addCase(patchProperty.fulfilled, (state, { payload }) => {
				state.updateProperty = {
					loading: 'succeeded',
					error: ''
				};
				state.loading = 'succeeded';
				state.property = payload;
			})

			.addCase(deleteProperty.pending, (state) => {
				state.deleteProperty = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(deleteProperty.rejected, (state, { payload }) => {
				state.deleteProperty = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(deleteProperty.fulfilled, (state) => {
				state.deleteProperty = {
					loading: 'succeeded',
					error: ''
				};
			})

			.addCase(fetchFindUsers.pending, (state) => {
				state.users = {
					loading: 'pending',
					error: '',
					data: state.users.data
				};
			})
			.addCase(fetchFindUsers.rejected, (state, { payload }) => {
				state.users = {
					loading: 'idle',
					error: String(payload),
					data: []
				};
			})
			.addCase(fetchFindUsers.fulfilled, (state, { payload }) => {
				state.users = { loading: 'idle', error: '', data: payload };
			})

			.addCase(fetchExportsCsvUsers.pending, (state) => {
				state.exportsCsvUsers = { loading: 'pending', error: '' };
			})
			.addCase(fetchExportsCsvUsers.rejected, (state, { payload }) => {
				state.exportsCsvUsers = {
					loading: 'idle',
					error: String(payload)
				};
			})
			.addCase(fetchExportsCsvUsers.fulfilled, (state) => {
				state.exportsCsvUsers = { loading: 'idle', error: '' };
			})

			.addCase(updateUserForAdmin.pending, (state) => {
				state.updateUserForAdmin = { loading: 'pending', error: '' };
			})
			.addCase(updateUserForAdmin.rejected, (state, { payload }) => {
				state.updateUserForAdmin = {
					loading: 'pending',
					error: String(payload)
				};
			})
			.addCase(updateUserForAdmin.fulfilled, (state, { payload }) => {
				const users = JSON.parse(JSON.stringify(state.users.data));

				const userIndexEdit = users.data.findIndex(
					(item: any) => item._id === payload.data?._id
				);
				users.data[userIndexEdit] = {
					...users.data[userIndexEdit],
					...payload.data
				};

				state.users.data.data = users.data;
				state.updateUserForAdmin = { loading: 'idle', error: '' };
			})

			.addCase(getPropertyIntegrationsByContext.pending, (state) => {
				state.propertyIntegrations = undefined;
				state.propertyIntegrationsState = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(getPropertyIntegrationsByContext.rejected, (state) => {
				state.propertyIntegrations = undefined;
				state.propertyIntegrationsState = {
					loading: 'failed',
					data: undefined,
					error: ''
				};
			})
			.addCase(
				getPropertyIntegrationsByContext.fulfilled,
				(state, { payload }) => {
					state.propertyIntegrations = payload;
					state.propertyIntegrationsState = {
						loading: 'succeeded',
						data: payload,
						error: ''
					};
				}
			)

			.addCase(updatePropertyIntegrationExtras.pending, (state) => {
				state.updatedPropertyIntegration = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(
				updatePropertyIntegrationExtras.rejected,
				(state, { payload }) => {
					state.updatedPropertyIntegration = {
						loading: 'failed',
						error: payload as string,
						data: undefined
					};
				}
			)
			.addCase(
				updatePropertyIntegrationExtras.fulfilled,
				(state, { payload }) => {
					state.updatedPropertyIntegration = {
						loading: 'succeeded',
						error: '',
						data: payload
					};
				}
			);

		builder
			.addCase(updatePropertyIntegration.pending, () => {})
			.addCase(updatePropertyIntegration.rejected, () => {})
			.addCase(updatePropertyIntegration.fulfilled, () => {});
	}
});

export const {
	setPropertyId,
	logIn,
	logOut,
	setUserLoading,
	cleanSuccess,
	cleanError,
	setCurrentProperty,
	setActiveApp,
	setAppName,
	setPropertyIdToUpdate,
	resetPropertyIdToUpdate,
	resetAddProperty,
	resetDeleteProperty,
	resetUpdateProperty,
	clearValidateUserExists,
	setStateUser,
	setPropertyIntegrationsData,
	removePropertyIntegrations,
	updatePropertyIntegrationManually,
	cleanPropertyIntegrations,
	cleanUpdatePropertyIntegration,
	replacePropertyIntegrations,
	updatePropertyIntegrationManually2,
	setListingConfigTimeSubmit
} = userSlice.actions;
