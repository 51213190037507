/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Icon } from '@hospy/hospy-ui';
import {
	IInternalAccount,
	Invoice,
	ThunkCreateFavoriteInternalAccount,
	ThunkDeleteFavoriteInternalAccount,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { currencyFormatter } from '@hospy/util-common';
import { Typography } from 'antd';
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';

interface IInvoiceItemCard {
	internalAccount: IInternalAccount;
	selectInvoice: () => void;
	detailOpened: boolean;
	isSelected: boolean;
	invoice?: Invoice;
	invoiceLoading: boolean;
	multipleInvoices?: boolean;
}

const AccountStatus = {
	closed: 'Cerrada',
	open: 'Abierta'
};

const invoiceStampStatusText = (
	stampStatus: string,
	invoices?: number,
	multipleInvoices?: boolean
) => {
	if (invoices && multipleInvoices)
		return (
			<FormattedMessage
				defaultMessage="{total, plural, =0 {facturas} one {# factura} other {# facturas}}"
				values={{ total: invoices }}
			/>
		);
	if (stampStatus === 'accepted' || stampStatus === 'canceled-pending')
		return 'Facturado';
	return 'Sin facturas';
};

const InvoiceItemCard = ({
	internalAccount: { name, id, status, stampStatus, invoices, isFavorite },
	selectInvoice,
	isSelected,
	invoice,
	invoiceLoading,
	multipleInvoices,
	detailOpened
}: IInvoiceItemCard) => {
	const { partnerIntegrationId } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;

	const dispatch = useAppDispatch();

	const { propertyId } = useAppSelector(({ user }) => user);
	const { appData } = useAppSelector(({ common }) => common);
	const { addFavoriteInternalAccount, removeFavoriteInternalAccount } =
		useAppSelector(({ siigoConnect }) => siigoConnect);

	const [showFavorite, setShowFavorite] = useState(false);

	const handleFavorite = () => {
		if (!appData?._id) return console.log('No appData');
		if (!propertyId) return console.log('No propertyId');
		console.log('Favorite');
		if (!isFavorite) {
			dispatch(
				ThunkCreateFavoriteInternalAccount({
					data: {
						thirdPartyId: id,
						propertyId: propertyId,
						appId: appData?._id || '',
						partnerIntegrationId
					}
				})
			);
		} else {
			dispatch(
				ThunkDeleteFavoriteInternalAccount({
					appId: appData?._id || '',
					partnerIntegrationId,
					propertyId,
					pathParams: id
				})
			);
		}
	};

	const isLoadingFavorite =
		addFavoriteInternalAccount.loading === 'pending' ||
		removeFavoriteInternalAccount.loading === 'pending';

	return (
		<InvoiceWrapper
			onMouseLeave={() => {
				setShowFavorite(false);
			}}
			onMouseEnter={() => {
				setShowFavorite(true);
			}}
			isSelected={isSelected}
			detailOpened={detailOpened}
			onClick={selectInvoice}
		>
			<MainInfo>
				{isFavorite && (
					<MarkFavorite>
						<RemoveFavoriteButton
							onClick={(e) => {
								e.stopPropagation();
								handleFavorite();
							}}
							type="text"
							tooltip="Quitar de favoritos"
						>
							<Icon material="star" />
						</RemoveFavoriteButton>
					</MarkFavorite>
				)}
				<CustomerInfo status={status}>
					<Customer>
						<Name detailOpened={detailOpened} title={name}>
							{name}
						</Name>
						{!isFavorite && showFavorite && (
							<AddFavoriteButton
								onClick={(e: any) => {
									e.stopPropagation();
									handleFavorite();
								}}
								type="text"
								tooltip={'Agregar a favoritos'}
								disabled={isLoadingFavorite}
							>
								<Icon material="stars" />
							</AddFavoriteButton>
						)}
					</Customer>
					<CustomerId status={status}>
						{AccountStatus[status as keyof typeof AccountStatus]}
					</CustomerId>
				</CustomerInfo>
			</MainInfo>
			<SecondaryInfo>
				<PriceWrapper>
					<Price isSelected={isSelected} stampStatus={stampStatus}>
						{invoiceLoading && isSelected && (
							<LoadingOutlined spin />
						)}
						{invoiceLoading && !isSelected && (
							<p>
								{invoiceStampStatusText(
									stampStatus,
									invoices,
									multipleInvoices
								)}
							</p>
						)}
						{!invoiceLoading && (
							<>
								{isSelected ? (
									<p>
										{!stampStatus
											? currencyFormatter.format(
													invoice?.total || 0,
													'USD',
													2,
													'symbol'
											  )
											: invoiceStampStatusText(
													stampStatus,
													invoices,
													multipleInvoices
											  )}
									</p>
								) : (
									<p>
										{invoiceStampStatusText(
											stampStatus,
											invoices,
											multipleInvoices
										)}
									</p>
								)}
							</>
						)}
					</Price>
				</PriceWrapper>
			</SecondaryInfo>
			{stampStatus === 'rejected' && (
				<CheckSection hasError>
					<DangerIcon style={{ color: 'red' }} material="error" />
				</CheckSection>
			)}
		</InvoiceWrapper>
	);
};

const InvoiceWrapper = styled(({ isSelected, detailOpened, ...props }) => (
	<button {...props} />
))`
	position: relative;
	align-items: center;

	${({ detailOpened }) =>
		detailOpened
			? css`
					width: 380px;
					min-height: 88px;
			  `
			: css`
					min-width: 650px;
					min-height: 88px;
			  `}

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 0px;
	padding: 1rem;
	padding-left: 1.8rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.base};
	background-color: white;
	box-shadow: ${({ theme }) => theme.boxShadow.level1()};
	cursor: pointer;
	${({ isSelected }) =>
		isSelected
			? css`
					box-shadow: ${({ theme }) =>
						theme.boxShadow.level1('#cde8f0')};
					border: none;
					background-color: #edfbff;
					:after {
						content: '';
						position: absolute;
						height: 0;
						width: 0;
						left: 100%;
						top: 0;
						border: 44px solid transparent;
						border-left: 28px solid #edfbff;
						z-index: 1;
					}
					:hover {
						background-color: #edfbff !important;
					}
					:active {
						background-color: #edfbff !important;
					}
			  `
			: css`
					:hover {
						border: 1px solid #36d3fe;
					}
			  `}
`;

const CustomerInfo = styled(({ status, ...props }) => <div {...props} />)`
	${({ status, theme }) =>
		(status === 'no_show' || status === 'canceled') &&
		css`
			span {
				color: ${theme.colors.danger.base};
			}
		`}
	display: flex;
	flex-direction: column;
	span {
		text-align: start;
	}
`;

const MainInfo = styled.div`
	width: 68%;
	display: flex;
	flex-direction: row;
	gap: 8px;
	span {
		text-align: start;
	}
`;

const SecondaryInfo = styled.div`
	display: flex;
	flex-direction: column-reverse;
	height: 100%;
	${({ theme }: any) =>
		theme.screens.sm &&
		css`
			flex-direction: row;
			gap: 20px;
		`}
`;

const PriceWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Price = styled(({ isSelected, stampStatus, ...props }) => (
	<div {...props} />
))`
	background-color: ${({ theme }) => theme.colors.gray.lighten['20%']};
	color: ${({ theme }) => theme.colors.black.base};

	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20px;
	padding-top: 2px;
	border-radius: 20px;
	height: 2.2rem;
	p {
		font-size: ${({ theme }) => theme.text.size.small}px;
		margin-bottom: 0;
	}

	${({ stampStatus, theme }) => {
		let stampColor = {
			background: '',
			color: theme.colors.white.base
		};
		if (stampStatus === '' || stampStatus === undefined || !stampStatus)
			stampColor = {
				background: theme.colors.gray.lighten['30%'],
				color: theme.colors.black.base
			};

		if (stampStatus === 'accepted' || stampStatus === 'canceled-pending')
			stampColor.background = theme.colors.success.base;

		if (stampStatus === 'draft' || stampStatus === 'rejected')
			stampColor.background = '#00c8f8';

		if (stampStatus === 'deleted')
			stampColor.background = theme.colors.gray.darken['40%'];

		return css`
			background-color: ${stampColor.background};
			color: ${stampColor.color};
			p {
				color: ${stampColor.color};
			}
		`;
	}}
`;

const Customer = styled.div`
	display: flex;
	align-items: center;
	text-align: center;

	gap: 20px;
`;

const Name = styled(({ detailOpened, ...props }) => (
	<Typography.Text {...props} />
))`
	font-size: ${({ theme }) => theme.title.size.small}px;
	text-align: start;

	${({ detailOpened }) =>
		detailOpened &&
		css`
			max-width: 160px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		`}
`;

const CustomerId = styled(({ status, ...props }) => (
	<Typography.Text {...props} />
))`
	width: fit-content;
	line-height: 1;
	${({ status, theme }) => {
		if (status === 'closed')
			return css`
				background-color: ${theme.colors.danger.lighten['70%']};
			`;
		if (status === 'open')
			return css`
				background-color: ${theme.colors.primary.lighten['70%']};
			`;

		return css``;
	}};
`;

const DangerIcon = styled(Icon)`
	color: ${({ theme }) => theme.colors.primary.base};
	font-size: ${({ theme }) => theme.text.size.large}px;
`;

const CheckSection = styled(({ hasError, ...props }) => <div {...props} />)`
	position: absolute;
	left: 97%;
	bottom: 79%;
	z-index: 2;
	svg {
		font-size: 24px;
	}
	${({ hasError, theme }) =>
		hasError &&
		css`
			left: 96%;
			bottom: 70%;
			svg {
				color: ${theme.colors.danger.base};
				font-size: 36px;
			}
		`}
`;

const AddFavoriteButton = styled(Button)`
	z-index: 2;
	padding: 0;
	margin: 0;
	height: 20px;
	width: 20px;

	svg {
		font-size: 24px;
	}

	${({ theme }) =>
		css`
			color: ${theme.colors.black?.lighten?.['70%']};
		`}
`;

const MarkFavorite = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	height: 50px;
	width: 50px;
	color: ${({ theme }) => `${theme.colors.white.base}`};
	clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);
	background-color: #d52f6e;
`;

const RemoveFavoriteButton = styled(Button)`
	margin-right: 24px;
	margin-top: 2px;
	height: 20px;
	width: 20px;
	padding: 0;

	svg {
		font-size: ${({ theme }) => theme.text.size.large * 1.4}px;
		color: white;
	}
`;

export default InvoiceItemCard;
