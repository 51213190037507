/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { Button, Icon } from '@hospy/hospy-ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';
import UploadReservationTotalModal from './upload-reservation-total-modal';
import { RequestImportReservationTotal } from '@hospy/util-api/service/reservation';
import {
	PropertyIntegrations,
	updatePropertyIntegrationManually,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Spin, message } from 'antd';
import { css } from 'styled-components';
import { getPropertyIntegrationsService } from '@hospy/util-api';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
	<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
);

const UploadReservationTotal = () => {
	const dispatch = useAppDispatch();

	const { loading, partnerIntegrationId, partnerIntegrationIdTo } =
		useContext(InvoiceFilterContext) as InvoiceFilterContextType;

	const { propertyId, propertyIntegrationsState } = useAppSelector(
		({ user }) => user
	);
	const { appData } = useAppSelector(({ common }) => common);

	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const [uploading, setUploading] = useState<boolean>(false);
	const [loadingGetIntegration, setLoadingGetIntegration] =
		useState<boolean>(false);

	const [fileResponse, setFileResponse] = useState<any>(null);

	const importStatus = useMemo(() => {
		const fromIntegration = propertyIntegrationsState.data?.find(
			(pi) =>
				['ota', 'pms'].includes(pi.partnerIntegrationType) &&
				pi.appId === appData?._id
		);
		if (!fromIntegration) return 'idle';
		const extra = fromIntegration.extras?.find(
			(e) => e.slug === 'airbnb.import-reservation-totals'
		);

		if (!extra) return 'idle';

		return extra.value;
	}, [propertyIntegrationsState.loading, propertyIntegrationsState.data]);

	const total = useMemo(() => {
		const fromIntegration = propertyIntegrationsState.data?.find(
			(pi) =>
				['ota', 'pms'].includes(pi.partnerIntegrationType) &&
				pi.appId === appData?._id
		);
		if (!fromIntegration) return 'idle';
		const extra = fromIntegration.extras?.find(
			(e) => e.slug === 'airbnb.import-reservation-totals-count'
		);

		if (!extra) return undefined;

		return extra.value;
	}, [propertyIntegrationsState.loading, propertyIntegrationsState.data]);

	const openFilters = () => {
		setModalOpened(true);
	};

	const upload = async (inputFile: File, product?: any) => {
		if (!inputFile) return;
		setUploading(true);
		setFileResponse(null);
		if (!appData?._id || !propertyId) return;
		try {
			const data: any = {
				appId: appData?._id,
				propertyId: propertyId,
				partnerIntegrationId: partnerIntegrationId,
				partnerIntegrationIdTo: partnerIntegrationIdTo,
				file: inputFile
			};
			if (product) data.product = product;
			const response = await RequestImportReservationTotal({
				data,
				pathParams: { format: 'csv' }
			});
			if ((response as any).data) {
				setFileResponse({
					slug: 'airbnb.import-reservation-totals',
					type: 'string',
					value: 'pending'
				});
				setTimeout(() => {
					getIntegration(true, 0);
				}, 3000);
			}
		} catch (error) {
			console.log(error);
			message.error('Error al cargar el archivo');
		} finally {
			setUploading(false);
		}
	};

	useEffect(() => {
		if (!fileResponse) return;

		if (fileResponse.errors?.length > 0) {
			message.error('No se pudo importar las reservas');
			return;
		}

		const _integrations: PropertyIntegrations[] = JSON.parse(
			JSON.stringify(propertyIntegrationsState.data)
		);
		const pmsIndex = _integrations.findIndex(
			(pi) =>
				['ota', 'pms'].includes(pi.partnerIntegrationType) &&
				pi.appId === appData?._id
		);

		_integrations[pmsIndex].extras = [
			...(_integrations[pmsIndex].extras?.filter(
				(extra) => extra.slug !== 'airbnb.import-reservation-totals'
			) || []),
			fileResponse
		];

		dispatch(updatePropertyIntegrationManually(_integrations));
	}, [JSON.stringify(fileResponse)]);

	const getIntegration = useCallback(
		(isAuto?: boolean, times?: number) => {
			const fromIntegration = propertyIntegrationsState.data?.find(
				(pi) =>
					['ota', 'pms'].includes(pi.partnerIntegrationType) &&
					pi.appId === appData?._id
			);
			if (!fromIntegration?._id) return;

			(async () => {
				setLoadingGetIntegration(true);
				const { data: currentPropertyIntegration } =
					await getPropertyIntegrationsService({
						filter: `{"_id": ObjectId("${fromIntegration?._id}")}`
					});

				if (
					!currentPropertyIntegration ||
					currentPropertyIntegration?.length === 0
				) {
					setLoadingGetIntegration(false);
					return;
				}

				const _integrations: PropertyIntegrations[] = JSON.parse(
					JSON.stringify(propertyIntegrationsState.data)
				);
				const fromIntegrationIndex = _integrations.findIndex(
					(pi) => pi._id === fromIntegration?._id
				);

				const extraIndex =
					currentPropertyIntegration[0].extras?.findIndex(
						(e: any) =>
							e.slug === 'airbnb.import-reservation-totals'
					);
				if (
					extraIndex > -1 &&
					currentPropertyIntegration[0].extras[extraIndex].value ===
						'success'
				)
					currentPropertyIntegration[0].extras[extraIndex].value =
						'show-success';

				_integrations[fromIntegrationIndex].extras =
					currentPropertyIntegration[0]?.extras;

				if (
					['show-success', 'failed'].includes(
						currentPropertyIntegration[0].extras[extraIndex].value
					)
				)
					dispatch(updatePropertyIntegrationManually(_integrations));
				if (
					currentPropertyIntegration[0].extras[extraIndex].value ===
						'pending' &&
					isAuto
				) {
					setTimeout(() => {
						if (times !== undefined && times < 5)
							getIntegration(true, times + 1);
					}, 4000);
				}
				if (
					['show-success', 'success'].includes(
						currentPropertyIntegration[0].extras[extraIndex].value
					) &&
					isAuto
				) {
					message.success('Reservas importadas correctamente');
				}
				if (
					currentPropertyIntegration[0].extras[extraIndex].value ===
						'failed' &&
					isAuto
				) {
					message.error('Error al importar las reservas');
				}
				setLoadingGetIntegration(false);
			})();
		},
		[propertyIntegrationsState.loading]
	);

	const cleanShow = () => {
		const _integrations: PropertyIntegrations[] = JSON.parse(
			JSON.stringify(propertyIntegrationsState.data)
		).map((pi: any) => {
			const extraIndex = pi.extras?.findIndex(
				(e: any) => e.value === 'show-success'
			);
			if (extraIndex > -1) pi.extras[extraIndex].value = 'success';
			return pi;
		});

		dispatch(updatePropertyIntegrationManually(_integrations));
	};

	return (
		<div>
			<FilterButton
				onClick={openFilters}
				shape="round"
				disabled={loading}
				tooltip="Importar el archivo de ingresos de Airbnb para actualizar los valores de las facturas"
				status={importStatus}
			>
				{importStatus !== 'pending' && <Icon material="download" />}
				{importStatus === 'pending' && <Spin indicator={antIcon} />}
				{importStatus !== 'pending' && 'CSV'}

				{importStatus === 'pending' && 'Importando'}
			</FilterButton>
			<UploadReservationTotalModal
				cleanShow={cleanShow}
				getIntegration={getIntegration}
				onClose={() => setModalOpened(false)}
				upload={upload}
				open={modalOpened}
				uploading={uploading}
				loadingGetIntegration={loadingGetIntegration}
				status={importStatus}
				total={total}
			/>
		</div>
	);
};

const FilterButton = styled(({ status, ...props }: any) => (
	<Button {...props} />
))`
	color: black;
	position: relative;
	svg {
		font-size: 22px;
	}
	z-index: 0 !important;

	${({ status, theme }) => {
		if (status === 'pending') {
			return css`
				background-color: ${theme.colors.primary.lighten['10%']};
				color: white;
				:hover,
				:focus,
				:active,
				:not(disabled) {
					background-color: ${theme.colors.primary.lighten[
						'10%'
					]} !important;
					color: white !important;
				}
			`;
		}
		return css``;
	}}
`;

export default UploadReservationTotal;
