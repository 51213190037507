import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	IThirdPartyReservationFilters,
	postRntReportsBulk
} from '@hospy/util-api';

export const postRntReportsBulkThunk = createAsyncThunk(
	'third-party-rnt-reports/postRntReportsBulk',
	async (
		{
			type,
			data,
			parameters
		}: {
			type: 'rnt' | 'sire';
			data: any;
			parameters: IThirdPartyReservationFilters;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await postRntReportsBulk(type, data, parameters);
			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar enviar reportes pendientes'
			);
		}
	}
);
