/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { languageApi } from './languageAPI';

export const fetchLanguageMessages = createAsyncThunk(
	'language/getLanguageMessages',
	async ({ app }: { app: string }, { getState }) => {
		const state: any = getState();
		const appMessages = await languageApi.getLanguageMessages(
			state.language.language,
			app
		);

		const libsMessages = await languageApi.getLibsLanguageMessages(
			state.language.language
		);

		return { ...libsMessages, ...appMessages };
	}
);
