/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Icon } from '@hospy/hospy-ui';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useCreditPurchase, {
	packageOptions
} from '../hooks/use-credit-purchase';

const CreditPurchase = () => {
	const {
		credits,
		hasInsufficientCredits,
		buy,
		selectedPackage,
		setSelectedPackage,
		navigate,
		loading
	} = useCreditPurchase();

	return (
		<CreditPurchaseWrapper>
			<p>Comprar paquete</p>
			<Select
				options={packageOptions}
				onSelect={(value) =>
					setSelectedPackage(
						packageOptions.find((option) => option.value === value)
					)
				}
				filterOption={(input, option) =>
					(option?.label ?? '')
						.toLocaleLowerCase()
						.includes(input.toLocaleLowerCase())
				}
				defaultValue={packageOptions[0].value}
			/>

			<Button
				loading={loading}
				disabled={!selectedPackage || hasInsufficientCredits}
				type="primary"
				onClick={buy}
			>
				{!loading && <>Pagar USD {selectedPackage?.price}</>}
			</Button>

			{hasInsufficientCredits ? (
				<>
					<InsufficientCredits>
						<FormattedMessage
							defaultMessage="{credits, plural, =0 {No tienes créditos disponibles} other {Solo tienes $ # en tu saldo de créditos.}}"
							values={{
								credits: credits.data?.credits?.amount || 0
							}}
						/>
					</InsufficientCredits>
					<Button
						icon={<Icon material="add_circle" ant />}
						onClick={() => navigate('/credits/create')}
						type="text"
					>
						Comprar créditos
					</Button>
				</>
			) : (
				<BuyStatus>
					{loading
						? `Procesando tu pago`
						: `Se restarán ${selectedPackage?.price?.toLocaleString(
								'en-US',
								{
									style: 'currency',
									currency: 'USD'
								}
						  )} de tus ${credits.data?.credits?.amount?.toLocaleString(
								'en-US',
								{
									style: 'currency',
									currency: 'USD'
								}
						  )} créditos`}
				</BuyStatus>
			)}
		</CreditPurchaseWrapper>
	);
};

const CreditPurchaseWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 15px;
	gap: 15px;
	p {
		margin: 0;
	}
	button {
		min-width: 100%;
	}
`;

const InsufficientCredits = styled.p`
	color: red;
	font-size: ${({ theme }) => theme.text.size.small}px;
`;

const BuyStatus = styled.p`
	font-size: ${({ theme }) => theme.text.size.small}px;
	text-align: center;
`;

export default CreditPurchase;
