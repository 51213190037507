import { createSlice } from '@reduxjs/toolkit';
import { AdminLeadsInitialState, AdminLeadsSliceName } from './constants';
import { IAdminLeadsInitialState } from './interface';

import {
	ThunkCreateLeadsImports,
	ThunkFindAllLeads,
	ThunkFindAllWhatsappTemplates,
	ThunkRemoveLeads,
	ThunkUpdateLeads, ThunkCreateLeadsNotifications
} from './thunks';

export const AdminLeadsSlice = createSlice({
	name: AdminLeadsSliceName,
	initialState: AdminLeadsInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(
				ThunkFindAllLeads.pending,
				(state: IAdminLeadsInitialState) => {
					state.findAllLeads.loading = 'pending';
				}
			)
			.addCase(
				ThunkFindAllLeads.rejected,
				(state: IAdminLeadsInitialState, payload: any) => {
					state.findAllLeads = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkFindAllLeads.fulfilled,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.findAllLeads = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
		builder
			.addCase(
				ThunkUpdateLeads.pending,
				(state: IAdminLeadsInitialState) => {
					state.updateLeads.loading = 'pending';
				}
			)
			.addCase(
				ThunkUpdateLeads.rejected,
				(state: IAdminLeadsInitialState, payload: any) => {
					state.updateLeads = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkUpdateLeads.fulfilled,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.updateLeads = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);

		builder
			.addCase(
				ThunkRemoveLeads.pending,
				(state: IAdminLeadsInitialState) => {
					state.removeLeads.loading = 'pending';
				}
			)
			.addCase(
				ThunkRemoveLeads.rejected,
				(state: IAdminLeadsInitialState, payload: any) => {
					state.removeLeads = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkRemoveLeads.fulfilled,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.removeLeads = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
		builder
			.addCase(
				ThunkCreateLeadsImports.pending,
				(state: IAdminLeadsInitialState) => {
					state.importLeads.loading = 'pending';
				}
			)
			.addCase(
				ThunkCreateLeadsImports.rejected,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.importLeads = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkCreateLeadsImports.fulfilled,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.importLeads = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
		builder
			.addCase(
				ThunkFindAllWhatsappTemplates.pending,
				(state: IAdminLeadsInitialState) => {
					state.whatsappTemplates.loading = 'pending';
				}
			)
			.addCase(
				ThunkFindAllWhatsappTemplates.rejected,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.whatsappTemplates = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkFindAllWhatsappTemplates.fulfilled,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.whatsappTemplates = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
		builder
			.addCase(
				ThunkCreateLeadsNotifications.pending,
				(state: IAdminLeadsInitialState) => {
					state.leadNotifications.loading = 'pending';
				}
			)
			.addCase(
				ThunkCreateLeadsNotifications.rejected,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.leadNotifications = {
						loading: 'failed',
						error: payload,
						payload: undefined
					};
				}
			)
			.addCase(
				ThunkCreateLeadsNotifications.fulfilled,
				(state: IAdminLeadsInitialState, { payload }: any) => {
					state.leadNotifications = {
						loading: 'succeeded',
						error: undefined,
						payload: payload
					};
				}
			);
	}
});
