import {
	resetPropertyIdToUpdate,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect, useRef, useState } from 'react';

export interface usePropertyModalProps {
	open: boolean;
	onClose: () => void;
}
const usePropertyModal = ({ onClose }: usePropertyModalProps) => {
	const ref = useRef<any>(null);
	const dispatch = useAppDispatch();
	const { propertyIdToUpdate } = useAppSelector(({ user }) => user);
	const [blocking, setBlocking] = useState(false);

	useEffect(() => {
		if (!open) {
			dispatch(resetPropertyIdToUpdate());
			ref?.current?.reset();
		}
	}, [open]);

	const handleOnClose = () => {
		setTimeout(() => {
			dispatch(resetPropertyIdToUpdate());
		}, 600);
		if (onClose) onClose();
	};

	return {
		ref,
		handleOnClose,
		blocking,
		propertyIdToUpdate,
		setBlocking
	};
};

export default usePropertyModal;
