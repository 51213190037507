/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { ReservationSliceName, initialState } from './constants';
import {
	ThunkFindAllReservationNotes,
	createReservation,
	findReservations
} from './thunks';

export const ReservationSlice = createSlice({
	name: ReservationSliceName,
	initialState,
	reducers: {
		setLoading: (state: any, action) => {
			state.loading = action.payload;
		},
		cleanSuccess: (state: any, action) => {
			state.success[action.payload] = false;
		},
		cleanError: (state: any, action) => {
			state.error[action.payload] = undefined;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(createReservation.fulfilled, (state, action) => {
				const payload = action.payload;
				if (payload.statusCode !== 404) {
					state.success = {
						...state.success,
						createReservation: true
					};
					state.reservations = [payload.data];
				} else
					state.error = {
						...state.error,
						createReservation: payload.error
					};

				state.loading = 'idle';
			})
			.addCase(findReservations.fulfilled, (state, action) => {
				const payload = action.payload;
				if (payload) {
					state.reservations = action.payload;
					state.success = {
						...state.success,
						findReservations: true
					};
				} else state.error = { ...state.error, findReservations: true };
			});

		builder
			.addCase(ThunkFindAllReservationNotes.pending, (state) => {
				state.notes.loading = 'pending';
			})
			.addCase(
				ThunkFindAllReservationNotes.fulfilled,
				(state, { payload }: any) => {
					state.notes.data = payload.data;
					state.notes.loading = 'succeeded';
				}
			)
			.addCase(
				ThunkFindAllReservationNotes.rejected,
				(state, { payload }) => {
					state.loading = 'failed';
					state.notes.error = payload;
				}
			);
	}
});

export const { cleanSuccess, cleanError, setLoading } =
	ReservationSlice.actions;
