/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchGetLogs } from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FindLog } from './interface';

export const getLogs = createAsyncThunk(
	'commonLogs/getLogs',
	async (params: FindLog, { rejectWithValue }) => {
		try {
			const response = await fetchGetLogs(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const getSiigoLogs = createAsyncThunk(
	'commonLogs/getSiigoLogs',
	async (params: FindLog, { rejectWithValue }) => {
		try {
			const response = await fetchGetLogs(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const getSatLogs = createAsyncThunk(
	'commonLogs/getSatLogs',
	async (params: FindLog, { rejectWithValue }) => {
		try {
			const response = await fetchGetLogs(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
