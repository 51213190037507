import { Async } from '../../common/common.interfaces';
import { InitialState } from './interface';

export const AccountSliceName = 'partner-accounting';

const initialAsync: Async = {
	loading: 'idle',
	error: undefined,
	data: undefined
};

export const initialState: InitialState = {
	paymentMethodsInternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	paymentMethodsExternal: {
		loading: 'idle',
		error: undefined,
		data: []
	},
	paymentMethods: initialAsync,
	invoice: initialAsync,
	taxes: initialAsync,
	destinyTaxes: initialAsync,
	updatedInvoice: initialAsync,
	deletedInvoice: initialAsync,
	joinItems: initialAsync,
	stamp: initialAsync,
	reservationToBulk: [],
	stampBulk: initialAsync
};
