import { Col, Row, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from '../../UI/template/account';
import VerificationCodeForm from './components/verification-code-form';
import useVerificationCode from './hook/use-verification-code';

const Loading = () => (
	<div
		style={{
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}
	>
		<Spin />
	</div>
);

export const VerificationCode = () => {
	const location: any = useLocation();
	const { username } = useParams();

	const { confirmed, loadingConfirmed, ...props } = useVerificationCode(
		username || '',
		location?.state?.password || '',
		location
	);

	if (loadingConfirmed || confirmed) return <Loading />;

	return (
		<Container>
			<Row align="middle" justify="center">
				<Col xs={24} sm={24} lg={12}>
					<VerificationCodeForm {...props} />
				</Col>
			</Row>
		</Container>
	);
};

export default VerificationCode;
