/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestFindAll } from '../requests';
import { Fetch } from '../fetch';

import { ResponseFailure, ResponseSuccess } from '../responses';

export const RequestFindAllAccounts = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/pms/internal-accounts', params);
