/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	PARTNER,
	SIIGO_DEFAULT_TAX_AMOUNT,
	accountingGetPaymentTypes,
	clearUpdateFieldMapping,
	updateFieldMappingV2,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { Form, message } from 'antd';
import { useCallback, useEffect } from 'react';

const useFirstTimePaymentWay = ({
	close,
	onFinish
}: {
	close?: () => void;
	onFinish: () => void;
}) => {
	const dispatch = useAppDispatch();

	const [form] = Form.useForm();

	const { fieldMapping, updatedFieldMapping } = useAppSelector(
		({ onboarding }) => onboarding
	);
	const { invoice, updatedInvoice, paymentMethodsFinance } = useAppSelector(
		({ siigoConnect }) => siigoConnect
	);
	const { propertyIntegrations, propertyId } = useAppSelector(
		({ user }) => user
	);
	const { appData } = useAppSelector(({ common }) => common);

	useEffect(() => {
		if (updatedFieldMapping.loading === 'idle') return;
		if (updatedFieldMapping.loading === 'succeeded') {
			(async () => {
				message.success(
					'Mapeo de forma de pago actualizado correctamente'
				);
				await dispatch(clearUpdateFieldMapping());
				if (close) close();
				if (onFinish) onFinish();
			})();
			return;
		}
		if (updatedFieldMapping.loading === 'failed') {
			message.error('Error al actualizar el mapeo de forma de pago');
			dispatch(clearUpdateFieldMapping());
			if (close) close();
		}
	}, [updatedFieldMapping.loading]);

	const getDestinyPaymentWay = useCallback(() => {
		if (!appData || !propertyId || !propertyIntegrations) return;
		const propertyIntegrationSiigo = propertyIntegrations?.find(
			(item) =>
				item.partnerIntegrationId === PARTNER.SIIGO &&
				item.appId === appData?._id
		);

		if (!propertyIntegrationSiigo) return;

		dispatch(
			accountingGetPaymentTypes({
				appId: appData?._id,
				partnerIntegrationId: PARTNER.SIIGO,
				propertyId: propertyId
			})
		);
	}, [appData, propertyId, propertyIntegrations]);

	useEffect(() => {
		if (paymentMethodsFinance.loading !== 'idle') return;
		getDestinyPaymentWay();
	}, [paymentMethodsFinance.loading]);

	const onSubmit = async (paymentWayValue: any) => {
		const defaultTaxAmount =
			invoice?.data?.defaultTaxAmount?.amount || SIIGO_DEFAULT_TAX_AMOUNT;
		if (!defaultTaxAmount) return;

		const fullPaymentWay = paymentMethodsFinance.data?.find(
			(paymentMethod: any) =>
				paymentMethod.thirdPartyId === paymentWayValue
		);

		const fieldMappingId = fieldMapping?.data?._id || '';

		const extras = [];
		if (fullPaymentWay.due_date)
			extras.push({
				slug: 'external-due-date',
				type: 'boolean',
				name: 'external-due-date',
				value: 'true'
			});

		await dispatch(
			updateFieldMappingV2({
				id: fieldMappingId,
				data: {
					specific: {
						fields: {
							groupName: 'paymentWay',
							internalSlug: fullPaymentWay.name,
							internalName: fullPaymentWay.name,

							externalId: fullPaymentWay.thirdPartyId,
							externalName: fullPaymentWay.name,
							externalSlug: fullPaymentWay.thirdPartyId,
							extras
						}
					}
				},
				updateMode: 'push',
				items: []
			})
		);
	};

	return {
		paymentMethods: paymentMethodsFinance?.data?.map(
			(paymentMethod: any) => ({
				label: paymentMethod.name,
				value: paymentMethod.thirdPartyId
			})
		),
		loadingMethods: paymentMethodsFinance.loading === 'pending',
		availableRetry: {
			taxes: paymentMethodsFinance.loading === 'failed'
		},
		getDestinyPaymentWay,
		updatedInvoice,
		form,
		onSubmit,
		updatedFieldMapping
	};
};

export default useFirstTimePaymentWay;
