export const currencyFormatter = {
	format: (
		rawNumber: number,
		currency?: string,
		minFractions?: number,
		currencyDisplay?: string
	) =>
		new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency || 'USD',
			minimumFractionDigits:
				minFractions !== undefined ? minFractions : 2,
			currencyDisplay: currencyDisplay || undefined
		}).format(rawNumber)
};
