/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseHttpFilters } from '../util-parse-filters';
import { ApiURL } from '../util-url';

export const getOnboardings = (
	appId: string,
	propertyId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const httpParams = parseHttpFilters([
			{ key: 'appId', typeOf: 'ObjectId', value: appId },
			{ key: 'propertyId', typeOf: 'ObjectId', value: propertyId }
		]);

		fetch(
			ApiURL(`api/common/onboardings?filter=${httpParams}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const initOnboardingService = (
	appId: string,
	propertyId: string,
	steps?: any[]
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				propertyId,
				appId,
				steps
			})
		};

		fetch(ApiURL(`api/common/onboardings`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const patchOnboarding = (id: string, steps?: any[]): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ steps })
		};

		fetch(ApiURL(`api/common/onboardings/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteOnboardingService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`api/common/onboardings/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
