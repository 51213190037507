import { IStartState } from './start.interfaces';

export const initialState = {
	apps: [],
	app: undefined,
	loadingProperty: false,
	errorProperty: '',
	properties: [],
	loadingApp: 'idle',
	loadingActivateApp: 'idle',
	showPropertyModal: false,
	activateApp: {
		error: null,
		loading: 'idle'
	},
	stripePayment: {
		error: null,
		loading: 'idle',
		data: null
	},
	stripeClientSecret: {
		error: null,
		loading: 'idle',
		data: null
	},
	error: {
		app: false
	}
} as IStartState;
