/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchUserInformation,
	resetUpdateProperty,
	useAppDispatch,
	useAppSelector,
	updateProperty as userUpdateProperty
} from '@hospy/store';
import { cognitoService } from '@hospy/util-auth';
import { Form, message } from 'antd';
import { useEffect, useState } from 'react';

export interface useAddPhoneChannelPropertyFormProps {
	onComplete: () => void;
}

const useAddPhoneChannelPropertyForm = ({
	onComplete
}: useAddPhoneChannelPropertyFormProps) => {
	const dispatch = useAppDispatch();
	const { updateProperty, property } = useAppSelector(({ user }) => user);
	const [onSubmitted, setOnSubmitted] = useState(false);

	const [form] = Form.useForm();

	const country = (property?.location?.country?.iso ?? 'us').toLowerCase();

	useEffect(() => {
		if (updateProperty.loading === 'succeeded') {
			form.resetFields();
			if (onComplete) onComplete();
		}
	}, [updateProperty.loading]);

	const broadcastChannels = [
		{
			label: 'Me lo recomendaron',
			value: 'referred'
		},
		{
			label: 'Me enviaron un email',
			value: 'email'
		},
		{
			label: 'Por medio de Cloudbeds',
			value: 'cloudbeds'
		},
		{
			label: 'Google',
			value: 'google'
		},
		{
			label: 'Redes sociales',
			value: 'social media'
		},
		{
			label: 'En una feria o evento',
			value: 'fair and event'
		},
		{
			label: 'Mauro Hofra',
			value: 'mauro hofra'
		},
		{
			label: 'Asohost',
			value: 'asohost'
		}
	];

	useEffect(() => {
		if (updateProperty.loading === 'failed') {
			message.error('Error al intentar actualizar el negocio');
			return;
		}
		if (onSubmitted && updateProperty.loading === 'succeeded') {
			message.success('Negocio actualizado exitosamente');
			dispatch(resetUpdateProperty());
			refreshUserData();
			setOnSubmitted(false);
			return;
		}
	}, [updateProperty.loading]);

	const onSubmit = async ({ phone }: any) => {
		setOnSubmitted(true);
		await dispatch(
			userUpdateProperty({
				data: { ...property, phone },
				propertyId: property?._id || ''
			})
		);
	};

	const refreshUserData = async () => {
		const session = await cognitoService.getSession();
		await dispatch(
			fetchUserInformation({ user: session?.idToken?.payload })
		);
	};

	return {
		form,
		onSubmit,
		broadcastChannels,
		loading: updateProperty.loading === 'pending',
		country
	};
};

export default useAddPhoneChannelPropertyForm;
