import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	createGuestReport,
	searchGuestReports,
	deleteGuestReport,
	searchGuestReportCategories
} from './thunks';

export const guestReportSlice = createSlice({
	name: 'guest-report',
	initialState,
	reducers: {
		setCreateReportLoading: (state, action) => {
			state.loading.createReport = action.payload;
		},
		setCountries: (state, action) => {
			state.countries = action.payload;
		},
		setQuery: (state, action) => {
			state.query = action.payload;
		}
	},
	extraReducers: (builder) => {
		// createGuestReport
		builder
			.addCase(createGuestReport.fulfilled, (state, action) => {
				if (action.payload) state.loading.createReport = false;
			})
			.addCase(createGuestReport.pending, (state) => {
				state.loading.createReport = true;
			})
			.addCase(createGuestReport.rejected, (state) => {
				state.loading.createReport = false;
			});

		// searchGuestReports
		builder
			.addCase(searchGuestReports.fulfilled, (state, action) => {
				if (action.payload) {
					const { meta, data } = action.payload;
					if (meta?.prevPage) {
						const allReports = [...state.reports, ...data];
						state.reports = allReports.filter(
							(item, i) =>
								allReports.findIndex(
									(report) => report._id === item._id
								) === i
						);
					} else {
						state.reports = data;
					}
					state.nextPage = meta?.nextPage;
					state.loading.findReports = false;
				}
			})
			.addCase(searchGuestReports.pending, (state) => {
				state.loading.findReports = true;
			})
			.addCase(searchGuestReports.rejected, (state) => {
				state.loading.findReports = false;
			});
		// deleteGuestReport
		builder
			.addCase(deleteGuestReport.fulfilled, (state, action) => {
				if (action.payload) {
					state.loading.deleteReport = false;
				}
			})
			.addCase(deleteGuestReport.pending, (state) => {
				state.loading.deleteReport = true;
			})
			.addCase(deleteGuestReport.rejected, (state) => {
				state.loading.deleteReport = false;
			});
		// searchGuestReportCategories
		builder
			.addCase(searchGuestReportCategories.fulfilled, (state, action) => {
				if (action.payload) {
					state.reportCategories = action.payload.map(
						(item: any) => ({
							_id: item?._id,
							name: item?.name?.es
						})
					);
					state.loading.findReportCategories = false;
				}
			})
			.addCase(searchGuestReportCategories.pending, (state) => {
				state.loading.findReportCategories = true;
			})
			.addCase(searchGuestReportCategories.rejected, (state) => {
				state.loading.findReportCategories = false;
			});
	}
});
