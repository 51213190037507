/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IReservation } from './interfaces';
import { Api } from './api';
import { ReservationSliceName } from './constants';
import { RequestFindAll } from '@hospy/util-api/requests';
import { RequestFindAllReservationNotes } from '@hospy/util-api/service/reservation';

export const createReservation = createAsyncThunk(
	'reservation/createReservation',
	async (data: IReservation) => {
		const response = await Api.createReservation(data);

		if (response) {
			return response;
		} else return response;
	}
);

export const findReservations = createAsyncThunk(
	'reservation/findReservations',
	async (propertyId: any) => {
		const response = await Api.findReservations(propertyId);
		if (response?.data.length > 0) {
			return response.data;
		} else return undefined;
	}
);

export const ThunkFindAllReservationNotes = createAsyncThunk(
	`${ReservationSliceName}/${RequestFindAllReservationNotes.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllReservationNotes(params);
		if (response.status === 'success' && response) return response;
		return rejectWithValue(response);
	}
);
