import { maxDevice, minDevice } from '@hospy/feature/devices';
import { Col, Image, Row, Typography } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import image404 from './../../../../assets/images/image-404.png';
import logo from './../../../../assets/logos/logo-hospy-light.png';

const Main = styled.div`
	width: 100%;
	max-width: 26rem;
	margin-left: auto;
	/* margin-top: 2rem;
    margin-bottom: 2rem; */
	@media ${maxDevice.mobileL} {
		margin-top: 0;
		margin-bottom: 1rem;
	}

	@media ${minDevice.tablet} {
		margin-right: auto;
	}
`;

const pageVariants = {
	initial: {
		opacity: 0,
		x: '-10%'
	},
	in: {
		opacity: 1,
		x: 0
	},
	out: {
		opacity: 0,
		x: '-10%'
	}
};

export const Container = ({ children }: { children: any }) => {
	return (
		<motion.div
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
		>
			<Main>{children}</Main>
		</motion.div>
	);
};

const Cont = styled.div`
	background: #fff !important;
	padding: 3rem 3rem;
	padding-bottom: 1.5rem;
	margin-bottom: 0.5rem;
	@media ${maxDevice.mobileL} {
		min-height: auto;
		padding: 3rem 1.5rem;
		padding-bottom: 0.5rem;
		& input {
			font-size: 1.3rem;
			padding: 9.5px 15px;
		}
		& button {
			padding: 0.8rem;
			height: auto;
		}
	}
`;

const LoginImage = styled(Image)`
	width: 100%;
	max-width: 7.5rem;
	margin-bottom: 1.9rem;
`;

const LoginTitle = styled(Typography.Text)`
	font-size: 0.8rem;
	margin-bottom: 1.2rem;
	display: block;
`;

export const Content = ({
	children,
	title
}: {
	children: any;
	title?: string;
}) => {
	return (
		<Cont>
			<Row justify="center">
				<Col>
					<motion.div layout>
						<LoginImage
							preview={false}
							src={logo}
							fallback={image404}
						/>
					</motion.div>
				</Col>
				{title && (
					<Col span={24}>
						<motion.div layout style={{ textAlign: 'center' }}>
							<LoginTitle>{title}</LoginTitle>
						</motion.div>
					</Col>
				)}
			</Row>
			{children}
		</Cont>
	);
};
