import { LoadingAppView } from '@hospy/shared-components';
import { useAppSelector } from '@hospy/store';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import AppRoute from './app-routes';
import { setIntercomUser } from '@hospy/util-common';

const App = () => {
	const { isAuth, user } = useAppSelector(({ user }) => user);

	if (isAuth && !user) return <LoadingAppView />;
	setIntercomUser(user);
	return (
		<React.Suspense>
			<AnimatePresence>
				<AppRoute />
			</AnimatePresence>
		</React.Suspense>
	);
};

export default App;
