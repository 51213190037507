/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
	getProductsImports as thunkGetProductsImports,
	createProductsImports as thunkCreateProductsImports,
	deleteProductsImports as thunkDeleteProductsImports,
	deleteSync as thunkDeleteSync,
	mappingDynamic as thunkMappingDynamic,
	getPropertyIntegrationsProducts
} from './thunks';

export const partnerWarehouseSlice = createSlice({
	name: 'partner-accounting',
	initialState,
	reducers: {
		setLoadingPartnerWarehouse: (state: any, action) => {
			state[action.payload.state].loading = action.payload.loading;
		},
		cleanDeleteProductImports: (state) => {
			state.deleteProductsImports = initialState.deleteProductsImports;
		},
		setPropertyIntegrations: (state, { payload }) => {
			state.propertyIntegrations = payload;
		},
		cleanSyncDelete: (state) => {
			state.deleteSync = initialState.deleteSync;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(thunkGetProductsImports.pending, (state) => {
				state.getProductsImports.loading = 'pending';
			})
			.addCase(thunkGetProductsImports.rejected, (state, payload) => {
				state.getProductsImports.loading = 'failed';
				state.getProductsImports.error = payload;
			})
			.addCase(
				thunkGetProductsImports.fulfilled,
				(state, { payload }) => {
					state.getProductsImports.data = {
						...state.getProductsImports.data,
						...payload
					};
					state.getProductsImports.loading = 'succeeded';
				}
			)

			.addCase(thunkCreateProductsImports.pending, (state) => {
				state.createProductsImports.loading = 'pending';
				state.propertyIntegrations.loading = 'pending';
			})
			.addCase(
				thunkCreateProductsImports.rejected,
				(state, payload: any) => {
					state.createProductsImports.loading = 'failed';
					state.propertyIntegrations.loading = 'failed';
					state.createProductsImports.error = payload;
				}
			)
			.addCase(
				thunkCreateProductsImports.fulfilled,
				(state, { payload }: any) => {
					state.createProductsImports.data = payload.format;
					state.propertyIntegrations.data =
						payload.propertyIntegrations;
					state.propertyIntegrations.loading = 'succeeded';
					state.createProductsImports.loading = 'succeeded';
				}
			)

			.addCase(thunkDeleteProductsImports.pending, (state) => {
				state.deleteProductsImports.loading = 'pending';
				state.propertyIntegrations.loading = 'pending';
				
			})
			.addCase(thunkDeleteProductsImports.rejected, (state, payload) => {
				state.deleteProductsImports.loading = 'failed';
				state.propertyIntegrations.loading = 'pending';
				state.deleteProductsImports.error = payload;
			})
			.addCase(
				thunkDeleteProductsImports.fulfilled,
				(state, { payload }) => {
					state.propertyIntegrations.loading = 'succeeded';
					state.propertyIntegrations.data = payload.data;
					if (
						state.getProductsImports.data &&
						state.getProductsImports.data[payload.deleteId]
					)
						delete state.getProductsImports.data[payload.deleteId];
					state.deleteProductsImports.loading = 'succeeded';
				}
			)

			.addCase(thunkMappingDynamic.pending, (state) => {
				state.mappingDynamic.loading = 'pending';
				state.propertyIntegrations.loading = 'pending';
			})
			.addCase(thunkMappingDynamic.rejected, (state, payload) => {
				state.mappingDynamic.loading = 'failed';
				state.propertyIntegrations.loading = 'failed';
				state.mappingDynamic.error = payload;
			})
			.addCase(thunkMappingDynamic.fulfilled, (state, { payload }) => {
				state.propertyIntegrations.data = payload;
				state.propertyIntegrations.loading = 'succeeded';
				state.mappingDynamic.loading = 'succeeded';
			})

			.addCase(thunkDeleteSync.pending, (state) => {
				state.deleteSync.loading = 'pending';
				state.propertyIntegrations.loading = 'pending';
			})
			.addCase(thunkDeleteSync.rejected, (state, payload) => {
				state.deleteSync.loading = 'failed';
				state.propertyIntegrations.loading = 'failed';
				state.deleteSync.error = payload;
			})
			.addCase(thunkDeleteSync.fulfilled, (state, { payload }) => {
				state.propertyIntegrations.data = payload.data;
				state.propertyIntegrations.loading = 'succeeded';
				state.deleteSync.loading = 'succeeded';
			})

			.addCase(getPropertyIntegrationsProducts.pending, (state) => {
				state.propertyIntegrations.loading = 'pending';
			})
			.addCase(
				getPropertyIntegrationsProducts.rejected,
				(state, payload) => {
					state.propertyIntegrations.loading = 'failed';
					state.propertyIntegrations.error = payload;
				}
			)
			.addCase(
				getPropertyIntegrationsProducts.fulfilled,
				(state, { payload }) => {
					state.propertyIntegrations.data = payload;
					state.propertyIntegrations.loading = 'succeeded';
				}
			);
	}
});

export const {
	setLoadingPartnerWarehouse,
	cleanDeleteProductImports,
	setPropertyIntegrations,
	cleanSyncDelete
} = partnerWarehouseSlice.actions;
