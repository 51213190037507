/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '../util-url';

export const fetchGetSubscription = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/common/subscriptions`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetCustomer = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/common/subscriptions/customers`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchUpdateCustomer = (payload: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(payload)
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/common/subscriptions/customers`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetIntentStripe = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/third-party/stripe/payment/intent`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetPaymentMethods = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/third-party/stripe/payment/methods`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchUpdateCustomerStripe = (payload: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(payload)
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/third-party/stripe/payment/customer`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchDeletePaymentMethod = (payload: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(
			ApiURL(`api/third-party/stripe/payment/methods/${payload.cardId}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetInvoices = (payload: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(
			ApiURL(
				`api/common/subscriptions/invoices?status=${payload.status}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetDiscounts = (payload: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		const params = new URLSearchParams(payload).toString();
		const url = ApiURL('/api/common/subscriptions/discounts?filter={code:{$not:/AUTO_DISCOUNT/i}}&' + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchCreateDiscounts = (payload: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(payload)
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(ApiURL(`api/common/subscriptions/discounts`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchDeleteDiscounts = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(
			ApiURL(`api/common/subscriptions/discounts?id=${id}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchSubscriptionsToRenew = (): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions: any = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		if (localStorage.getItem('userIdView'))
			requestOptions.headers['X-Hospy-User-View-Id'] =
				localStorage.getItem('userIdView');

		fetch(
			ApiURL(`api/common/subscriptions/prepare-to-renew`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
