import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { getPlaylists, getPlaylistVideo } from './thunks';

export const playlistsSlice = createSlice({
	name: 'playlists',
	initialState,
	reducers: {
		cleanPlaylists: (state) => {
			state.playlists = initialState.playlists;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPlaylists.pending, (state) => {
				state.playlists = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getPlaylists.rejected, (state, { payload }) => {
				state.playlists = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(getPlaylists.fulfilled, (state, { payload }) => {
				state.playlists = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(getPlaylistVideo.pending, (state) => {
				state.playlistVideo = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(getPlaylistVideo.rejected, (state, { payload }) => {
				state.playlistVideo = {
					loading: 'failed',
					error: String(JSON.stringify(payload)),
					data: undefined
				};
			})
			.addCase(getPlaylistVideo.fulfilled, (state, { payload }) => {
				state.playlistVideo = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});
	}
});

export const { cleanPlaylists } = playlistsSlice.actions;
