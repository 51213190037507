import {
	ButtonProps as AntButtonProps,
	Button as AntButtons,
	Tooltip
} from 'antd';

import styled, { css } from 'styled-components';

type ColorPercentage =
	| '10%'
	| '20%'
	| '30%'
	| '40%'
	| '50%'
	| '60%'
	| '70%'
	| '80%'
	| '90%';

export type ButtonProps = AntButtonProps & {
	readonly shadow?: boolean;
	gray?: boolean;
	graydark?: boolean;
	info?: boolean;
	contrast?: boolean;
	oval?: boolean;
	width?: string;
	colorPercentage?: ColorPercentage;
	colorText?: string;
	readonly dark?: boolean;
	yellow?: boolean;
	green?: boolean;
	tooltip?: string;
	black?: boolean;
};

export const Button = (props: ButtonProps) => {
	const { children, ...rest } = props;

	if (rest.disabled && rest.tooltip) {
		return (
			<Tooltip title={rest.tooltip}>
				<div
					style={{
						zIndex: 1,
						cursor: 'no-drop',
						position: 'relative'
					}}
				>
					<Btn
						size="large"
						{...rest}
						style={{ ...rest?.style, zIndex: -1 }}
					>
						{children}
					</Btn>
				</div>
			</Tooltip>
		);
	}

	if (rest.tooltip) {
		return (
			<Tooltip title={rest.tooltip}>
				<Btn size="large" {...rest} style={{ ...rest?.style }}>
					{children}
				</Btn>
			</Tooltip>
		);
	}

	return (
		<Btn size="large" {...rest}>
			{children}
		</Btn>
	);
};

export default Button;

const CSStype: any = {
	primary: ({ contrast, dark, ghost }: any) => css`
		color: #fff;
		background-color: ${({ theme }) => theme.colors.primary.base};
		border-color: ${({ theme }) => theme.colors.primary.darken['10%']};

		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #fff;
			border-color: ${({ theme }) => theme.colors.primary.darken['10%']};
			background-color: ${({ theme }) =>
				theme.colors.primary.darken['10%']};
		}

		${contrast &&
		css`
			color: ${({ theme }) => theme.colors.primary.base};
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['90%']};
			border-color: ${({ theme }) => theme.colors.primary.lighten['80%']};

			&.ant-btn:hover:not(:disabled),
			&.ant-btn:active:not(:disabled),
			&.ant-btn:focus:not(:disabled) {
				color: ${({ theme }) => theme.colors.primary.base};
				border-color: ${({ theme }) =>
					theme.colors.primary.lighten['60%']};
				background-color: ${({ theme }) =>
					theme.colors.primary.lighten['70%']};
			}
		`}

		${dark &&
		css`
			color: #fff;
			background-color: ${({ theme }) =>
				theme.colors.primary.darken['50%']};
			border-color: ${({ theme }) => theme.colors.primary.darken['50%']};

			&.ant-btn:hover:not(:disabled),
			&.ant-btn:active:not(:disabled),
			&.ant-btn:focus:not(:disabled) {
				color: #fff;
				border-color: ${({ theme }) =>
					theme.colors.primary.darken['30%']};
				background-color: ${({ theme }) =>
					theme.colors.primary.darken['30%']};
			}
		`}

		${ghost &&
		css`
			&.ant-btn:hover:not(:disabled),
			&.ant-btn:active:not(:disabled),
			&.ant-btn:focus:not(:disabled) {
				color: ${({ theme }) => theme.colors.primary.darken['10%']};
				border-color: ${({ theme }) =>
					theme.colors.primary.darken['10%']};
				background-color: initial;
			}
		`}

		&.ant-btn-primary:disabled,
		&.ant-btn-primary:disabled:hover {
			color: #fff;
			background-color: ${({ theme }) =>
				theme.colors.primary.lighten['70%']};
			border-color: ${({ theme }) => theme.colors.primary.lighten['50%']};
		}
	`,
	default: () => css`
		&.ant-btn:focus:not(:disabled),
		&.ant-btn:hover:not(:disabled) {
			color: initial;
			border-color: ${({ theme }) => theme.colors.gray.lighten['50%']};
			background-color: ${({ theme }) =>
				theme.colors.gray.lighten['90%']}b5;
		}
	`,
	gray: () => css`
		color: #000;
		background-color: ${({ theme }) => theme.colors.gray.lighten['90%']};
		border-color: ${({ theme }) => theme.colors.gray.lighten['50%']};
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #000;
			border-color: ${({ theme }) => theme.colors.gray.lighten['50%']};
			background-color: ${({ theme }) =>
				theme.colors.gray.lighten['70%']};
		}
	`,
	graydark: () => css`
		color: #000;
		background-color: ${({ theme }) => theme.colors.gray.lighten['30%']};
		border-color: ${({ theme }) => theme.colors.gray.lighten['30%']};
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #000;
			background-color: ${({ theme }) =>
				theme.colors.gray.lighten['60%']};
			border-color: ${({ theme }) => theme.colors.gray.lighten['50%']};
		}
		&.ant-btn-primary:disabled,
		&.ant-btn-primary:disabled:hover {
			color: #505050;
			background-color: ${({ theme }) =>
				theme.colors.gray.lighten['20%']};
			border-color: ${({ theme }) => theme.colors.gray.lighten['20%']};
		}
	`,
	black: () => css`
		background-color: ${({ theme }) => theme.colors.black.base};
		color: ${({ theme }) => theme.colors.white.base};
		border-color: ${({ theme }) => theme.colors.white.base};
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			border-color: ${({ theme }) => theme.colors.black.lighten['20%']};
			background-color: ${({ theme }) =>
				theme.colors.black.lighten['20%']};
		}

		&.ant-btn-primary:disabled,
		&.ant-btn-primary:disabled:hover {
			color: ${({ theme }) => theme.colors.white.base};
			border-color: ${({ theme }) => theme.colors.black.lighten['50%']};
			background-color: ${({ theme }) =>
				theme.colors.black.lighten['50%']};
		}
	`,
	info: (colorPercentage: string, colorText: string) => css`
		background-color: ${({ theme }) =>
			theme.colors.info.lighten[colorPercentage || '90%']};
		border-color: ${({ theme }) =>
			theme.colors.info.lighten[colorPercentage || '50%']};
		${colorText ? `color: ${colorText};` : ''}
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			border-color: ${({ theme }) =>
				theme.colors.info.lighten[colorPercentage || '50%']};
			background-color: ${({ theme }) =>
				theme.colors.info.lighten[colorPercentage || '70%']};
			${colorText ? `color: ${colorText};` : ''}
		}
	`,
	danger: () => css`
		color: ${({ theme }) => theme.colors.danger.base};

		&:not(.ant-btn-text) {
			border-color: ${({ theme }) => theme.colors.danger.lighten['50%']};
		}

		&.ant-btn-primary {
			color: #fff;
			background-color: ${({ theme }) => theme.colors.danger.base};
		}
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled) {
			color: ${({ theme }) => theme.colors.danger.base};
			background-color: ${({ theme }) =>
				theme.colors.danger.lighten['90%']};

			&:not(.ant-btn-text) {
				border-color: ${({ theme }) =>
					theme.colors.danger.lighten['50%']};
			}

			&.ant-btn-primary {
				color: #fff;
				border-color: ${({ theme }) =>
					theme.colors.danger.lighten['50%']};
				background-color: ${({ theme }) =>
					theme.colors.danger.lighten['20%']};
			}
		}

		&.ant-btn:focus:not(:disabled) {
			color: ${({ theme }) => theme.colors.danger.base};
			&:not(.ant-btn-text) {
				border-color: ${({ theme }) =>
					theme.colors.danger.lighten['50%']};
			}
			&.ant-btn-primary {
				color: #fff;
				background-color: ${({ theme }) => theme.colors.danger.base};
			}
		}

		&.ant-btn-primary:disabled,
		&.ant-btn-primary:disabled:hover {
			color: #fff;
			background-color: ${({ theme }) =>
				theme.colors.danger.lighten['70%']};
			border-color: ${({ theme }) => theme.colors.danger.lighten['50%']};
		}
	`,
	yellow: () => css`
		color: #000;
		background-color: ${({ theme }) => theme.colors.yellow.base};
		border-color: ${({ theme }) => theme.colors.yellow.base};
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #000;
			border-color: ${({ theme }) => theme.colors.yellow.lighten['10%']};
			background-color: ${({ theme }) =>
				theme.colors.yellow.lighten['30%']};
		}
	`,
	green: () => css`
		color: #fff;
		background-color: ${({ theme }) => theme.colors.success.base};
		border-color: ${({ theme }) => theme.colors.success.base};
		&.ant-btn:hover:not(:disabled),
		&.ant-btn:active:not(:disabled),
		&.ant-btn:focus:not(:disabled) {
			color: #fff;
			border-color: ${({ theme }) => theme.colors.success.lighten['10%']};
			background-color: ${({ theme }) =>
				theme.colors.success.lighten['30%']};
		}
		&.ant-btn-primary:disabled,
		&.ant-btn-primary:disabled:hover {
			color: #fff;
			background-color: ${({ theme }) =>
				theme.colors.success.lighten['70%']};
			border-color: ${({ theme }) => theme.colors.success.lighten['50%']};
		}
	`
};

const Btn = styled(AntButtons)<ButtonProps>`
	${({ oval, shape }) =>
		shape !== 'round' &&
		shape !== 'circle' &&
		css`
			border-radius: 0;
			border-radius: ${oval ? '30px' : 'initial'};
		`}

	padding-left: 15px;
	padding-right: 15px;
	${({ width }) => (width && `width: ${width};`) || ''};

	${({ oval }) =>
		oval &&
		css`
			border: none;
		`}
	height: ${({ size, theme }) => theme.input.height[size || 'middle']}px;
	${({ type, contrast, dark, ghost }) =>
		CSStype[type || 'default'] &&
		CSStype[type || 'default']({ contrast, dark, ghost })}
	${({ gray }) => gray && CSStype['gray']()}
	${({ yellow }) => yellow && CSStype['yellow']()}
	${({ green }) => green && CSStype['green']()}
	${({ graydark }) => graydark && CSStype['graydark']()}
    ${({ info, colorPercentage, colorText }) =>
		info && CSStype['info'](colorPercentage, colorText)}
    ${({ danger }) => danger && CSStype['danger']()}
	${({ black }) => black && CSStype['black']()}
    ${({ shadow, theme, type }) =>
		shadow &&
		css`
			box-shadow: ${theme.boxShadow.level1(
				type === 'primary'
					? `${theme.colors.primary.darken['50%']}87`
					: null
			)};
		`}
`;
