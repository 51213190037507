/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	InvoiceCreditsProps,
	createCreditsInvoice,
	updateCredits,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface PackageOption {
	value: number;
	price: number;
	label: string;
}

interface PackageOption {
	value: number;
	price: number;
	label: string;
}

export const packageOptions = [
	{ value: 100, price: 15, label: '100 timbres - USD 15' },
	{ value: 250, price: 28, label: '250 timbres - USD 28' },
	{ value: 500, price: 44, label: '500 timbres - USD 44' },
	{ value: 800, price: 66, label: '800 timbres - USD 66' },
	{ value: 1500, price: 116, label: '1500 timbres - USD 116' },
	{ value: 3500, price: 231, label: '3500 timbres - USD 231' }
];

const useCreditPurchase = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [selectedPackage, setSelectedPackage] = useState<
		PackageOption | undefined
	>(packageOptions[0]);

	const {
		credits,
		updateCredits: updateCreditsState,
		creditInvoice
	} = useAppSelector(({ credits }) => credits);
	const { propertyId } = useAppSelector(({ user }) => user);
	const { appData } = useAppSelector(({ common }) => common);

	const buy = async () => {
		const id = credits.data?.credits?._id;
		if (
			!credits.data?.credits ||
			!selectedPackage ||
			!id ||
			!propertyId ||
			!appData?._id
		)
			return;
		const currentProperties = JSON.parse(
			JSON.stringify([...(credits.data?.credits?.properties || [])])
		);

		const creditInfo: InvoiceCreditsProps = {
			active: true,
			amount: selectedPackage.price,
			id,
			properties: [
				{
					id: propertyId,
					amount: selectedPackage.price,
					apps: [
						{
							id: appData._id,
							name: appData.name.es,
							amount: selectedPackage.price,
							quantity: selectedPackage.value
						}
					]
				}
			]
		};

		await dispatch(createCreditsInvoice(creditInfo));

		const foundPropertyIndex = currentProperties.findIndex(
			(creditProperty: any) => creditProperty.id === propertyId
		);
		const currentAmount =
			credits.data?.credits?.amount - selectedPackage.price;

		if (foundPropertyIndex < 0) {
			const newProperties = [
				{
					id: propertyId,
					apps: [
						{
							id: appData._id,
							quantity: selectedPackage.value
						}
					]
				}
			];
			await dispatch(
				updateCredits({
					id,
					properties: newProperties,
					amount: currentAmount
				})
			);
		}

		const foundAppIndex = currentProperties[
			foundPropertyIndex
		]?.apps.findIndex(
			(creditProperty: any) => creditProperty.id === appData?._id
		);
		if (foundAppIndex < 0) {
			currentProperties[foundPropertyIndex].apps = [
				...currentProperties[foundPropertyIndex].apps,
				{
					id: appData._id,
					quantity: selectedPackage.value
				}
			];
			await dispatch(
				updateCredits({
					id,
					properties: currentProperties,
					amount: currentAmount
				})
			);
		}

		if (foundAppIndex >= 0 && foundPropertyIndex >= 0) {
			currentProperties[foundPropertyIndex].apps[foundAppIndex] = {
				...currentProperties[foundPropertyIndex].apps[foundAppIndex],
				quantity:
					currentProperties[foundPropertyIndex].apps[foundAppIndex]
						.quantity + selectedPackage?.value
			};

			await dispatch(
				updateCredits({
					id,
					properties: currentProperties,
					amount: currentAmount
				})
			);
		}
		message.success('Paquete comprado correctamente');
	};

	const hasInsufficientCredits =
		(credits.data?.credits?.amount || 0) < (selectedPackage?.price || 0);

	return {
		hasInsufficientCredits,
		selectedPackage,
		setSelectedPackage,
		buy,
		credits,
		navigate,
		loading:
			creditInvoice.loading === 'pending' ||
			updateCreditsState.loading === 'pending'
	};
};

export default useCreditPurchase;
