/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchCreateCustomer, fetchCrmUpdateCustomer } from '@hospy/util-api';
import { RequestCreate, RequestFindAll } from '@hospy/util-api/requests';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerSliceName } from './constants';
import { CrmCreateCustomer, CrmUpdateCustomer } from './interface';
import {
	RequestCreateCustomer,
	RequestFindAllCustomers
} from '@hospy/util-api/service/customer';

export const createCustomer = createAsyncThunk(
	'partner-customers/createCustomer',
	async (payload: CrmCreateCustomer, { rejectWithValue }) => {
		try {
			const response = await fetchCreateCustomer(payload);
			if (response.errors)
				return rejectWithValue({
					title:
						response.description ||
						'Ocurrió un problema, inténtalo más tarde',
					details: response.errors || []
				});
			else if (response.statusCode < 200 || response.statusCode > 299) {
				if (response.statusCode === 504)
					return rejectWithValue({
						title: 'Siigo está tomando mucho tiempo en responder, inténtelo más tarde por favor.'
					});
				return rejectWithValue({
					title: 'Ocurrió un problema, inténtalo más tarde',
					details: []
				});
			}
			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const updateCustomer = createAsyncThunk(
	'partner-customers/updateCustomer',
	async (payload: CrmUpdateCustomer, { rejectWithValue }) => {
		try {
			const response = await fetchCrmUpdateCustomer(payload);
			if (response.errors)
				return rejectWithValue({
					title:
						response.description ||
						'Ocurrió un problema, inténtalo más tarde',
					details: response.errors || []
				});
			else if (response.statusCode < 200 || response.statusCode > 299) {
				if (response.statusCode === 504)
					return rejectWithValue({
						title: 'Siigo está tomando mucho tiempo en responder, inténtelo más tarde por favor.'
					});
				return rejectWithValue({
					title: 'Ocurrió un problema, inténtalo más tarde',
					details: []
				});
			}
			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const ThunkFindAllCustomer = createAsyncThunk(
	`${CustomerSliceName}/${RequestFindAllCustomers.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllCustomers(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);

export const ThunkCreateCustomer = createAsyncThunk(
	`${CustomerSliceName}/${RequestCreateCustomer.name}`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreateCustomer(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
