/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';
import moment from 'moment';
import { initialState } from './constants';
import { TpReservationSireTraData } from './interfaces';
import {
	deleteReservation,
	findThirdPartyReservationCheckIn,
	findThirdPartyReservationCheckOut,
	getReservationDetail,
	getReservationSummary,
	getReservationsSummaryCheckin,
	getTpReservations,
	getTpReservationsNoRegistered,
	postTpReservations,
	updateReservation
} from './thunks';

const parseReservationData = ({
	_id,
	guests,
	adults,
	children,
	thirdPartyId,
	checkInDate,
	checkOutDate,
	roomName,
	roomId,
	total,
	propertyId,
	channelSlug,
	channelName,
	totalGuests,
	...rest
}: any): TpReservationSireTraData => {
	let guestName = '';
	const findGuest = guests.find((e: any) => e.isMain);
	if (findGuest) {
		const { firstName, lastName } = findGuest;
		guestName = [firstName, lastName].filter((e) => e).join(' ');
	}
	return {
		reservationId: _id,
		guestName: guestName,
		adults: adults,
		children: children,
		thirdPartyId: thirdPartyId,
		checkInDate: checkInDate?.substring(0, 10),
		checkOutDate: checkOutDate?.substring(0, 10),
		roomId,
		roomName,
		total,
		propertyId,
		channelSlug,
		channelName,
		totalGuests: isNumber(Number(totalGuests)) ? Number(totalGuests) : 0,
		...rest
	};
};

export const ThirdPartyReservationsSlice = createSlice({
	name: 'third-party-reservations',
	initialState,
	reducers: {
		cleanSuccess: (state: any, action) => {
			state.success[action.payload] = false;
			if (action.payload == 'findThirdPartyReservationCheckIn') {
				state.checkInReservations = undefined;
				state.loading.findThirdPartyReservationCheckIn = 'idle';
			}
			if (action.payload == 'findThirdPartyReservationCheckOut') {
				state.checkOutReservations = undefined;
				state.loading.findThirdPartyReservationCheckOut = 'idle';
			}
		},
		cleanError: (state: any, action) => {
			state.error[action.payload] = undefined;
		},
		closeReservationDetail: (state) => {
			state.reservationDetail = {
				loading: 'idle',
				error: '',
				data: undefined
			};
		},
		cleanReservation: (state, action) => {
			if (action.payload === 'update') {
				state.error.updateThirdPartyReservation = null;
				state.loading.updateThirdPartyReservation = 'idle';
			}

			if (action.payload === 'delete') {
				state.error.deleteThirdPartyReservation = null;
				state.loading.deleteThirdPartyReservation = 'idle';
			}
		},
		updateLocalReservation: (state, { payload }) => {
			if (!state.tpReservations.data) return;

			state.tpReservations.loading = 'pending';
			const _localReservations = current(state.tpReservations.data).map(
				(e) => ({ ...e })
			);

			if (payload?.remove) {
				state.tpReservations.data = [
					..._localReservations.filter(
						(e) => e.reservationId !== payload.remove
					)
				];
				state.tpReservations.loading = 'succeeded';
				return;
			}

			const indexToUpdate = state.tpReservations.data?.findIndex(
				(reservation) => reservation.reservationId === payload._id
			);

			if (indexToUpdate > -1) {
				const reservationToUpdate = {
					..._localReservations[indexToUpdate]
				};
				if (payload.guests !== undefined) {
					if (payload.guests.length > 0) {
						const mainGuest = payload.guests.find(
							(guest: any) => guest.isMain
						);
						reservationToUpdate.guestName = `${mainGuest?.firstName} ${mainGuest?.lastName}`;

						reservationToUpdate.mainGuestEmail =
							mainGuest?.email || '';
						reservationToUpdate.mainGuestPhone =
							mainGuest?.phone || '';
					} else {
						reservationToUpdate.guestName = '';
						reservationToUpdate.adults = 0;
					}
				}

				if (payload.email) {
					reservationToUpdate.email = payload.email;
				}

				if (payload.phone) {
					reservationToUpdate.phone = payload.phoe;
				}

				if (payload.checkInDate) {
					reservationToUpdate.checkInDate = moment(
						typeof payload.checkInDate === 'string'
							? payload.checkInDate.substring(0, 10)
							: moment(payload.checkInDate).format('YYYY-MM-DD')
					).format('YYYY-MM-DD');
				}
				if (payload.checkOutDate) {
					reservationToUpdate.checkOutDate = moment(
						typeof payload.checkOutDate === 'string'
							? payload.checkOutDate.substring(0, 10)
							: moment(payload.checkOutDate).format('YYYY-MM-DD')
					).format('YYYY-MM-DD');
				}

				if (payload.status) reservationToUpdate.status = payload.status;
				if (payload.totalGuests)
					reservationToUpdate.totalGuests = payload.totalGuests;
				if (payload.roomId) reservationToUpdate.roomId = payload.roomId;
				if (payload.roomName)
					reservationToUpdate.roomName = payload.roomName;

				if (payload.extras) reservationToUpdate.extras = payload.extras;

				if (payload.accessCodes) {
					reservationToUpdate.accessCodes = payload.accessCodes;
				}
				if (payload.enableAccess !== undefined) {
					reservationToUpdate.enableAccess = payload.enableAccess;
				}

				if (payload.guests !== undefined) {
					reservationToUpdate.guests = payload.guests;

					if (payload.guests.filter((e: any) => e.state).length) {
						reservationToUpdate.adults = payload.guests.filter(
							(e: any) => e.state === 'complete'
						).length;
					} else {
						reservationToUpdate.adults = payload.guests.length;
					}
				}

				_localReservations[indexToUpdate] = reservationToUpdate;
			}

			state.tpReservations.data = [..._localReservations];
			state.tpReservations.loading = 'succeeded';
		},
		reOrderSelectedTpReservation: (state, { payload }) => {
			// payload ->
			if (!state.tpReservations.data) return;
			state.tpReservations.loading = 'pending';
			const reservations = [...state.tpReservations.data];

			const indexToUpdate = reservations.findIndex(
				(reservation) => reservation.reservationId === payload
			);

			const reservationSelected = reservations.splice(indexToUpdate, 1);

			state.tpReservations.data = [
				reservationSelected[0],
				...reservations
			];
			state.tpReservations.loading = 'succeeded';
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(postTpReservations.pending, (state) => {
				state.tpCreateReservation = {
					loading: 'pending'
				};
			})
			.addCase(postTpReservations.rejected, (state, { payload }) => {
				state.tpCreateReservation = {
					loading: 'failed',
					error: payload
				};
			})
			.addCase(postTpReservations.fulfilled, (state, { payload }) => {
				state.tpCreateReservation = {
					loading: 'succeeded',
					data: payload
				};
				if (payload?._id) {
					if (state.tpReservations.data) {
						state.tpReservations.meta = {
							...state.tpReservations?.meta,
							count: (state.tpReservations?.meta?.count || 0) + 1
						};
						state.tpReservations.data = [
							...(state.tpReservations?.data || []),
							parseReservationData(payload)
						]
							.sort(compararFechasOut)
							.sort(compararFechasIn)
							.sort((a: any, b: any) => {
								const _a = Boolean(a.adults > 0);
								const _b = Boolean(b.adults > 0);
								return _a === _b ? 0 : _a ? -1 : 1;
							});
					}
				}
			});

		builder
			.addCase(getTpReservations.pending, (state) => {
				state.tpReservations.loading = 'pending';
			})
			.addCase(getTpReservations.rejected, (state, { payload }) => {
				state.tpReservations.loading = 'failed';
				state.tpReservations.error = payload;
			})
			.addCase(getTpReservations.fulfilled, (state, { payload }) => {
				state.tpReservations.loading = 'succeeded';
				state.tpReservations.error = undefined;

				if (payload.page === 1) {
					state.tpReservations.data = payload?.data;
				} else {
					state.tpReservations.data = [
						...(state?.tpReservations?.data || []),
						...payload?.data
					];
				}

				state.tpReservations.meta = payload.meta;
			});

		builder
			.addCase(getTpReservationsNoRegistered.pending, (state) => {
				state.tpReservationsNoRegistered.loading = 'pending';
			})
			.addCase(
				getTpReservationsNoRegistered.rejected,
				(state, { payload }) => {
					state.tpReservationsNoRegistered.loading = 'failed';
					state.tpReservationsNoRegistered.error = payload;
				}
			)
			.addCase(
				getTpReservationsNoRegistered.fulfilled,
				(state, { payload }) => {
					state.tpReservationsNoRegistered = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(
				findThirdPartyReservationCheckIn.fulfilled,
				(state, action) => {
					const payload: any = action.payload;
					if (payload) {
						state.checkInReservations = action.payload;
						state.loading.findThirdPartyReservationCheckIn =
							'succeeded';
						state.success = {
							...state.success,
							findThirdPartyReservationCheckIn: true
						};
					} else {
						state.error = {
							...state.error,
							findThirdPartyReservationCheckIn: false
						};
					}
				}
			)
			.addCase(findThirdPartyReservationCheckIn.pending, (state: any) => {
				state.loading.findThirdPartyReservationCheckIn = 'pending';
			})
			.addCase(
				findThirdPartyReservationCheckIn.rejected,
				(state: any) => {
					state.loading.findThirdPartyReservationCheckIn = 'failed';
				}
			);

		builder
			.addCase(
				findThirdPartyReservationCheckOut.fulfilled,
				(state, { payload }) => {
					if (payload) {
						state.checkOutReservations = payload;
						state.loading.findThirdPartyReservationCheckOut =
							'succeeded';
						state.success = {
							...state.success,
							findThirdPartyReservationCheckOut: true
						};
					} else {
						state.error = {
							...state.error,
							findThirdPartyReservationCheckOut: false
						};
					}
				}
			)
			.addCase(
				findThirdPartyReservationCheckOut.pending,
				(state: any) => {
					state.loading.findThirdPartyReservationCheckOut = 'pending';
				}
			)
			.addCase(
				findThirdPartyReservationCheckOut.rejected,
				(state: any) => {
					state.loading.findThirdPartyReservationCheckOut = 'failed';
				}
			);

		builder
			.addCase(getReservationDetail.pending, (state, { payload }) => {
				state.reservationDetail = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(getReservationDetail.rejected, (state, { payload }) => {
				state.reservationDetail = {
					loading: 'failed',
					data: undefined,
					error: String(payload)
				};
			})
			.addCase(getReservationDetail.fulfilled, (state, { payload }) => {
				state.reservationDetail = {
					loading: 'succeeded',
					data: payload,
					error: ''
				};
			});

		builder
			.addCase(getReservationSummary.fulfilled, (state, { payload }) => {
				state.reservationSummary = {
					loading: 'succeeded',
					data: payload,
					error: ''
				};
			})
			.addCase(getReservationSummary.pending, (state, { payload }) => {
				state.reservationSummary = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(getReservationSummary.rejected, (state, { payload }) => {
				state.reservationSummary = {
					loading: 'failed',
					data: undefined,
					error: String(payload)
				};
			});

		builder
			.addCase(updateReservation.pending, (state) => {
				state.loading.updateThirdPartyReservation = 'pending';
				state.error.updateThirdPartyReservation = undefined;
				state.success.updateThirdPartyReservation = false;
			})
			.addCase(updateReservation.fulfilled, (state, { payload }) => {
				const item = parseReservationData(payload);

				state.loading.updateThirdPartyReservation = 'succeeded';
				state.error.updateThirdPartyReservation = item;
				state.success.updateThirdPartyReservation = true;

				const reservations = current(state.tpReservations.data || []);

				if (
					!reservations?.find(
						(e) => e.reservationId === payload?._id
					) &&
					payload?._id
				) {
					if (reservations.length > 0) {
						state.tpReservations.data = [
							...(reservations || []),
							item
						]
							.sort(compararFechasOut)
							.sort(compararFechasIn)
							.sort((a: any, b: any) => {
								const _a = Boolean(a.adults > 0);
								const _b = Boolean(b.adults > 0);
								return _a === _b ? 0 : _a ? -1 : 1;
							});
					}
					state.tpReservationsNoRegistered.data =
						state.tpReservationsNoRegistered.data?.filter(
							(e) => e.reservationId !== payload?._id
						);
				} else if (
					reservations?.find(
						(e) => e.reservationId === payload?._id
					) &&
					payload?._id
				) {
					const item = parseReservationData({
						...reservations.find(
							(e) => e.reservationId == payload._id
						),
						...payload
					});

					state.tpReservations.data = [
						...reservations.map((e) => {
							if (e.reservationId === payload._id)
								return { ...item };
							return e;
						})
					]
						.sort(compararFechasOut)
						.sort(compararFechasIn)
						.sort((a: any, b: any) => {
							const _a = Boolean(a.adults > 0);
							const _b = Boolean(b.adults > 0);
							return _a === _b ? 0 : _a ? -1 : 1;
						});
				}
			})
			.addCase(updateReservation.rejected, (state, { payload }) => {
				state.loading.updateThirdPartyReservation = 'failed';
				state.error.updateThirdPartyReservation = payload;
				state.success.updateThirdPartyReservation = false;
			});

		builder
			.addCase(deleteReservation.pending, (state) => {
				state.loading.deleteThirdPartyReservation = 'pending';
				state.error.deleteThirdPartyReservation = undefined;
				state.success.deleteThirdPartyReservation = false;
			})
			.addCase(deleteReservation.fulfilled, (state, { payload }) => {
				state.loading.deleteThirdPartyReservation = 'succeeded';
				state.error.deleteThirdPartyReservation = undefined;
				state.success.deleteThirdPartyReservation = true;

				if (payload?.reservationId) {
					state.tpReservations.data =
						state.tpReservations.data?.filter(
							(e) => e.reservationId !== payload?.reservationId
						);
					state.tpReservationsNoRegistered.data =
						state.tpReservationsNoRegistered.data?.filter(
							(e) => e.reservationId !== payload?.reservationId
						);
				}
			})
			.addCase(deleteReservation.rejected, (state, { payload }) => {
				state.loading.deleteThirdPartyReservation = 'failed';
				state.error.deleteThirdPartyReservation = payload;
				state.success.deleteThirdPartyReservation = false;
			});

		builder
			.addCase(getReservationsSummaryCheckin.pending, (state) => {
				state.summaryCheckin.loading = 'pending';
			})
			.addCase(
				getReservationsSummaryCheckin.rejected,
				(state, payload) => {
					state.summaryCheckin.loading = 'failed';
					state.summaryCheckin.error = payload;
				}
			)
			.addCase(
				getReservationsSummaryCheckin.fulfilled,
				(state, { payload }) => {
					state.summaryCheckin.data = payload;
					state.summaryCheckin.loading = 'succeeded';
				}
			);
	}
});

const compararFechasIn = (
	a: TpReservationSireTraData,
	b: TpReservationSireTraData
) => {
	const fechaA = new Date(a.checkInDate);
	const fechaB = new Date(b.checkInDate);

	return fechaA.getTime() - fechaB.getTime();
};

const compararFechasOut = (
	a: TpReservationSireTraData,
	b: TpReservationSireTraData
) => {
	const fechaA = new Date(a.checkOutDate);
	const fechaB = new Date(b.checkOutDate);

	return fechaA.getTime() - fechaB.getTime();
};

export const { updateLocalReservation, reOrderSelectedTpReservation } =
	ThirdPartyReservationsSlice.actions;
