/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { ApiURL } from '../util-url';
import { Fetch } from '../fetch';
import {
	RequestCreate,
	RequestFindAll,
	RequestFindOne,
	RequestRemove
} from '../requests';
import { ResponseFailure, ResponseSuccess } from '../responses';
interface IParamsBase {
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo?: string;
	propertyId: string;
	find?: {
		sync?: boolean;
	};
}

interface IInvoiceCreditNoteRequest {
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	propertyId: string;
	notes: string;
}

interface DeleteParams {
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	propertyId: string;
}

export const getPaymentMethods = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
}): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();
		const url = ApiURL(`/api/accounting/payment-methods?` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPaymentTypes = (
	data: {
		partnerIntegrationId: string;
		appId: string;
		propertyId: string;
	},
	filter: string
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();
		const url = ApiURL(`/api/accounting/payment-types?${filter}&` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getDocumentTypes = (
	data: {
		partnerIntegrationId: string;
		appId: string;
		propertyId: string;
	},
	filter: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/accounting/document-types?${filter}&` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getTaxes = (
	data: {
		partnerIntegrationId: string;
		appId: string;
		propertyId: string;
	},
	filter?: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();
		const url = ApiURL(
			`/api/accounting/taxes?` +
				params +
				`${filter ? `&filter=${filter}` : ''}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCostCenters = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(`/api/accounting/cost-centers?` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getListingTypes = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(`/api/pms/listing-types?` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getProducts = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/warehouse/products?filter=${filter || '{}'}&limit=999&` +
				params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetReservations = (data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/pms/reservations?` + params + `&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchInvoiceReservations = (data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/pms/reservations-invoices?` + params + `&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetCompanies = (data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/core/companies?` + params + `&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchCreateCompany = (data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/core/companies`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchCreateCustomer = (data: any): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/crm/customers`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchCrmUpdateCustomer = (data: any): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/crm/customers/${data.siigoId}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchGetAllInvoice = ({ filter, ...data }: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams({ ...data } as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices?${params}&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((rs) => rs.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetAllInvoiceCsv = ({ filter, ...data }: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams({ ...data } as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/csv?${params}&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((response: any) => {
				if (response.ok) return response.blob();
				else if (response.status === 400) throw response.json();
				throw response;
			})
			.then((blob) => {
				const file = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = file;
				a.download = `invoice-${moment().format(
					'YYYY-MM-DD_HH:mm:ss'
				)}.csv`;
				document.body.appendChild(a);
				a.click();
				a.remove();
				resolve({ status: 'success' });
			})
			.catch((error) => reject(error));
	});

export const fetchGetInvoice = (
	data: any,
	reservationId: string | undefined
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}?` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchCreateInvoice = (data: any, invoice?: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(invoice)
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(`/api/accounting/invoices?` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchUpdateInvoice = (
	data: any,
	reservationId?: string,
	invoice?: any
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(invoice)
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}?` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchDeleteInvoice = (
	data: any,
	reservationId: string | undefined
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}?` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCustomerDocumentTypes = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/crm/customers/document-types?filter=${
				filter || '{}'
			}&limit=999&` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getUsers = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		delete data.filter;
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();
		const url = ApiURL(`/api/crm/users?` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface validateRfcProps {
	Rfc: string;
	Name: string;
	ZipCode: string;
	FiscalRegime: number;
}
export const validateRfcServices = (data: validateRfcProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`api/third-party/facturama/validate-rfc`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface validateCsdsProps {
	Rfc: string;
	Certificate: string;
	PrivateKey: string;
	PrivateKeyPassword: number;
}
export const validateCsdsServices = (data: validateCsdsProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`api/third-party/facturama/csds`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCsdsServices = (rfc: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`api/third-party/facturama/csds/${rfc}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteCsdsServices = (rfc: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`api/third-party/facturama/csds/${rfc}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetCurrencyRate = (data: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		let filter = '';
		if (data.filter) filter = '&filter=' + data.filter;
		delete data.filter;

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(`/api/core/currencies/rates?` + params + filter);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchStampInvoice = (
	data: any,
	reservationId?: string
): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		let invoiceId = '';
		if (reservationId) invoiceId = `tp_${reservationId}`;
		else invoiceId = data._id;

		const url = ApiURL(`/api/accounting/invoices/${invoiceId}/stamps`);

		fetch(url, requestOptions)
			.then((response) => {
				const data = response.json();
				if (response.status === 504) {
					return {
						...data,
						statusCode: response.status
					};
				}
				return data;
			})
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchInvoiceNotifications = ({
	id,
	data
}: {
	id: any;
	data: any;
}): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		const url = ApiURL(`/api/accounting/invoices/${id}/notifications`);
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const updateAsyncStampInvoiceService = (
	data: any,
	reservationId?: string
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}/stamps/async`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const updateStampInvoiceService = (
	data: any,
	reservationId?: string
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/accounting/invoices/${reservationId}/stamps`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetStampInvoice = (
	data: any,
	reservationId?: string
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();

		let invoiceId = '';
		if (reservationId) invoiceId = `tp_${reservationId}`;
		else invoiceId = data._id;

		const url = ApiURL(
			`/api/accounting/invoices/${invoiceId}/stamps?${params}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchApplyCreditNote = (
	data: IInvoiceCreditNoteRequest,
	reservationId?: string
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/accounting/credit-notes/tp_${reservationId}/stamps`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getInvoiceStampsErrorsService = (
	reservationId: string,
	data: any
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}/stamp/errors?${params}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchJoinItems = (
	data: any,
	reservationId?: string,
	name?: string
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ name })
		};

		const params = new URLSearchParams(data as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}/join-items?` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchGetProductsImports = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();

		const url = ApiURL(
			`/api/warehouse/products-imports?filter=${filter || '{}'}&` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchDeleteProductsImports = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/warehouse/products-imports?filter=${filter || '{}'}&` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchCreateProductsImports = (
	data: IParamsBase | any,
	format?: string,
	file?: any
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const params = new FormData();
		for (const key of Object.keys(data)) {
			params.append(
				key,
				typeof data[key] === 'string'
					? data[key]
					: JSON.stringify(data[key])
			);
		}
		if (file) params.append('file', file);
		const requestOptions = {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: params
		};

		const url = ApiURL(
			`/api/warehouse/products-imports/${format}?sync=${data.find?.sync}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchMappingDynamic = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/warehouse/products-imports/mapping-dynamic?filter=${
				filter || '{}'
			}&` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchDeleteSync = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const filter = data.filter;
		delete data.filter;

		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(
			`/api/warehouse/products-imports/delete-all-mapping?filter=${
				filter || '{}'
			}&` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export type BucketOptions = 'logo' | 'cdn';

interface uploadFileServiceProps {
	file: string;
	name: string;
	contentType: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	resource: string;
	bucket?: BucketOptions;
}
export const uploadFileService = async ({
	file,
	name,
	contentType,
	propertyId,
	partnerIntegrationId,
	appId,
	resource,
	bucket
}: uploadFileServiceProps): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const url = ApiURL('/api/common/files/base64');

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				data: file,
				name,
				contentType,
				propertyId,
				partnerIntegrationId,
				appId,
				resource,
				bucket
			})
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteFileService = async (file: string): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const url = ApiURL(`/api/common/files`);

		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				file
			})
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchDeleteStampInvoice = (
	reservationId: string,
	params: DeleteParams
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const parsedParams = new URLSearchParams(params as any).toString();

		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}/stamps?` + parsedParams
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchSearchPublicReservation = (
	appId: string,
	searchArgs: any
): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		};
		const { reservationId } = searchArgs;

		delete searchArgs.reservationId;

		const params = new URLSearchParams({
			appId,
			...searchArgs
		} as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/${reservationId}/self?` + params
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchSelfStampInvoice = (
	data: any,
	reservationId?: string
): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/accounting/invoices/tp_${reservationId}/self`);

		fetch(url, requestOptions)
			.then((response) => {
				const data = response.json();
				if (response.status === 504) {
					return {
						...data,
						statusCode: response.status
					};
				}
				return data;
			})
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchStampInvoiceAsync = (data: any): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				invoice: data.invoice,
				customer: data.customer,
				wasEdited: data.wasEdited
			})
		};

		const url = ApiURL(`/api/accounting/invoices/stamps/async`);

		fetch(url, requestOptions)
			.then((response) => {
				const data = response.json();
				if (response.status === 504) {
					return {
						...data,
						statusCode: response.status
					};
				}
				return data;
			})
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchAdvancePayment = (data: any): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				body: JSON.stringify(data)
			})
		};

		const url = ApiURL(`/api/third-party/cloudbeds/webhook/payment`);

		fetch(url, requestOptions)
			.then((response) => {
				const data = response.json();
				if (response.status === 504) {
					return {
						...data,
						statusCode: response.status
					};
				}
				return data;
			})
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchRetryJournal = (data: any): Promise<any> =>
	new Promise(async (resolve) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/accounting/journals/siigo-connect-single-journal-retry`
		);

		fetch(url, requestOptions)
			.then((response) => {
				const data = response.json();
				if (response.status === 504) {
					return {
						...data,
						statusCode: response.status
					};
				}
				return data;
			})
			.then((data) => resolve(data))
			.catch((error) => {
				console.error(error);
				resolve({ statusCode: 504 });
			});
	});

export const fetchGetAllSummaryReport = ({
	filter,
	...data
}: any): Promise<any> =>
	new Promise(async (resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams({ ...data } as any).toString();
		const url = ApiURL(
			`/api/accounting/invoices/summary-report?${params}&filter=${filter}`
		);

		fetch(url, requestOptions)
			.then((rs) => rs.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getUnits = (data: {
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
	filter?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data).toString();

		const url = ApiURL(`/api/accounting/units/?limit=999&` + params);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface FindCsdByRfcProps {
	Rfc: string;
	partnerIntegrationId: string;
	appId: string;
	propertyId: string;
}

export const findCsdByRfc = (data: FindCsdByRfcProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const params = new URLSearchParams(data as any).toString();

		fetch(
			ApiURL(`/api/accounting/csd/${data.Rfc}?${params}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllListings = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('RequestFindAllListings', 'api/pms/listings', params);

export const RequestFindOneInternalAccountInvoice = (
	params: RequestFindOne
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/accounting/invoices/internal-account/:id', params);

export const RequestCreateInternalAccountInvoice = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch(
		'post',
		'api/accounting/invoices/internal-account/stamps/async',
		params
	);

export const RequestUpdateInternalAccountInvoice = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch(
		'put',
		'api/accounting/invoices/internal-account/:id/stamps/async',
		params
	);

export const RequestCreateCreditNote = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', '/api/accounting/credit-notes/:id/stamps', params);

export const RequestCreateFavoriteInternalAccount = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/accounting/favorite-internal-account', params);

export const RequestRemoveFavoriteInternalAccount = (
	params: RequestRemove
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('delete', 'api/accounting/favorite-internal-account/:id', params);

export const RequestCreatePropertyName = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/common/properties/names', params);

export const RequestCreateStampBulk = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/accounting/invoices/bulk', params);
