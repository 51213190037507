import { ApiURL } from '../util-url';

export const getCloudbedsCustomFieldsService = (
	propertyId: string,
	partnerId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/common/partners/cloudbeds/custom-fields?propertyId=${propertyId}&partnerId=${partnerId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface getCloudbedsPostCustomFieldProps {
	partnerId: string;
	propertyId: string;
	propertyID: number;
	name: string;
	shortcode: string;
}
export const cloudbedsPostCustomField = (
	props: getCloudbedsPostCustomFieldProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(props)
		};

		fetch(
			ApiURL(`/api/common/partners/cloudbeds/custom-fields`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getCloudbedsRoomsService = (propertyId: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/common/partners/cloudbeds/rooms?propertyId=${propertyId}&partnerId=6364387f9cc192f99879bbed`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
