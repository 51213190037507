/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	deleteOnboardingService,
	getFieldMappingService,
	getOnboardings,
	initOnboardingService,
	patchOnboarding,
	updateFieldMappingService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Field } from '../common/common.types';

interface IOnboardingBaseProps {
	appId: string;
	propertyId: string;
}

interface IUpdateOnboardingProps {
	id: string;
	steps?: any[];
	appId?: string;
	propertyId?: string;
}

interface IFieldMappingRequest {
	propertyId: string;
	partnerIntegrationId: string;
	propertyIntegrationId?: string;
	propertyIntegrationIdTo?: string;
}

interface IUpdateFieldMapping {
	id: string;
	propertyId?: string;
	partnerIntegrationId?: string;
	apps?: string[];
	fields: Field[];
}

export const initOnboarding = createAsyncThunk(
	'onboarding/init',
	async (
		{ appId, propertyId }: IOnboardingBaseProps,
		{ rejectWithValue, dispatch }
	) => {
		const response = await getOnboardings(appId, propertyId);

		if (response.statusCode !== 200)
			return rejectWithValue(
				response.error || 'No se pudo obtener el onboarding'
			);

		if (!response.data || response.data.length === 0) {
			const responseCreate = await dispatch(
				createOnboarding({
					appId,
					propertyId
				})
			);
			return responseCreate.payload;
		}

		return response.data && response.data.length > 0 && response.data[0];
	}
);

export const getOnboarding = createAsyncThunk(
	'onboarding/get',
	async (
		{ appId, propertyId }: IOnboardingBaseProps,
		{ rejectWithValue }
	) => {
		const response = await getOnboardings(appId, propertyId);
		if (response.statusCode !== 200)
			return rejectWithValue(
				response.error || 'No se pudo obtener el onboarding'
			);

		return response.data && response.data.length > 0 && response.data[0];
	}
);

export const createOnboarding = createAsyncThunk(
	'onboarding/create',
	async (
		{ appId, propertyId }: IOnboardingBaseProps,
		{ rejectWithValue }
	) => {
		const response = await initOnboardingService(appId, propertyId);
		if (response.statusCode !== 201)
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);

		return response.data;
	}
);

export const updateOnboarding = createAsyncThunk(
	'onboarding/update',
	async (
		{ id, steps, appId, propertyId }: IUpdateOnboardingProps,
		{ rejectWithValue }
	) => {
		const response = await patchOnboarding(id, steps);

		if (response.statusCode === 500 && appId && propertyId) {
			const response = await initOnboardingService(appId, propertyId, steps);
			return response.data;
		}
		if (response.statusCode < 200 && response.statusCode > 299)
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);

		return response.data;
	}
);

export const resetOnboarding = createAsyncThunk(
	'onboarding/reset',
	async ({ id, steps }: IUpdateOnboardingProps, { rejectWithValue }) => {
		const response = await patchOnboarding(id, steps);
		if (response.statusCode < 200 && response.statusCode > 299)
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);

		return response.data;
	}
);

export const deleteOnboarding = createAsyncThunk(
	'onboarding/delete',
	async (id: string, { rejectWithValue }) => {
		const { statusCode, data, errors } = await deleteOnboardingService(id);
		if (statusCode !== 200)
			return rejectWithValue(
				errors || 'No se pudo iniciar el onboarding'
			);
		return data;
	}
);

export const getFieldMapping = createAsyncThunk(
	'onboarding/getFieldMapping',
	async (
		{
			propertyId,
			partnerIntegrationId,
			propertyIntegrationIdTo,
			propertyIntegrationId
		}: IFieldMappingRequest,
		{ rejectWithValue }
	) => {
		try {
			let filter = ``;
			if (propertyId)
				filter = `propertyId: new ObjectId('${propertyId}')`;
			if (partnerIntegrationId)
				filter = `${filter},partnerIntegrationId: new ObjectId('${partnerIntegrationId}')`;
			if (propertyIntegrationId)
				filter = `${filter},propertyIntegrationId: new ObjectId('${propertyIntegrationId}')`;
			if (propertyIntegrationIdTo)
				filter = `${filter},propertyIntegrationIdTo: new ObjectId('${propertyIntegrationIdTo}')`;

			const { data, errors } = await getFieldMappingService(
				`{${filter}}`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const updateFieldMapping = createAsyncThunk(
	'onboarding/updateFieldMapping',
	async ({ id, fields }: IUpdateFieldMapping, { rejectWithValue }) => {
		const response = await updateFieldMappingService(id, {
			fields
		});
		if (response.statusCode !== 200)
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);

		return response.data;
	}
);

interface IUpdateFieldMappingV2 {
	id: string;
	data: any;
	arrayFilters?: any[];
	updateMode?: 'pull' | 'push';
	items: any[];
}

export const updateFieldMappingV2 = createAsyncThunk(
	'onboarding/updateFieldMappingV2',
	async (
		{ id, data, arrayFilters, updateMode, items }: IUpdateFieldMappingV2,
		{ rejectWithValue }
	) => {
		const response = await updateFieldMappingService(
			id,
			data,
			`filter=${JSON.stringify({ arrayFilters, updateMode })}`
		);

		if (response.statusCode !== 200) {
			return rejectWithValue(
				response.error || 'No se pudo iniciar el onboarding'
			);
		}

		if (updateMode === 'push') {
			return {
				items: response.data,
				updateMode
			};
		}

		return { items, updateMode };
	}
);
