/* eslint-disable @typescript-eslint/no-empty-function */
import { Modal } from '@hospy/hospy-ui';
import { cognitoService } from '@hospy/util-auth';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AddPhoneChannelPropertyForm from './components/add-phone-channel-property-form/add-phone-channel-property-form';
import useAddPhoneChannelPropertyModal, {
	useAddPhoneChannelPropertyModalProps
} from './use-add-phone-channel-property-modal';

type AddPhoneChannelPropertyModalProp = useAddPhoneChannelPropertyModalProps

const AddPhoneChannelPropertyModal = (
	props: AddPhoneChannelPropertyModalProp
) => {
	const { handleOnClose } = useAddPhoneChannelPropertyModal(props);
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		loadIsAdmin();
	}, []);

	const loadIsAdmin = async () => {
		const isAdmin = await cognitoService.isRolUser({
			Name: 'custom:admin-app-acl',
			Value: 'admin'
		});
		setIsAdmin(Boolean(isAdmin));
	};

	return (
		<Modal
			maxWidth={500}
			open={props.open}
			onClose={isAdmin ? props.onClose : () => {}}
			closable={isAdmin ? true : false}
		>
			<Title>Actualizar información del negocio</Title>
			<Description>
				Por favor completa la siguiente información:
			</Description>
			<AddPhoneChannelPropertyForm onComplete={() => handleOnClose()} />
		</Modal>
	);
};
export default AddPhoneChannelPropertyModal;

const Title = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.large}px;
	display: block;
	text-align: center;
`;

const Description = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.middle}px;
	display: block;
	text-align: center;
	margin-bottom: 50px;
`;
