import { ApiURL } from '../util-url';

export const getPropertyById = (appId: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('Hospy-idToken');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        };
        fetch(ApiURL(`api/common/apps/${appId}/properties`), requestOptions)
            .then((response) => response.json())
            .then((data) => resolve(data))
            .catch((error) => reject(error));
    });

export const getUserWaitListById = (appId: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('Hospy-idToken');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        };
        fetch(ApiURL(`api/common/apps/${appId}/user-wait-list`), requestOptions)
            .then((response) => response.json())
            .then((data) => resolve(data))
            .catch((error) => reject(error));
    });

export const fetchExportCsvPropertiesById = (appId: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('Hospy-idToken');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        };
        fetch(ApiURL(`api/common/apps/exports/csv/properties?appId=${appId}`), requestOptions)
            .then((response) => response.blob())
            .then(blob => {
                const file = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = file;
                a.download = `app-${appId}-properties.csv`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                resolve({ status: 'success' })
            })
            .catch((error) => reject(error));
    });

export const fetchExportCsvUserWaitListById = (appId: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem('Hospy-idToken');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        };
        fetch(ApiURL(`api/common/apps/exports/csv/user-wait-list?appId=${appId}`), requestOptions)
            .then((response) => response.blob())
            .then(blob => {
                const file = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = file;
                a.download = `app-${appId}-users.csv`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                resolve({ status: 'success' })
            })
            .catch((error) => reject(error));
    });
