/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { SiigoConnectSliceName, siigoInitialState } from './constants';
import { SiigoConnectInitialState } from './interface';
import {
	ThunkCreateFavoriteInternalAccount,
	ThunkCreateInternalAccountStampAsync,
	ThunkCreditNoteInternalAccountStampAsync,
	ThunkDeleteFavoriteInternalAccount,
	ThunkFindAllAccounts,
	ThunkUpdateInternalAccountStampAsync,
	accountingApplyCreditNote,
	accountingDeleteInvoice,
	accountingDestinyAccomodations,
	accountingDestinyGetTaxes,
	accountingGetCostCenters,
	accountingGetDestinyProducts,
	accountingGetDocumentTypes,
	accountingGetInvoice,
	accountingGetOriginProducts,
	accountingGetPaymentMethods,
	accountingGetPaymentTypes,
	accountingGetReservations,
	accountingGetStamp,
	accountingGetTaxes,
	accountingInvoiceStamp,
	accountingInvoiceStampErrors,
	accountingOriginAccomodations,
	accountingOriginGetTaxes,
	accountingUpdateInvoice,
	accountingUpdateInvoiceSync,
	createFieldsMapping,
	crmCustomerDocumentTypes,
	crmUsers,
	retryAdvance,
	retryJournal,
	siigoUpdateStampInvoice
} from './thunks';

export const siigoConnectSlice = createSlice({
	name: SiigoConnectSliceName,
	initialState: siigoInitialState,
	reducers: {
		reOrderReservation: (state, action) => {
			state.reservations.data = {
				...state.reservations.data,
				data: action.payload
			};
		},
		reOrderInternalAccounts: (state, action) => {
			state.internalAccounts.data = {
				meta: state.internalAccounts.data?.meta,
				data: action.payload
			};
		},
		setSiigoInvoice: (state, action) => {
			state.invoice.data = action.payload;
		},
		cleanInvoice: (state) => {
			state.invoice = siigoInitialState.invoice;
		},
		cleanUpdateInvoice: (state) => {
			state.updatedInvoice = siigoInitialState.updatedInvoice;
		},
		cleanDeletedInvoice: (state) => {
			state.deletedInvoice = siigoInitialState.deletedInvoice;
		},
		updateInvoiceValidation: (state, { payload }) => {
			state.invoiceValidation = payload;
		},
		cleanStampInvoice: (state) => {
			state.deletedInvoice = siigoInitialState.deletedInvoice;
			state.stamp = siigoInitialState.stamp;
		},
		cleanCreditNoteStamp: (state) => {
			state.stampCreditNote = siigoInitialState.stampCreditNote;
		},
		resetUpdateStampInvoice: (state) => {
			state.updateStampInvoice = {
				loading: 'idle',
				error: undefined
			};
		},
		siigoClearSettings: (state) => {
			state.paymentMethodsPMS = siigoInitialState.paymentMethodsPMS;
			state.paymentMethodsFinance =
				siigoInitialState.paymentMethodsFinance;
			state.taxes = siigoInitialState.taxes;
			state.destinyTaxes = siigoInitialState.destinyTaxes;
			state.originListingTypes = siigoInitialState.originListingTypes;
			state.destinyListingTypes = siigoInitialState.destinyListingTypes;
			state.originProducts = siigoInitialState.originProducts;
			state.destinyProducts = siigoInitialState.destinyProducts;
		},
		cleanDestinyGetTaxes: (state) => {
			state.destinyTaxes = siigoInitialState.destinyTaxes;
		},
		cleanDestinyGetProducts: (state) => {
			state.destinyProducts = siigoInitialState.destinyProducts;
		},
		cleanRetryAdvance: (state) => {
			state.retryAdvancePayment = siigoInitialState.retryAdvancePayment;
		},
		cleanRetryJournal: (state) => {
			state.retryJournal = siigoInitialState.retryJournal;
		},
		cleanAddFavoriteInternalAccount: (state) => {
			state.addFavoriteInternalAccount =
				siigoInitialState.addFavoriteInternalAccount;
		},
		cleanRemoveFavoriteInternalAccount: (state) => {
			state.removeFavoriteInternalAccount =
				siigoInitialState.removeFavoriteInternalAccount;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				accountingGetPaymentMethods.pending,
				(state: SiigoConnectInitialState) => {
					state.paymentMethodsPMS.loading = 'pending';
				}
			)
			.addCase(
				accountingGetPaymentMethods.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.paymentMethodsPMS = {
						loading: 'failed',
						error: payload,
						data: undefined
					};
				}
			)
			.addCase(
				accountingGetPaymentMethods.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.paymentMethodsPMS = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(
				accountingGetPaymentTypes.pending,
				(state: SiigoConnectInitialState) => {
					state.paymentMethodsFinance.loading = 'pending';
				}
			)
			.addCase(
				accountingGetPaymentTypes.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.paymentMethodsFinance = {
						loading: 'failed',
						error: payload,
						data: undefined
					};
				}
			)
			.addCase(
				accountingGetPaymentTypes.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.paymentMethodsFinance = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(
				createFieldsMapping.pending,
				(state: SiigoConnectInitialState) => {
					state.createFieldsMapping.loading = 'pending';
				}
			)
			.addCase(
				createFieldsMapping.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.createFieldsMapping.loading = 'idle';
					state.createFieldsMapping.error = payload;
				}
			)
			.addCase(
				createFieldsMapping.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.createFieldsMapping.data = payload;
					state.createFieldsMapping.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingGetDocumentTypes.pending,
				(state: SiigoConnectInitialState) => {
					state.documentTypes.loading = 'pending';
				}
			)
			.addCase(
				accountingGetDocumentTypes.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.documentTypes.loading = 'failed';
					state.documentTypes.error = payload;
				}
			)
			.addCase(
				accountingGetDocumentTypes.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					if (payload.type) {
						state.documentTypes = {
							loading: 'succeeded',
							error: undefined,
							data: {
								...state.documentTypes.data,
								[payload.type]: payload[payload.type].map(
									(e: any) => ({
										...e,
										value: e.code,
										label:
											e.description &&
											e.description !== 'undefined'
												? e.description
												: e.name
									})
								)
							}
						};
						return;
					}
					Object.keys(payload).forEach((documentTypeKey) => {
						payload[documentTypeKey] = payload[documentTypeKey].map(
							(documentType: any) => ({
								...documentType,
								value: documentType.code,
								label: !!documentType.description && documentType.description !== 'undefined'
									? documentType.description
									: documentType.name
							})
						);
					});
					state.documentTypes.data = payload;
					state.documentTypes.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingGetCostCenters.pending,
				(state: SiigoConnectInitialState) => {
					state.costCenter.loading = 'pending';
				}
			)
			.addCase(
				accountingGetCostCenters.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.costCenter.loading = 'failed';
					state.costCenter.error = payload;
				}
			)
			.addCase(
				accountingGetCostCenters.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((documentType: any) => ({
						...documentType,
						value: documentType.code,
						label: documentType.name
					}));
					state.costCenter.data = payload;
					state.costCenter.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingOriginGetTaxes.pending,
				(state: SiigoConnectInitialState) => {
					state.taxes.loading = 'pending';
				}
			)
			.addCase(
				accountingOriginGetTaxes.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.taxes.loading = 'failed';
					state.taxes.error = payload;
				}
			)
			.addCase(
				accountingOriginGetTaxes.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						label: taxes.name
					}));
					state.taxes.data = payload;
					state.taxes.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingDestinyGetTaxes.pending,
				(state: SiigoConnectInitialState) => {
					state.destinyTaxes.loading = 'pending';
				}
			)
			.addCase(
				accountingDestinyGetTaxes.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.destinyTaxes.loading = 'failed';
					state.destinyTaxes.error = payload;
				}
			)
			.addCase(
				accountingDestinyGetTaxes.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						name: taxes.name
					}));
					state.destinyTaxes.data = payload;
					state.destinyTaxes.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingOriginAccomodations.pending,
				(state: SiigoConnectInitialState) => {
					state.originListingTypes.loading = 'pending';
				}
			)
			.addCase(
				accountingOriginAccomodations.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.originListingTypes.loading = 'failed';
					state.originListingTypes.error = payload;
				}
			)
			.addCase(
				accountingOriginAccomodations.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						name: taxes.name
					}));
					state.originListingTypes.data = payload;
					state.originListingTypes.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingDestinyAccomodations.pending,
				(state: SiigoConnectInitialState) => {
					state.destinyListingTypes.loading = 'pending';
				}
			)
			.addCase(
				accountingDestinyAccomodations.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.destinyListingTypes.loading = 'failed';
					state.destinyListingTypes.error = payload;
				}
			)
			.addCase(
				accountingDestinyAccomodations.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						name: taxes.name
					}));
					state.destinyListingTypes.data = payload;
					state.destinyListingTypes.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingGetOriginProducts.pending,
				(state: SiigoConnectInitialState) => {
					state.originProducts.loading = 'pending';
				}
			)
			.addCase(
				accountingGetOriginProducts.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.originProducts.loading = 'failed';
					state.originProducts.error = payload;
				}
			)
			.addCase(
				accountingGetOriginProducts.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						name: taxes.name
					}));
					state.originProducts.data = payload;
					state.originProducts.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingGetDestinyProducts.pending,
				(state: SiigoConnectInitialState) => {
					state.destinyProducts.loading = 'pending';
				}
			)
			.addCase(
				accountingGetDestinyProducts.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.destinyProducts.loading = 'failed';
					state.destinyProducts.error = payload;
				}
			)
			.addCase(
				accountingGetDestinyProducts.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((product: any) => ({
						...product,
						value: product.thirdPartyId,
						id: product.thirdPartyId,
						name: product.name
					}));
					state.destinyProducts.data = payload;
					state.destinyProducts.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingGetReservations.pending,
				(state: SiigoConnectInitialState) => {
					state.reservations.loading = 'pending';
				}
			)
			.addCase(
				accountingGetReservations.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.reservations.loading = 'failed';
					state.reservations.error = payload;
				}
			)
			.addCase(
				accountingGetReservations.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.reservations.data = payload;
					state.reservations.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingGetInvoice.pending,
				(state: SiigoConnectInitialState) => {
					state.updateStampInvoice =
						siigoInitialState.updateStampInvoice;
					state.stamp = siigoInitialState.stamp;
					state.invoice.loading = 'pending';
					state.invoice.loading = 'pending';
					state.updatedInvoice.loading = 'idle';
				}
			)
			.addCase(
				accountingGetInvoice.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.invoice.loading = 'failed';
					state.invoice.error = payload;
					state.invoiceValidation =
						siigoInitialState.invoiceValidation;
				}
			)
			.addCase(
				accountingGetInvoice.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.invoice.data = payload;
					state.invoiceSnapshot = payload;
					state.invoice.loading = 'succeeded';
					state.invoiceValidation =
						siigoInitialState.invoiceValidation;
					state.stamp = siigoInitialState.stamp;
					if (state.reservations.data?.data && state.invoice.data) {
						const newReservations = [
							...state.reservations.data.data
						];
						const reservationIndex = newReservations.findIndex(
							(reservation) =>
								reservation.reservationID ===
								state.invoice.data?.thirdPartyId
						);
						if (reservationIndex > -1) {
							newReservations[reservationIndex].stampStatus =
								currentStampStatus(
									state.invoice.data.stamp?.status || '',
									state.invoice.data.state,
									state.invoice.data.stamp?.documentType || ''
								);
						}
						state.reservations.data.data = newReservations;
					}
				}
			);

		builder
			.addCase(
				accountingUpdateInvoice.pending,
				(state: SiigoConnectInitialState) => {
					state.updatedInvoice.loading = 'pending';
				}
			)
			.addCase(
				accountingUpdateInvoice.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.updatedInvoice.loading = 'failed';
					state.updatedInvoice.error = payload;
				}
			)
			.addCase(
				accountingUpdateInvoice.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.updatedInvoice.data = payload;
					state.invoice.data = payload;
					state.updatedInvoice.loading = 'succeeded';
					state.invoiceValidation =
						siigoInitialState.invoiceValidation;
					if (
						state.reservations.data?.data &&
						state.reservations.data?.data.length > 0
					) {
						const newReservations = [
							...state.reservations.data.data
						];
						const foundReservationIndex =
							state.reservations.data?.data.findIndex(
								(reservation) =>
									reservation.reservationID ===
									payload?.thirdPartyId
							);
						if (foundReservationIndex > -1) {
							newReservations[
								foundReservationIndex
							].guestName = `${payload?.customer?.name} ${payload.customer.lastName}`;
							newReservations[foundReservationIndex].checked =
								payload?.checked;
							state.reservations.data.data = newReservations;
						}
					}
				}
			);

		builder
			.addCase(
				accountingUpdateInvoiceSync.pending,
				(state: SiigoConnectInitialState) => {
					state.updatedInvoice.loading = 'pending';
				}
			)
			.addCase(
				accountingUpdateInvoiceSync.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.updatedInvoice.loading = 'failed';
					state.updatedInvoice.error = payload;
				}
			)
			.addCase(
				accountingUpdateInvoiceSync.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.updatedInvoice.data = payload;
					state.invoice.data = payload;
					state.updatedInvoice.loading = 'succeeded';
					state.invoiceValidation =
						siigoInitialState.invoiceValidation;
					if (
						state.reservations.data?.data &&
						state.reservations.data?.data.length > 0
					) {
						const newReservations = [
							...state.reservations.data.data
						];
						const foundReservationIndex =
							state.reservations.data?.data.findIndex(
								(reservation) =>
									reservation.reservationID ===
									payload?.thirdPartyId
							);
						if (foundReservationIndex > -1) {
							newReservations[
								foundReservationIndex
							].guestName = `${payload?.customer?.name} ${payload.customer.lastName}`;
							newReservations[foundReservationIndex].checked =
								payload?.checked;
							state.reservations.data.data = newReservations;
						}
					}
				}
			);

		builder
			.addCase(
				accountingDeleteInvoice.pending,
				(state: SiigoConnectInitialState) => {
					state.deletedInvoice.loading = 'pending';
				}
			)
			.addCase(
				accountingDeleteInvoice.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.deletedInvoice.loading = 'failed';
					state.deletedInvoice.error = payload;
				}
			)
			.addCase(
				accountingDeleteInvoice.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.deletedInvoice.data = payload;
					state.invoice.data = payload;
					state.invoiceSnapshot = payload;
					state.deletedInvoice.loading = 'succeeded';
					state.invoiceValidation =
						siigoInitialState.invoiceValidation;

					if (
						state.reservations.data?.data &&
						state.reservations.data?.data.length > 0
					) {
						const newReservations = [
							...state.reservations.data.data
						];
						const foundReservationIndex =
							state.reservations.data?.data.findIndex(
								(reservation) =>
									reservation.reservationID ===
									payload?.thirdPartyId
							);
						if (foundReservationIndex > -1) {
							newReservations[foundReservationIndex].stampStatus =
								'';
						}
					}
				}
			);

		builder
			.addCase(
				accountingGetTaxes.pending,
				(state: SiigoConnectInitialState) => {
					state.taxes.loading = 'pending';
				}
			)
			.addCase(
				accountingGetTaxes.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.taxes.loading = 'failed';
					state.taxes.error = payload;
				}
			)
			.addCase(
				accountingGetTaxes.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					payload = payload.map((taxes: any) => ({
						...taxes,
						value: taxes.thirdPartyId,
						id: taxes.thirdPartyId,
						label: taxes.name
					}));
					state.taxes.data = payload;
					state.taxes.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				crmCustomerDocumentTypes.pending,
				(state: SiigoConnectInitialState) => {
					state.customersDocumentTypes.loading = 'pending';
				}
			)
			.addCase(
				crmCustomerDocumentTypes.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.customersDocumentTypes.loading = 'failed';
					state.customersDocumentTypes.error = payload;
				}
			)
			.addCase(
				crmCustomerDocumentTypes.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.customersDocumentTypes.data = payload;
					state.customersDocumentTypes.loading = 'succeeded';
				}
			);

		builder
			.addCase(crmUsers.pending, (state: SiigoConnectInitialState) => {
				state.users.loading = 'pending';
			})
			.addCase(
				crmUsers.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.users.loading = 'failed';
					state.users.error = payload;
				}
			)
			.addCase(
				crmUsers.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.users.data = payload;
					state.users.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingInvoiceStamp.pending,
				(state: SiigoConnectInitialState) => {
					state.stamp.loading = 'pending';
				}
			)
			.addCase(
				accountingInvoiceStamp.rejected,
				(state: SiigoConnectInitialState, { payload }) => {
					state.stamp.loading = 'failed';
					state.stamp.error = payload;
				}
			)
			.addCase(
				accountingInvoiceStamp.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.stamp.data = payload;
					if (state.invoice.data) state.invoice.data = payload;
					state.stamp.loading = 'succeeded';
					if (state.reservations.data?.data && state.invoice.data) {
						const newReservations = [
							...state.reservations.data.data
						];
						const reservationIndex = newReservations.findIndex(
							(reservation) =>
								reservation.reservationID ===
								state.invoice.data?.thirdPartyId
						);
						if (reservationIndex > -1)
							newReservations[reservationIndex].stampStatus =
								currentStampStatus(
									state.invoice.data.stamp?.status || '',
									state.invoice.data.state,
									state.invoice.data.stamp?.documentType || ''
								);
						state.reservations.data.data = newReservations;
					}
				}
			);

		builder
			.addCase(
				accountingGetStamp.pending,
				(state: SiigoConnectInitialState) => {
					state.getStamp.loading = 'pending';
				}
			)
			.addCase(
				accountingGetStamp.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.getStamp.loading = 'failed';
					state.getStamp.error = payload;
				}
			)
			.addCase(
				accountingGetStamp.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.stamp.data = payload;
					if (state.invoice.data) {
						state.invoice.data = payload;
						if (
							state.reservations.data?.data &&
							state.invoice.data
						) {
							const newReservations = [
								...state.reservations.data.data
							];
							const reservationIndex = newReservations.findIndex(
								(reservation) =>
									reservation.reservationID ===
									state.invoice.data?.thirdPartyId
							);
							if (reservationIndex > -1)
								newReservations[reservationIndex].stampStatus =
									currentStampStatus(
										state.invoice.data.stamp?.status || '',
										state.invoice.data.state,
										state.invoice.data.stamp
											?.documentType || ''
									);
							state.reservations.data.data = newReservations;
						}
					}
					state.getStamp.loading = 'succeeded';
				}
			);

		builder
			.addCase(
				accountingApplyCreditNote.pending,
				(state: SiigoConnectInitialState) => {
					state.stampCreditNote.loading = 'pending';
				}
			)
			.addCase(
				accountingApplyCreditNote.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.stampCreditNote.loading = 'failed';
					state.stampCreditNote.error = payload;
				}
			)
			.addCase(
				accountingApplyCreditNote.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.stampCreditNote.data = payload;
					if (state.invoice.data) {
						state.invoice.data = payload;
						if (
							state.reservations.data?.data &&
							state.invoice.data
						) {
							const newReservations = [
								...state.reservations.data.data
							];
							const reservationIndex = newReservations.findIndex(
								(reservation) =>
									reservation.reservationID ===
									state.invoice.data?.thirdPartyId
							);
							if (reservationIndex > -1) {
								newReservations[reservationIndex].stampStatus =
									currentStampStatus(
										state.invoice.data.stamp?.status || '',
										state.invoice.data.state,
										state.invoice.data.stamp
											?.documentType || ''
									);
							}
							state.reservations.data.data = newReservations;
						}
					}
					state.stampCreditNote.loading = 'succeeded';
				}
			);

		builder
			.addCase(accountingInvoiceStampErrors.pending, (state) => {
				state.stampErrors = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				accountingInvoiceStampErrors.rejected,
				(state, payload) => {
					state.stampErrors = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				accountingInvoiceStampErrors.fulfilled,
				(state, { payload }) => {
					if (state.invoice.data && payload.invoice) {
						state.invoice.data = payload.invoice;
						if (
							state.reservations.data?.data &&
							state.invoice.data
						) {
							const newReservations = [
								...state.reservations.data.data
							];
							const reservationIndex = newReservations.findIndex(
								(reservation) =>
									reservation.reservationID ===
									state.invoice.data?.thirdPartyId
							);
							if (reservationIndex > -1)
								newReservations[reservationIndex].stampStatus =
									currentStampStatus(
										state.invoice.data.stamp?.status || '',
										state.invoice.data.state,
										state.invoice.data.stamp
											?.documentType || ''
									);
							state.reservations.data.data = newReservations;
						}
						state.stampErrors = {
							loading: 'succeeded',
							error: undefined,
							data: { errors: [] }
						};
						return;
					}

					state.stampErrors = {
						loading: 'succeeded',
						error: undefined,
						data: payload
					};
				}
			);

		builder
			.addCase(siigoUpdateStampInvoice.pending, (state) => {
				state.updateStampInvoice = {
					loading: 'pending',
					error: undefined
				};
			})
			.addCase(siigoUpdateStampInvoice.rejected, (state, payload) => {
				state.updateStampInvoice = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(
				siigoUpdateStampInvoice.fulfilled,
				(state, { payload }) => {
					state.updateStampInvoice = {
						loading: 'succeeded',
						error: undefined
					};

					state.invoice.data = payload.invoice;

					if (state.reservations.data?.data && state.invoice.data) {
						const newReservations = [
							...state.reservations.data.data
						];
						const reservationIndex = newReservations.findIndex(
							(reservation) =>
								reservation.reservationID ===
								state.invoice.data?.thirdPartyId
						);
						if (reservationIndex > -1) {
							newReservations[reservationIndex].stampStatus =
								currentStampStatus(
									state.invoice.data.stamp?.status || '',
									state.invoice.data.state,
									state.invoice.data.stamp?.documentType || ''
								);
						}
						state.reservations.data.data = newReservations;
					}
				}
			);

		builder
			.addCase(retryAdvance.pending, (state) => {
				state.retryAdvancePayment = {
					loading: 'pending',
					error: undefined,
					data: null
				};
			})
			.addCase(retryAdvance.rejected, (state, { payload }) => {
				state.retryAdvancePayment = {
					loading: 'failed',
					error: String(payload),
					data: null
				};
			})
			.addCase(retryAdvance.fulfilled, (state, { payload }) => {
				state.retryAdvancePayment = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(retryJournal.pending, (state) => {
				state.retryJournal = {
					loading: 'pending',
					error: undefined,
					data: null
				};
			})
			.addCase(retryJournal.rejected, (state, { payload }) => {
				state.retryJournal = {
					loading: 'failed',
					error: String(payload),
					data: null
				};
			})
			.addCase(retryJournal.fulfilled, (state, { payload }) => {
				state.retryJournal = {
					loading: 'succeeded',
					error: undefined,
					data: payload
				};
			});

		builder
			.addCase(ThunkFindAllAccounts.pending, (state) => {
				state.internalAccounts = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(ThunkFindAllAccounts.rejected, (state, { payload }) => {
				state.internalAccounts = {
					loading: 'failed',
					error: String(payload),
					data: undefined
				};
			})
			.addCase(ThunkFindAllAccounts.fulfilled, (state, { payload }) => {
				state.internalAccounts = {
					loading: 'succeeded',
					error: undefined,
					data: payload as any
				};
			});

		builder
			.addCase(ThunkCreateInternalAccountStampAsync.pending, (state) => {
				state.stamp = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				ThunkCreateInternalAccountStampAsync.rejected,
				(state, { payload }) => {
					state.stamp = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				ThunkCreateInternalAccountStampAsync.fulfilled,
				(state, { payload }) => {
					state.stamp = {
						loading: 'succeeded',
						error: undefined,
						data: payload as any
					};
					if (
						state.internalAccounts.data?.data &&
						state.invoice.data
					) {
						const newInternalAccounts = [
							...state.internalAccounts.data.data
						];
						const internalAccountIndex =
							newInternalAccounts.findIndex(
								(reservation) =>
									reservation.id ===
									state.invoice.data?.thirdPartyId
							);
						if (internalAccountIndex > -1) {
							newInternalAccounts[
								internalAccountIndex
							].stampStatus = currentStampStatus(
								state.invoice.data.stamp?.status || '',
								state.invoice.data.state,
								state.invoice.data.stamp?.documentType || ''
							);

							newInternalAccounts[internalAccountIndex].invoices =
								(newInternalAccounts[internalAccountIndex]
									.invoices || 0) + 1;
						}
						state.internalAccounts.data.data = newInternalAccounts;
					}
				}
			);

		builder
			.addCase(ThunkUpdateInternalAccountStampAsync.pending, (state) => {
				state.updateStampInvoice = {
					loading: 'pending',
					error: undefined,
					data: undefined
				};
			})
			.addCase(
				ThunkUpdateInternalAccountStampAsync.rejected,
				(state, { payload }) => {
					state.updateStampInvoice = {
						loading: 'failed',
						error: String(payload),
						data: undefined
					};
				}
			)
			.addCase(
				ThunkUpdateInternalAccountStampAsync.fulfilled,
				(state, { payload }) => {
					state.updateStampInvoice = {
						loading: 'succeeded',
						error: undefined,
						data: payload as any
					};
				}
			);

		builder
			.addCase(
				ThunkCreditNoteInternalAccountStampAsync.pending,
				(state: SiigoConnectInitialState) => {
					state.stampCreditNote.loading = 'pending';
				}
			)
			.addCase(
				ThunkCreditNoteInternalAccountStampAsync.rejected,
				(state: SiigoConnectInitialState, payload: any) => {
					state.stampCreditNote.loading = 'failed';
					state.stampCreditNote.error = payload;
				}
			)
			.addCase(
				ThunkCreditNoteInternalAccountStampAsync.fulfilled,
				(state: SiigoConnectInitialState, { payload }: any) => {
					state.stampCreditNote.data = payload;
					if (state.invoice.data) {
						state.invoice.data = payload;
						if (
							state.reservations.data?.data &&
							state.invoice.data
						) {
							const newReservations = [
								...state.reservations.data.data
							];
							const reservationIndex = newReservations.findIndex(
								(reservation) =>
									reservation.reservationID ===
									state.invoice.data?.thirdPartyId
							);
							if (reservationIndex > -1) {
								newReservations[reservationIndex].stampStatus =
									currentStampStatus(
										state.invoice.data.stamp?.status || '',
										state.invoice.data.state,
										state.invoice.data.stamp
											?.documentType || ''
									);
							}
							state.reservations.data.data = newReservations;
						}
					}
					state.stampCreditNote.loading = 'succeeded';
				}
			);

		builder
			.addCase(ThunkCreateFavoriteInternalAccount.pending, (state) => {
				state.addFavoriteInternalAccount.loading = 'pending';
			})
			.addCase(
				ThunkCreateFavoriteInternalAccount.rejected,
				(state, { payload }) => {
					state.addFavoriteInternalAccount.loading = 'failed';
				}
			)
			.addCase(
				ThunkCreateFavoriteInternalAccount.fulfilled,
				(state, { payload }: any) => {
					console.log('payload', payload);
					state.addFavoriteInternalAccount.loading = 'succeeded';
					if (state.internalAccounts.data?.data)
						state.internalAccounts.data = {
							...state.internalAccounts.data,
							data: state.internalAccounts.data?.data?.map(
								(account: any) => {
									if (
										account.id ===
										payload?.data?.thirdPartyId
									)
										return { ...account, isFavorite: true };
									return account;
								}
							)
						};
				}
			);

		builder
			.addCase(ThunkDeleteFavoriteInternalAccount.pending, (state) => {
				state.removeFavoriteInternalAccount.loading = 'pending';
			})
			.addCase(
				ThunkDeleteFavoriteInternalAccount.rejected,
				(state, { payload }) => {
					state.removeFavoriteInternalAccount.loading = 'failed';
				}
			)
			.addCase(
				ThunkDeleteFavoriteInternalAccount.fulfilled,
				(state, { payload }: any) => {
					state.removeFavoriteInternalAccount.loading = 'succeeded';
					if (state.internalAccounts.data?.data)
						state.internalAccounts.data = {
							...state.internalAccounts.data,
							data: state.internalAccounts.data?.data?.map(
								(account: any) => {
									if (
										account.id ===
										payload.data?.thirdPartyId
									)
										return {
											...account,
											isFavorite: false
										};
									return account;
								}
							)
						};
				}
			);
	}
});

const currentStampStatus = (
	rawStampStatus: string,
	state: string,
	documentType: string
): string => {
	if (!state) {
		if (documentType === 'invoice') {
			if (rawStampStatus === 'draft') return 'draft';

			if (rawStampStatus === 'accepted') return 'accepted';

			if (rawStampStatus === 'rejected') return 'rejected';
		}
	}
	if (state === 'processing') return 'draft';
	if (state === 'failure') {
		return 'rejected';
	}
	if (state === 'success' && documentType === 'invoice') {
		if (rawStampStatus === 'draft') return 'draft';

		if (rawStampStatus === 'accepted') return 'accepted';

		if (rawStampStatus === 'rejected') return 'rejected';
	}

	return '';
};

export const {
	reOrderReservation,
	cleanUpdateInvoice,
	cleanDeletedInvoice,
	cleanInvoice,
	updateInvoiceValidation,
	cleanStampInvoice,
	cleanCreditNoteStamp,
	resetUpdateStampInvoice,
	setSiigoInvoice,
	siigoClearSettings,
	cleanDestinyGetTaxes,
	cleanDestinyGetProducts,
	cleanRetryAdvance,
	cleanRetryJournal,
	reOrderInternalAccounts,
	cleanRemoveFavoriteInternalAccount,
	cleanAddFavoriteInternalAccount
} = siigoConnectSlice.actions;
