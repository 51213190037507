export const scrollToElement = (title: string) => {
	try {
		const elementToScroll = document.getElementById(title);

		if (!elementToScroll)
			return console.error(`Element to scroll was not found ${title}`);

		elementToScroll.scrollIntoView({
			behavior: 'smooth',
			block: 'center'
		});
	} catch (error) {
		console.error(error);
	}
};
