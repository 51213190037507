import IconAnt from '@ant-design/icons';
import { maxDevice } from '@hospy/feature/devices';
import { Button, Grid, Image, Typography } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { NativeButtonProps } from 'antd/lib/button/button';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

export interface CardStyleProps {
	container?: React.CSSProperties;
	wrapper?: React.CSSProperties;
	button?: React.CSSProperties;
	name?: React.CSSProperties;
	icon?: React.CSSProperties;
}
export interface ICardApp {
	key?: string;
	name?: string;
	status: boolean;
	free?: boolean;
	icon: React.ElementType | string;
	iconCustom?: React.ElementType | any;
	styleProps?: CardStyleProps;
	isDarkMode?: boolean;
	noInteraction?: boolean;
	onClick?: () => void;
	type: 'front' | 'back';
}

const { useBreakpoint } = Grid;

export const CardApp = ({
	icon: Icon,
	name,
	status,
	free,
	styleProps,
	isDarkMode,
	noInteraction,
	onClick,
	type,
	iconCustom
}: ICardApp) => {
	const [extraPropsContainer, setExtraPropsContainer] = useState({});
	const screens = useBreakpoint();
	useEffect(() => {
		setExtraPropsContainer({});
		if (screens.xs) {
			setExtraPropsContainer({
				onClick
			});
		}
	}, [screens]);

	return (
		<CardAppContainer
			style={{ ...styleProps?.container }}
			{...extraPropsContainer}
		>
			{!iconCustom && (
				<motion.div
					whileHover={{ scale: noInteraction ? 1 : 1.1 }}
					whileTap={{ scale: noInteraction ? 1 : 0.95 }}
				>
					<CardAppWp
						isDarkMode={isDarkMode}
						style={{ ...styleProps?.wrapper }}
					>
						{free && (
							<FreeLabel
								isDarkMode={isDarkMode}
								disabledStyle={!status}
							/>
						)}

						<ButtonApp
							disabledStyle={!status}
							free={free}
							noInteraction={noInteraction}
							style={{
								color: free ? '#2ed1b0' : '#016af3',
								...styleProps?.button
							}}
							{...(typeof Icon === 'object'
								? {
										icon: (
											<IconAnt
												component={Icon}
												style={{ ...styleProps?.icon }}
											/>
										)
								  }
								: null)}
							onClick={onClick}
							colorApp={type}
						>
							{typeof Icon === 'string' && (
								<Image
									src={Icon}
									preview={false}
									style={{
										...styleProps?.icon,
										minWidth: 20
									}}
								/>
							)}
						</ButtonApp>
					</CardAppWp>
				</motion.div>
			)}
			{iconCustom}
			{name && (
				<LabelAppName style={{ ...styleProps?.name }}>
					{name}
				</LabelAppName>
			)}
		</CardAppContainer>
	);
};

const LabelAppName = styled(Typography.Text)`
	margin-top: 1rem;
	text-align: center;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			margin-top: 0;
			margin-left: 0.8rem;
			font-size: 20px;
		`}
`;

const CardAppContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0.8rem;
	${({ theme }) =>
		theme.screens['xs'] &&
		css`
			border-bottom: 1px solid
				${({ theme }) =>
					theme.colors.gray.lighten &&
					theme.colors.gray.lighten['80%']};
			flex-direction: row;
			margin: 0;
			padding: 1.2rem 0;
		`}
`;

interface DivProps {
	isDarkMode?: boolean;
}

const CardAppWp = styled.div<DivProps>`
	position: relative;
	width: 4.9rem;
	height: 4.9rem;
	overflow: hidden;
	border-radius: 10px;
	${(props) =>
		!props.isDarkMode &&
		css`
			box-shadow: 0px 5px 7px 1px #92929254;
		`}

	${(props) =>
		props.isDarkMode &&
		css`
			box-shadow: 0px;
		`}

    @media ${maxDevice.tablet} {
		width: 4rem;
		height: 4rem;
	}
`;

interface IFreeLabelProps {
	disabledStyle?: boolean;
	isDarkMode?: boolean;
	screens?: Partial<Record<Breakpoint, boolean>>;
}
const FreeLabelContainer = styled.div<IFreeLabelProps>`
	position: absolute;
	background: #fefb64;
	text-align: center;
	transform: rotate(-45deg);
	z-index: 2;
	top: 0rem;
	left: 0rem;
	font-size: 0.9rem;
	padding: 0 2rem;
	left: -1.9rem;
	top: 0.5rem;
	${(props) =>
		props.isDarkMode &&
		props.disabledStyle &&
		css`
			background: #fefb6422;
			border: 1px solid #fefb64;
			& > span {
				color: #fefb64;
			}
		`}

	${(props) =>
		props.isDarkMode &&
		!props.disabledStyle &&
		css`
			& > span {
				color: #000;
			}
		`}

    ${(props) =>
		props.screens &&
		props.screens.xs &&
		css`
			margin-left: 0.8rem;
		`}
`;

const FreeLabel = ({
	isDarkMode,
	disabledStyle
}: {
	isDarkMode?: boolean;
	disabledStyle?: boolean;
}) => {
	return (
		<FreeLabelContainer
			isDarkMode={isDarkMode}
			disabledStyle={disabledStyle}
		>
			<Typography.Text>Gratis</Typography.Text>
		</FreeLabelContainer>
	);
};

interface ButtonProps extends NativeButtonProps {
	disabledStyle?: boolean;
	free?: boolean;
	noInteraction?: boolean;
	colorApp?: 'front' | 'back';
}

const ButtonApp = styled(Button)<ButtonProps>`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100% !important;
	height: 100% !important;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.disabledStyle &&
		css`
			border-width: 2px !important;
		`}

	${(props) =>
		props.disabledStyle &&
		props.colorApp === 'front' &&
		css`
			& svg {
				color: ${props.theme.colors.apps.front};
			}
		`}

    ${(props) =>
		props.disabledStyle &&
		props.colorApp === 'back' &&
		css`
			& svg {
				color: ${props.theme.colors.apps.back};
			}
		`}
    
    ${(props) =>
		!props.disabledStyle &&
		props.colorApp === 'front' &&
		css`
			/* background: linear-gradient(#29deff, #002ec7) !important; */
			background: linear-gradient(#2afcf2, #1ca0bf) !important;
		`}

    ${(props) =>
		!props.disabledStyle &&
		props.colorApp === 'back' &&
		css`
			background: linear-gradient(#2afcf2, #1ca0bf) !important;
		`}

    ${(props) =>
		!props.disabledStyle &&
		css`
			border-width: 0px !important;
			& svg {
				color: #fff !important;
			}
		`}

    ${(props) =>
		props.noInteraction &&
		css`
			cursor: initial;
			:hover,
			:active,
			:focus {
				border-color: #d9d9d9 !important;
			}
		`}
`;
