/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	fetchCreateProductsImports,
	fetchDeleteProductsImports,
	fetchDeleteSync,
	fetchGetProductsImports,
	fetchMappingDynamic,
	getPropertyIntegrationsService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PayloadBaseInvoice } from './interface';
import { Find } from '../reservations/interface';
import { GetPropertyIntegrations } from '../../user/user.interfaces';

export const getProductsImports = createAsyncThunk(
	'partner-warehouse/getProductsImports',
	async (payload: PayloadBaseInvoice<any>, { rejectWithValue }) => {
		try {
			const response: any = await fetchGetProductsImports(
				payload as Find
			);
			return {
				[payload.partnerIntegrationIdTo ||
				payload.partnerIntegrationId]: response.data
			} as any;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const createProductsImports = createAsyncThunk(
	'partner-warehouse/createProductsImports',
	async (
		payload: PayloadBaseInvoice<{ format: string; file: any }>,
		{ rejectWithValue, getState }
	) => {
		try {
			const state: any = getState();

			const response = await fetchCreateProductsImports(
				{
					partnerIntegrationId: payload.partnerIntegrationId,
					partnerIntegrationIdTo: payload.partnerIntegrationIdTo,
					appId: payload.appId,
					propertyId: state.user.propertyId,
					find: payload.find as any
				},
				payload.payload?.format,
				payload.payload?.file
			);
			if (!response || response.statusCode !== 201)
				return rejectWithValue('No se pudo guardar la información');

			return {
				format: payload.payload?.format,
				propertyIntegrations: response.data
			};
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);

export const deleteProductsImports = createAsyncThunk(
	'partner-warehouse/deleteProductsImports',
	async (payload: any, { rejectWithValue }) => {
		try {
			const response: any = await fetchDeleteProductsImports(payload);

			if (!response || response.statusCode !== 200)
				return rejectWithValue('No se pudo guardar la información');

			return {
				data: response.data,
				deleteId:
					payload.partnerIntegrationIdTo ||
					payload.partnerIntegrationId
			} as any;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const mappingDynamic = createAsyncThunk(
	'partner-warehouse/mappingDynamic',
	async (payload: any, { rejectWithValue }) => {
		try {
			const response: any = await fetchMappingDynamic(payload);
			if (!response || response.statusCode !== 200)
				return rejectWithValue('No se pudo guardar la información');
			return response.data;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const deleteSync = createAsyncThunk(
	'partner-warehouse/deleteSync',
	async (payload: any, { rejectWithValue }) => {
		try {
			const response: any = await fetchDeleteSync(payload);
			if (!response || response.statusCode !== 200)
				return rejectWithValue('No se pudo guardar la información');
			return response.data;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);

export const getPropertyIntegrationsProducts = createAsyncThunk(
	'partner-warehouse/propertyIntegrations',
	async (
		{ propertyId, appId }: GetPropertyIntegrations,
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getPropertyIntegrationsService({
				filter: `{"appId": ObjectId("${appId}"), "propertyId": ObjectId("${propertyId}")}`
			});
			if (errors) {
				console.error(errors);
				return rejectWithValue(String(errors));
			}
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(String(error));
		}
	}
);
