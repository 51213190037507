import { SpaState } from './interfaces';

export const initialState = {
	// categoría
	filterCategory: [],
	filterCategoryLoading: 'idle',
	filterCategoryError: '',
	// conexiones
	filterConnection: [],
	filterConnectionLoading: 'idle',
	filterConnectionError: '',
	// estado de la app
	filterAppStatus: [],
	filterAppStatusLoading: 'idle',
	filterAppStatusError: '',
	// métodos de pago
	filterPaymentMethods: [],
	filterPaymentMethodsLoading: 'idle',
	filterPaymentMethodsError: '',

	filterApps: { data: [], meta: {} },
	userWaitList: undefined,
	loadingUserWaitList: 'idle',
	loadingRemoveUserWaitList: 'idle',
	filterAppsLoading: 'idle',
	loadingCountries: 'idle',
	countries: [],

	loadingPatchApp: 'idle',

	success: { patchApp: false },
	error: { patchApp: false }
} as SpaState;
