/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fetch } from '../fetch';
import { RequestUpdate } from '../requests';
import { ResponseFailure, ResponseSuccess } from '../responses';
import { ApiURL } from '../util-url';

export const rntValidateCredentialsService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ ...data, rnt: String(data.rnt) })
		};

		fetch(
			ApiURL(`/api/third-party/rnt/validate-credentials`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const createSireTraSettingsService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ ...data })
		};

		fetch(ApiURL(`/api/common/sire-tra-settings`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const putSireTraSettingsService = (
	id: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ ...data })
		};

		fetch(ApiURL(`/api/common/sire-tra-settings/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getSireTraSettingsService = (filter: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/common/sire-tra-settings?filter=${JSON.stringify(filter)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteSireTraSettingsService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`/api/common/sire-tra-settings/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface getListingsServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	page?: number;
	limit?: number;
	searchValue?: string;
	fields?: string;
	searchInside?: boolean;
	searchOutside?: boolean;
	state?: string;
}

export const getListingsService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	page,
	limit,
	searchValue,
	fields,
	searchInside,
	state,
	searchOutside
}: getListingsServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const filters = [];
		if (searchValue || searchInside || state || searchOutside) {
			if (searchValue) {
				filters.push(`"name": "${searchValue}"`);
			}
			if (searchInside) {
				filters.push(`"searchInside":true`);
			}
			if (searchOutside) {
				filters.push(`"searchOutside":true`);
			}
			if (state && state !== 'all') {
				filters.push(`"state":"${state}"`);
			}
		}
		const filter = `{${filters.join(',')}}`;

		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/pms/listings?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&appId=${appId}&limit=${limit}&page=${page}&sort={"createdAt":-1}&filter=${filter}&fields=${fields}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const airbnbValidateICalService = (calendar: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ calendar })
		};
		fetch(ApiURL(`/api/third-party/airbnb/validate-ical`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const createListingBulkService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL(`/api/pms/listings/bulk`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const createListingService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};
		fetch(ApiURL(`/api/pms/listings`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const editListingService = ({ _id, ...data }: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ ...data })
		};
		fetch(
			ApiURL(
				`/api/pms/listings/${_id}?appId=${data.appId}&partnerIntegrationId=${data.partnerIntegrationId}&propertyId=${data.propertyId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteListingService = (
	id: string,
	propertyId: string,
	appId: string,
	partnerIntegrationId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/pms/listings/${id}?propertyId=${propertyId}&appId=${appId}&partnerIntegrationId=${partnerIntegrationId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface getListingServiceProps {
	id: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}
export const getListingService = ({
	id,
	propertyId,
	partnerIntegrationId,
	appId
}: getListingServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/pms/listings/${id}?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&appId=${appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestPatchListing = (
	params: RequestUpdate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('patch', '/api/pms/listings/:id', params);

export const getListingsValidateTimeService = ({
	propertyId,
	partnerIntegrationId
}: {
	propertyId: string;
	partnerIntegrationId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/pms/listings/validate-times?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const potListingsValidateTimeService = ({
	propertyId,
	partnerIntegrationId,
	arrivalTime,
	departureTime
}: {
	propertyId: string;
	partnerIntegrationId: string;
	arrivalTime: string;
	departureTime: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ arrivalTime, departureTime })
		};
		fetch(
			ApiURL(
				`/api/pms/listings/validate-times?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
