import { fetchGetCurrencyRate } from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Find } from './interface';

export const getCurrencyRate = createAsyncThunk(
	'partner-currency/getCurrencyRate',
	async (payload: Find, { rejectWithValue }) => {
		try {
			const response = await fetchGetCurrencyRate(payload);
			if (response.statusCode === 200) return response.data;
			return response;
		} catch (error: any) {
			console.error(error);
			rejectWithValue('Error');
		}
	}
);
