import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './tra-sire.constants';
import { TraSireSettingsState } from './tra-sire.interfaces';
import {
	deleteTraSireSettings,
	loadTraSireSettings,
	validateRnt
} from './tra-sire.thunks';

export const traSireSlice = createSlice({
	name: 'traSire',
	initialState,
	reducers: {
		setTraSireSettings: (
			state,
			action: PayloadAction<TraSireSettingsState>
		) => {
			state.traSireSettings = action.payload;
		},
		manualUpdateTraSireSettings: (state, { payload }) => {
			state.traSireSettings = {
				...state.traSireSettings,
				data: {
					...state.traSireSettings.data,
					...payload
				}
			};
		},
		traSireClearState: (state, { payload }) => {
			if (payload === 'delete' || payload === 'all') {
				state.deleteTraSireSettings = {
					loading: 'idle',
					error: ''
				};
			}
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadTraSireSettings.pending, (action) => {
				action.traSireSettings = {
					loading: 'pending',
					data: undefined,
					error: ''
				};
			})
			.addCase(loadTraSireSettings.rejected, (action, { payload }) => {
				action.traSireSettings = {
					loading: 'failed',
					data: undefined,
					error: String(payload)
				};
			})
			.addCase(loadTraSireSettings.fulfilled, (action, { payload }) => {
				action.traSireSettings = {
					loading: 'succeeded',
					data: payload,
					error: ''
				};
			});

		builder
			.addCase(deleteTraSireSettings.pending, (action) => {
				action.deleteTraSireSettings = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(deleteTraSireSettings.rejected, (action, { payload }) => {
				action.deleteTraSireSettings = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(deleteTraSireSettings.fulfilled, (action, { payload }) => {
				action.deleteTraSireSettings = {
					loading: 'succeeded',
					error: ''
				};
				action.traSireSettings = {
					loading: 'idle',
					data: undefined,
					error: ''
				};
			});

		builder
			.addCase(validateRnt.pending, (action) => {
				action.validateRnt = {
					loading: 'pending',
					error: ''
				};
			})
			.addCase(validateRnt.rejected, (action, { payload }) => {
				action.validateRnt = {
					loading: 'failed',
					error: String(payload)
				};
			})
			.addCase(validateRnt.fulfilled, (action, { payload }) => {
				action.validateRnt = {
					loading: 'succeeded',
					error: ''
				};
			});
	}
});

export const {
	traSireClearState,
	manualUpdateTraSireSettings,
	setTraSireSettings
} = traSireSlice.actions;
