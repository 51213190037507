/* eslint-disable @typescript-eslint/no-explicit-any */
import { Async, IInternalAccount, IReservationInvoice } from '@hospy/store';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import InvoiceFilterContext from '../../../context/index';
import HeaderInvoiceList from './components/header';
import InvoiceFilterForm from './components/invoice-filter-form';
import MainInvoice from './components/main-invoice';
import { useInvoices } from './hooks/use-invoice';
import { useInvoiceListFilters } from './hooks/use-invoice-filters';

export interface ListInvoicesAccountsProps {
	appId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	internalAccounts: Async;
	getInternalAccounts: (payload: any) => any;
	setInternalAccounts: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	showCreateInvoiceButton?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedReservation: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	screen: string;
	selectScreen: (screen: string) => void;
	selectSource: (
		source: IReservationInvoice | IInternalAccount,
		index: number
	) => void;
	selectedSource: IReservationInvoice | IInternalAccount | null;
	multipleInvoices: boolean | undefined;
}

export const ListInvoicesAccounts = (props: ListInvoicesAccountsProps) => {
	const {
		loading,
		internalAccounts,
		closeInvoiceDetail,
		invoice,
		invoiceLoading,
		actionCloseDetail
	} = useInvoices(props);

	const {
		updateSelectedDates,
		updateFilterField,
		refresh,
		searchByValue,
		searchValue,
		page,
		updatePage,
		selectedAdditionalFilters,
		updateAdditionalFilters,
		selectedFilterField,
		selectedDates
	} = useInvoiceListFilters({ ...props, closeInvoiceDetail });

	return (
		<InvoiceFilterContext.Provider
			value={{
				partnerIntegrationId: props.partnerIntegrationId,
				partnerIntegrationIdTo: props.partnerIntegrationIdTo,
				updateAdditionalFilters,
				updateSelectedDates,
				updateFilterField,
				selectedAdditionalFilters,
				justReservationsInProcess: false,
				actionCloseDetail,
				refresh,
				searchByValue,
				searchValue,
				loading,
				selectedSource: props.selectedSource,
				page,
				updatePage,
				inProcessOrFailureCounter: 0,
				swipeJustInvoiceInProcess: () => null,
				filterField: selectedFilterField,
				dates: selectedDates
			}}
		>
			<InvoiceReservationWrapper>
				<HeaderWrapper>
					{!props.selectedSource && (
						<InvoiceFilterForm
							showCreateInvoiceButton={
								props.showCreateInvoiceButton
							}
						/>
					)}
					<HeaderInvoiceList
						count={props.internalAccounts.data?.meta?.count || 0}
						isSelectedInternalAccount={!!props.selectedSource}
					/>
				</HeaderWrapper>

				{internalAccounts && (
					<PaginationSummary>
						<FormattedMessage
							id="partner.invoice.counter-account"
							defaultMessage="{reservations, plural, =0 {No se encontraron cuentas internas} one {Se encontró una cuenta interna} other {Mostrando # cuentas internas} }"
							values={{
								reservations: internalAccounts?.length || 0
							}}
						/>
					</PaginationSummary>
				)}
				<MainInvoice
					invoice={invoice}
					invoiceLoading={invoiceLoading}
					internalAccounts={internalAccounts}
					selectInternalAccount={props.selectSource}
					multipleInvoices={props.multipleInvoices}
				/>
			</InvoiceReservationWrapper>
		</InvoiceFilterContext.Provider>
	);
};

const InvoiceReservationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	.ant-form-item {
		margin: 0;
	}

	.ant-picker-suffix {
		color: black;
	}

	p {
		margin-bottom: 0;
	}
`;

const PaginationSummary = styled.div``;
