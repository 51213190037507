import { Button } from '@hospy/hospy-ui';
import { LogoIcon } from '@hospy/shared-components';
import { FallbackRender } from '@sentry/react';
import { Image, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

const ErrorBoundaryFallback: FallbackRender = ({
	error,
	componentStack,
	resetError
}) => {
	const navigate = useNavigate();
	const theme: any = useTheme();

	return (
		<Container>
			<div style={{ marginBottom: 30 }}>
				<LogoIcon />
			</div>
			<Typography.Title>
				¡Ups! <br />
				Algo salió mal
			</Typography.Title>
			<Content>
				<Right>
					<Typography.Paragraph style={{ fontSize: 18 }}>
						Lo sentimos, se ha producido un error en el que estamos
						trabajando.
					</Typography.Paragraph>
					<ErrorContainer>
						<Typography.Paragraph
							style={{
								color: theme.colors.gray.darken['50%']
							}}
						>
							{error ? error.toString() : ''}
						</Typography.Paragraph>
						<CodeContainer>
							<code>{componentStack}</code>
						</CodeContainer>
					</ErrorContainer>
					<Button
						type="primary"
						onClick={() => {
							navigate('/');
							if (resetError) resetError();
						}}
					>
						Llévame de vuelta
					</Button>
				</Right>

				<Image
					src="https://cdn.hospy.co/images/bot.svg"
					preview={false}
					prefixCls="bot"
				/>
			</Content>
		</Container>
	);
};
export default ErrorBoundaryFallback;

const Container = styled.div`
	padding: 50px 15px;
	max-width: 900px;
	margin: 0px auto;
`;

const Content = styled.div`
	display: flex;
	gap: 15px;

	.bot {
		width: 200px;
		right: 0;
		&-img {
			width: 100%;
		}
	}

	${({ theme }) =>
		theme.screens.xs &&
		css`
			flex-direction: column-reverse;
			align-items: center;
		`}
`;

const CodeContainer = styled.details`
	overflow-y: auto;
`;

const ErrorContainer = styled.div`
	border: 1px solid;
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.gray.darken['50%']};
	background-color: ${({ theme }) => theme.colors.gray.lighten['50%']};
	padding: 15px;
	margin: 30px 0;
`;

const Right = styled.div`
	width: calc(100% - 200px);
	${({ theme }) =>
		theme.screens.xs &&
		css`
			width: 100%;
		`}
`;
