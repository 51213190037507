import { CheckCircleFilled, EyeOutlined } from '@ant-design/icons';
import { AccountForm, SocialMediaButton } from '@hospy/shared-components';
import {
	Alert,
	Button,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Tooltip,
	Typography
} from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import useSocialResponse from '../../social-response/hook/use-social-response';
import { IUseRegister } from '../hook/use-register';

const RegisterForm = ({
	onSubmit,
	loading,
	step,
	isValidEmail,
	setIsValidEmail
}: IUseRegister) => {
	const [form] = Form.useForm();
	const userName = Form.useWatch('username', form);
	const { processAccessWithGoogle } = useSocialResponse();
	const intl = useIntl();

	useEffect(() => {
		if (isValidEmail !== '') {
			form.validateFields(['username']);
		}
	}, [isValidEmail, form]);

	useEffect(() => {
		if (userName && isValidEmail === 'INVALID_EMAIL') setIsValidEmail('');
	}, [userName]);

	const handlerOnClick = async () => processAccessWithGoogle('register');

	return (
		<AccountForm.Container>
			<motion.div layout>
				<AccountForm.Content
					title={intl.formatMessage({
						id: 'register.title',
						defaultMessage: 'Regístrate para activar una cuenta'
					})}
				>
					<Form form={form} onFinish={onSubmit}>
						<motion.div layout>
							{isValidEmail === 'VALID_EMAIL' && (
								<motion.div
									layout
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginBottom: 20,
										flexWrap: 'wrap',
										marginTop: 10
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<Typography style={{ marginRight: 8 }}>
											{userName}
										</Typography>
										<CheckCircleFilled
											style={{ color: '#4cd864' }}
										/>
									</div>

									<Button
										htmlType="button"
										style={{
											background: '#ebebeb',
											marginTop: 10
										}}
										onClick={() => {
											setIsValidEmail('');
											form.resetFields();
										}}
									>
										<FormattedMessage
											id="register.userOtherEmail"
											defaultMessage="Usar otro email"
										/>
									</Button>
								</motion.div>
							)}

							<Form.Item
								hidden={isValidEmail === 'VALID_EMAIL'}
								name="username"
								rules={[
									{
										type: 'email',
										message: intl.formatMessage({
											id: 'form.invalidEmail',
											defaultMessage:
												'No es un email valido'
										})
									},
									{
										required: true,
										message: intl.formatMessage({
											id: 'form.requiredField',
											description: 'Form required field',
											defaultMessage: 'Campo requerido'
										})
									},
									{
										validator() {
											if (
												isValidEmail === 'INVALID_EMAIL'
											) {
												return Promise.reject(
													new Error(
														intl.formatMessage({
															id: 'register.emailYetRegistered',
															description:
																'Form email yet registered',
															defaultMessage:
																'El email ingresado ya se encuentra en nuestro sistema'
														})
													)
												);
											}
											return Promise.resolve();
										}
									}
								]}
								hasFeedback
								validateStatus={
									loading
										? 'validating'
										: isValidEmail === ''
										? ''
										: isValidEmail === 'VALID_EMAIL'
										? 'success'
										: 'error'
								}
							>
								<Input
									size="large"
									placeholder={intl.formatMessage({
										id: 'form.inputEmail.placeholder',
										description:
											'Form input email placeholder',
										defaultMessage: 'Ingresa tu email'
									})}
									disabled={loading}
								/>
							</Form.Item>
						</motion.div>
						<AnimatePresence>
							{step === 'COMPLETE_FORM' && (
								<motion.div
									layout
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
								>
									<Form.Item
										name="name"
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id: 'form.requiredField',
													description:
														'Form required field',
													defaultMessage:
														'Campo requerido'
												})
											}
										]}
									>
										<Input
											size="large"
											placeholder={intl.formatMessage({
												id: 'register.inputName',
												description:
													'Form required field',
												defaultMessage:
													'Ingresa tu nombre'
											})}
											disabled={loading}
										/>
									</Form.Item>
									<Form.Item
										name="password"
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id: 'form.requiredField',
													description:
														'Form required field',
													defaultMessage:
														'Campo requerido'
												})
											},
											{
												pattern: new RegExp(
													/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/
												),
												message: intl.formatMessage({
													id: 'form.invalidPasswordFormat',
													description:
														'Invalid password format',
													defaultMessage:
														'La contraseña no contiene el formato requerido'
												})
											}
										]}
										hasFeedback
									>
										<Input.Password
											size="large"
											placeholder={intl.formatMessage({
												id: 'form.inputPassword.placeholder',
												description:
													'Form input password placeholder',
												defaultMessage: 'Contraseña'
											})}
											disabled={loading}
										/>
									</Form.Item>
									<Form.Item
										name="confirm"
										dependencies={['password']}
										hasFeedback
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id: 'form.requiredField',
													description:
														'Form required field',
													defaultMessage:
														'Campo requerido'
												})
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														getFieldValue(
															'password'
														) === value
													)
														return Promise.resolve();

													return Promise.reject(
														new Error(
															intl.formatMessage({
																id: 'form.passwordsNotEqual',
																description:
																	'The two password are not the same',
																defaultMessage:
																	'¡Las dos contraseñas que ingresó no coinciden!'
															})
														)
													);
												}
											})
										]}
									>
										<Input.Password
											size="large"
											placeholder={intl.formatMessage({
												id: 'register.confirmPasswordPlaceholder',
												description:
													'Confirm password placeholder',
												defaultMessage:
													'Confirmar contraseña'
											})}
											disabled={loading}
										/>
									</Form.Item>
									<motion.div layout>
										<Tooltip
											placement="right"
											title={
												<div>
													<b>
														<FormattedMessage
															id="register.minimunLogitud"
															defaultMessage="Longitud mínima de la contraseña"
														/>
													</b>
													<br />8 carácter/es
													<br />
													<b>
														<FormattedMessage
															id="form.passwordRequirements"
															defaultMessage="Requisitos de contraseña"
														/>
													</b>
													<br />
													<FormattedMessage
														id="form.containeAtLeast"
														defaultMessage="Contiene al menos 1 número"
													/>
													<br />
													<FormattedMessage
														id="form.containeAtLeastSpecial"
														defaultMessage="Contiene al menos 1 carácter especial"
													/>
													<br />
													<FormattedMessage
														id="form.containeAtLeastUppercase"
														defaultMessage="Contiene al menos una letra mayúscula"
													/>
													<br />
													<FormattedMessage
														id="form.containeAtLeastLowercase"
														defaultMessage="Contiene al menos una letra minúscula"
													/>
												</div>
											}
										>
											<Alert
												message={intl.formatMessage({
													id: 'form.passwordSecureRequired',
													description:
														'Password secure required',
													defaultMessage:
														'Ver formato de la contraseña 👉'
												})}
												type="info"
												action={
													<Button
														type="text"
														shape="circle"
														icon={<EyeOutlined />}
													/>
												}
												style={{ marginBottom: 25 }}
											/>
										</Tooltip>
									</motion.div>
								</motion.div>
							)}
						</AnimatePresence>
						<motion.div layout initial={{ marginTop: 10 }}>
							<Form.Item>
								<Button
									htmlType="submit"
									loading={loading}
									size="large"
									type="primary"
									block
								>
									{isValidEmail === 'VALID_EMAIL'
										? intl.formatMessage({
												id: 'register.completeRegister',
												defaultMessage:
													'Completar registro'
										  })
										: intl.formatMessage({
												id: 'register.continue',
												defaultMessage: 'Continuar'
										  })}
								</Button>
							</Form.Item>
						</motion.div>
					</Form>
					{isValidEmail !== 'VALID_EMAIL' && (
						<motion.div
							layout
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						>
							<Row justify="center">
								<Col span={24}>
									<Divider>
										<FormattedMessage
											id="login.orPreference"
											defaultMessage="O si prefieres:"
										/>
									</Divider>
								</Col>
								<Col span={24}>
									<SocialMediaButton
										onClick={handlerOnClick}
										type="GOOGLE"
										label={intl.formatMessage({
											id: 'register.google',
											description: 'Form register google',
											defaultMessage:
												'Registrarse con Google'
										})}
									/>
								</Col>
								<Col>
									<span
										style={{
											fontSize: '.8rem'
										}}
									>
										<FormattedMessage
											id="register.haveAccount"
											defaultMessage="Si ya tienes una cuenta"
										/>{' '}
										<Link to={'/login'}>
											<FormattedMessage
												id="register.inputHere"
												defaultMessage="ingresa aquí"
											/>
										</Link>
									</span>
								</Col>
							</Row>
						</motion.div>
					)}
				</AccountForm.Content>
			</motion.div>
		</AccountForm.Container>
	);
};

export default RegisterForm;
