/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';
import { IDiscountValidation } from '../common/common.types';
import { ILandingPayment, IPayment } from './subscription.interfaces';

export class hospyApi {
	static fetchSubscriptionInfo = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(ApiURL('api/common/subscriptions/properties'), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static fetchClientSecret = (
		frequency: string,
		properties: any
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					frequency,
					properties
				})
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(ApiURL(`api/third-party/stripe/subscribe`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static postSavePayment = (
		payment: IPayment,
		invoiceId: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(payment)
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/third-party/stripe/payment/${invoiceId}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static validateDiscount = (discount: IDiscountValidation): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(discount)
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/common/subscriptions/discounts/validate`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static putTryPayment = (paymentMethod: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({ paymentMethod })
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(ApiURL(`api/third-party/stripe/payment`), requestOptions)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static deleteActualPayment = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/third-party/stripe/payment/methods/defaults`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static initLandingPaymentService = (
		email: string,
		name: string,
		invoiceId: string
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					email,
					name,
					invoiceId
				})
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/third-party/stripe/landing-subscribe`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static postLandingSavePayment = (payment: ILandingPayment): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify(payment)
			};

			if (localStorage.getItem('userIdView'))
				requestOptions.headers['X-Hospy-User-View-Id'] =
					localStorage.getItem('userIdView');

			fetch(
				ApiURL(`api/third-party/stripe/landing-payment`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});

	static getValidatePaymentTried = (email: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const accessToken = localStorage.getItem('Hospy-idToken');

			const requestOptions: any = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
			};

			fetch(
				ApiURL(`api/third-party/stripe/landing-payment/${email}`),
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => resolve(data))
				.catch((error) => reject(error));
		});
}
