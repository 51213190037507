import { Button, Icon } from '@hospy/hospy-ui';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import useCreditView from '../hooks/use-credit-view';
import CreditPurchase from './credit-purchase';

export const CreditView = ({ mode }: { mode: 'full' | 'detail' }) => {
	const { availableCredits, creditRef, isOpen, setIsOpen, credits } =
		useCreditView();

	if (credits.loading === 'pending') return <div />;

	return (
		<CreditViewWrapper mode={mode} isOpen={isOpen} ref={creditRef}>
			<QuantityWrapper
				credits={availableCredits}
				onClick={() => setIsOpen(!isOpen)}
				tooltip="Tus timbres disponibles"
			>
				{credits.loading === 'succeeded' && (
					<>
						<p>
							<FormattedMessage
								defaultMessage="{credits, plural, =0 {0 disponibles} one {# disponible} other {# disponibles} }"
								values={{
									credits: availableCredits
								}}
							/>
						</p>

						<Icon material="arrow_drop_down" />
					</>
				)}
			</QuantityWrapper>

			{isOpen && <CreditPurchase />}
		</CreditViewWrapper>
	);
};

interface CreditViewWrapperProps {
	isOpen: boolean;
	mode: string;
}

export const CreditViewWrapper = styled.div<CreditViewWrapperProps>`
	display: flex;
	flex-direction: column;
	top: -20px;
	z-index: 2;
	background-color: white;
	width: 262px;
	${({ mode }) => {
		if (mode === 'full')
			return css`
				position: absolute;
				right: 0;
			`;
		return css`
			position: relative;
		`;
	}}

	${({ isOpen, theme }) =>
		isOpen &&
		css`
			box-shadow: ${theme.boxShadow.level2()};
			border: 1px solid ${theme.colors.gray.lighten?.['20%']};
		`}
`;

interface QuantityWrapperProps {
	credits: number;
}

const QuantityWrapper = styled(Button)<QuantityWrapperProps>`
	display: flex;
	${({ credits, theme }) => {
		if (credits < 20)
			return css`
				background-color: ${theme.colors.danger.base};
				&.ant-btn:hover:not(:disabled),
				&.ant-btn:active:not(:disabled),
				&.ant-btn:focus:not(:disabled) {
					color: #fff;
					border-color: ${theme.colors.danger.base};
					background-color: ${theme.colors.danger.lighten?.['20%']};
				}
			`;
		return css`
			background-color: ${theme.colors.success.base};
			&.ant-btn:hover:not(:disabled),
			&.ant-btn:active:not(:disabled),
			&.ant-btn:focus:not(:disabled) {
				color: #fff;
				border-color: ${theme.colors.success.base};
				background-color: ${theme.colors.success.lighten?.['20%']};
			}
		`;
	}};
	width: 260px;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-radius: 0 0 15px 15px;
	color: white;
	font-size: ${({ theme }) => theme.text.size.middle}px;
	font-weight: bold;

	position: relative !important;

	span {
		position: static !important;
	}

	svg {
		font-size: 26px;
		position: absolute;
		right: 20px !important;
		top: 18% !important;
	}
	p {
		margin: 0;
	}
`;

export default CreditView;
