/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '../util-url';

export const getSellByApplicationService = (
	startDate: string,
	endDate: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(
				`/api/common/analytics/sell-application?filter={"startDt":"${startDate}", "endDt":"${endDate}"}`
			),
			requestOptions
		)
			.then(async (response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
