import { HubspotInitialState } from './interface';

export const initialState: HubspotInitialState = {
  fieldMapping: {
    loading: 'idle',
    error: undefined,
    data: []
  },
  updatedFieldMapping: {
    loading: 'idle',
    error: undefined
  },
  properties: {
    loading: 'idle',
    error: undefined
  },
  propertyDeals: {
    loading: 'idle',
    error: undefined
  },
  pipelines: {
    loading: 'idle',
    error: undefined
  },
  logs: {
    loading: 'idle',
    error: undefined,
    data: []
  }
};
