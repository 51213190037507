/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from '@hospy/hospy-ui';
import { Button, Space, Typography } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

export const ModalConfirmDelete = ({
	itemName,
	title,
	description,
	loading,
	...props
}: any) => {
	const intl = useIntl();
	return (
		<Modal {...props} size="small">
			<Space
				direction="vertical"
				align="center"
				style={{ width: '100%' }}
			>
				<Title>{title}</Title>
				<Message>{description}</Message>
			</Space>
			<ButtonContainer>
				<ButtonStyle
					type="primary"
					onClick={props.onOk}
					danger
					loading={loading}
				>
					{intl.formatMessage({
						id: 'manageProperties.deleteProperty.delete',
						defaultMessage: 'Eliminar'
					})}
					{itemName && ` ${itemName}`}
				</ButtonStyle>
			</ButtonContainer>
		</Modal>
	);
};

const Title = styled(Typography.Text)`
	display: block;
	font-weight: bold;
	text-align: center;
	font-size: ${({ theme }) => theme.title.size.small}px;
`;

const Message = styled(Typography.Text)`
	display: block;
	text-align: center;
	font-size: ${({ theme }) => theme.text.size.large}px;
`;

const ButtonStyle = styled(Button)`
	border-radius: 0px;
	height: 50px;
	margin-top: 20px;
	border: none;
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: grid;
	column-gap: 15px;
	gap: 15px;
	justify-content: center;
	margin-top: 15px;
`;

export default ModalConfirmDelete;
