/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fetch } from '../fetch';
import { RequestCreate, RequestFindAll } from '../requests';
import { ResponseFailure, ResponseSuccess } from '../responses';
import { ApiURL } from '../util-url';

export const getWhatsappMeService = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const queryParams = new URLSearchParams(params).toString();
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`/api/notifications/whatsapp/me?${queryParams}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postWhatsappConnectService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/notifications/whatsapp/signup`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getWhatsappDisconnectService = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const queryParams = new URLSearchParams(params).toString();
		fetch(
			ApiURL(`/api/notifications/whatsapp/logout?${queryParams}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getWhatsappMessageService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/notifications/whatsapp/messages`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postTTLockTokenService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/third-party/ttlock/integration`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getTTLockListService = (id: string, page: number): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/third-party/ttlock/integration/list-lock/${id}?page=${page}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllUpsellingProducts = (
	propertyId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`/api/upselling/products?propertyId=${propertyId}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postPolicySavePayment = (
	payment: any
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/third-party/stripe/policy', { data: payment });

export const RequestFinOneProduct = (params: {
	pathParams: { _id: string };
}): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/upselling/products/:_id', params);

export const RequestActiveUpsellingProducts = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/products/active`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestCreateIntentPaymentHold = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/third-party/stripe/payment/secure-3ds', params);

export const RequestCreateIntentPaymentHoldSecure3ds = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/third-party/stripe/payment/secure-3ds', params);

export const RequestCreatePaymentHold = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/third-party/stripe/payment/hold', params);

export const RequestUpsellingPurchaseIntent = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/purchase-intent`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestPatchUpsellingPurchaseIntent = (
	id: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/purchase-intent/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface RequestUpsellingPaymentHoldParams {
	lang: 'es' | 'en' | 'pt';
	// transactionType: 'capture-method-automatic' | 'capture-method-manual';
	purchaseIntentId: string;
	propertyId: string;
	propertyName: string;
	propertyType: string;
	countryId: string;
	countryName: string;
	cityId: string;
	cityName: string;
	reservationId: string;
	reservationThirdPartyId: string;
	pax: number;
	checkInDate: string;
	checkOutDate: string;
	productId: string;
	productName: { es: string; en: string };
	productDescription: { es: string; en: string };
	guestId: string;
	guestName: string;
	guestCountryId: string;
	guestCountryName: string;
	gender?: 'male' | 'female';
	phone: string;
	email: string;
	birthday: Date;
	reasonTrip: string;
	interests?: string[];
	settings: any;
	amount: number;
	serviceRate: number;
	hospyCommission: number;
	customerCommission: number;
	total: number;
	currency: string;
	channelSlug: string;
	channelName: string;
	rooms: { id: string; name: string }[];
	nights: number;
	reservationValue: number;
	nightValue: number;
	reservationCurrency: string;
	hostApprovalRequired: boolean;
}
export const RequestUpsellingPaymentHold = (
	data: RequestUpsellingPaymentHoldParams
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/payment/hold`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestUpsellingPaymentProcess = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`/api/upselling/payment/process/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

function jsonToUrlParams(json: any) {
	return Object.keys(json)
		.map((key) => {
			return key + '=' + json[key];
		})
		.join('&');
}

export const RequestFindAllUpsellingPayment = (props: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const params = jsonToUrlParams(props);

		const url = ApiURL(`/api/upselling/payment?${params}`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllUpsellingPurchaseIntent = (
	props: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const params = jsonToUrlParams(props);

		const url = ApiURL(`/api/upselling/purchase-intent?${params}`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestUpsellingPaymentProcessHold = (
	id: string,
	process: 'accept' | 'cancel'
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const url = ApiURL(`/api/upselling/payment/${id}/${process}`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface RequestUpsellingProductValidationProps {
	lang: 'es' | 'en' | 'pt';
	propertyId: string;
	reservationId: string;
	productId: string;
}
export const RequestUpsellingProductValidation = (
	data: RequestUpsellingProductValidationProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/upselling/products/validations?lang=${data.lang}&propertyId=${data.propertyId}&reservationId=${data.reservationId}&productId=${data.productId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllUpsellingBalance = (props: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const params = jsonToUrlParams(props);

		const url = ApiURL(`/api/upselling/balance?${params}`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllUpsellingBankAccounts = (props: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		const params = jsonToUrlParams(props);

		const url = ApiURL(`/api/upselling/bank-accounts?${params}`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestUpsellingBankAccountsByProperty = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/upselling/bank-accounts/find-by-property/:id', params);

interface RequestUpsellingBankAccounts {
	propertyId: string;
	email: string;
	bankName: string;
}
export const RequestPostUpsellingBankAccounts = (
	data: RequestUpsellingBankAccounts
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/bank-accounts`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestPatchUpsellingBankAccounts = (
	id: string,
	data: Partial<RequestUpsellingBankAccounts>
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/bank-accounts/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestDeleteUpsellingProducts = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(
			ApiURL(
				`/api/upselling/products/delete-setting/${data._id}?propertyId=${data.propertyId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const enableAccessService = (props: {
	id: string;
	enableAccess: boolean;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ enableAccess: props.enableAccess })
		};

		fetch(
			ApiURL(
				`/api/pms/reservations/${props.id}/enable-access?propertyId=${props.propertyId}&partnerIntegrationId=${props.partnerIntegrationId}&appId=${props.appId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestCreatePaymentWithUpsellingPayment = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', '/api/upselling/payment/subscription', params);
