/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGuestState } from './interfaces';

export const initialState = {
	loading: 'idle',
	error: {},
	success: {}
} as IGuestState;

export const errorDictionary: any = {
	'The client secret supplied for a confidential client is invalid.':
		'El client secret suministrado no es válido',
	invalid_scope:
		'No tienes los permisos suficientes para completar tu conexión'
};
