/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestFindAllListings } from '@hospy/util-api/service/partner';
import { RequestFindAll } from '@hospy/util-api/requests';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ListingsSliceName } from './constants';
import { ResponseSuccess } from '@hospy/util-api/responses';

export const ThunkFindAllListings = createAsyncThunk(
	`${ListingsSliceName}/${RequestFindAllListings.name}`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const response = await RequestFindAllListings(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
export const ThunkFindAllListingsRecursive = createAsyncThunk(
	`${ListingsSliceName}/${RequestFindAllListings.name}-recursive`,
	async (params: RequestFindAll, { rejectWithValue }) => {
		const listings = [];
		let hasMore = true;
		let currentPage = 1;
		try {
			while (hasMore) {
				const response = await RequestFindAllListings({
					...params,
					page: currentPage
				});
				if (response.statusCode !== 200) hasMore = false;

				const responseSuccess = response as ResponseSuccess<any>;

				if (
					(responseSuccess.meta?.currentPage || currentPage) >=
					(responseSuccess.meta?.lastPage || 0)
				)
					hasMore = false;

				listings.push(...responseSuccess.data);

				currentPage++;
			}
			if (listings.length === 0)
				return rejectWithValue('No listings found');

			return { data: listings };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
