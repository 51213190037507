import OpenInNew from '@2fd/ant-design-icons/lib/OpenInNew';
import { Button, Icon as IconHospy } from '@hospy/hospy-ui';
import { useAppSelector } from '@hospy/store';
import { Image, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import useUiApps from '../../../hooks/use-ui-apps';

type AppData = {
	name: any;
	description: any;
	extraInfo?: any;
	icon?: any;
	style?: any;
	knowledgeBaseUrl?: string;
	availableCountries?: any;
};

type InfoData = {
	[name: string]: AppData;
};

interface Props {
	typeApp?: 'admin' | 'developer';
};

const infoData: InfoData = {
	developer: {
		name: {
			id: 'login.titleDevelopers',
			description: 'developers',
			defaultMessage: 'Developers'
		},
		description: {
			id: 'login.descriptionDevelopers',
			description: 'Description developers',
			defaultMessage: `
			Accede a nuestro ecosistema de APIs para desarrolladores y 
			construye todo lo que necesitas para automatizar las 
			operaciones de tus negocios de alojamiento.
			`
		},
		extraInfo: {
			id: 'login.welcome',
			description: 'welcome!',
			defaultMessage: '¡ Bienvenidos !'
		},
		icon: {
			"type": "icons-material",
			"name": "mouse"
		},
		style: {
			fontSize: 55,
			color: '#FFFFFF'
		}
	},
	admin: {
		name: {
			id: 'login.titleSystemControl',
			description: 'System control',
			defaultMessage: 'Sistema de control'
		},
		description: {
			id: 'login.descriptionSystemControl',
			description: 'Description admin',
			defaultMessage: `
				Esta app fue desarrollada para que administradores, 
				colaboradores y socios de Hospy, accedan a nuestros 
				sistemas para realizar actividades de control y 
				revisar reportes importantes.
			`
		},
		extraInfo: {
			id: 'login.welcome',
			description: 'welcome!',
			defaultMessage: '¡ Bienvenidos !'
		},
		icon: {
			"type": "icons-material",
			"name": "sports_esports"
		},
		style: {
			fontSize: 55,
			color: '#FFFFFF'
		}
	}
};

const Info = ({ typeApp }: Props) => {
	const [externalInfo, setExternalInfo] = useState<AppData | null>(null);
	const { appData } = useAppSelector(({ common }) => common);
	const { getIcon } = useUiApps();
	const theme: any = useTheme();

	if (!appData && !typeApp) return null;

	const styleDefault = externalInfo?.style
		? { ...externalInfo.style }
		: { fontSize: 30, color: theme.colors.primary.base }
		;
		
	const img: any = getIcon(appData?.icon || externalInfo?.icon);
	const knowledgeBaseUrl = appData?.knowledgeBaseUrl || externalInfo?.knowledgeBaseUrl
	const availableCountries = appData?.availableCountries || externalInfo?.availableCountries
	
	const Icon =
		typeof img === 'object'
			? React.createElement(img, { style: styleDefault })
			: <IconHospy src={img} circle={true}/>;

	const buttonInfo = knowledgeBaseUrl ? (
		<Button
			style={{ color: '#fff' }}
			size="middle"
			type="text"
			href={knowledgeBaseUrl}
			target="_blank"
		>
			Revisar como funciona <OpenInNew />
		</Button>
	) : null;
	
	const logo = appData ?
		<LogoContainer>
			{typeof img === 'string' && (
				<Logo src={img} preview={false} />
			)}
			{typeof img === 'object' && Icon}
		</LogoContainer>
		: Icon;

	useEffect(() => {
		if (typeApp) setExternalInfo(infoData[typeApp]);
	}, []);
	
	return (
		<Container>
			<Header>
				{logo}
				<Title> 
					{appData?.name.es}
					{externalInfo?.name && <FormattedMessage {...externalInfo?.name}></FormattedMessage>}
				</Title>
			</Header>
			<Description>
				{appData?.description.es}
				{externalInfo?.description && <FormattedMessage {...externalInfo?.description}></FormattedMessage>}
			</Description>
			{externalInfo?.extraInfo && 
				<Description top='50'>
					<FormattedMessage {...externalInfo.extraInfo}></FormattedMessage>
				</Description>
			}
			<div
				style={{
					marginTop: 40,
					display: theme.screens.xs ? 'none' : 'flex',
					justifyContent: theme.screens.xs ? 'center' : 'start',
					flexWrap: 'wrap'
				}}
			>
				{availableCountries?.length === 1 &&
					availableCountries.map((e: any, index: number) => (
						<AvailableForContainer key={index}>
							<CountryContainer>
								<CircleFlag
									height={25}
									countryCode={String(
										e.iso2
									).toLocaleLowerCase()}
								/>
								<span>Solo para {e.name}</span>
							</CountryContainer>
							{buttonInfo}
						</AvailableForContainer>
					))}
				{appData && appData.availableCountries.length > 1 && (
					<>
						<AvailableForLabel>Disponible para:</AvailableForLabel>
						<AvailableForContainer>
							<FlagContainer>
								{appData.availableCountries.map((e: any) => (
									<CircleFlag
										height={25}
										countryCode={String(
											e.iso2
										).toLocaleLowerCase()}
									/>
								))}
							</FlagContainer>
							{buttonInfo}
						</AvailableForContainer>
					</>
				)}
			</div>
		</Container>
	);
};
const AvailableForLabel = styled.div`
	width: 100%;
	color: #fff;
`;
const CountryContainer = styled.div`
	border: 1px solid;
	padding: 15px;
	color: #fff;
	display: flex;
	gap: 15px;
	border-radius: 30px;
	align-items: center;
	${({ theme }: any) =>
		theme.screens.xs &&
		css`
			color: ${theme.colors.gray.darken['30%']};
			padding: 0px;
		`}
`;

const Container = styled.div`
	padding: 0 60px;
	margin-top: 100px;
	${({ theme }: any) =>
		theme.screens.xs &&
		css`
			margin-top: 30px;
		`}
`;

const Header = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
	margin-bottom: 20px;
	${({ theme }: any) =>
		theme.screens.xs &&
		css`
			margin-bottom: 0px;
			justify-content: center;
		`}
`;

const Description = styled(Typography.Text) <{ top?: string }>`
	color: ${({ theme }: any) => (theme.screens.xs ? '#000' : '#fff')};
	display: block;
	font-size: ${({ theme }: any) => theme.title.size.small}px;
	${({ theme }: any) =>
		theme.screens.xs &&
		css`
			display: none;
		`}

	${({ top }) =>
		top &&
		css`
			margin-top: ${top}px;
		`}
`;

const Title = styled(Description)`
	font-size: ${({ theme }: any) => theme.title.size.large * 1.8}px;
	font-weight: bold;
	${({ theme }: any) =>
		theme.screens.xs &&
		css`
			display: block;
			font-size: ${({ theme }) => theme.text.size.small}px;
			font-weight: normal;
		`}
`;

const Logo = styled(Image)``;
const LogoContainer = styled.div`
	width: ${({ theme }) => theme.title.size.large * 2.5}px;
	height: ${({ theme }) => theme.title.size.large * 2.5}px;
	background-color: #fff;
	border-radius: 50%;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	${({ theme }: any) =>
		theme.screens.xs &&
		css`
			padding: 0px;
			width: ${({ theme }) => theme.title.size.large * 1.5}px;
			height: ${({ theme }) => theme.title.size.large * 1.5}px;
		`}
`;

const AvailableForContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
`;

const FlagContainer = styled.div`
	display: flex;
	gap: 10px;
`;

export default Info;
