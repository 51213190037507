/* eslint-disable @typescript-eslint/no-explicit-any */
import { activeAppService } from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { hospyApi } from './hospyAPI';
import { IRequestPaymentSecret, ISavePayment } from './start.interfaces';

export const fetchApps = createAsyncThunk('start/fetchStatus', async () => {
	const accessToken = localStorage.getItem('Hospy-idToken') || '';
	const response = await hospyApi.fetchApps(accessToken);
	return response.data;
});

export const fetchApp = createAsyncThunk(
	'start/fetchApp',
	async (appId: string) => {
		const response = await hospyApi.fetchApp(appId);
		if (response?.data?.length > 0) return response.data[0];

		return response;
	}
);

export const activateApp = createAsyncThunk(
	'start/activateApp',
	async (appId: string, { getState, rejectWithValue }) => {
		const accessToken = localStorage.getItem('Hospy-idToken') || '';
		const state: any = getState();

		const response = await activeAppService(
			accessToken,
			state.user.propertyId,
			appId
		);
		if (response?.data) return true;

		return rejectWithValue(response);
	}
);

export const requestClientSecret = createAsyncThunk(
	'start/requestClientSecret',
	async (
		{
			request,
			appId
		}: {
			request: IRequestPaymentSecret;
			appId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await hospyApi.fetchClientSecret(request, appId);

			if (response.errors)
				return rejectWithValue(
					'No se pudo establecer la conexión con el provedor'
				);

			if (response.statusCode === 201) return response.data;

			return rejectWithValue(response.errors);
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const savePayment = createAsyncThunk(
	'start/savePayment',
	async (
		{ request, appId }: { request: ISavePayment; appId: string },
		{ getState, rejectWithValue }
	) => {
		const state: any = getState();
		const response = await hospyApi.postSavePayment(
			request,
			appId,
			state.user.propertyId
		);

		if (response.errors)
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);

		if (response.statusCode === 201) return response.data.payment;

		if (response.statusCode === 400)
			return rejectWithValue(response.message);

		return { error: response };
	}
);
