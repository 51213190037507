/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	RequestCreate,
	RequestFindAll,
	RequestRemove,
	RequestUpdate
} from '../requests';
import { Fetch } from '../fetch';

import { ResponseFailure, ResponseSuccess } from '../responses';

export const RequestFindAllLeads = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/common/customers/leads', params);

export const RequestUpdateLeads = (
	params: RequestUpdate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('put', 'api/common/customers/leads/:id', params);

export const RequestRemoveLeads = (
	params: RequestRemove
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('delete', 'api/common/customers/leads/:id', params);

export const RequestCreateLeadsImports = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/common/customers/leads/imports/:format', params, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});

export const RequestCreateLeadsNotifications = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/common/customers/leads/notifications/:type', params);
