/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetSchedulesProps } from './interface';
import { getSchedulesService } from '@hospy/util-api';

export const getSchedules = createAsyncThunk(
	'common/get-schedules',
	async (params: IGetSchedulesProps, { rejectWithValue }) => {
		try {
			const response = await getSchedulesService(params);
			if (response.errors) return rejectWithValue(response.errors);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response;
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);
