import { useAppSelector } from '@hospy/store';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { internalRoutes } from '../routes';
import moment from 'moment';

export const useValidateExpiredApps = () => {
	const navigate = useNavigate();
	const { user, propertyIntegrations, property } = useAppSelector(
		({ user }) => user
	);
	const { appData } = useAppSelector(({ common }) => common);
	const { pathname } = useLocation();

	useEffect(() => {
		validate();
	}, [propertyIntegrations?.length, pathname, property?._id, appData?._id]);

	const validate = useCallback(() => {
		if (
			internalRoutes.find((e) => pathname.match(new RegExp(e.path))) &&
			pathname !== 'expired' &&
			pathname !== '/expired' &&
			!pathname.includes('blocked')
		)
			return;

		if (propertyIntegrations === undefined || !appData || !user) return;

		const currentAppPropertyDetails = property?.apps?.find(
			(app) => app._id === appData._id
		);

		if (currentAppPropertyDetails?.subscription === 'block') {
			setTimeout(() => {
				navigate('/blocked');
			}, 100);
			return;
		}

		if (
			currentAppPropertyDetails?.subscription === 'subscribed' ||
			currentAppPropertyDetails?.subscription === 'lifetime'
		) {
			if (
				pathname === 'expired' ||
				pathname === '/expired' ||
				pathname.includes('blocked')
			)
				setTimeout(() => {
					navigate('/');
				}, 100);
			return;
		}

		const expirationDate = moment(
			moment(currentAppPropertyDetails?.expirationDate).format(
				'YYYY-MM-DD'
			)
		).hour(23);

		const today = moment().subtract(1, 'd').hour(0);

		const daysLocal = moment(moment(expirationDate)).diff(today, 'day');

		if (daysLocal <= 0) {
			setTimeout(() => {
				navigate('/expired');
			}, 100);
		} else {
			if (pathname === 'expired' || pathname === '/expired')
				setTimeout(() => {
					navigate('/');
				}, 100);
		}
	}, [propertyIntegrations?.length, pathname, property?._id, appData?._id]);

	return {
		validate
	};
};
