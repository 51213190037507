import { Fetch } from '../fetch';
import { RequestCreate, RequestFindAll } from '../requests';
import { ResponseFailure, ResponseSuccess } from '../responses';

export const ColppyValidateCredentials = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/third-party/colppy/auth/validate-credentials', params);

export const ColppyListCompanies = ( params: RequestCreate ): Promise<ResponseSuccess<any> | ResponseFailure> => //{data: {user: string, password: string, claveSesion: string}}
	Fetch('post', 'api/third-party/colppy/companies', params)

export const ColppyListResolutions = ( params: RequestCreate ): Promise<ResponseSuccess<any> | ResponseFailure> => //{data: {user: string, password: string, claveSesion: string, idEmpresa: string}}
	Fetch('post', 'api/third-party/colppy/resolutions', params)

export const ColppyIntegration = ( params: RequestCreate ): Promise<ResponseSuccess<any> | ResponseFailure> => //{data: {appId: string, propertyId: string, user: string, password: string, extras: any[]}}
	Fetch('post', 'api/third-party/colppy/integration', params)

export const ColppyGetTreasuries = ( params: RequestFindAll ): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/third-party/colppy/treasuries', params)

export const ColppyFetchGetDiaryAccounts = ( params: RequestFindAll ): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/third-party/colppy/diary-accounts', params)

export const ColppyFindClients = ( params: RequestFindAll ): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/third-party/colppy/clients', params)

export const ColppyCreateClient = ( params: RequestCreate ): Promise<ResponseSuccess<any> | ResponseFailure> => //{data: {appId: string, propertyId: string, generalData:{{idEmpresa, NombreFantasia, RazonSocial, CUIT, dni, DirPostal,DirPostalCiudad,DirPostalCodigoPostal, DirPostalProvincia, DirPostalPais, Telefono,  Email }}}}
	Fetch('post', 'api/third-party/colppy/clients', params)