import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { useDebounce } from '@hospy/shared-components';
import { Input, InputProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = InputProps & {
	onSearch: (search: string) => any;
};

export const SearchInput: FC<Props> = ({
	onSearch,
	defaultValue,
	value: v,
	...rest
}: Props) => {
	const [value, setValue] = useState(`${defaultValue || ''}`);
	const debouncedSearch = useDebounce(value, 500);

	useEffect(() => {
		if (!v) setValue('');
	}, [v]);

	useEffect(() => {
		onSearch(debouncedSearch);
	}, [debouncedSearch]);

	return (
		<SearchInputStyled
			name="search"
			type="text"
			prefix={<SearchOutlined />}
			size="large"
			onChange={(e) => setValue(e.target.value)}
			allowClear
			defaultValue={defaultValue}
			value={value}
			autoComplete="off"
			{...rest}
		/>
	);
};
export default SearchInput;

const SearchInputStyled = styled(Input)`
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 45px;

	.ant-input-prefix {
		margin-right: 15px;
	}

	&.ant-input {
		background-color: ${({ theme }) => theme.colors.gray.lighten['90%']};
	}
	&.ant-input-affix-wrapper-focused,
	.ant-input-affix-wrapper:focus {
		box-shadow: ${({ theme }) => theme.colors.primary.darken['10%']}87;
	}

	&.ant-input-affix-wrapper-focused,
	.ant-input-affix-wrapper:focus,
	&.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
		border-color: ${({ theme }) => theme.colors.primary.darken['10%']};
	}
	.anticon.anticon-close-circle {
		font-size: 21.5px;
		:hover {
			color: initial;
		}
	}
`;
