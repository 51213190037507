import { CreditsState } from './credits.interfaces';

export const initialCreditsState = {
	intent: {
		error: null,
		loading: 'idle'
	},
	payment: {
		error: null,
		loading: 'idle'
	},
	currentCredits: {
		error: null,
		loading: 'idle'
	},
	invoices: {
		error: null,
		loading: 'idle'
	},
	credits: {},
	updateCredits: {
		loading: 'idle'
	},
	creditInvoice: {
		loading: 'idle'
	}
} as CreditsState;
