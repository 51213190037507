/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { IDiscountValidation } from '../common/common.types';

import { hospyApi } from './hospyAPI';
import { ILandingPayment, IPayment } from './subscription.interfaces';

const stripErrorDictionary = {
	card_declined: 'La información de la tarjeta es inválida',
	'Your card was declined.': 'La información de la tarjeta es inválida'
};

export const fetchSubscriptionInfo = createAsyncThunk(
	'subscription/fetchSubscriptionInfo',
	async (payload, { rejectWithValue }) => {
		const response = await hospyApi.fetchSubscriptionInfo();
		if (response.statusCode === 400) {
			const { message: messageRaw } = response;
			let messageFormatted = messageRaw?.split(':');
			if (messageFormatted?.length > 0 && messageFormatted[1])
				messageFormatted = messageFormatted[1] as string;
			return rejectWithValue(messageFormatted.trim());
		}

		const parsedProperties = response.data.map((property: any) => ({
			...property,
			apps: property.apps.map((app: any) => ({
				...app,
				daysToExpire:
					moment(app.expirationDate)
						.hour(23)
						.diff(moment().subtract(1, 'd').hour(0), 'd') > 0
						? moment(app.expirationDate)
								.hour(23)
								.diff(moment().subtract(1, 'd').hour(0), 'd')
						: 0
			}))
		}));

		return parsedProperties;
	}
);

export const subscribe = createAsyncThunk(
	'subscription/subscribe',
	async (
		{
			frequency,
			properties
		}: {
			frequency: string;
			properties: any[];
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await hospyApi.fetchClientSecret(
				frequency,
				properties
			);

			if (response.errors)
				return rejectWithValue(
					'No se pudo establecer la conexión con el provedor'
				);

			if (response.statusCode === 201) return response.data;

			return rejectWithValue(response.errors);
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const makePayment = createAsyncThunk(
	'subscription/makePayment',
	async (
		{
			payment,
			invoiceId
		}: {
			payment: IPayment;
			invoiceId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const subscriptionDetail = JSON.parse(
				localStorage.getItem('subscriptionCustomer') || '{}'
			);
			localStorage.setItem(
				'subscriptionCustomer',
				JSON.stringify({
					...subscriptionDetail,
					tried: true
				})
			);

			const response = await hospyApi.postSavePayment(payment, invoiceId);
			if (response.statusCode === 400) {
				const { message: messageRaw } = response;
				let messageFormatted = messageRaw?.split(':');
				if (messageFormatted?.length > 0 && messageFormatted[1])
					messageFormatted = messageFormatted[0] as string;
				return rejectWithValue(
					stripErrorDictionary[
						messageFormatted.trim() as keyof typeof stripErrorDictionary
					]
				);
			}

			return response.data;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const tryPayment = createAsyncThunk(
	'subscription/tryPayment',
	async (
		{
			paymentMethod
		}: {
			paymentMethod: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await hospyApi.putTryPayment(paymentMethod);
			if (response.statusCode === 400) {
				const { message: messageRaw } = response;

				const splittedMessage = messageRaw?.split(':');

				const keyMessage =
					splittedMessage?.length > 0
						? splittedMessage[0].trim()
						: messageRaw;

				let finalMessage =
					stripErrorDictionary[
						keyMessage as keyof typeof stripErrorDictionary
					];

				if (!finalMessage) finalMessage = keyMessage;

				return rejectWithValue(finalMessage);
			}

			return response.data;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el proveedor'
			);
		}
	}
);

export const deleteActualPayment = createAsyncThunk(
	'subscription/deleteActualPayment',
	async (_, { rejectWithValue }) => {
		try {
			const response = await hospyApi.deleteActualPayment();
			if (response.statusCode === 400) {
				const { message: messageRaw } = response;
				let messageFormatted = messageRaw?.split(':');
				if (messageFormatted?.length > 0 && messageFormatted[1])
					messageFormatted = messageFormatted[0] as string;
				return rejectWithValue(
					stripErrorDictionary[
						messageFormatted.trim() as keyof typeof stripErrorDictionary
					]
				);
			}

			return response.data;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const validateDiscount = createAsyncThunk(
	'subscription/validateDiscount',
	async (
		{
			discount
		}: {
			discount: IDiscountValidation;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await hospyApi.validateDiscount(discount);
			if (response.statusCode === 400) {
				const { message: messageRaw } = response;
				let messageFormatted = messageRaw?.split(':');
				if (messageFormatted?.length > 0 && messageFormatted[1])
					messageFormatted = messageFormatted[0] as string;
				return rejectWithValue(
					`discount.error.${messageFormatted?.trim()}`
				);
			}

			if (response.statusCode !== 201)
				return rejectWithValue(`discount.error.base`);
			return response.data;
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const initLandingPayment = createAsyncThunk(
	'subscription/initLandingPayment',
	async (
		{
			email,
			name,
			invoiceId
		}: {
			email: string;
			name: string;
			invoiceId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await hospyApi.initLandingPaymentService(
				email,
				name,
				invoiceId
			);

			if (response.errors)
				return rejectWithValue(
					'No se pudo establecer la conexión con el provedor'
				);

			if (response.statusCode === 201) return response.data;

			return rejectWithValue(response.errors);
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const makeLandingPayment = createAsyncThunk(
	'subscription/makeLandingPayment',
	async (
		{
			payment
		}: {
			payment: ILandingPayment;
		},
		{ rejectWithValue }
	) => {
		try {
			try {
				const customerInfo = JSON.parse(
					localStorage.getItem('landingSubscribeCustomerResponse') ||
						'{}'
				);
				localStorage.setItem(
					'landingSubscribeCustomerResponse',
					JSON.stringify({
						...customerInfo,
						tried: true
					})
				);

				const response = await hospyApi.postLandingSavePayment(payment);
				if (response.statusCode === 400) {
					const { message: messageRaw } = response;
					let messageFormatted = messageRaw?.split(':');
					if (messageFormatted?.length > 0 && messageFormatted[1])
						messageFormatted = messageFormatted[0] as string;
					return rejectWithValue(
						stripErrorDictionary[
							messageFormatted.trim() as keyof typeof stripErrorDictionary
						]
					);
				}
				return response.data;
			} catch (error) {
				console.error('error');
				return rejectWithValue('El cobro no pudo ser iniciado');
			}
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);

export const validatePaymentTried = createAsyncThunk(
	'subscription/validatePaymentTried',
	async (email: string, { rejectWithValue }) => {
		try {
			try {
				const response = await hospyApi.getValidatePaymentTried(email);
				if (response.statusCode === 400) {
					const { message: messageRaw } = response;
					let messageFormatted = messageRaw?.split(':');
					if (messageFormatted?.length > 0 && messageFormatted[1])
						messageFormatted = messageFormatted[0] as string;
					return rejectWithValue(
						stripErrorDictionary[
							messageFormatted.trim() as keyof typeof stripErrorDictionary
						]
					);
				}
				return response.data;
			} catch (error) {
				console.error('error');
				return rejectWithValue('No se pudo validar la suscripción');
			}
		} catch (error) {
			return rejectWithValue(
				'No se pudo establecer la conexión con el provedor'
			);
		}
	}
);
