/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Check from '@2fd/ant-design-icons/lib/Check';
import Close from '@2fd/ant-design-icons/lib/Close'; //TODO: edit.svg
import Pencil from '@2fd/ant-design-icons/lib/Pencil';
import { LoadingOutlined } from '@ant-design/icons';
import {
	Input as InputAnt,
	InputNumber,
	Select,
	SelectProps,
	Spin,
	Switch,
	Typography
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Button from '../../atoms/button/button';

type SelectItem = {
	value: string;
	label: string;
};
export interface SectionEditorProps {
	type?: 'input' | 'select' | 'textarea' | 'switch';
	prefixIcon?: any;
	label?: string;
	styleLabel?: any;
	value: string | undefined;
	labelValue?: string | undefined;
	editMode?: boolean;
	editAction?: () => void;
	onCancel?: () => void;
	onEdited?: (value?: string) => void;
	hiddenEditIcon?: boolean;
	validating?: (value?: string) => string | Promise<string>;
	disabled?: boolean;
	loading?: boolean;
	selectItem?: SelectItem[];
	placeholder?: string;
	password?: boolean;
	selectProps?: SelectProps;
	style?: any;
	propsTextArea?: any;
	propsInput?: any;
	propsLabelValue?: any;
	onClickCheck?: any;
	customRender?: any;
	propsContent?: any;
	closeInput?: boolean;
	propsLabelError?: any;
	buttonProps?: any;
}

export const SectionEditor: React.FC<SectionEditorProps> = (props) => {
	const theme: any = useTheme();
	const {
		type = 'input',
		value,
		label,
		prefixIcon,
		editMode,
		editAction,
		onCancel,
		onEdited,
		hiddenEditIcon,
		validating,
		disabled,
		loading,
		selectItem,
		placeholder,
		password,
		selectProps,
		style,
		styleLabel,
		propsTextArea,
		onClickCheck,
		propsInput,
		propsLabelValue,
		customRender,
		propsContent,
		closeInput,
		labelValue,
		propsLabelError,
		buttonProps
	} = props;

	const ref = useRef<any>(null);
	const [v, set] = useState<string | undefined>(value);
	const [error, setError] = useState<string>();

	useEffect(() => {
		setError('');
	}, [v]);

	useEffect(() => {
		if (editMode) set(value);
	}, [editMode]);

	const handlerKeyDown = (e: any) => {
		if (e.key === 'Escape' && onCancel) {
			setError('');
			onCancel();
		}
		if (e.key === 'Enter' && !error) setValue();
	};

	const handlerOnBlur = (e: any) => {
		if (!error) setValue();
	};

	const setValue = async () => {
		if (onEdited) {
			let err = '';

			if (validating !== undefined) {
				err = await validating(v);
			}

			if (err) {
				setError(err);
				return;
			}

			setError('');
			onEdited(v);
		}
	};

	const handlerOnClick = (e: any) => {
		if (e.detail === 2 && editAction) {
			if (disabled) return;
			editAction();
		}
	};

	const antIcon = (
		<LoadingOutlined
			style={{ fontSize: 24, color: theme.colors.primary.base }}
			spin
		/>
	);

	const renderInput = () => {
		if (propsInput?.type === 'number')
			return (
				<InputNumber
					autoFocus
					size="middle"
					value={v}
					bordered={false}
					onChange={(e) => set(e?.toString() || '')}
					onBlur={handlerOnBlur}
					onKeyDown={handlerKeyDown}
					disabled={disabled}
					status={Boolean(error) ? 'error' : ''}
					textAlign={Boolean(label) ? 'right' : 'left'}
					placeholder={placeholder}
					{...propsInput}
				/>
			);

		return (
			<Input
				ref={ref}
				autoFocus
				size="middle"
				value={v}
				bordered={false}
				onChange={(e) => set(e.target.value)}
				onBlur={handlerOnBlur}
				onKeyDown={handlerKeyDown}
				disabled={disabled}
				status={Boolean(error) ? 'error' : ''}
				textAlign={Boolean(label) ? 'right' : 'left'}
				placeholder={placeholder}
				{...propsInput}
			/>
		);
	};

	const renderLabel = () => {
		let content: any = '';
		if (selectItem && selectItem.length > 0) {
			content = selectItem.find(
				(e) => e.value?.toString() === value?.toString()
			)?.label;
		} else {
			if (!value) content = placeholder;
			else content = password ? '****' : value;
		}

		if (
			content === value &&
			(selectItem || []).length === 0 &&
			labelValue
		) {
			content = labelValue;
		}

		return (
			<LabelValue
				style={{
					textAlign: Boolean(label) ? 'right' : 'left',
					...(!value && placeholder
						? {
								fontWeight: 'initial',
								color: theme.colors.gray.darken['10%']
						  }
						: {})
				}}
				typeEditor={type}
				onClick={handlerOnClick}
				flex={1}
				{...propsLabelValue}
			>
				{customRender ? customRender(content) : content}
			</LabelValue>
		);
	};

	return (
		<Container error={Boolean(error)} style={style}>
			<Content {...propsContent}>
				{label && <Label style={styleLabel}>{label}</Label>}
				{prefixIcon}
				{editMode ? (
					<>
						{type === 'select' && (
							<Select
								size="middle"
								value={v}
								options={selectItem || []}
								onBlur={handlerOnBlur}
								onChange={(value) => set(value)}
								autoFocus
								loading={(selectItem || []).length === 0}
								disabled={(selectItem || []).length === 0}
								{...selectProps}
								style={{ minWidth: 150, ...selectProps?.style }}
							/>
						)}
						{type === 'input' &&
							!(selectItem && selectItem.length > 0) &&
							renderInput()}
						{type === 'textarea' && (
							<Input.TextArea
								autoFocus
								size="middle"
								value={v}
								bordered={false}
								onChange={(e) => set(e.target.value)}
								onBlur={handlerOnBlur}
								onKeyDown={handlerKeyDown}
								disabled={disabled}
								status={Boolean(error) ? 'error' : ''}
								placeholder={placeholder}
								{...propsTextArea}
							/>
						)}
					</>
				) : (
					renderLabel()
				)}
			</Content>
			{editAction !== undefined &&
				!hiddenEditIcon &&
				!(type === 'textarea' && editMode && onClickCheck) && (
					<ButtonEdit
						onClick={
							closeInput && editMode
								? () => {
										setError('');
										if (onCancel) onCancel();
								  }
								: editAction
						}
						icon={
							closeInput && editMode ? (
								<Close
									style={{ ...buttonProps?.icon?.style }}
								/>
							) : (
								<Pencil
									style={{ ...buttonProps?.icon?.style }}
								/>
							)
						}
						size="small"
						type="text"
						shape="circle"
						disabled={
							loading ||
							(closeInput && editMode ? false : disabled) ||
							editMode
						}
						style={{
							...buttonProps?.style
						}}
					/>
				)}
			{type === 'textarea' && editMode && onClickCheck && (
				<ButtonEdit
					onClick={onClickCheck}
					icon={<Check />}
					size="small"
					type="text"
					shape="circle"
				/>
			)}
			{type === 'switch' && editMode && onClickCheck && (
				<Switch
					checkedChildren={placeholder ? placeholder : ''}
					unCheckedChildren={placeholder ? placeholder : ''}
					defaultChecked={v === 'true' ? true : false}
					onChange={onClickCheck}
				/>
			)}
			{loading && (
				<Spin
					indicator={antIcon}
					style={{
						position: 'absolute',
						zIndex: 1,
						right: 20,
						...buttonProps?.loading.style
					}}
				/>
			)}
			{error && editMode && (
				<LabelError {...propsLabelError}>{error}</LabelError>
			)}
		</Container>
	);
};

export default SectionEditor;

const LabelError = styled.span`
	color: ${({ theme }) => theme.colors.danger.base};
	position: absolute;
	left: 15px;
	bottom: 0;
	font-size: ${({ theme }) => theme.text.size.small}px;
`;

type ContainerProp = {
	error?: boolean;
};

const Container = styled.div<ContainerProp>`
	position: relative;
	display: flex;
	gap: 10px;
	background-color: ${({ theme }) => theme.colors.gray.lighten['70%']};
	align-items: center;
	padding: 5px 15px;
	position: relative;
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten['70%']};
	${({ error, theme }) =>
		error &&
		css`
			border-color: ${theme.colors.danger.base};
		`}
`;

interface ContentProps {
	justifyContent?: string;
	flexDirection?: string;
	alignItems?: string;
}

const Content = styled.div<ContentProps>`
	display: flex;
	justify-content: ${({ justifyContent }) =>
		justifyContent || 'space-between'};
	align-items: center;
	flex: 1 auto;
	min-height: ${({ theme }) => theme.input.height.large}px;
	gap: 15px;
	${({ theme, alignItems, flexDirection }) =>
		theme.screens.xs &&
		css`
			flex-direction: ${flexDirection || 'column'};
			align-items: ${alignItems || 'stretch'};
			gap: 5px;
			width: calc(100% - 45px);
		`}
`;

const Label = styled(Typography.Text)``;

type LabelValueProp = {
	typeEditor?: string;
	flex?: string;
	width?: string;
};

const LabelValue = styled(Label)<LabelValueProp>`
	${({ typeEditor }) =>
		css`
			font-weight: ${typeEditor !== 'textarea' ? 'bold' : 'none'};
		`}
	cursor: pointer;
	flex: ${({ flex }) => flex};
	${({ width }) =>
		width &&
		css`
			width: ${width};
		`};
	min-height: 22px;
	max-width: 100%;
`;

const ButtonEdit = styled(Button)`
	color: ${({ theme }) => theme.colors.primary.base};
	& svg {
		font-size: 20px;
	}
	&:hover {
		background-color: ${({ theme }) => theme.colors.primary.lighten['80%']};
		color: ${({ theme }) => theme.colors.primary.base};
	}
`;

type inputProps = {
	textAlign: 'right' | 'left';
};

const Input = styled(InputAnt)<inputProps>`
	min-width: 50%;
	flex: 1;
	text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
`;
