/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Async,
	cleanInvoice,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect } from 'react';

export interface Props {
	appId: string;
	partnerIntegrationId: string;
	internalAccounts: Async;
	getInternalAccounts: (payload: any) => any;
	setInternalAccounts: (payload: any) => any;
	actionCloseDetail?: (action: any) => any;
	hiddenButtonScheduleInvoice?: boolean;
	showCreateInvoiceButton?: boolean;
	currencyDisplay?: string;
	detailChildren?: (
		selectedInternalAccount: any,
		closeInvoiceDetail: any
	) => React.ReactElement | React.ReactElement[] | undefined;
	multipleInvoices?: boolean;
}

export const useInvoices = ({ internalAccounts, ...props }: Props) => {
	const dispatch = useAppDispatch();

	const { invoice } = useAppSelector(
		({ partnerAccounting }) => partnerAccounting
	);

	useEffect(() => {
		if (invoice.loading === 'succeeded')
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
	}, [invoice.loading]);

	const closeInvoiceDetail = () => {
		dispatch(cleanInvoice());
	};

	const actionCloseDetail = (action: any) => {
		if (props.actionCloseDetail) props.actionCloseDetail(() => action());
		else action();
	};

	const orderedInternalAccounts = [
		...(internalAccounts.data?.data || [])
	].sort((a: any, b: any) => {
		if (a.isFavorite && !b.isFavorite) return -1;
		if (!a.isFavorite && b.isFavorite) return 1;
		return 0;
	});

	return {
		loading: internalAccounts.loading === 'pending',
		internalAccounts: orderedInternalAccounts,
		closeInvoiceDetail,
		invoice: invoice.data,
		invoiceLoading: invoice.loading === 'pending',
		actionCloseDetail
	};
};
