/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	IReservationCheckInFilters,
	IReservationCheckOutFilters,
	createReservationDataService,
	deleteReservationDataService,
	deleteReservationGuestService,
	fetchReservationsSummaryCheckin,
	getReservationDetailService,
	getReservationSummaryService,
	getThirdPartyReservations,
	updateReservationDataService
} from '@hospy/util-api';
import { TpCreateReservationData } from './interfaces';

/**
 *
 */
export const getTpReservations = createAsyncThunk(
	'get-tp-reservations',
	async (
		parameters: IReservationCheckInFilters &
			IReservationCheckOutFilters & {
				page: number;
				search?: string;
				reservationStatus?: string;
			},
		{ rejectWithValue }
	) => {
		try {
			const { errors, data, meta }: any = await getThirdPartyReservations(
				parameters
			);

			if (errors) {
				return rejectWithValue(
					'Error al intentar cargar la información de lar reservas (Checkin)'
				);
			}

			return { data, meta, page: parameters.page };
		} catch (error: any) {
			console.error('getTpReservations: ', error);
			return rejectWithValue(
				'Error al intentar cargar la información de lar reservas (Checkin)'
			);
		}
	}
);

export const postTpReservations = createAsyncThunk(
	'post-tp-reservations',
	async (d: TpCreateReservationData, { rejectWithValue }) => {
		try {
			const { errors, data }: any = await createReservationDataService(d);
			if (errors) return rejectWithValue(errors);
			return data;
		} catch (error: any) {
			return rejectWithValue('Error al intentar crear la reserva');
		}
	}
);

export const getTpReservationsNoRegistered = createAsyncThunk(
	'get-tp-reservations-no-registered',
	async (
		parameters: IReservationCheckInFilters & IReservationCheckOutFilters,
		{ rejectWithValue }
	) => {
		try {
			const { errors, data }: any = await getThirdPartyReservations(
				parameters
			);

			if (errors) {
				return rejectWithValue(
					'Error al intentar cargar la información de lar reservas (Checkin)'
				);
			}

			return data;
		} catch (error: any) {
			console.error('getTpReservationsNoRegistered: ', error);
			return rejectWithValue(
				'Error al intentar cargar la información de lar reservas (Checkin)'
			);
		}
	}
);
/**
 *
 */
export const postTpReservationsResendReports = createAsyncThunk(
	'post-tp-reservations-resend-reports',
	async (parameters: IReservationCheckInFilters, { rejectWithValue }) => {
		try {
			const response: any = await getThirdPartyReservations(parameters);
			if (response.errors)
				return rejectWithValue(
					'Error al intentar cargar la información de lar reservas (Checkin)'
				);
			return response.data;
		} catch (error: any) {
			console.error('getTpReservations: ', error);
			return rejectWithValue(
				'Error al intentar cargar la información de lar reservas (Checkin)'
			);
		}
	}
);

export const findThirdPartyReservationCheckIn = createAsyncThunk(
	'third-party-reservations/findThirdPartyReservationCheckIn',
	async (parameters: IReservationCheckInFilters, { rejectWithValue }) => {
		try {
			const response: any = await getThirdPartyReservations(parameters);
			if (response.errors)
				return rejectWithValue(
					'Error al intentar cargar la información de lar reservas (Checkin)'
				);
			return response;
		} catch (error: any) {
			console.error('findThirdPartyReservationCheckIn: ', error);
			return rejectWithValue(
				'Error al intentar cargar la información de lar reservas (Checkin)'
			);
		}
	}
);

export const findThirdPartyReservationCheckOut = createAsyncThunk(
	'third-party-reservations/findThirdPartyReservationCheckOut',
	async (parameters: IReservationCheckOutFilters, { rejectWithValue }) => {
		try {
			const response: any = await getThirdPartyReservations(parameters);
			if (response?.errors)
				return rejectWithValue(
					'Error al intentar cargar la información de lar reservas (Checkout)'
				);
			return response;
		} catch (error: any) {
			console.error('findThirdPartyReservationCheckOut: ', error);
			return rejectWithValue(
				'Error al intentar cargar la información de lar reservas (Checkout)'
			);
		}
	}
);

export const getReservationDetail = createAsyncThunk(
	'third-party-reservations/getReservationDetail',
	async (
		{
			source,
			reservationId
		}: { source: 'sire' | 'tra'; reservationId: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getReservationDetailService(
				source,
				reservationId
			);
			if (errors)
				return rejectWithValue(
					'Error al intentar cargar el detalle de la reserva'
				);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar cargar el detalle de la reserva'
			);
		}
	}
);

export const getReservationSummary = createAsyncThunk(
	'third-party-reservations/getReservationSummary',
	async (
		{ propertyId, date }: { propertyId: string; date: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, errors } = await getReservationSummaryService(
				propertyId,
				date
			);
			if (errors)
				return rejectWithValue(
					'Error al intentar cargar el resumen de huéspedes'
				);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar cargar el resumen de huéspedes'
			);
		}
	}
);

export const updateReservation = createAsyncThunk(
	'third-party-reservations/updateReservation',
	async (
		payload: {
			reservationId: string;
			propertyId: string;
			partnerIntegrationId: string;
			appId: string;
			checkInDate: string;
			checkOutDate: string;
			total: number;
			adults?: number;
			status?: 'draft' | 'check-in' | 'check-out';
			roomId?: string;
			roomName?: string;
			currency?: string;
			phone?: string;
			holder?: string;
			countryId?: string;
			channelSlug?: string;
			channelName?: string;
			totalGuests?: number;
			email?: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const {
				holder,
				reservationId,
				propertyId,
				partnerIntegrationId,
				appId,
				checkInDate,
				checkOutDate,
				total,
				adults,
				roomId,
				roomName,
				status,
				countryId,
				channelSlug,
				channelName,
				currency,
				totalGuests,
				phone,
				email
			} = payload;

			const d: any = {
				checkInDate,
				checkOutDate,
				total
			};

			if (holder) {
				d.holder = holder;
			}

			if (countryId) {
				d.countryId = countryId;
			}

			if (roomId) {
				d.roomId = roomId;
			}

			if (roomName) {
				d.roomName = roomName;
			}

			if (channelSlug) {
				d.channelSlug = channelSlug;
			}

			if (channelName) {
				d.channelName = channelName;
			}

			if (status) {
				d.status = status;
			}

			if (adults) {
				d.adults = adults;
			}

			if (total) {
				d.total = total;
			}

			if (currency) {
				d.currency = currency;
			}

			if (totalGuests) {
				d.totalGuests = totalGuests;
			}

			if (phone) {
				d.phone = phone;
			}

			if (email) {
				d.email = email;
			}

			const { data, errors } = await updateReservationDataService(
				reservationId,
				{ propertyId, partnerIntegrationId, appId },
				d
			);

			if (errors)
				return rejectWithValue(
					'Error al intentar actualizar la reservación'
				);
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(
				'Error al intentar actualizar la reservación'
			);
		}
	}
);

export const deleteReservation = createAsyncThunk(
	'third-party-reservations/deleteReservation',
	async (
		payload: {
			reservationId: string;
			propertyId: string;
			partnerIntegrationId: string;
			appId: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const { reservationId, propertyId, partnerIntegrationId, appId } =
				payload;

			const { statusCode, errors } = (await deleteReservationDataService(
				reservationId,
				{ propertyId, partnerIntegrationId, appId }
			)) as any;

			if (statusCode === 200) return { reservationId };

			if (errors) {
				console.error('deleteReservation: ', { errors });
				return rejectWithValue('Error al eliminar la reservación');
			}

			return;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue('Error al intentar eliminar la reservación');
		}
	}
);

interface deleteReservationGuestProps {
	id: string;
	guestId: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}
export const deleteReservationGuest = createAsyncThunk(
	'third-party-reservations/deleteReservationGuest',
	async (props: deleteReservationGuestProps, { rejectWithValue }) => {
		try {
			const { id, guestId, propertyId, partnerIntegrationId, appId } =
				props;
			const { data, errors } = (await deleteReservationGuestService(
				id,
				guestId,
				{ propertyId, partnerIntegrationId, appId }
			)) as any;

			if (errors)
				return rejectWithValue('Error al eliminar la reservación');
			return data;
		} catch (error) {
			console.error(error);
			return rejectWithValue('Error al intentar eliminar la reservación');
		}
	}
);

export const getReservationsSummaryCheckin = createAsyncThunk(
	'partner-reservations/getReservationsSummaryCheckin',
	async (
		{ appId, partnerIntegrationId }: any,
		{ rejectWithValue, getState }
	) => {
		try {
			const state: any = getState();
			const { statusCode, data } = await fetchReservationsSummaryCheckin({
				partnerIntegrationId,
				appId,
				propertyId: state.user.propertyId
			});
			if (statusCode !== 200)
				return rejectWithValue(
					'No se pudo obtener el resumen de las reservas'
				);

			return data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue('Error');
		}
	}
);
