import { maxDevice } from '@hospy/feature/devices';
import { Button as HospyBtn } from '@hospy/hospy-ui';
import {
	FacebookIconSvg,
	LanguageSelector,
	LinkedinIconSvg,
	MapSvg,
	YoutubeIconSvg
} from '@hospy/shared-components';
import { latamCountriesIso2 } from '@hospy/store';
import { Typography } from 'antd';
import { CircleFlag } from 'react-circle-flags';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const Footer = () => (
	<FooterWrapper>
		<FirstSection>
			<PolicySection>
				<HospyBtn href="https://soporte.hospy.co/hc/es-419/articles/12439676635668-T%C3%A9rminos-y-condiciones-de-Hospy" target='_blank'>
					<Typography.Text>
						<FormattedMessage
							id="home.main-footer.terms-and-conditions"
							defaultMessage="Términos y condiciones"
						/>
					</Typography.Text>
				</HospyBtn>
				<HospyBtn href="https://soporte.hospy.co/hc/es-419/articles/12439676585236-Pol%C3%ADtica-de-privacidad-de-Hospy" target='_blank'>
					<Typography.Text>
						<FormattedMessage
							id="home.main-footer.privacy-Policy"
							defaultMessage="Política de privacidad"
						/>
					</Typography.Text>
				</HospyBtn>
				<HospyBtn href="https://soporte.hospy.co" target='_blank'>
					<Typography.Text>
						<FormattedMessage
							id="home.main-footer.help-Center"
							defaultMessage="Centro de ayuda"
						/>
					</Typography.Text>
				</HospyBtn>
			</PolicySection>
			<SocialSection>
				<SocialIcons>
					<HospyBtn
						target="__blank"
						href="https://www.linkedin.com/company/hospysolutions/"
					>
						<LinkedinIconSvg />
					</HospyBtn>
					<HospyBtn target="__blank" href="">
						<YoutubeIconSvg />
					</HospyBtn>
					<HospyBtn target="__blank" href="">
						<FacebookIconSvg />
					</HospyBtn>
				</SocialIcons>
				<LanguageSelector style={{ color: '#000' }} />
			</SocialSection>
		</FirstSection>
		<SecondSection>
			<MadeWithHeart>
				<FormattedMessage
					id="home.main-footer.made-with"
					defaultMessage="Hecho con"
				/>
				<img
					src="https://cdn.hospy.co/images/434-blue-heart.svg"
					alt="Heart"
				/>
				<FormattedMessage
					id="home.main-footer.by-latam"
					defaultMessage="para latam"
				/>
				:
			</MadeWithHeart>
			<CountryList>
				{latamCountriesIso2.map((flag) => (
					<CircleFlag
						key={`Flag-key-${flag}`}
						height={20}
						countryCode={flag.toLowerCase()}
					/>
				))}
			</CountryList>
			<CountryMap>
				<MapSvg />
			</CountryMap>
		</SecondSection>
	</FooterWrapper>
);

const FooterWrapper = styled.div`
	width: 1280px;
	margin: auto;
	min-height: 120px;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	margin-bottom: 40px;
	gap: 30px;
	div:nth-child(2) {
		display: flex;
		justify-content: center;
	}
	@media (max-width: 1300px) {
		width: 96vw;
	}
	@media ${maxDevice.mobileL} {
		width: 95vw;
	}
`;

const FirstSection = styled.div`
	border-bottom: 1px solid #ebebeb;
	display: flex;
	justify-content: space-between;
	min-height: 80px;
	padding-bottom: 20px;
	@media ${maxDevice.tablet} {
		margin-top: 20px;
		flex-direction: column;
		gap: 20px;
	}
`;

const PolicySection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 50%;
	@media (max-width: 1080px) {
		width: 60%;
	}
	@media ${maxDevice.tablet} {
		margin-top: 20px;
		flex-direction: column;
		gap: 10px;
		width: 100%;
		justify-content: center;
	}
	a {
		border: none;
		padding: 0;
		height: auto;
	}
`;

const SocialSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	button {
		display: flex;
		gap: 10px;
		border: none;
	}
	@media ${maxDevice.tablet} {
		justify-content: center;
		flex-direction: column-reverse;
	}
`;

const SecondSection = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 120px;
	align-items: center;
	flex-direction: column;
	gap: 20px;
	@media ${maxDevice.tablet} {
		margin-top: 30px;
	}
`;

const MadeWithHeart = styled.div`
	display: flex;
	gap: 8px;
	img {
		width: 20px;
	}
`;

const CountryList = styled.div`
	@media ${maxDevice.laptop} {
		display: none !important;
	}
	img {
		width: 40px;
	}
`;

const CountryMap = styled.div`
	@media (min-width: 1025px) {
		display: none !important;
	}
	img {
		width: 50px;
	}
`;

const SocialIcons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${maxDevice.mobileL} {
		gap: 30px;
	}
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		padding: 0 10px 11px 10px;
	}
	svg {
		fill: #a7a7a7;
	}
	svg:hover {
		fill: ${({ theme }) => theme.colors.primary.base};
	}
	> a:nth-child(2) {
		margin-top: 5px;
	}
	@media ${maxDevice.mobileL} {
		svg {
			fill: ${({ theme }) => theme.colors.primary.base};
		}
	}
`;

export default Footer;
