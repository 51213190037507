import { Button, Typography } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import useErrorView, { useErrorViewProps } from './use-error-view';

export interface ErrorViewProps extends useErrorViewProps {
	type: 'full' | 'details' | 'text';
}
const ErrorView: React.FC<ErrorViewProps> = ({ type, ...props }) => {
	// const intl = useIntl();
	const [open, setOpen] = useState(false);

	const { title, description, errors } = useErrorView({ ...props });

	const handlerToggle = () => {
		setOpen(!open);
	};

	if (type === 'text')
		return (
			<>
				<b>{title}:</b> {description}
			</>
		);

	return (
		<Container full={type === 'full'}>
			<Title>{title ? title : 'Error'}</Title>
			{description && <Description>{description}</Description>}
			{errors && (
				<Footer style={{ textAlign: 'right' }}>
					{type !== 'full' && errors && (
						<BtnDanger type="link" onClick={handlerToggle}>
							{!open ? (
								<FormattedMessage
									id="see.more"
									defaultMessage={'Ver más'}
								/>
							) : (
								<FormattedMessage
									id="see.less"
									defaultMessage={'Ver menos'}
								/>
							)}
						</BtnDanger>
					)}

					{(open || type === 'full') && errors && (
						<ErrorDetail>
							{Object.keys(errors).map((key) => (
								<div key={key}>
									<b>{key}</b>
									<ul>
										{errors[key].map((e: string) => (
											<li>{e}</li>
										))}
									</ul>
								</div>
							))}
						</ErrorDetail>
					)}
				</Footer>
			)}
		</Container>
	);
};
export default ErrorView;

const ErrorDetail = styled.div`
	text-align: left;
	padding: 15px 30px;
	background-color: ${({ theme }) => theme.colors.danger.lighten['80%']};
	margin-top: 15px;
`;

interface ContainerProps {
	full?: boolean;
}
const Container = styled.div<ContainerProps>`
	border: 1px solid;
	border-color: ${({ theme }) => theme.colors.danger.lighten['10%']};
	background-color: ${({ theme }) => theme.colors.danger.lighten['90%']};
	padding: 15px;
	${({ full }) =>
		full &&
		css`
			padding: 0;
			border: 0;
			background-color: initial;
			${ErrorDetail} {
				background-color: ${({ theme }) =>
					theme.colors.danger.lighten['90%']};
			}
		`}
`;

const Title = styled(Typography.Text)`
	display: block;
	font-size: ${({ theme }) => theme.title.size.middle}px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.danger.darken['50%']};
	margin-bottom: 15px;
`;

const Description = styled(Typography.Text)`
	display: block;
	font-size: ${({ theme }) => theme.text.size.middle}px;
	color: ${({ theme }) => theme.colors.danger.darken['50%']};
`;

const Footer = styled.div`
	margin-top: 15px;
`;

const BtnDanger = styled(Button)`
	height: auto;
	padding: 0px;
	color: ${({ theme }) => theme.colors.danger.darken['50%']};
	&:hover,
	&:active,
	&:focus {
		color: ${({ theme }) => theme.colors.danger.base};
	}
`;
