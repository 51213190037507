/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Icon, Modal } from '@hospy/hospy-ui';
import { Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';
import { PARTNER } from '@hospy/store';

interface Props {
	open: boolean;
	onClose: () => void;
}

const FilterModal = ({ open, onClose }: Props) => {
	const {
		updateAdditionalFilters,
		selectedAdditionalFilters,
		partnerIntegrationId
	} = useContext(InvoiceFilterContext) as InvoiceFilterContextType;

	const [selectedFilter, setSelectedFilters] = useState<string[]>([]);

	useEffect(() => {
		setSelectedFilters(selectedAdditionalFilters);
	}, [selectedAdditionalFilters]);

	const selectFilter = (filter: string, pair?: string) => {
		const _additionalFilters = [...selectedFilter];
		if (_additionalFilters.includes(filter)) {
			_additionalFilters.splice(
				_additionalFilters.findIndex((_filter) => filter === _filter),
				1
			);
			setSelectedFilters(_additionalFilters);
			return;
		}
		if (pair) {
			const toInactive = _additionalFilters.findIndex(
				(_filter) => _filter === pair
			);
			if (toInactive > -1) _additionalFilters.splice(toInactive, 1);
		}
		_additionalFilters.push(filter);

		setSelectedFilters(_additionalFilters);
	};

	const applySelectedFilters = () => {
		updateAdditionalFilters(selectedFilter);
	};

	const unSelectFilters = () => {
		setSelectedFilters([]);
	};

	return (
		<Modal open={open} onClose={() => {}} closable={false}>
			<Title>Filtrar reservas:</Title>
			<Content>
				<Subtitle>Selecciona uno o varios filtros:</Subtitle>
				<ButtonSection>
					{partnerIntegrationId === PARTNER.CLOUDBEDS && (
						<>
							<RadioButton
								isSelected={selectedFilter.includes(
									'checked_in'
								)}
								onClick={() => {
									selectFilter('checked_in');
								}}
							>
								Reservas In-House
							</RadioButton>

							<RadioButton
								isSelected={selectedFilter.includes(
									'checked_out'
								)}
								onClick={() => {
									selectFilter('checked_out');
								}}
							>
								Reservas con check-out realizado
							</RadioButton>
						</>
					)}

					<RadioButton
						isSelected={selectedFilter.includes('accepted')}
						onClick={() => {
							selectFilter('accepted', 'not-billed');
						}}
					>
						Solo reserva facturadas
					</RadioButton>
					<RadioButton
						isSelected={selectedFilter.includes('not-billed')}
						onClick={() => {
							selectFilter('not-billed', 'accepted');
						}}
					>
						Solo reservas sin facturar
					</RadioButton>

					{partnerIntegrationId === PARTNER.AIRBNB && (
						<>
							<RadioButton
								isSelected={selectedFilter.includes(
									'totalImported'
								)}
								onClick={() => {
									selectFilter(
										'totalImported',
										'not-totalImported'
									);
								}}
							>
								Sincronizada con archivo
							</RadioButton>
							<RadioButton
								isSelected={selectedFilter.includes(
									'not-totalImported'
								)}
								onClick={() =>
									selectFilter(
										'not-totalImported',
										'totalImported'
									)
								}
							>
								No sincronizadas con archivo
							</RadioButton>
							<RadioButton
								isSelected={selectedFilter.includes('cop')}
								onClick={() => selectFilter('cop', 'usd')}
							>
								Reservas en COP
							</RadioButton>
							<RadioButton
								isSelected={selectedFilter.includes('usd')}
								onClick={() => selectFilter('usd', 'cop')}
							>
								Reservas en USD
							</RadioButton>
						</>
					)}
				</ButtonSection>
				<Footer>
					<DisableFilters onClick={unSelectFilters} type="text">
						<Icon materialOutlined="sync" />
						Desactivar todos los filtros
					</DisableFilters>
					<Button
						shape="round"
						onClick={() => {
							applySelectedFilters();
							onClose();
						}}
						type="primary"
					>
						Guardar
					</Button>
				</Footer>
			</Content>
		</Modal>
	);
};

const Title = styled(Typography.Text)`
	display: flex;
	font-size: ${({ theme }) => theme.title.size.middle}px;
	border-bottom: 1px solid black;
	width: 100%;
	margin-bottom: 30px;
`;

const Subtitle = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.small}px;
`;

const RadioButton = styled(({ isSelected, ...props }) => <Button {...props} />)`
	${({ isSelected, theme }) =>
		isSelected &&
		css`
			background-color: ${theme.colors.primary.base};
			color: white;
			&.ant-btn:hover:not(:disabled),
			&.ant-btn:active:not(:disabled),
			&.ant-btn:focus:not(:disabled) {
				color: white;
				background-color: ${theme.colors.primary.base};
			}
		`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
`;

const ButtonSection = styled.div`
	display: flex;
	gap: 20px;
	max-width: 100%;
	flex-wrap: wrap;
	> button {
		width: 48%;
	}
`;

const DisableFilters = styled(Button)`
	display: flex;
	gap: 10px;
	> svg {
		font-size: 20px;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
`;

export default FilterModal;
