import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Container } from '../../UI/template/account';
import RegisterForm from './components/register-form';
import useRegister from './hook/use-register';

export const Register = () => {
	const { isAuth, ...props } = useRegister();
	const location = useLocation();

	const navigatePathname = useMemo(() => {
		const state = location.state as { from: any };
		if (state && state.from) return state.from?.pathname;
		return '/';
	}, [location]);

	if (isAuth) return <Navigate to={navigatePathname} replace />;

	return (
		<Container>
			<Row align="middle">
				<Col xs={24} sm={24} lg={9}>
					<RegisterForm {...props} />
				</Col>
				<Col xs={24} sm={24} lg={15}>
					{/* <Info /> */}
				</Col>
			</Row>
		</Container>
	);
};

export default Register;
