/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import CreditView from './credit-view';
import { AppData, IInternalAccount, IReservationInvoice } from '@hospy/store';
import { Select } from 'antd';
import { AVAILABLE_SCREENS } from '..';

interface ScreenHeader {
	app?: AppData;
	screen: string;
	multipleScreenMode: boolean;
	selectScreen: (screen: string) => void;
	selectedDetail: IReservationInvoice | IInternalAccount | null;
}

const ScreenHeader = ({
	app,
	screen,
	selectScreen,
	selectedDetail,
	multipleScreenMode
}: ScreenHeader) => {
	return (
		<Header>
			{app?.creditFees && (
				<CreditView mode={selectedDetail ? 'detail' : 'full'} />
			)}
			{multipleScreenMode && (
				<SelectScreen
					options={AVAILABLE_SCREENS}
					value={screen}
					onSelect={(value: any) => selectScreen(value as string)}
				/>
			)}
			{!multipleScreenMode && <Title>Facturar reservas</Title>}
		</Header>
	);
};

export default ScreenHeader;

const Title = styled.span`
	font-size: ${({ theme }) => theme.title.size.large}px;
	font-weight: bold;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-between;

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: none;
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}
`;

const SelectScreen = styled(Select)`
	font-size: ${({ theme }) => theme.title.size.large}px;
	font-weight: bold;
	width: 360px;
`;
